define("bocce/models/item", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/models/item.js
  var _default = _model.default.extend({
    db_id: (0, _model.attr)('number'),
    title: (0, _model.attr)('string'),
    content: (0, _model.attr)('string'),
    lesson: (0, _model.belongsTo)('lesson', {
      async: false
    }),
    type: (0, _model.attr)('string'),
    date: (0, _model.attr)('date'),
    url: (0, _model.attr)(),
    read: (0, _model.attr)(),
    page: (0, _model.belongsTo)('page'),
    showcaseEdit: (0, _model.attr)('boolean'),
    showBookmark: (0, _model.attr)('boolean'),
    // read: computed.oneWay('read'),
    pastDue: Ember.computed('date', function () {
      var d = this.date;
      return d && d < new Date();
    }),
    // This should really be a controller property, but
    // it's insane to create a controller for every single
    // item in the syllabus.
    formattedDate: Ember.computed('date', function () {
      var d = this.date;

      if (!d) {
        return '';
      }

      return moment(d).tz('America/New_York').calendar();
    }),
    isDiscussion: Ember.computed.equal('type', 'Discussion'),
    isPage: Ember.computed.equal('type', 'Page'),
    isAssignment: Ember.computed.equal('type', 'Assignment'),
    cssString: Ember.computed('id', function () {
      return CSS.escape(this.id);
    })
  });

  _exports.default = _default;
});