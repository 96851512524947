define("bocce/utilities/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function logout() {
    var body = document.querySelector('body'),
        form = document.createElement('form');
    form.setAttribute('action', '/logout');
    form.setAttribute('method', 'POST');
    form.innerHTML = '<input type="hidden" name="_method" value="delete">';
    body.appendChild(form);
    form.submit();
  }

  var _default = logout;
  _exports.default = _default;
});