define("bocce/templates/bookmarks-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0jQ9LWuc",
    "block": "{\"symbols\":[\"topic\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"clear-backdrop \",[34,2,[\"code\"]]]]],[4,[38,1],[[32,0],\"closeMenu\"],[[\"bubbles\"],[false]]],[12],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"bookmarks-menu menu \",[34,2,[\"code\"]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"bookmarks-menu-header\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"menu-title\"],[12],[2,\"\\n      Bookmarks for \"],[1,[35,2,[\"title\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[11,\"div\"],[24,0,\"create-bookmark hidden\"],[4,[38,1],[[32,0],\"createBookmark\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n      Create Bookmark\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"bookmarks-list\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[14,0,\"bookmark\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"bookmark-details\"],[12],[2,\"\\n          \"],[1,[35,0,[\"message\"]]],[2,\" \"],[10,\"div\"],[14,0,\"bookmark-link\"],[12],[1,[35,0,[\"link_text\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[11,\"div\"],[24,0,\"bookmark-remove\"],[24,\"aria-label\",\"Remove Bookmark\"],[4,[38,1],[[32,0],\"removeBookmark\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fas fa-times-circle\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"      \"],[10,\"li\"],[14,0,\"class-divider\"],[12],[2,\"\\n        No Bookmarks.\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"notification\",\"action\",\"data\",\"topics\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "bocce/templates/bookmarks-menu.hbs"
    }
  });

  _exports.default = _default;
});