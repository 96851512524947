define("bocce/templates/components/bookmarks/bookmark-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M52UQHzR",
    "block": "{\"symbols\":[\"@bookmark\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"bookmark-item-note\",[30,[36,1],[[32,1,[\"notesMoreShowing\"]],\" more-showing\"],null],\" \"]]],[12],[1,[32,1,[\"notes\"]]],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"noteClipped\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"notesMoreShowing\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[11,\"div\"],[24,0,\"bookmark-item-notes-show-more-less button\"],[4,[38,0],[[32,0],\"setNotesMoreShowing\",[32,1],false],null],[12],[2,\"Show Less\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[11,\"div\"],[24,0,\"bookmark-item-notes-show-more-less button\"],[4,[38,0],[[32,0],\"setNotesMoreShowing\",[32,1],true],null],[12],[2,\"Show All\"],[13],[2,\" \\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/bookmarks/bookmark-note.hbs"
    }
  });

  _exports.default = _default;
});