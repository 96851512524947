define("bocce/templates/interactions/timedwriting/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "15cvhnOK",
    "block": "{\"symbols\":[\"tab\",\"tab\"],\"statements\":[[10,\"div\"],[14,0,\"tabs\"],[12],[2,\"\\n  \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[2,[32,2,[\"linkHtml\"]]],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,1,[31,[[32,1,[\"id\"]]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"isSummaryTab\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"summary-container\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"question-container\"],[12],[2,\"\\n          \"],[2,[32,1,[\"question\"]]],[2,\"\\n          \"],[2,[32,1,[\"question_node\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"feedback hidden\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"buttons-row cf\"],[12],[2,\"\\n          \"],[10,\"button\"],[14,0,\"start-timer\"],[12],[2,\"Start\"],[13],[2,\"\\n          \"],[10,\"button\"],[14,0,\"reset\"],[12],[2,\"Reset\"],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"time-container\"],[12],[2,\"Time: \"],[10,\"span\"],[14,0,\"time\"],[12],[13],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n          \"],[2,[32,1,[\"textareaHtml\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"tabs\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "bocce/templates/interactions/timedwriting/main.hbs"
    }
  });

  _exports.default = _default;
});