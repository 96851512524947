define("bocce/controllers/classroom/lessons/submission", ["exports", "bocce/mixins/editable", "bocce/mixins/assignments", "bocce/mixins/uploadable", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/enmodal", "bocce/mixins/user-profile", "bocce/mixins/boot", "bocce/mixins/discussable", "bocce/utilities/dialog"], function (_exports, _editable, _assignments, _uploadable, _audioRec, _videoRec, _rtcRec, _enmodal, _userProfile, _boot, _discussable, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */
  var _default = Ember.Controller.extend(_enmodal.default, _editable.default, _assignments.default, _uploadable.default, _audioRec.default, _videoRec.default, _rtcRec.default, _userProfile.default, _boot.default, _discussable.default, {
    userprofile: Ember.inject.controller(),
    // required by UserProfileMixin
    discussions: Ember.inject.controller(),
    // required by EditableMixin
    lessons: Ember.inject.controller('classroom.lessons'),
    // required by UploadableMixin
    classroom: Ember.inject.controller(),
    // required by UploadableMixin and EnmodalMixin

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    assignments: Ember.computed(function () {
      var _this = this;

      return this.store.findAll('assignment').then(function () {
        _this.bootContent();
      });
    }),
    isWorking: false,
    bootContent: function bootContent() {
      /* eslint-disable-next-line ember/no-jquery */
      var node = Ember.$('.submission-body');

      if (node && node.length > 0) {
        this.boot_area(node, false, true, false, false, true);
      }
    },
    actions: {
      deleteHistoricSubmission: function deleteHistoricSubmission(attemptNum, historicAttachments, userId) {
        var _this2 = this;

        var message = 'Are you sure you wish to delete this past submission? This action CANNOT be undone!';
        (0, _dialog.default)(message, ["Yes. But, before you click this button, first perform any manual file deletion in S3 and Canvas (in that order). submission db id: ".concat(submissionDbId), 'No']).then(function (choice) {
          if (choice.indexOf('Yes') === 0) {
            /* eslint-disable-next-line ember/no-get */
            var sectionId = _this2.get('session.section.id');
            /* eslint-disable-next-line ember/no-get */


            var assignmentId = _this2.get('openAssignmentView.assignment.content.id').split('-')[0];
            /* eslint-disable-next-line ember/no-get */


            var _submissionDbId = _this2.get('openAssignmentView.db_id');

            var self = _this2;
            Ember.$.post("/interface/sections/".concat(sectionId, "/assignments/").concat(assignmentId, "/submissions/").concat(_submissionDbId, "/delete_historic_submission"), JSON.stringify({
              attempt: attemptNum,
              userId: userId,
              attachments: historicAttachments ? historicAttachments.map(function (ha) {
                return ha.id;
              }).join(',') : []
            }), function () {
              var submission = self.submissions.find(function (item) {
                return item.id = _submissionDbId && item.attempt === attemptNum;
              });
              self.submissions.removeObject(submission);
            }).fail(function () {
              (0, _dialog.default)('Problem occurred deleting historic submission. Please reload the page and try again.');
            });
          }
        });
      },
      deleteAssignmentComment: function deleteAssignmentComment(postID) {
        (false && !(Boolean(postID)) && Ember.assert('postID should exist', Boolean(postID)));
        /* eslint-disable-next-line ember/no-get */

        var section = this.get('session.section.id');
        /* eslint-disable-next-line ember/no-get */

        var assignment = this.get('openAssignmentView.assignment.content.id').split('-')[0];
        /* eslint-disable-next-line ember/no-get */

        var submission = this.get('openAssignmentView.id');
        (false && !(section) && Ember.assert('section id exists', section));
        (false && !(assignment) && Ember.assert('assignment id exists', assignment));
        (false && !(submission) && Ember.assert('submission id exists', submission));
        this.store.nestResources('comment', [{
          section: section
        }, {
          assignment: assignment
        }, {
          submission: submission
        }]);
        this.store.findRecord('comment', postID).then(function (record) {
          record.set('is_deleted', true);
          record.save();
        });
      },
      deleteSubmissionAttachment: function deleteSubmissionAttachment(sectionId, submissionDbId, submissionId, assignmentDbId, userId, attachmentId) {
        var _this3 = this;

        var message = 'Are you sure you wish to delete this attachment? This action CANNOT be undone!';
        var self = this;
        (0, _dialog.default)(message, ["Yes. But, before you click this button, first perform any manual file deletion in S3 and Canvas (in that order). attachment id: ".concat(attachmentId), 'No']).then(function (choice) {
          if (choice.indexOf('Yes') === 0) {
            var destroy = false;
            self.set('isWorking', true);

            _this3.store.findRecord('attachment', attachmentId).then(function (record) {
              _this3.store.nestResources('attachment', [{
                section: sectionId
              }, {
                assignment: assignmentDbId
              }, {
                submission: submissionDbId
              }, {
                user: userId
              }, {
                destroy: destroy
              }]);

              try {
                record.destroyRecord().then(function () {
                  if (submissionId) {
                    _this3.store.findRecord('submission', submissionId).then(function (record) {
                      var currentAttachmentIds = record.get('attachments');
                      record.set('attachments', currentAttachmentIds.filter(function (a) {
                        return a != attachmentId;
                      }));
                      self.set('isWorking', false);
                    });
                  } else {
                    self.set('isWorking', false); //submissionId is not defined if we are deleting an attachment for submission history
                  }

                  _this3.store.nestResources('attachment', []);
                }).catch(function () {
                  (0, _dialog.default)('Problem occurred deleting attachment. Please reload the page and try again.');
                  self.set('isWorking', false);
                });
              } catch (e) {
                (0, _dialog.default)('Problem occurred deleting attachment. Please reload the page and try again.');
                self.set('isWorking', false);
              }
            });
          }
        });
      },
      deleteSubmissionCommentAttachment: function deleteSubmissionCommentAttachment(sectionId, commentId, commentDbId, assignmentId, userId, attachmentId) {
        var _this4 = this;

        var message = 'Are you sure you wish to delete this attachment? This action CANNOT be undone!';
        var self = this;
        (0, _dialog.default)(message, ["Yes. Soft delete.", "Yes. Leave no trace. But, before you click this button, permanent deletion in S3 must be performed manually. Attachment id: ".concat(attachmentId), 'No']).then(function (choice) {
          if (choice.indexOf('Yes') === 0) {
            var destroy = choice === 'Yes. Leave no trace.';
            self.set('isWorking', true);

            _this4.store.findRecord('attachment', attachmentId).then(function (record) {
              _this4.store.nestResources('attachment', [{
                section: sectionId
              }, {
                assignment: assignmentId
              }, {
                comment: commentDbId
              }, {
                user: userId
              }, {
                destroy: destroy
              }]);

              try {
                record.destroyRecord().then(function () {
                  _this4.store.findRecord('comment', commentId).then(function (record) {
                    var currentAttachmentIds = record.get('attachments');
                    record.set('attachments', currentAttachmentIds.filter(function (a) {
                      return a != attachmentId;
                    }));
                    self.set('isWorking', false);
                  });

                  _this4.store.nestResources('attachment', []);
                }).catch(function () {
                  (0, _dialog.default)('Problem occurred deleting attachment. Please reload the page and try again.');
                  self.set('isWorking', false);
                });
              } catch (e) {
                (0, _dialog.default)('Problem occurred deleting attachment. Please reload the page and try again.');
                self.set('isWorking', false);
              }
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});