define("bocce/controllers/classroom/lessons/announcement-new", ["exports", "bocce/controllers/classroom/lessons/discussion-new"], function (_exports, _discussionNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discussionNew.default.extend({
    isAnnouncement: true,
    activeNewDiscussion: 'announcement'
  });

  _exports.default = _default;
});