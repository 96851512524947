define("bocce/components/side-panel/panel-list-item/assignment-icon", ["exports", "bocce/components/side-panel/panel-list-item/icon"], function (_exports, _icon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _icon.default.extend({
    session: Ember.inject.service(),
    iconClass: Ember.computed('session.isInstructor', 'item.currentUserSubmission', function () {
      /* eslint-disable-next-line ember/no-get */
      if (this.get('item.currentUserSubmission')) {
        return 'fas fa-check';
      }

      return 'fas fa-pencil';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    grade: Ember.computed('item.{currentUserSubmission,grade_matches_current_submission}', function () {
      /* eslint-disable-next-line ember/no-get */
      if (this.get('item.currentUserSubmission') &&
      /* eslint-disable-next-line ember/no-get */
      this.get('item.grade_matches_current_submission')) {
        /* eslint-disable-next-line ember/no-get */
        return this.get('item.currentUserSubmissionGrade');
      }

      return null;
    })
  });

  _exports.default = _default;
});