define("bocce/mixins/interactions/reveal_content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function RevealContent(el) {
    // TODO: remove this check once boot.js no longer has jquery
    if (el instanceof Ember.$) {
      el = el.get(0);
    }

    this.el = el;
    this.interactionData = el.querySelector('.interaction_data');
    this.container = el.querySelector('.interaction_content');
  }

  RevealContent.prototype = {
    init: function init() {
      var hiddenContent = this.interactionData.querySelectorAll('td')[1];
      hiddenContent.remove();
      var hiddenContentDiv = document.createElement('div');
      hiddenContentDiv.append(hiddenContent);
      hiddenContentDiv.style.display = 'none';
      var initialContent = this.interactionData.querySelector('td:nth-of-type(1)');
      initialContent.remove();
      var initialContentDiv = document.createElement('div');
      initialContentDiv.classList.add('pointer');
      initialContentDiv.append(initialContent);
      var closeButton = document.createElement('button');
      closeButton.classList.add('close');
      closeButton.setAttribute('aria-label', 'Open');
      closeButton.innerHTML = '<i class="fas fa-plus" aria-hidden />';
      initialContentDiv.append(closeButton);
      this.container = this.container.parentNode.querySelector('.RevealContent');
      this.container.append(initialContentDiv);
      this.container.append(hiddenContentDiv);
      initialContentDiv.addEventListener('click', function () {
        if (hiddenContentDiv.style.display) {
          hiddenContentDiv.style.removeProperty('display');
        } else {
          hiddenContentDiv.style.display = 'none';
        }

        var aria_label = closeButton.getAttribute('aria-label');
        closeButton.classList.toggle('rotate');
        closeButton.setAttribute('aria-label', swapClose(aria_label));

        function swapClose(x) {
          return x === 'Close' ? 'Open' : 'Close';
        }
      }); // prevent any links within initialContent from navigating anywhere

      var _iterator = _createForOfIteratorHelper(initialContentDiv.querySelectorAll('a')),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var a = _step.value;
          a.addEventListener('click', function (e) {
            return e.preventDefault();
          });
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
  };
  var _default = RevealContent;
  _exports.default = _default;
});