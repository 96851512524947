define("bocce/mixins/interactions/solfege_ladder", ["exports", "bocce/mixins/support/render-template", "bocce/mixins/support/util"], function (_exports, _renderTemplate, util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function SolfegeExercise($el, $svg, $selectSpeed, playSpeed, doNoteID, exerciseData) {
    // binds all methods to this
    util.bindAll(this);
    this.$el = $el;
    this.$svg = $svg;
    this.$selectSpeed = $selectSpeed;
    this.$btnAns = this.$el.find('#solfege-btn-answer');
    this.$btnNext = this.$el.find('#solfege-btn-next');
    this.playSpeed = playSpeed;
    this.exerciseData = exerciseData;
    this.doNoteID = doNoteID;
    this.highlightSelectors = this.getSelectorsForNotes(exerciseData);
    this.chromaticOffsets = this.getExerciseNoteOffsets(exerciseData);
    this.isAnimating = false;
    this.piano = this.piano || window.piano;
  }

  SolfegeExercise.prototype = {
    init: function init() {
      var $btnPlay = this.$el.find('#solfege-btn-play'); // remove any previous attached highlights

      $btnPlay.off('click');
      this.$btnAns.off('click');
      this.$btnAns.addClass('hidden');
      this.$btnNext.addClass('hidden'); // attach new highlights

      $btnPlay.click(this.highlightNotes);
      this.$btnAns.click(this.playHighlightsAndSounds);
      this.$selectSpeed.on('change', this.selectSpeed);
    },
    getSelectorsForNotes: function getSelectorsForNotes(e) {
      var highlightSelectors = [];

      var _iterator = _createForOfIteratorHelper(e),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var n = _step.value;
          // get note rectangle
          var rect = this.$svg.find('#' + n.selector); // put them in variables to give back to init/player

          highlightSelectors.push(rect);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return highlightSelectors;
    },
    playHighlightsAndSounds: function playHighlightsAndSounds() {
      this.playNotesOffsetFromDo();
      this.highlightNotes();
    },
    highlightNotes: function highlightNotes() {
      var _this = this;

      if (this.isAnimating == false) {
        (function () {
          var hs = _this.highlightSelectors;
          _this.isAnimating = true; // mark buttons as un-clickable while isAnimating

          _this.$el.toggleClass('disable-sound-btns');

          var setIsAnimatingToFalse = function setIsAnimatingToFalse() {
            // remove isAnimating class from buttons
            _this.$el.toggleClass('disable-sound-btns');

            return _this.isAnimating = false;
          };

          setTimeout(setIsAnimatingToFalse, hs.length * _this.playSpeed);

          var _loop = function _loop(i) {
            var timeInMilliseconds = i * _this.playSpeed;
            setTimeout(function () {
              hs[i].addClass('animate-highlight');
            }, timeInMilliseconds);
            setTimeout(function () {
              hs[i].removeClass('animate-highlight');
            }, timeInMilliseconds + _this.playSpeed);
          };

          for (var i = 0; i < hs.length; i++) {
            _loop(i);
          }

          _this.$btnAns.removeClass('hidden');

          _this.$btnNext.removeClass('hidden');
        })();
      }
    },
    selectSpeed: function selectSpeed() {
      this.playSpeed = this.$selectSpeed[0].value * 1000;
    },
    getExerciseNoteOffsets: function getExerciseNoteOffsets(e) {
      var noteOffsets = {
        1: 0,
        2: 2,
        3: 4,
        4: 5,
        5: 7,
        6: 9,
        7: 11
      };
      var chromaticOffsets = [];

      var _iterator2 = _createForOfIteratorHelper(e),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var n = _step2.value;
          var offset = 0,
              noteNum = noteOffsets[n.note],
              octaveNum = 0,
              toneNum = 0;

          if (n.octave === 'low') {
            octaveNum += -12;
          } else if (n.octave === 'high') {
            octaveNum += 12;
          }

          if (n.tone === 'flat') {
            toneNum += -1;
          } else if (n.tone === 'sharp') {
            toneNum += 1;
          }

          chromaticOffsets.push(offset += noteNum += octaveNum += toneNum);
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      return chromaticOffsets;
    },
    playNotesOffsetFromDo: function playNotesOffsetFromDo() {
      var _this2 = this;

      if (this.isAnimating == false) {
        var noteIDs = this.chromaticOffsets.map(function (offset) {
          return _this2.doNoteID + offset;
        });

        if (this.piano && this.piano.playNotes) {
          this.piano.playNotes(noteIDs, this.playSpeed, true);
        }
      }
    }
  }; // constructor

  function SolfegeLadder($el) {
    // binds all methods to this
    util.bindAll(this);
    this.$el = $el;
    this.$interactionData = $el.find('.interaction_data');
    this.$containerEl = $el.find('.interaction_content');
    this.dataTables = this.$interactionData[0].getElementsByTagName('table');
    this.solfegeObject = this.formatData(this.dataTables);
    this.solfegeExercises = this.solfegeObject.exercises;
    this.solfegeType = this.solfegeObject.settings[0].type;
    this.solfegeScale = this.solfegeObject.settings[0].scale;
    this.doNoteID = getDoMIDIFromTable(this.solfegeScale);
    this.currentExercise = 0;
    this.piano = this.piano || window.piano;
  }

  var MIDIOffsetsFromC = {
    'C': 0,
    'D': 2,
    'E': 4,
    'F': 5,
    'G': 7,
    'A': 9,
    'B': 11
  };
  var accidentalOffsets = {
    'NATURAL': 0,
    'FLAT': -1,
    'SHARP': 1
  };

  function getDoMIDIFromTable(table) {
    var key = table;

    var _key$toUpperCase$spli = key.toUpperCase().split(' '),
        _key$toUpperCase$spli2 = _slicedToArray(_key$toUpperCase$spli, 2),
        keyName = _key$toUpperCase$spli2[0],
        accidental = _key$toUpperCase$spli2[1];

    return 36 + MIDIOffsetsFromC[keyName] + accidentalOffsets[accidental];
  } // prototype


  SolfegeLadder.prototype = {
    init: function init() {
      var _this3 = this;

      this.$containerEl.attr('style', '');
      return (0, _renderTemplate.default)('solfege_ladder', 'main').then(function (content) {
        // put the rendered content on the page:
        _this3.$containerEl.append(content); // filter which notes show per scale type


        _this3.displayScale(_this3.solfegeType); // grab $selectors


        var $btnRef = _this3.$containerEl.find('#solfege-btn-ref'),
            $textRef = _this3.$containerEl.find('#solfege-scale-text'),
            $btnNext = _this3.$containerEl.find('#solfege-btn-next'),
            $btnBack = _this3.$containerEl.find('#solfege-btn-back'),
            $btnForward = _this3.$containerEl.find('#solfege-btn-forward'),
            $currentExercise = _this3.$containerEl.find('#exercise_number'),
            $totalExercise = _this3.$containerEl.find('#total_exercise_number'); // set displays


        $textRef.text(_this3.solfegeScale);
        $totalExercise.text(_this3.solfegeObject.exercises.length);
        $currentExercise.text(_this3.currentExercise + 1);
        $btnRef.click(_this3.playRefTone);
        $btnNext.click(_this3.nextExercise);
        $btnForward.click(_this3.nextExercise);
        $btnBack.click(_this3.prevExercise);

        _this3.newExercise();

        if (_this3.solfegeExercises.length === 1) {
          $btnNext.remove();
          $btnForward.remove();
          $btnBack.remove();
        }
      });
    },
    nextExercise: function nextExercise() {
      var $currentExercise = this.$containerEl.find('#exercise_number');

      if (this.currentExercise + 1 < this.solfegeExercises.length) {
        this.currentExercise += 1;
      } else {
        this.currentExercise = 0;
      }

      $currentExercise.text(this.currentExercise + 1);
      this.newExercise();
    },
    prevExercise: function prevExercise() {
      var $currentExercise = this.$containerEl.find('#exercise_number');

      if (this.currentExercise > 0) {
        this.currentExercise -= 1;
      } else {
        this.currentExercise = this.solfegeExercises.length - 1;
      }

      $currentExercise.text(this.currentExercise + 1);
      this.newExercise();
    },
    newExercise: function newExercise() {
      var $exerciseEl = this.$containerEl.find('#solfege-exercise-area'),
          $svgEl = this.$containerEl.find('#solfege-svg-container'),
          $selectSpeed = this.$containerEl.find('#select_speed');
      var playSpeed = $selectSpeed[0].value * 1000;
      var e = new SolfegeExercise($exerciseEl, $svgEl, $selectSpeed, playSpeed, this.doNoteID, this.solfegeExercises[this.currentExercise]);
      e.init();
    },
    formatData: function formatData(dataTables) {
      var data = this.getDataFromTables(dataTables);
      data.exercises = this.formatExercises(data.exercises);
      return data;
    },
    formatExercises: function formatExercises(exercises) {
      var exercisesCleaned = [];

      var _iterator3 = _createForOfIteratorHelper(exercises),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var k = _step3.value;
          var arrayPosition = k.exercise_number - 1;
          var selector = k.octave + "-" + k.note + "-" + k.tone;
          k.selector = selector;

          if (exercisesCleaned[arrayPosition]) {
            exercisesCleaned[arrayPosition].push(k);
          } else {
            var k_array = [k];
            exercisesCleaned.splice(arrayPosition, 0, k_array);
          }
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }

      return exercisesCleaned;
    },
    getDataFromTables: function getDataFromTables(dataTables) {
      var keys = ['settings', 'exercises'];
      var columns = {
        'settings': ['type', 'scale'],
        'exercises': ['exercise_number', 'octave', 'note', 'tone']
      };
      var data = {};
      Ember.$(dataTables).each(function (i) {
        var table = dataTables[i],
            tableid = keys[i],
            column = columns[tableid];
        var arr = [];
        /* go through each row */

        Ember.$(table).find('tr').each(function (i) {
          /* skip first row (title row) */
          if (i === 0) {
            return true;
          }
          /* if table is empty, do not create object */


          if (/\S/.test(this.innerText) === true) {
            var args = {};
            /* go through each cell and match contents to preset keys */

            Ember.$(this).find('td').each(function (ndx) {
              if (Ember.$(this).children().length > 0) {
                args[column[ndx]] = Ember.$(this).text();
              }
            });
            arr.push(args);
          }
        });
        /* put keys into object under keyname of tableid */

        data[tableid] = arr;
      });
      return data;
    },
    displayScale: function displayScale(solfegeType) {
      var container = Ember.$(this.$containerEl);
      var scaleMin = container.find('.scale-maj-min'),
          scaleMod = container.find('.scale-modal'),
          scaleChrom = container.find('.scale-chrom');

      if (solfegeType === 'major') {
        scaleMin.hide();
        scaleMod.hide();
        scaleChrom.hide();
      } else if (solfegeType === 'minor') {
        scaleMod.hide();
        scaleChrom.hide();
      } else if (solfegeType === 'modal') {
        scaleChrom.hide();
      }
    },
    playRefTone: function playRefTone() {
      var doNote = [this.doNoteID];

      if (this.piano && this.piano.playNotes) {
        this.piano.playNotes(doNote);
      }
    }
  };
  var _default = SolfegeLadder;
  _exports.default = _default;
});