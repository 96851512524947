define("bocce/mixins/routable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  if (!window.bocce) {
    window.bocce = {};
  }

  window.bocce.lastRoutes = [];
  /* eslint-disable-next-line ember/no-new-mixins */

  var _default = Ember.Mixin.create({
    setupController: function setupController() {
      this._super.apply(this, arguments);

      window.bocce.lastRoutes.push(this.routeName);
    },

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    defaultPanel: Ember.computed(function () {
      var panl = 'syllabus',
          cfg = this.panelConfig;

      if (cfg) {
        /* eslint-disable-next-line ember/no-get */
        if (this.get('session.isInstructor')) {
          panl = cfg.instructor;
        } else {
          panl = cfg.withinApp;

          if (window.bocce.lastRoutes >= 1) {
            panl = cfg.fromOutside;
          }
        }
      }

      return panl;
    })
  });

  _exports.default = _default;
});