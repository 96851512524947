define("bocce/components/side-panel/activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    // Element info
    classNames: ['activity-component'],
    // Default empty arrays for when assignments or discussions is falsey
    // These arrays are also responsible for filtering out locked items.
    discussionsArray: Ember.computed('discussions.@each.locked', function () {
      var discussions = this.discussions || Ember.ArrayProxy.create();
      return discussions.filter(function (d) {
        return !d.get('locked');
      });
    }),
    assignmentsArray: Ember.computed('assignments', function () {
      return this.assignments || Ember.ArrayProxy.create();
    }),
    // Since the criteria for "unread" is different for teachers vs. students,
    // this computed property attempts to hide that logic.
    assignmentsNeedingAttention: Ember.computed('session.isInstructor', 'assignmentsArray.@each.{needsTeacherAttention,needsStudentAttention}', function () {
      var assignments = this.assignmentsArray;
      /* eslint-disable-next-line ember/no-get */

      var filter = this.get('session.isInstructor') ? 'needsTeacherAttention' : 'needsStudentAttention';
      return assignments.filterBy(filter);
    }),
    // Sets the type of model to filter on.
    // possible values:
    //  * 'all'
    //  * 'assignments'
    //  * 'discussions'
    //  * 'requiredDiscussions'
    //  * 'announcements'
    filterType: 'all',
    // Sets whether to show unread items (and what constitutes 'unread')
    // Possible values:
    //   * 'all'
    //   * 'unread'
    //   * 'ungraded'
    //   * 'resubmission'
    //   * 'comments'
    //   * 'new'
    filterUnread: 'all',
    // This computed property makes the filter template easier to read.
    filterString: Ember.computed('filterType', 'filterUnread', function () {
      var type = this.filterType;
      var unread = this.filterUnread; // For development/testing builds, this is a safety check to try and catch
      // if we're accidentally setting either filter option to an unsupported value.

      (false && !(['all', 'assignments', 'conversations', 'requiredDiscussions', 'announcements'].includes(type)) && Ember.assert("filterType must be a supported type: ".concat(type), ['all', 'assignments', 'conversations', 'requiredDiscussions', 'announcements'].includes(type)));
      (false && !(['all', 'unread', 'ungraded', 'resubmission', 'comments', 'new', 'unsubmitted'].includes(unread)) && Ember.assert("filterUnread must be a supported type: ".concat(unread), ['all', 'unread', 'ungraded', 'resubmission', 'comments', 'new', 'unsubmitted'].includes(unread)));
      return "".concat(this.filterType, "_").concat(this.filterUnread);
    }),
    // Functions for filtered categories
    all_all: Ember.computed('discussionsArray.[]', 'assignmentsArray.[]', function () {
      var discussions = this.discussionsArray.toArray();
      var assignments = this.assignmentsArray.toArray();
      return Ember.ArrayProxy.create({
        content: [].concat(_toConsumableArray(discussions), _toConsumableArray(assignments))
      });
    }),
    all_unread: Ember.computed('discussionsArray.@each.unread', 'assignmentsNeedingAttention.[]', function () {
      var discussions = this.discussionsArray.filterBy('unread');
      var assignments = this.assignmentsNeedingAttention;
      return Ember.ArrayProxy.create({
        content: [].concat(_toConsumableArray(discussions), _toConsumableArray(assignments))
      });
    }),
    announcements_all: Ember.computed.filterBy('discussionsArray', 'is_announcement'),
    announcements_unread: Ember.computed.filterBy('announcements_all', 'unread'),
    assignments_all: Ember.computed.alias('assignmentsArray'),
    assignments_unsubmitted: Ember.computed.filterBy('assignments_all', 'needsStudentAttention'),
    assignments_ungraded: Ember.computed('assignmentsArray.@each.numUngradedSubmissions', function () {
      var ungraded = this.assignmentsArray.filterBy('numUngradedSubmissions');
      return ungraded;
    }),
    assignments_comments: Ember.computed.filterBy('assignments_all', 'numUnreadSubmissions'),
    assignments_resubmission: Ember.computed.filterBy('assignments_all', 'numResubmissionsPending'),
    nonAnnouncements: Ember.computed.filterBy('discussionsArray', 'isNotAnnouncement'),
    requiredDiscussions_all: Ember.computed('nonAnnouncements.@each.is_required', 'session.isInstructor', function () {
      var required = this.nonAnnouncements.filterBy('is_required');
      /* eslint-disable-next-line ember/no-get */

      if (this.get('session.isInstructor')) {
        required = required.filter(function (d) {
          var now = moment();
          var due = moment(d.get('due_at'));
          return !d.get('due_at') || due < now || d.get('dueWithinOneWeek') || d.get('dueWithinTwoWeeks');
        });
      }

      return required;
    }),
    requiredDiscussions_unread: Ember.computed.filterBy('requiredDiscussions_all', 'unread'),
    conversations_all: Ember.computed.filterBy('nonAnnouncements', 'isConversation'),
    conversations_unread: Ember.computed.filterBy('conversations_all', 'unread'),
    // activities computed property selects the correct filter computed property,
    // and returns that value. Activities is also responsible for sorting.
    activities: Ember.computed('filterString', 'all_all.[]', 'all_unread.[]', 'announcements_all.[]', 'announcements_unread.[]', 'assignments_all.[]', 'assignments_unsubmitted.[]', 'assignments_ungraded.[]', 'assignments_comments.[]', 'assignments_resubmission.[]', 'requiredDiscussions_all.[]', 'requiredDiscussions_unread.[]', 'conversations_all.[]', 'conversations_unread.[]', function () {
      var filter = this.filterString;
      var result = this.get(filter);
      (false && !(result !== undefined) && Ember.assert("".concat(filter, " must be defined on activity class"), result !== undefined));
      return result.toArray().sort(function (a, b) {
        return new Date(b.get('sortDate')) - new Date(a.get('sortDate'));
      });
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    totalUngradedSubmissions: Ember.computed('assignmentsArray.@each.numUngradedSubmissions', function () {
      var ungraded = 0;
      this.assignments_ungraded.forEach(function (assignment) {
        ungraded += assignment.get('numUngradedSubmissions');
      });
      return ungraded;
    }),
    actions: {
      changeFilter: function changeFilter(type) {
        var unread = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'all';

        if (typeof unread !== 'string') {
          (false && !(unread.target && unread.target.value && typeof unread.target.value === 'string') && Ember.assert('If not a string, unread must be an event', unread.target && unread.target.value && typeof unread.target.value === 'string'));
          unread = unread.target.value;
        }

        this.set('filterType', type);
        this.set('filterUnread', unread);
      }
    }
  });

  _exports.default = _default;
});