define("bocce/models/enrollment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/models/enrollment.js
  var _default = _model.default.extend({
    user: (0, _model.belongsTo)('user', {
      inverse: 'enrolled',
      async: false
    }),
    section: (0, _model.belongsTo)('section', {
      inverse: 'enrollments',
      async: false
    }),
    type: (0, _model.attr)('string'),
    degree: (0, _model.attr)('string'),
    certificate: (0, _model.attr)('string'),
    role: (0, _model.attr)('string'),
    accommodation: (0, _model.attr)('string')
  });

  _exports.default = _default;
});