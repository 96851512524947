define("bocce/templates/components/datetime-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bf6qGm9o",
    "block": "{\"symbols\":[],\"statements\":[[2,\"Date: \"],[8,\"input\",[],[[\"@type\",\"@value\"],[\"date\",[34,0]]],null],[2,\" Time: \"],[8,\"input\",[],[[\"@type\",\"@value\"],[\"time\",[34,1]]],null]],\"hasEval\":false,\"upvars\":[\"date\",\"time\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/datetime-picker.hbs"
    }
  });

  _exports.default = _default;
});