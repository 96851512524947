define("bocce/routes/classroom/lessons/conversation-new-with", ["exports", "bocce/routes/classroom/lessons/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _modalBase.default.extend({
    model: function model(params) {
      var _this = this;

      var user_ids = params.user_ids.toString().split('-');
      var conversations = this.store.findAll('conversation').then(function (conversations) {
        // filtered should be an array of conversations that match the
        // list of user ids to whom we want to send a group message
        var filtered = conversations.filter(function (item) {
          // if the length is different, we know it's not a match
          if (!Array.isArray(user_ids) || item.get('conversationPartners.length') !== user_ids.length) {
            return false;
          } // if the length is the same, check that each user id is present


          var partners = item.get('conversationPartners').toArray();
          var existing_ids = partners.map(function (c) {
            return c.id;
          });
          var wanted_ids = user_ids.concat();

          var _iterator = _createForOfIteratorHelper(wanted_ids),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var id = _step.value;

              if (!existing_ids.includes(id)) {
                return false;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          return true;
        });
        return filtered;
      });
      var users = conversations.then(function (filtered) {
        if (filtered.get('length') > 0) {
          var id = filtered[0].get('id');

          _this.transitionTo('classroom.lessons.conversation', id);

          return false;
        } else {
          var _users = []; // make sure to include yourself in the conversation!

          /* eslint-disable-next-line ember/no-get */

          user_ids.push(_this.get('session.user.id'));
          user_ids.forEach(function (id) {
            _users.push(_this.store.findRecord('user', id));
          });
          return Promise.all(_users);
        }
      });
      return users.then(function (users) {
        if (users) {
          var group_conversation = user_ids.length > 2 ? true : false;

          var conversation = _this.store.createRecord('conversation', {
            recipients: user_ids,
            private: !group_conversation,
            conversationPartners: users,
            group_conversation: group_conversation
          });

          return conversation;
        }
      });
    }
  });

  _exports.default = _default;
});