define("bocce/mixins/quiz", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function shuffle(arr) {
    for (var i = arr.length; i > 0; --i) {
      var j = Math.floor(Math.random() * i);
      var x = arr[i - 1];
      arr[i - 1] = arr[j];
      arr[j] = x;
    }
  }
  /* eslint-disable-next-line ember/no-new-mixins */


  var _default = Ember.Mixin.create({
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    quiz: Ember.computed('item', 'data', function () {
      /* eslint-disable-next-line ember/no-get */
      var type = this.get('item.type').toLowerCase();

      if (type === 'quiz') {
        this.quizSetup();
        return this.data;
      }

      return null;
    }),
    quizSetup: function quizSetup() {
      /* eslint-disable-next-line ember/no-get */
      var type = this.get('item.type').toLowerCase();

      if (type !== 'quiz') {
        return false;
      }

      var quiz = this.data;

      if (quiz) {
        if (quiz.get('attempts.length') > 0) {
          quiz.set('quiz_attempt', {
            seeking: true
          });
          quiz.set('quiz_attempt', quiz.get('attempts').objectAt(0));
        }

        if (quiz.get('shuffle')) {
          var arr = quiz.get('quiz_questions.content.currentState');
          shuffle(arr);
          quiz.set('quiz_questions.content.currentState', arr);
          quiz.set('quiz_questions.content.canonicalState', arr);
        }
      }
    },
    srSpeak: function srSpeak(text, priority) {
      var el = document.createElement("div");
      var id = 'speak-' + Date.now();
      el.setAttribute('id', id);
      el.setAttribute('aria-live', priority || 'polite');
      el.classList.add('sr-only');
      document.body.appendChild(el);
      window.setTimeout(function () {
        document.getElementById(id).innerHTML = text;
      }, 100);
      window.setTimeout(function () {
        document.body.removeChild(document.getElementById(id));
      }, 1000);
    },
    features: Ember.inject.service(),
    allowSubmit: Ember.computed('features.staticContent', function () {
      return !this.features.isEnabled('staticContent');
    }),
    actions: {
      retryQuiz: function retryQuiz(quiz) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#' + quiz + ' .quiz-container').removeClass('graded');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#' + quiz + ' .quiz-container').removeClass('grading');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#' + quiz + ' .quiz-container input:not(.question-checker-handle)').attr('checked', true);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#' + quiz + ' .quiz-container .question-handle:first-of-type').prop('checked', true);
        this.set('quiz.quiz_attempt', {
          retrying: true
        });
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#' + quiz + ' .question-handle').removeAttr('aria-hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.main-panel').scrollTop(0)
        /* eslint-disable-next-line ember/no-jquery */
        .scrollTop(Ember.$('#' + quiz).position().top - 10);
      },
      checkAnswer: function checkAnswer(quiz, question, sr) {
        /* eslint-disable-next-line ember/no-jquery */
        var mistakes = Ember.$('#' + quiz + ' .quiz-questions #question-' + question).find('input:not(.question-handle):not(.correct):checked, ' + 'input.correct:not(.question-handle):not(:checked)');

        if (sr) {
          var text = mistakes.length > 0 ? 'Incorrect!' : 'Correct!';
          this.srSpeak(text, 'assertive');
        } else {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#' + quiz + ' .quiz-questions #quiz-' + quiz + '-question-' + question + '-checker').prop('checked', true);

          if (mistakes.length > 0) {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('#' + quiz + ' .quiz-questions #question-' + question + '-modal').addClass('incorrect').removeClass('correct');
          } else {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('#' + quiz + ' .quiz-questions #question-' + question + '-modal').addClass('correct').removeClass('incorrect');
          }
        }
      },
      submitQuiz: function submitQuiz(quiz) {
        var _this = this;

        /* eslint-disable-next-line ember/no-get */
        var section = this.get('classroom.model.section.id');
        /* eslint-disable-next-line ember/no-jquery */

        if (Ember.$('#' + quiz + ' .quiz-questions' + ' input[type=checkbox]:not(\'.question-checker-handle\'):not(\'.question-handle\'):checked, #' + quiz + ' .quiz-questions' + ' input[type=radio]:not(\'.question-handle\'):checked').length === 0) {
          return;
        }

        var dialogPromise;
        /* eslint-disable-next-line ember/no-get */

        if (this.get('quiz.allowed_attempts') === 1) {
          dialogPromise = (0, _dialog.default)('This is your last attempt. Are you sure you want to submit? TIP: Make sure to double-check your answers!', ['Submit', 'No']);
        } else {
          dialogPromise = Promise.resolve('Submit');
        }

        dialogPromise.then(function (choice) {
          if (choice === 'Submit') {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('#' + quiz + ' .question-handle:last').prop('checked', true);
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('#' + quiz + ' .quiz-container').addClass('grading');
            Ember.debug('Requesting token...');
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$.post('/interface/sections/' + section + '/quizzes/' + quiz + '/attempts', Ember.$.proxy(function (data) {
              var _this2 = this;

              var answer_map = {},
                  answers = [];
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('#' + this.quiz + ' .quiz-questions' + ' input[type=checkbox]:not(\'.question-checker-handle\'):not(\'.question-handle\'):checked').each(function () {
                var q_id = this.attributes['question-id'].value;
                answer_map[q_id] = answer_map[q_id] || [];
                answer_map[q_id].push(this.attributes['answer-id'].value);
              }).get();

              for (var q_id in answer_map) {
                answers.push({
                  question_id: q_id,
                  answer_id: answer_map[q_id]
                });
              }
              /* eslint-disable-next-line ember/no-jquery */


              Ember.$('#' + this.quiz + ' .quiz-questions' + ' input[type=radio]:not(\'.question-checker-handle\'):not(\'.question-handle\'):checked').each(function () {
                answers.push({
                  question_id: this.attributes['question-id'].value,
                  answer_id: this.attributes['answer-id'].value
                });
              });
              this.context.lessons.store.nestResources('attempt', [{
                section: this.section
              }, {
                quiz: this.quiz
              }]);
              this.context.lessons.store.findRecord('attempt', data.id).then(function (resp) {
                resp.set('token', data.token);
                resp.set('number', data.number);
                resp.set('answers', answers);
                resp.set('state', 'complete');
                resp.save().then(function (resp_data) {
                  /* eslint-disable-next-line ember/no-jquery */
                  Ember.$('#' + _this2.quiz + ' .quiz-container').addClass('graded');
                  /* eslint-disable-next-line ember/no-jquery */

                  Ember.$('#' + _this2.quiz + ' .quiz-container .question-checker-handle').attr('checked', false);
                  /* eslint-disable-next-line ember/no-jquery */

                  Ember.$('#' + _this2.quiz + ' .question-handle').attr('aria-hidden', true);

                  _this2.context.set('quiz.quiz_attempt', resp_data);

                  _this2.context.set('quiz.todo', false);

                  _this2.context.get('quiz').reload();
                });
              }, function (err) {
                Ember.debug('Quiz submission error: ' + err);
              });
            }, {
              context: _this,
              quiz: quiz,
              section: section
            })).fail(function (err) {
              Ember.debug(err, 'Post quiz');
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('#' + quiz + ' .question-handle:last').prop('checked', false);
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('#' + quiz + ' .quiz-container').removeClass('grading');
              window.alert('Unable to grade quiz. Please try again in 10-15 seconds!');
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});