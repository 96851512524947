define("bocce/mixins/support/render-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = render_template;
  _exports.set_container = set_container;
  var ember_container;

  function render_template(mod_name, template_name) {
    var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    // http://stackoverflow.com/questions/27950413/render-ember-js-hbs-template-to-a-string
    // This creates a throw-away component that pulls in the specific template
    // we want.  It renders it, returns the dom nodes when ready, then destroys
    // itself.
    // https://discuss.emberjs.com/t/ember-component-creation-error-in-2-10/12087/4
    // renderer is needed for ember 2.10 and above. This is *extremely*
    // hacky (as noted in the link above), but it works... for now.
    return new Promise(function (resolve) {
      var tpl_path = "template:interactions/".concat(mod_name.replace(/[ _-]/g, '').toLowerCase(), "/").concat(template_name.replace(/[ _-]/g, '').toLowerCase()),
          tpl = ember_container.lookup(tpl_path);
      var renderer = ember_container.lookup('renderer:-dom');
      /* eslint-disable-next-line ember/no-new-mixins */

      var Interaction = Ember.Component.extend(Ember.assign({
        init: function init() {
          Ember.setOwner(this, ember_container);
          this.renderer = this.renderer || renderer;

          this._super.apply(this, arguments);
        },
        style: 'display:none;',
        layout: tpl,
        container: ember_container,
        didRender: function didRender() {
          /* eslint-disable-next-line ember/no-jquery */
          resolve(this.$());
          this.destroy();
        }
      }, data)); // TODO (NK): Ohhhhh my lordy this is janky. This is my duct-tape-on-top-of-
      // duct-tape fix for rendering interactions. May the Ember gods forgive me.
      // It registers a factory for a fake 'interaction-dummy' component, only
      // long enough to give the component what it needs to render. Once we've
      // instantiated the component, we kill the factory to make way for other
      // calls to render_template.

      ember_container.register('component:interaction-dummy', Interaction, {
        singleton: false
      });
      var component = ember_container.lookup('component:interaction-dummy');
      component.append();
      ember_container.unregister('component:interaction-dummy');
    });
  }

  function set_container(cont) {
    ember_container = cont;
  }
});