define("bocce/controllers/events", ["exports", "bocce/mixins/user-profile", "bocce/mixins/enmodal", "bocce/mixins/webex"], function (_exports, _userProfile, _enmodal, _webex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend(_userProfile.default, _enmodal.default, _webex.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.sortChatsBy = this.sortChatsBy || ['startAt'];
      this.sortPastChatsBy = this.sortPastChatsBy || ['startAt:desc'];
    },
    event: Ember.inject.controller('classroom.lessons.event'),
    classroom: Ember.inject.controller(),
    userprofile: Ember.inject.controller(),
    // required by UserProfileMixin
    application: Ember.inject.controller(),
    // required by WebexMixin
    events: Ember.inject.controller(),
    // required by WebexMixin
    newStudentEvent: Ember.inject.controller('classroom.lessons.student-event-new'),
    // required by WebexMixin
    showOnlyInstructorChats: false,
    upcomingChatsExpanded: false,

    /* eslint-disable-next-line ember/no-observers */
    instructorChatsFilterObserver: Ember.observer('showOnlyInstructorChats', function () {
      if (!this.showOnlyInstructorChats) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.events-instructor-chat-toggle-container').removeClass('checked');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.event.student-chat').removeClass('hidden');
      } else {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.events-instructor-chat-toggle-container').addClass('checked');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.event.student-chat').addClass('hidden');
      }
    }),
    pastChats: Ember.computed('eventsList.[]', 'session.time', 'sesssion.user', function () {
      var now = new Date(),
          currentUserAcsId = this.get('session.user.acsUserId'),
          isInstructor = this.get('session.isInstructor'),
          isStaff = this.get('session.isStaff'),
          canShow = true;
      var pastChats = this.eventsList.filter(function (e) {
        var hasMedia = e.get('meetings.length') > 0 || e.get('s3Recordings.length') > 0 || e.get('kalturaRecordings.length') > 0,
            isPastTime = moment(e.get('endAt')) < now;

        if (e.get('privateLessonStudentId')) {
          canShow = e.get('privateLessonStudentId') === currentUserAcsId || isInstructor || isStaff;
        }

        return isPastTime && canShow && hasMedia;
      });
      return pastChats;
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    currentChat: Ember.computed('eventsList.[]', 'session.{time,areStudentChatsRunning,isInstructorChatRunning}', function () {
      /* eslint-disable-next-line ember/no-get */
      if (!this.get('session.isInstructorChatRunning') && !this.get('session.areStudentChatsRunning')) {
        return null;
      }

      var currentUserAcsId = this.get('session.user.acsUserId'),
          isInstructor = this.get('session.isInstructor'),
          isStaff = this.get('session.isStaff'),
          canShow = true;
      var now = new Date(); // Shift the start time check back 5 minutes.  If there's not an already-running chat
      // scheduled, that'll let us catch cases where a meeting is started a few minutes early.

      var chats = this.sortedChats;

      if (!chats || chats.get('length') === 0) {
        return null;
      }

      chats = chats.filter(function (e) {
        var timeWindow = moment(e.get('endAt')) >= now && moment(e.get('startAt')) - 300000 <= now;

        if (e.get('privateLessonStudentId')) {
          canShow = e.get('privateLessonStudentId') === currentUserAcsId || isInstructor || isStaff;
        }

        return timeWindow && canShow;
      });

      if (chats.get('length') === 0) {
        return null;
      }

      return chats.objectAt(0);
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    upcomingChats: Ember.computed('eventsList.[]', 'session.time', 'currentChat', function () {
      var now = new Date(),
          chats = this.eventsList,
          cur = this.currentChat,
          teachingUser = this.get('session.teachingUser'),
          currentUserAcsId = this.get('session.user.acsUserId'),
          isInstructor = this.get('session.isInstructor'),
          isStaff = this.get('session.isStaff'),
          canShow = true;

      if (chats.get('length') === 0) {
        return [];
      } // JRW: If it's a Private Lesson, set the late start time to 5 minutes
      // If not, broaden the window to 30 minutes

      /* eslint-disable-next-line ember/no-get */


      var chatWindowInMinutes = this.get('session.course.isPrivateLesson') ? 5 : 30;
      return chats.filter(function (e) {
        var cantStart = moment(e.get('startAt')) > now - chatWindowInMinutes * 60 * 1000,
            isNotCurrent = !cur || !cur.id || e.id != cur.id;

        if (e.get('privateLessonStudentId')) {
          canShow = e.get('privateLessonStudentId') === currentUserAcsId || isInstructor || isStaff;
        } // JRW: sometimes staff edit events and it changes the
        // owner to admin, this sets the event to the instructor
        // user.


        if (e.get('user').get('id') === "1") {
          e.set('user', teachingUser);
        }

        return cantStart && isNotCurrent && canShow;
      });
    }),
    // NK: I'm reworking otherSectionChats to be an observer here, because
    // the computed property introduces side effects. Ideally we'll be
    // able to rewrite this at some point so that we can use only
    // computed properties.

    /* eslint-disable-next-line ember/no-observers */
    otherSectionChatsObserver: Ember.observer('upcomingChats', function () {
      var _this = this;

      // JRW: If the next chat starts within +/-10 minutes, check if it's a multisection chat.
      // If it is, set the model of the event controller to get the otherSectionChats
      // computed property.
      var chats = this.sortedUpcomingChats.filter(function (e) {
        return !e.get('isStudentChat');
      }),
          now = moment(),
          tenMinutesInMilliseconds = 1000 * 60 * 10,
          eventController = this.event,
          nextUpcomingChat = chats.objectAt(0),
          otherSectionChatsPromise;
      /* eslint-disable-next-line ember/no-get */

      if (this.get('session.isInstructor') && nextUpcomingChat) {
        var start = moment(nextUpcomingChat.get('startAt'));

        if (start - tenMinutesInMilliseconds < now && start + tenMinutesInMilliseconds > now) {
          this.set('isWorking', true);
          eventController.set('model', nextUpcomingChat);
          otherSectionChatsPromise = eventController.get('otherSectionChats').then(function (otherSectionChats) {
            // Attach the otherSectionChats event to the events list controller and send the length
            // of the array to the otherSectionChatsPromise handler
            _this.set('isWorking', false);

            if (Array.isArray(otherSectionChats) && otherSectionChats.length !== 0) {
              _this.set('otherSectionChats', otherSectionChats);

              return otherSectionChats.length;
            } else {
              return 0;
            }
          }); //return the DS.PromiseObject (and its result)

          return otherSectionChatsPromise.then(function (result) {
            _this.set('hasOtherSectionChats', result !== 0);
          });
        }
      } // default to false - doesn't get here if isInstructor is true and there's a chat to check


      return this.set('hasOtherSectionChats', false);
    }),
    // Returns the next chat, if it starts within 30 minutes from now

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    chatStartingSoon: Ember.computed('upcomingChats', 'session.time', function () {
      var candidate = this.sortedUpcomingChats.objectAt(0);

      if (!candidate) {
        return null;
      }

      var start = moment(candidate.get('startAt'));

      if (start - moment() < 1800000) {
        return candidate;
      }

      return null;
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    studentRunChats: Ember.computed('session.{course.events,studentMeetingKeys}', function () {
      /* eslint-disable-next-line ember/no-get */
      var studentMeetingKeys = this.get('session.studentMeetingKeys'),
          eventsList = this.eventsList.filter(function (e) {
        var eventMeetingKeys = e.get('meetingKeys');

        if (eventMeetingKeys) {
          var _iterator = _createForOfIteratorHelper(studentMeetingKeys),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var studentMeetingKey = _step.value;

              if (eventMeetingKeys.includes(studentMeetingKey.meeting_key)) {
                return true;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        } else {
          return false;
        }
      });
      return eventsList;
    }),
    eventsList: Ember.computed.reads('session.course.events'),
    eventsLoaded: Ember.computed.reads('classroom.eventsLoaded'),
    sortedChats: Ember.computed.sort('eventsList', 'sortChatsBy'),
    sortedUpcomingChats: Ember.computed.sort('upcomingChats', 'sortChatsBy'),
    sortedPastChats: Ember.computed.sort('pastChats', 'sortPastChatsBy'),
    hasChats: Ember.computed('eventsList.[]', function () {
      /* eslint-disable-next-line ember/no-get */
      return this.get('eventsList.length') - this.eventsList.filterBy('meetings').filterBy('endAt').length > 0;
    }),
    actions: {
      viewEvent: function viewEvent(event_id) {
        this.transitionToRoute('classroom.lessons.event', event_id);
      },
      openEventNewModal: function openEventNewModal() {
        this.transitionToRoute("classroom.lessons.event-new");
      },
      filterEvents: function filterEvents(filterType) {
        switch (filterType) {
          case 'all':
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.side-panel .chat-panel .events .past-event').show();
            break;

          case 'current':
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.side-panel .chat-panel .events .past-event').hide();
            break;

          default:
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.side-panel .chat-panel .events .past-event').hide();
        }
      },
      dismissStartChatConfirmation: function dismissStartChatConfirmation() {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.chat-confirmation').addClass('hidden');
      },
      toggleExpandUpcomingChats: function toggleExpandUpcomingChats() {
        this.toggleProperty('upcomingChatsExpanded');
      },
      toggleInstructorChats: function toggleInstructorChats() {
        this.set('showOnlyInstructorChats', !this.showOnlyInstructorChats);
      }
    }
  });

  _exports.default = _default;
});