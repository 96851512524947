define("bocce/templates/classroom/lessons/survey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "giL5FKLk",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,\"role\",\"dialog\"],[14,\"aria-modal\",\"true\"],[14,\"aria-labelledby\",\"survey-modal-title\"],[14,\"aria-describedby\",\"survey-modal-title\"],[14,0,\"floating-modal active\"],[14,1,\"survey-view\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-title\"],[14,1,\"survey-modal-title\"],[12],[2,\"Course Survey\"],[13],[2,\"\\n  \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"close-button\"],[24,\"aria-label\",\"Close Survey\"],[4,[38,0],[[32,0],\"closeModals\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fas fa-times\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"modal-content\"],[12],[2,\"\\n    \"],[10,\"iframe\"],[14,\"src\",\"about:blank\"],[14,3,\"survey_frame\"],[14,1,\"survey_frame\"],[14,\"title\",\"Course Survey\"],[14,5,\"height:100%;width:100%;border:0;\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"form\"],[14,1,\"survey_init\"],[14,0,\"no-visibility\"],[14,\"method\",\"POST\"],[14,\"action\",\"https://online-berklee.evaluationkit.com/MyEval/LoginLti.aspx\"],[14,\"target\",\"survey_frame\"],[14,\"data-tool-launch-type\",\"\"],[14,\"data-tool-id\",\"online-berklee.evaluationkit.com\"],[14,\"data-tool-path\",\"/MyEval/LoginLti.aspx\"],[14,\"data-message-type\",\"survey_frame\"],[12],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"modals/dark-backdrop\",[],[[\"@action\"],[[30,[36,0],[[32,0],\"closeModals\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "bocce/templates/classroom/lessons/survey.hbs"
    }
  });

  _exports.default = _default;
});