define("bocce/templates/classroom/lessons/admin/assignments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aJW1NFPY",
    "block": "{\"symbols\":[\"assignment\"],\"statements\":[[10,\"div\"],[14,0,\"assignment-management admin-area\"],[12],[2,\"\\n  \"],[10,\"table\"],[14,0,\"assignments\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[14,0,\"assignment-title\"],[12],[2,\"\\n          \"],[1,[32,1,[\"title\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"assignment-summary\"],[12],[2,\"\\n            Submitted: \"],[1,[32,1,[\"submissions\",\"length\"]]],[2,\" \"],[10,\"br\"],[12],[13],[2,\"\\n            Ungraded: \"],[1,[32,1,[\"numUngradedSubmissions\"]]],[2,\" \"],[10,\"br\"],[12],[13],[2,\"\\n            Pending Resubs: \"],[1,[32,1,[\"numResubmissionsPending\"]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "bocce/templates/classroom/lessons/admin/assignments.hbs"
    }
  });

  _exports.default = _default;
});