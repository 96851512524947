define("bocce/mixins/video-rec", ["exports", "bocce/mixins/rtc-rec", "bocce/mixins/kaltura-upload", "bocce/mixins/legacy-attachment-manager"], function (_exports, _rtcRec, _kalturaUpload, _legacyAttachmentManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create(_legacyAttachmentManager.default, _rtcRec.default, _kalturaUpload.default, {
    mobileUpload: Ember.computed(function () {
      /* eslint-disable-next-line ember/no-jquery */
      return Ember.$.isMobile;
    }),
    useCameraTag: Ember.computed(function () {
      /* eslint-disable-next-line ember/no-jquery */
      if (Ember.$.isSafari || Ember.$.isEdge) {
        return true;
      }

      return false;
    }),
    attachRtc: function attachRtc(videoContents, removeIndex, blobURL) {
      var myVid = {
        isUrl: true,
        name: videoContents.name,
        url: 'https://cdnapisec.kaltura.com/p/2588802/sp/258880200/playManifest/entryId/' + videoContents.id + '/format/url/protocol/https/flavorParamId/4128/name/course_video.mp4',
        created_at_formatted: moment.tz('America/New_York').format('MMM Do[,] h:mm:ss a') + " ET",
        type: 'video/mp4'
      };

      if (videoContents && videoContents.id.length > 0) {
        Ember.debug('removing uploading status of file. Index:' + removeIndex + ' - Encoding videos array length: ' + this.encoding_videos.length);
        this.encoding_videos.replace(removeIndex, 1, [{}]);

        if (blobURL) {
          var fileExt = myVid.name.substr((~-myVid.name.lastIndexOf('.') >>> 0) + 2),
              className = "extension-".concat(fileExt).toLowerCase(); // Create the record with its default parameters, and then add it to the collection.

          var record = {
            file: myVid,
            valid: true,
            uploaded: false,
            deleted: false,
            className: className,
            blobURL: blobURL
          };
          this.files.pushObject(record);
        } else {
          this.send('addValidFile', myVid);
        }

        this.send('toggleRTCPanel', true);
        this.set('videoReady', false);
      }
    },
    actions: {
      initMobileVideo: function initMobileVideo() {
        /* eslint-disable-next-line ember/no-jquery */
        var cameraContainer = Ember.$('.floating-modal.active .camera-container'),
            that = this;

        if (window.CameraTag) {
          window.CameraTag.setup();
        } // Force remove of captre property from CT to fix iPhone upload issue


        setTimeout(function () {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('input[type="file"]').removeAttr('capture');
        }, 100);
        cameraContainer.toggleClass('active');

        if (window.CameraTag && window.CameraTag.cameras['recorderAtt']) {
          window.CameraTag.observe('recorderAtt', 'published', function () {
            var myCamera = window.CameraTag.cameras['recorderAtt'],
                myVideo = myCamera.getVideo(),
                mp4_url = myVideo.medias.vga_mp4,
                model_title = that.get('model.title') || 'new-discussion',
                name = model_title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '') + '_video_' + new Date().getTime(),
                myVid = {
              isUrl: true,
              name: name,
              url: mp4_url,
              created_at_formatted: moment.tz('America/New_York').format('MMM Do[,] h:mm:ss a') + " ET",
              type: 'video/mp4'
            };

            if (mp4_url) {
              myCamera.reset();
              cameraContainer.removeClass('active');
              that.send('addValidFile', myVid);
            }
          }, true);
        }
      },
      toggleAttachmentDrawer: function toggleAttachmentDrawer() {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.pop-attachment-drawer').toggleClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        if (Ember.$('.pop-attachment-drawer').hasClass('hidden')) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.floating-modal.active').removeClass('drawer-open');
        } else {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.pop-poll-drawer').addClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.floating-modal.active').addClass('drawer-open');
        }
      },
      downloadFile: function downloadFile(fileURL) {
        window.open(fileURL, '_blank');
      },
      toggleCameraPanel: function toggleCameraPanel(close) {
        /* eslint-disable-next-line ember/no-jquery */
        var cameraContainer = Ember.$('.floating-modal.active .camera-container'),
            that = this;

        if (!this.useCameraTag) {
          this.send('toggleRTCPanel', close);
          return false;
        }

        if (close) {
          cameraContainer.removeClass('active');
          return false;
        }
        /* eslint-disable-next-line ember/no-jquery */


        if (Ember.$('.floating-modal.active .audio-rec-panel').length > 0) {
          this.send('toggleAudioRecPanel', true);
        }

        cameraContainer.toggleClass('active');

        if (cameraContainer.hasClass('active') && !!window.CameraTag) {
          window.CameraTag.setup();

          if (window.CameraTag.cameras['recorderAtt']) {
            window.CameraTag.observe('recorderAtt', 'published', function () {
              var myCamera = window.CameraTag.cameras['recorderAtt'],
                  myVideo = myCamera.getVideo(),
                  mp4_url = myVideo.medias.vga_mp4,

              /* eslint-disable-next-line ember/no-jquery */
              model_title = Ember.$('.floating-modal.active#assignment').length > 0 ? 'new-assignment' : that.get('model.title') || 'new-discussion',
                  name = model_title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '') + '_video_' + new Date().getTime(),
                  myVid = {
                isUrl: true,
                name: name,
                url: mp4_url,
                type: 'video/mp4'
              },
                  cas;

              if (mp4_url) {
                myCamera.reset(); // Add video to archive

                if (localStorage.localArchive) {
                  cas = JSON.parse(localStorage.localArchive);
                } else {
                  cas = {};
                }

                cas[myVid.name] = myVid;
                localStorage.localArchive = JSON.stringify(cas);
                cameraContainer.removeClass('active');
                that.send('addValidFile', myVid);
              }
            }, true);
          }
        }
      }
    }
  });

  _exports.default = _default;
});