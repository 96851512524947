define("bocce/templates/conversation-reply-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ENBaLhkf",
    "block": "{\"symbols\":[],\"statements\":[[8,\"drag-and-drop\",[],[[\"@files\",\"@store\",\"@controller\"],[[34,0],[34,1],[32,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,\"role\",\"region\"],[14,\"aria-label\",\"Conversation Reply Text Editor\"],[14,0,\"conversation-reply-box\"],[12],[2,\"\\n    \"],[19,\"vidrec\",[]],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"reply-container reply-quick \",[30,[36,3],[[35,2],\"active-editor\"],null]]]],[12],[2,\"\\n      \"],[8,\"rte-input\",[],[[\"@userInput\",\"@updateArchive\"],[[34,4],[30,[36,5],[[32,0],\"updateLocalDocs\"],null]]],null],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[19,\"audio-rec\",[]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[8,\"buttons/submit-button\",[],[[\"@active\",\"@action\",\"@activeLabel\",\"@inactiveLabel\"],[[34,8],[30,[36,5],[[32,0],\"updateConversation\",true],null],\"Reply to Conversation\",\"Reply to Conversation - Button disabled. Please write a reply first.\"]],null],[2,\"\\n      \"],[19,\"attachments\",[]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"files\",\"store\",\"inEditor\",\"if\",\"bodyInput\",\"action\",\"noRec\",\"unless\",\"postable\"]}",
    "meta": {
      "moduleName": "bocce/templates/conversation-reply-box.hbs"
    }
  });

  _exports.default = _default;
});