define("bocce/mixins/interactions/video_commentary", ["exports", "bocce/mixins/support/render-template", "bocce/mixins/support/util"], function (_exports, _renderTemplate, util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* **************************   *
   * Video Commentary interaction *
   * **************************   */
  function VideoCommentary($el, data) {
    // binds all methods to this
    util.bindAll(this);
    this.$el = $el;
    this.$interactionData = $el.find('.interaction_data');
    this.$containerEl = $el.find('.interaction_content');
    this.config = data.config; // array of all comments

    var dataTable = this.$interactionData.find('table')[0];
    this.intData = this.getDataFromTable(dataTable);
    this.currentCommentary = -1;
  } // AudioComments prototype


  VideoCommentary.prototype = {
    // get things up and running.
    init: function init() {
      var _this = this;

      this.$containerEl.attr('style', '');
      return (0, _renderTemplate.default)('video_commentary', 'main', {
        videoSrc: this.intData.video
      }).then(function (content) {
        // put the rendered content on the page:
        _this.$containerEl.append(content);

        var videoComm = _this.$containerEl.find('.video-commentary');

        var videoPlayer = _this.$containerEl.find('.commentary-video-container video')[0];

        var btnPlayPause = _this.$containerEl.find('.video-playpause');

        var scrubber = _this.$containerEl.find('.video-scrubber');

        var commentarContainer = _this.$containerEl.find('.commentary-container');

        var btnPlaythrough = _this.$containerEl.find('.option-play-through');

        var btnPausePly = _this.$containerEl.find('.option-pause');

        var btnMenu = _this.$containerEl.find('.video-menu');

        var btnContinue = _this.$containerEl.find('#ctrl-continue');

        var player = _this.$containerEl.find('.commentary-video-player');

        var btnPrev = _this.$containerEl.find('#ctrl-prev');

        var markers = _this.$containerEl.find('.time-markers');

        var btnNext = _this.$containerEl.find('#ctrl-next');

        var playThroughOpt = false;
        var video_commentaries = _this.intData.commentaries;

        var updateTime = function updateTime(vid) {
          timeUpdate(vid, playThroughOpt);
        };

        videoPlayer.addEventListener('loadedmetadata', function () {
          var markerString = '';
          video_commentaries.forEach(function (item) {
            var percentageStart = item.timeStamp / videoPlayer.duration * 100,
                percentageEnd = item.timeEnd / videoPlayer.duration * 100 - percentageStart,
                range;

            if (item.timeEnd) {
              range = '\' style=\'left: ' + percentageStart + '%; width:' + percentageEnd + '%\'';
            } else {
              range = '\' style=\'left: ' + percentageStart + '%\'';
            }

            markerString += '<div tcode=\'' + item.timeStamp + range + '></div>';
          });
          scrubber.attr('max', Math.ceil(videoPlayer.duration));
          scrubber.on('change', function (scr) {
            videoPlayer.currentTime = scr.target.value;
          });
          markers.html(markerString);

          _this.$el.find('.time-markers div').on('click', function (tgt) {
            videoPlayer.currentTime = tgt.target.attributes.tcode.value;
          });
        });
        videoPlayer.addEventListener('ended', function () {
          videoPlayer.removeEventListener('timeupdate', updateTime);
          _this.currentCommentary = -1;
          commentarContainer.html('');
          videoComm.removeClass('playing').addClass('ready');
        });
        btnMenu.on('click', function () {
          videoPlayer.pause();
          videoComm.removeClass('paused').removeClass('playing').addClass('ready');
        });
        btnPlayPause.on('click', function () {
          if (videoPlayer.paused) {
            videoComm.removeClass('ready').removeClass('paused').removeClass('ended').addClass('playing');
            videoPlayer.play();
          } else {
            videoComm.removeClass('playing').addClass('paused');
            videoPlayer.pause();
          }
        });
        btnPausePly.on('click', function () {
          playThroughOpt = false;
          videoComm.removeClass('ready').addClass('playing');
          videoComm.removeClass('no-comments');
          videoPlayer.play();
          videoPlayer.addEventListener('timeupdate', updateTime);
        });
        btnPlaythrough.on('click', function (tgt) {
          playThroughOpt = true;
          videoComm.removeClass('ready').addClass('playing');
          videoPlayer.play();

          if (tgt.target.classList.contains('no-comments')) {
            videoComm.addClass('no-comments');
          } else {
            videoComm.removeClass('no-comments');
          }

          videoPlayer.addEventListener('timeupdate', updateTime);
        });

        var timeUpdate = function timeUpdate(vid, playThrough) {
          scrubber[0].value = Math.floor(vid.target.currentTime);
          var minutes = Math.floor(videoPlayer.currentTime / 60),
              seconds = Math.floor(videoPlayer.currentTime - minutes * 60);
          minutes = minutes < 10 ? '0' + minutes : minutes;
          seconds = seconds < 10 ? '0' + seconds : seconds;
          player.attr('current', minutes + ':' + seconds);

          if (playThrough) {
            video_commentaries.forEach(function (item, index) {
              if (item.timeStamp === Math.floor(vid.target.currentTime) && _this.currentCommentary !== index) {
                _this.currentCommentary = index;
                commentarContainer.html(item.commentary);
              }

              if (item.timeEnd && _this.currentCommentary === index && Math.floor(vid.target.currentTime) > item.timeEnd) {
                commentarContainer.html('');
              }
            });
          } else {
            video_commentaries.forEach(function (item, index) {
              if (item.timeStamp === Math.floor(vid.target.currentTime) && _this.currentCommentary !== index) {
                videoComm.removeClass('playing').addClass('paused').addClass('comment');
                _this.currentCommentary = index;
                vid.target.pause();
                commentarContainer.html(item.commentary);
              }

              if (item.timeEnd && _this.currentCommentary === index && Math.floor(vid.target.currentTime) > item.timeEnd) {
                commentarContainer.html('');
              }
            });
          }
        };

        btnContinue.on('click', function () {
          videoPlayer.play();
          videoComm.removeClass('paused').removeClass('comment').addClass('playing');

          if (!video_commentaries[_this.currentCommentary].timeEnd && !playThroughOpt) {
            commentarContainer.html('');
          }
        });
        btnNext.on('click', function () {
          _this.currentCommentary += 1;

          if (video_commentaries[_this.currentCommentary]) {
            videoPlayer.currentTime = video_commentaries[_this.currentCommentary].timeStamp;
            commentarContainer.html(video_commentaries[_this.currentCommentary].commentary);

            if (playThroughOpt) {
              videoComm.removeClass('paused').addClass('playing');
              videoPlayer.play();
            } else {
              videoComm.removeClass('playing').addClass('paused').addClass('comment');
              videoPlayer.pause();
            }
          }
        });
        btnPrev.on('click', function () {
          _this.currentCommentary -= 1;

          if (video_commentaries[_this.currentCommentary]) {
            videoPlayer.currentTime = video_commentaries[_this.currentCommentary].timeStamp;
            commentarContainer.html(video_commentaries[_this.currentCommentary].commentary);

            if (playThroughOpt) {
              videoComm.removeClass('paused').addClass('playing');
              videoPlayer.play();
            } else {
              videoComm.removeClass('playing').addClass('paused').addClass('comment');
              videoPlayer.pause();
            }
          }
        });
      });
    },
    getDataFromTable: function getDataFromTable(table) {
      // array of columns in the table from the cms:
      var COLUMNS = ['timeStamp', 'timeEnd', 'commentary'];
      var data = {
        commentaries: []
      };
      /* eslint-disable-next-line ember/no-jquery */

      for (var i = 0; i < Ember.$(table).find('tr').length; ++i) {
        if (i === 0) {
          /* eslint-disable-next-line ember/no-jquery */
          data.video = Ember.$(table).find('tr').eq(i).find('td').eq(1).text(); // Fetch Kaltura Video

          if (data.video.indexOf('mp4') === -1) {
            data.video = 'https://cdnapisec.kaltura.com/p/2588802/sp/258880200/playManifest/entryId/' + data.video + '/format/url/protocol/https/flavorParamId/487081/name/course_video.mp4';
          }

          continue;
        } // Skip header row...


        if (i === 1) {
          continue;
        }

        var args = {},

        /* eslint-disable-next-line ember/no-jquery */
        $row = Ember.$(table).find('tr');

        for (var j = 0; j < $row.length; ++j) {
          if (COLUMNS[j] !== 'commentary') {
            var colCont = $row.eq(i).find('td').eq(j).text();

            if (COLUMNS[j] === 'timeStamp' || COLUMNS[j] === 'timeEnd') {
              var timeParse = colCont.toString().split(':');

              if (timeParse.length > 1) {
                var timeSeconds = parseInt(timeParse[timeParse.length - 1]);

                for (var k = 0; k < timeParse.length - 1; k++) {
                  timeSeconds += timeParse[k] * Math.pow(60, timeParse.length - k - 1);
                }

                colCont = timeSeconds;
              }
            }

            args[COLUMNS[j]] = parseInt(colCont);
          } else {
            args[COLUMNS[j]] = $row.eq(i).find('td').eq(j).html();
          }
        }

        data.commentaries.push(args);
      }

      return data;
    }
  };
  var _default = VideoCommentary;
  _exports.default = _default;
});