define("bocce/templates/components/lessons/topic-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uJZNLQ6z",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[19,[35,1],[]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"bootable-area\"],[12],[2,\"\\n    \"],[2,[35,0,[\"body\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"topicbody\",\"templateForType\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/lessons/topic-body.hbs"
    }
  });

  _exports.default = _default;
});