define("bocce/templates/components/assignment-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vi3ePL0o",
    "block": "{\"symbols\":[],\"statements\":[[8,\"user-portrait\",[],[[\"@user\",\"@action\"],[[34,0],[30,[36,1],[[32,0],\"toggleUserProfile\",[35,0,[\"id\"]]],null]]],null],[2,\"\\n\"],[11,\"button\"],[24,0,\"author-name\"],[4,[38,1],[[32,0],\"toggleUserProfile\",[35,0,[\"id\"]]],null],[12],[2,\"\\n  \"],[1,[35,0,[\"short_name\"]]],[2,\" \"],[8,\"user-icons\",[],[[\"@user\",\"@isInstructor\"],[[34,0],[34,2,[\"isInstructor\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"author\",\"action\",\"session\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/assignment-user.hbs"
    }
  });

  _exports.default = _default;
});