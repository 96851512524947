define("bocce/helpers/lessthan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lessthan = lessthan;
  _exports.default = void 0;

  // app/helpers/lessthan.js
  // Check if first provided number is less (!) than the second
  // Example: If number of assignments is less than 0
  // {{#if (lessthan assignments.length '0')}}
  function lessthan(inp) {
    var first = inp[0],
        second = inp[1];

    if (!first || !second) {
      return false;
    }

    return Number(first) < Number(second);
  }

  var _default = Ember.Helper.helper(lessthan);

  _exports.default = _default;
});