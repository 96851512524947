define("bocce/routes/classroom", ["exports", "bocce/mixins/routable", "bocce/mixins/recent-lesson-redirect"], function (_exports, _routable, _recentLessonRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Route.extend(_routable.default, _recentLessonRedirect.default, {
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.panelConfig = this.panelConfig || {
        withinApp: 'syllabus',
        fromOutside: 'syllabus',
        instructor: 'activity'
      };
    },
    // NK: This is more of a workaround than a clean solution. Right now we assume that "findAll"
    //     calls will only retrieve models related to the current course, but this causes items
    //     from other courses to show up when switching courses (among other issues). The real
    //     fix is to rewrite adapters so that they're more context-aware, and to rewrite the app
    //     logic so that we don't use findAll when we really just want the models for a specific
    //     course. That will take more time than we have right now, so I'm putting this in until
    //     the next cleanup-friendly push.
    unloadCourseModels: function unloadCourseModels() {
      this.store.unloadAll('assignment');
      this.store.unloadAll('page');
      this.store.unloadAll('discussion');
      this.store.unloadAll('quiz');
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var params = transition.to.find(function (item) {
        return item.name === 'classroom';
      }).params;
      /* eslint-disable-next-line ember/no-get */

      var previous_course_id = this.get('session.course.id');
      window.store = this.store;
      window.session = this.session;

      if (!previous_course_id || params.course_id !== previous_course_id) {
        this.unloadCourseModels();
        return this.store.findRecord('course', params.course_id, {
          reload: true
        }).then(function (course) {
          /* eslint-disable-next-line ember/no-get */
          var section = course.get('sections').objectAt(0),

          /* eslint-disable-next-line ember/no-get */
          section_id = section.get('id');

          _this.session.set('section', section);

          var body = document.querySelector('body');
          /* eslint-disable-next-line ember/no-get */

          if (course.get('isSample')) {
            body.classList.add('sample');
          } else {
            body.classList.remove('sample');
          }
          /* eslint-disable-next-line ember/no-get */


          if (course.get('isPublic')) {
            body.classList.add('public_course');
          } else {
            body.classList.remove('public_course');
          }

          _this.store.nestResources('section', {
            course: params.course_id
          });

          _this.store.nestResources('lesson', {
            course: params.course_id
          });

          _this.store.nestResources('discussion', {
            section: section_id
          });

          _this.store.nestResources('poll', {
            section: section_id
          });

          _this.store.nestResources('event', {
            course: params.course_id
          });

          _this.store.nestResources('page', {
            section: section_id
          });

          _this.store.nestResources('assignment', {
            section: section_id
          });

          _this.store.nestResources('quiz', {
            section: section_id
          });

          _this.store.nestResources('response', {
            section: section_id
          });

          _this.store.nestResources('gradebook', {
            course: params.course_id
          });
          /* eslint-disable-next-line ember/no-get */


          if (course.get('isArchived')) {
            Ember.debug('Course has been archived and is read-only');
            /* eslint-disable-next-line ember/no-get */

            var user_id = parseInt(_this.session.get('user.id'), 10);

            _this.store.peekAll('enrollment').forEach(function (enrollment) {
              /* eslint-disable-next-line ember/no-get */
              if (parseInt(enrollment.get('user.id'), 10) === user_id) {
                /* eslint-disable-next-line ember/no-get */
                var role = enrollment.get('role');
                section.set('role', role); // TODO: This needs to be replaced with the EAPM equivalent
                //if ( window.newrelic ) {
                //  window.newrelic.setCustomAttribute('role', role);
                //}
              }
            });

            return fetch("/interface/courses/".concat(params.course_id, "?for=user")).then(function (res) {
              return res.json();
            }).then(function (extra_data) {
              var grades = extra_data.grades;
              delete extra_data.grades;

              _this.store.pushPayload(extra_data);

              var _iterator = _createForOfIteratorHelper(grades),
                  _step;

              try {
                for (_iterator.s(); !(_step = _iterator.n()).done;) {
                  var grade = _step.value;

                  var sub = _this.store.peekRecord('submission', grade.submission_id);

                  sub.set('grade', grade.grade);
                  sub.set('score', grade.score);
                }
                /* eslint-disable-next-line ember/no-get */

              } catch (err) {
                _iterator.e(err);
              } finally {
                _iterator.f();
              }

              if (section.get('role') === 'student') {
                var assns = _this.store.peekAll('assignment');

                assns.forEach(function (assn) {
                  /* eslint-disable-next-line ember/no-get */
                  var my_sub = assn.get('submissions').find(function (s) {
                    /* eslint-disable-next-line ember/no-get */
                    return parseInt(s.get('user.id'), 10) === user_id;
                  });

                  if (my_sub) {
                    /* eslint-disable-next-line ember/no-get */
                    assn.set('currentUserSubmission', my_sub.get('id'));
                    /* eslint-disable-next-line ember/no-get */

                    assn.set('currentUserSubmissionGrade', my_sub.get('grade'));
                    assn.set('currentUserSubmissionScore', my_sub.get('score'));
                    /* eslint-disable-next-line ember/no-get */

                    assn.set('grade_matches_current_submission', true);
                  }
                });
              }
            });
          } else {// TODO: This needs to be replaced with the EAPM equivalent
            // let role = section.get('role');
            //if ( window.newrelic && role ) {
            //  window.newrelic.setCustomAttribute('role', role);
            //}
          }
        }, function (msg) {
          return Ember.debug('Course load error', msg);
        });
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        /* eslint-disable-next-line ember/no-get */
        section: this.session.get('section'),

        /* eslint-disable-next-line ember/no-get */
        classroom: this.session.get('course') // PRM: This breaks some courses, such as #311.  I have no idea why.
        //lessons: this.store.findAll('lesson'),

      });
    },
    afterModel: function afterModel(model) {
      /* eslint-disable-next-line ember/no-get */
      document.title = model.classroom.get('title') + ' - Berklee Online';
      /* eslint-disable-next-line ember/no-get */

      if (this.get('session.isInstructor')) {
        this.session.set('panel', 'activity');
      } else {
        this.session.set('panel', this.defaultPanel);
      }
    },
    redirect: function redirect() {
      var _this2 = this;

      // Navigate to the last place the user was on first load
      // IF there is no exact URL given
      // OTHERWISE navigate to given URL
      // this goes in redirect() instead of afterModel() because using
      // redirect() means that we do not repeat the classroom route's
      // model hooks.
      var lp = this.paramsFor('classroom.lessons');
      var hasItemID = lp && Object.prototype.hasOwnProperty.call(lp, 'item_id') && lp.item_id;

      if (!hasItemID) {
        return this.getRecentLessonInfo().then(function (info) {
          var lesson_id = info.lesson_id,
              item_id = info.item_id;

          _this2.replaceWith('classroom.lessons', lesson_id, item_id);
        });
      }
    },
    renderTemplate: function renderTemplate() {
      var notificationController = this.controllerFor('notifications');
      var bannerDrawerController = this.controllerFor('banner-drawer');
      var conversationsController = this.controllerFor('conversations');
      var gradebookController = this.controllerFor('gradebook');
      var eventsController = this.controllerFor('events');
      this.render(); // Render primary template

      this.render('notifications', {
        // the template to render
        into: 'classroom',
        outlet: 'notification-outlet',
        // the template to render into
        controller: notificationController,
        model: false
      });
      this.render('banner-drawer', {
        // the template to render
        into: 'classroom',
        outlet: 'banner-drawer',
        // the template to render into
        controller: bannerDrawerController,
        model: false
      });
      this.render('gradebook', {
        into: 'classroom',
        outlet: 'gradebook',
        controller: gradebookController
      });
      this.render('conversations', {
        into: 'classroom',
        outlet: 'conversations',
        controller: conversationsController,
        model: false
      });
      this.render('events', {
        into: 'classroom',
        outlet: 'events',
        controller: eventsController
      });
      var userprofileCtl = this.controllerFor('userprofile');
      this.render('userprofile', {
        // the template to render
        into: 'classroom',
        outlet: 'userprofile',
        // the template to render into
        controller: userprofileCtl
      });
      /* eslint-disable-next-line ember/no-get */

      if (this.get('session.isStaticContent') && (
      /* eslint-disable-next-line ember/no-get */
      this.get('session.course.isSample') || this.get('session.course.isPublic'))) {
        /* eslint-disable-next-line ember/no-get */
        var currentUser = this.get('session.user');
        /* eslint-disable-next-line ember/no-get */

        Appcues.identify(currentUser.get('id').toString(), {
          /* eslint-disable-next-line ember/no-get */
          name: currentUser.get('name'),

          /* eslint-disable-next-line ember/no-get */
          email: currentUser.get('email')
        });
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.setProperties({
        courseDiscussions: null,
        courseAssignments: null
      });
      this.store.findAll('discussion').then(function (d) {
        controller.set('courseDiscussions', d);
        controller.set('courseRequiredDiscussions', d.filterBy('is_required'));
      });
      this.store.findAll('assignment').then(function (a) {
        controller.set('courseAssignments', a);
      });
      this.store.findAll('quiz').then(function (q) {
        controller.set('courseQuizzes', q);
      });
    }
  });

  _exports.default = _default;
});