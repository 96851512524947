define("bocce/templates/classroom/lessons/announcement-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SnejjDW4",
    "block": "{\"symbols\":[],\"statements\":[[3,\" reuse the discussion-new template \"],[2,\"\\n\"],[19,\"classroom/lessons/discussion-new\",[]]],\"hasEval\":true,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/classroom/lessons/announcement-new.hbs"
    }
  });

  _exports.default = _default;
});