define("bocce/templates/discussion-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zmWHhjIP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"author\"],[14,\"role\",\"region\"],[14,1,\"discussion-header\"],[14,\"tabindex\",\"0\"],[12],[2,\"\\n  \"],[8,\"user-portrait\",[],[[\"@user\",\"@action\"],[[34,0,[\"user\"]],[30,[36,1],[[32,0],\"toggleUserProfile\",[35,0,[\"user\",\"id\"]]],null]]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"info clearfix\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"subject\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"discussion-type hidden\"],[12],[2,\"\\n        Conversation\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"discussion-title\"],[12],[2,\"\\n        \"],[2,[35,0,[\"title\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[11,\"button\"],[24,\"aria-hidden\",\"true\"],[24,0,\"author-name\"],[16,\"aria-label\",[31,[\"View User Profile of \",[34,0,[\"user\",\"name\"]]]]],[4,[38,1],[[32,0],\"toggleUserProfile\",[35,0,[\"user\",\"id\"]]],null],[12],[2,\"\\n      \"],[1,[35,0,[\"user\",\"name\"]]],[2,\" \"],[8,\"user-icons\",[],[[\"@user\",\"@isInstructor\"],[[34,0,[\"user\"]],[34,2,[\"isInstructor\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,3],[[35,0,[\"is_required\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"date\"],[12],[2,\"Due \"],[1,[35,0,[\"formattedDiscussionDueDate\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[11,\"button\"],[16,0,[31,[\"subscribe-button \",[30,[36,3],[[35,0,[\"subscribed\"]],\"subscribed\"],null]]]],[24,\"aria-label\",\"Subscribe to this discussion\"],[4,[38,1],[[32,0],\"subscribe\"],null],[12],[6,[37,3],[[35,0,[\"subscribed\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"i\"],[14,0,\"far fa-check-circle\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" Subscribed\"]],\"parameters\":[]},{\"statements\":[[2,\"Not Subscribed\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action\",\"session\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/discussion-header.hbs"
    }
  });

  _exports.default = _default;
});