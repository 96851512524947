define("bocce/utilities/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.textareaDialog = _exports.inputDialog = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  // Takes a message and an array of choices, and displays a dialog to
  // the user. Returns a promise that resolves to the user's selection.
  var NONE = 0;
  var INPUT = 1;
  var TEXTAREA = 2;

  function _default(message) {
    var choices = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['OK'];
    var body = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var textInput = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    var additionalClasses = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
    return dialog(message, choices, body, textInput ? INPUT : NONE, '', additionalClasses);
  }

  var inputDialog = function inputDialog(message) {
    var choices = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['OK'];
    var body = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var defaultInput = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    var additionalClasses = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
    return dialog(message, choices, body, INPUT, defaultInput, additionalClasses);
  };

  _exports.inputDialog = inputDialog;

  var textareaDialog = function textareaDialog(message) {
    var choices = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['OK'];
    var body = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var defaultInput = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    var additionalClasses = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
    return dialog(message, choices, body, TEXTAREA, defaultInput, additionalClasses);
  };

  _exports.textareaDialog = textareaDialog;

  function dialog(message) {
    var choices = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['OK'];
    var body = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var inputType = arguments.length > 3 ? arguments[3] : undefined;
    var defaultInput = arguments.length > 4 ? arguments[4] : undefined;
    var additionalClassesIn = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : [];
    return new Promise(function (resolve) {
      var textInputHTML = '';

      if (inputType === INPUT) {
        textInputHTML = "<input type=\"text\" value=\"".concat(defaultInput, "\" class=\"dialog-text-input\"></input>");
      } else if (inputType === TEXTAREA) {
        textInputHTML = "<textarea type=\"text\" class=\"dialog-text-input\">".concat(defaultInput, "</textarea>");
      }

      var bodyHTML = body ? "<div class=\"dialog-body-markup\">".concat(body, "</div>") : '';
      var choicesHTML = choices.map(function (choice, index) {
        return "<button data-option=\"".concat(choice, "\" data-option-id=\"").concat(index, "\" class=\"choice\">").concat(choice, "</button>");
      }).join('');
      var additionalClasses = null;

      if (Array.isArray(additionalClassesIn)) {
        additionalClasses = additionalClassesIn.length > 0 ? ' ' + additionalClassesIn.join(' ') : '';
      } else {
        additionalClasses = ' ' + additionalClassesIn;
      }

      var typeClass = '';

      if (inputType === INPUT) {
        typeClass = 'input';
      } else if (inputType === TEXTAREA) {
        typeClass = 'textarea';
      }

      var dialogHTML = "\n      <div class=\"conf-dialog-mask ".concat(typeClass).concat(additionalClasses, "\"></div>\n      <div class=\"conf-dialog ").concat(typeClass).concat(additionalClasses, "\" \n            role=\"alert\" aria-activedescendant=\"dialog-body\">\n        <div class=\"dialog-body\" tabindex=\"0\" id=\"dialog-body\">").concat(message, "</div>\n        ").concat(textInputHTML, "\n        ").concat(bodyHTML, "\n        ").concat(choicesHTML, "\n      </div>\n    ");
      var buttonDisabledsMap = new Map();

      var _iterator = _createForOfIteratorHelper(document.getElementsByTagName('a')),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var a = _step.value;
          a.setAttribute('tabIndex', '-1');
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      var _iterator2 = _createForOfIteratorHelper(document.getElementsByTagName('button')),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var button = _step2.value;
          buttonDisabledsMap.set(button, button.disabled);
          button.setAttribute('disabled', 'true');
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      document.body.insertAdjacentHTML('beforeend', dialogHTML);
      window.setTimeout(function () {
        var choice = document.querySelector('.conf-dialog .choice');

        if (choice) {
          choice.focus();
        }
      }, 1);

      var _iterator3 = _createForOfIteratorHelper(document.querySelectorAll('.conf-dialog .choice')),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var choice = _step3.value;
          choice.addEventListener('click', function (event) {
            // If there's a text input, resolve to the input value.
            // Otherwise, resolve to the button that was pushed.
            if (inputType !== NONE) {
              var inputValue = document.querySelector('.dialog-text-input').value;
              var firstOptionSelected = event.target.dataset.optionId === '0';

              if (firstOptionSelected) {
                resolve(inputValue);
              } else {
                resolve(false);
              }
            } else {
              var selection = event.target.getAttribute('data-option');
              resolve(selection);
            }

            var dmask = '.conf-dialog-mask, .conf-dialog';

            var _iterator4 = _createForOfIteratorHelper(document.querySelectorAll(dmask)),
                _step4;

            try {
              for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
                var elem = _step4.value;
                elem.remove();
              }
            } catch (err) {
              _iterator4.e(err);
            } finally {
              _iterator4.f();
            }

            var _iterator5 = _createForOfIteratorHelper(document.getElementsByTagName('a')),
                _step5;

            try {
              for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
                var _a = _step5.value;

                _a.removeAttribute('tabindex');
              }
            } catch (err) {
              _iterator5.e(err);
            } finally {
              _iterator5.f();
            }

            var _iterator6 = _createForOfIteratorHelper(document.getElementsByTagName('button')),
                _step6;

            try {
              for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
                var b = _step6.value;
                b.disabled = buttonDisabledsMap.get(b);
              }
            } catch (err) {
              _iterator6.e(err);
            } finally {
              _iterator6.f();
            }
          });
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
    });
  }

  var _default2 = _default;
  _exports.default = _default2;
});