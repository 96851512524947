define("bocce/mixins/av-players", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create({
    //Keep track of the currently playing audio so that playback can be tracked with a progress bar.
    playingAudio: {
      id: '',
      target: null
    },
    startAV: function startAV() {
      var _this$timeupdateEvent, _this$endedEventHandl, _this$pauseEventHandl, _this$playingEventHan;

      /* eslint-disable-next-line ember/no-jquery */
      var mainPanel = Ember.$('.main-panel'),

      /* eslint-disable-next-line ember/no-jquery */
      audio = Ember.$('.audio-player .player').get(0),

      /* eslint-disable-next-line ember/no-jquery */
      seek = Ember.$('.audio-player .audio-controls #player-seek'),

      /* eslint-disable-next-line ember/no-jquery */
      volume = Ember.$('.audio-player #player-vol'),

      /* eslint-disable-next-line ember/no-jquery */
      timeCode = Ember.$('.audio-player .audio-timecode'),

      /* eslint-disable-next-line ember/no-jquery */
      controls = Ember.$('.audio-controls > i, .audio-controls > label').not('.fa-repeat'),

      /* eslint-disable-next-line ember/no-jquery */
      controlsPlayPause = Ember.$('.audio-controls > i, .audio-controls > label').not('.fa-repeat').not('.nextBtn').not('prevBtn'),

      /* eslint-disable-next-line ember/no-jquery */
      repeatBtn = Ember.$('.audio-controls > .fa-repeat'),

      /* eslint-disable-next-line ember/no-jquery */
      player = Ember.$('.audio-player'),

      /* eslint-disable-next-line ember/no-jquery */
      title = Ember.$('.header .current-course-topic'),

      /* eslint-disable-next-line ember/no-jquery */
      lesson = Ember.$('.audio-player .topic-title');
      /* eslint-disable-next-line ember/no-jquery */

      if (Ember.$.isMobile) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.video-player .fa-chevron-circle-right').addClass('ipad-control');
      }
      /* eslint-disable-next-line ember/no-jquery */


      Ember.$(document)
      /* eslint-disable-next-line ember/no-jquery */
      .on('click', '.track-handle', Ember.$.proxy(this.playAudioFromOb, {
        playingAudio: this.playingAudio,
        lesson: lesson,
        audio: audio,
        controls: controls,
        player: player
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .on('click', '.audio-asset', Ember.$.proxy(this.playAudioFromOb, {
        playingAudio: this.playingAudio,
        lesson: lesson,
        audio: audio,
        controls: controls,
        player: player
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .on('click', '.video-asset', Ember.$.proxy(this.playVideoFromOb, {
        audio: audio,
        player: player,
        context: this
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .on('click', '.video-player .fa-chevron-circle-right', Ember.$.proxy(this.minmaxVideoPlayer, {
        mainPanel: mainPanel
      })).on('click', '.video-player .fa-play-circle', this.videoPlayAction).on('click', '.video-player .fa-pause', this.videoPauseAction).on('click', '.video-player .fa-times-circle', this.closeVideoPlayer)
      /* eslint-disable-next-line ember/no-jquery */
      .on('click', '.video-player #video-speed-1', Ember.$.proxy(this.changeVideoSpeed, {
        speed: 1
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .on('click', '.video-player #video-speed-75', Ember.$.proxy(this.changeVideoSpeed, {
        speed: 0.75
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .on('click', '.video-player #video-speed-05', Ember.$.proxy(this.changeVideoSpeed, {
        speed: 0.5
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .on('click', '.video-player #video-speed-25', Ember.$.proxy(this.changeVideoSpeed, {
        speed: 0.25
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .on('click', '.video-player #video-speed-2', Ember.$.proxy(this.changeVideoSpeed, {
        speed: 2
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .on('click', '.kaltura-asset', Ember.$.proxy(this.playKalturaFromOb, {
        audio: audio,
        player: player,
        videoPlayBtn: this.videoPlayBtn,
        videoPauseBtn: this.videoPauseBtn,
        context: this
      }));
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.video-player .player').on('pause', this.videoPlayBtn).on('playing', this.videoPauseBtn);
      /* eslint-disable-next-line ember/no-jquery */

      seek.on('change.fndtn.slider', Ember.$.proxy(this.seekAudio, audio));
      /* eslint-disable-next-line ember/no-jquery */

      volume.on('input.fndtn.slider', Ember.$.proxy(this.audioVolume, audio));
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.audio-relocate').on('click', Ember.$.proxy(this.audioRelocate, {
        player: player,
        mainPanel: mainPanel
      }));
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.ap-close').on('click', Ember.$.proxy(this.audioClose, {
        playingAudio: this.playingAudio,
        player: player,
        mainPanel: mainPanel
      }));
      this.timeupdateEventHandler = (_this$timeupdateEvent = this.timeupdateEventHandler) !== null && _this$timeupdateEvent !== void 0 ? _this$timeupdateEvent : Ember.$.proxy(this.updateSeek, {
        playingAudio: this.playingAudio,
        seek: seek,
        timeCode: timeCode
      });
      this.endedEventHandler = (_this$endedEventHandl = this.endedEventHandler) !== null && _this$endedEventHandl !== void 0 ? _this$endedEventHandl : Ember.$.proxy(this.ended, controls);
      this.pauseEventHandler = (_this$pauseEventHandl = this.pauseEventHandler) !== null && _this$pauseEventHandl !== void 0 ? _this$pauseEventHandl : Ember.$.proxy(this.paused, controls);
      this.playingEventHandler = (_this$playingEventHan = this.playingEventHandler) !== null && _this$playingEventHan !== void 0 ? _this$playingEventHan : Ember.$.proxy(this.isPlaying, controls);
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(audio)
      /* eslint-disable-next-line ember/no-jquery */
      .on('timeupdate', this.timeupdateEventHandler)
      /* eslint-disable-next-line ember/no-jquery */
      .on('ended', this.endedEventHandler)
      /* eslint-disable-next-line ember/no-jquery */
      .on('pause', this.pauseEventHandler)
      /* eslint-disable-next-line ember/no-jquery */
      .on('playing', this.playingEventHandler);
      mainPanel
      /* eslint-disable-next-line ember/no-jquery */
      .on('scrollstart', Ember.$.proxy(this.vidScrollStart, {
        context: this,
        lessonTitle: title
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .on('scrollstop', Ember.$.proxy(this.vidScrollCheck, {
        context: this,
        lessonTitle: title
      }));
      /* eslint-disable-next-line ember/no-jquery */

      repeatBtn.on('click', Ember.$.proxy(this.toggleRepeatAudio, {
        audio: audio,
        btn: repeatBtn
      }));
      /* eslint-disable-next-line ember/no-jquery */

      controlsPlayPause.on('click', Ember.$.proxy(this.playPauseAudio, {
        audio: audio,
        controls: controls
      }));
    },
    destroyAV: function destroyAV() {
      /* eslint-disable-next-line ember/no-jquery */
      var mainPanel = Ember.$('.main-panel'),

      /* eslint-disable-next-line ember/no-jquery */
      audio = Ember.$('.audio-player .player').get(0),

      /* eslint-disable-next-line ember/no-jquery */
      seek = Ember.$('.audio-player .audio-controls #player-seek'),

      /* eslint-disable-next-line ember/no-jquery */
      volume = Ember.$('.audio-player #player-vol'),

      /* eslint-disable-next-line ember/no-jquery */
      timeCode = Ember.$('.audio-player .audio-timecode'),

      /* eslint-disable-next-line ember/no-jquery */
      controls = Ember.$('.audio-controls i'),

      /* eslint-disable-next-line ember/no-jquery */
      repeatBtn = Ember.$('.audio-controls > .fa-repeat'),

      /* eslint-disable-next-line ember/no-jquery */
      title = Ember.$('.header .current-course-topic'),

      /* eslint-disable-next-line ember/no-jquery */
      player = Ember.$('.audio-player'),

      /* eslint-disable-next-line ember/no-jquery */
      lesson = Ember.$('.audio-player .topic-title');
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(document)
      /* eslint-disable-next-line ember/no-jquery */
      .off('click', '.track-handle', Ember.$.proxy(this.playAudioFromOb, {
        playingAudio: this.playingAudio,
        lesson: lesson,
        audio: audio,
        controls: controls,
        player: player
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .off('click', '.audio-asset', Ember.$.proxy(this.playAudioFromOb, {
        playingAudio: this.playingAudio,
        lesson: lesson,
        audio: audio,
        controls: controls,
        player: player
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .off('click', '.video-asset', Ember.$.proxy(this.playVideoFromOb, {
        audio: audio,
        player: player
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .off('click', '.video-player .fa-chevron-circle-right', Ember.$.proxy(this.minmaxVideoPlayer, {
        mainPanel: mainPanel
      })).off('click', '.video-player .fa-play-circle', this.videoPlayAction).off('click', '.video-player .fa-pause', this.videoPauseAction).off('click', '.video-player .fa-times-circle', this.closeVideoPlayer)
      /* eslint-disable-next-line ember/no-jquery */
      .off('click', '.video-player #video-speed-1', Ember.$.proxy(this.changeVideoSpeed, {
        speed: 1
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .off('click', '.video-player #video-speed-75', Ember.$.proxy(this.changeVideoSpeed, {
        speed: 0.75
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .off('click', '.video-player #video-speed-05', Ember.$.proxy(this.changeVideoSpeed, {
        speed: 0.5
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .off('click', '.video-player #video-speed-25', Ember.$.proxy(this.changeVideoSpeed, {
        speed: 0.25
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .off('click', '.video-player #video-speed-2', Ember.$.proxy(this.changeVideoSpeed, {
        speed: 2
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .off('click', '.kaltura-asset', Ember.$.proxy(this.playKalturaFromOb, {
        audio: audio,
        player: player,
        videoPlayBtn: this.videoPlayBtn,
        videoPauseBtn: this.videoPauseBtn
      }));
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.video-player .player').off('pause', this.videoPlayBtn).off('playing', this.videoPauseBtn);
      /* eslint-disable-next-line ember/no-jquery */

      seek.off('change.fndtn.slider', Ember.$.proxy(this.seekAudio, audio));
      /* eslint-disable-next-line ember/no-jquery */

      volume.off('input.fndtn.slider', Ember.$.proxy(this.audioVolume, audio));
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.audio-relocate').off('click', Ember.$.proxy(this.audioRelocate, player));
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.ap-close').off('click', Ember.$.proxy(this.audioClose, {
        playingAudio: this.playingAudio,
        player: player
      }));
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(audio)
      /* eslint-disable-next-line ember/no-jquery */
      //.off('timeupdate', $.proxy(this.updateSeek, {seek:seek, timeCode: timeCode}))
      .off('timeupdate', this.timeupdateEventHandler)
      /* eslint-disable-next-line ember/no-jquery */
      .off('ended', this.endedEventHandler)
      /* eslint-disable-next-line ember/no-jquery */
      .off('pause', this.pauseEventHandler)
      /* eslint-disable-next-line ember/no-jquery */
      .off('playing', this.playingEventHandler);
      mainPanel
      /* eslint-disable-next-line ember/no-jquery */
      .off('scrollstart', Ember.$.proxy(this.vidScrollStart, {
        context: this,
        lessonTitle: title
      }))
      /* eslint-disable-next-line ember/no-jquery */
      .off('scrollstop', Ember.$.proxy(this.vidScrollCheck, {
        context: this,
        lessonTitle: title
      }));
      /* eslint-disable-next-line ember/no-jquery */

      repeatBtn.off('click', Ember.$.proxy(this.toggleRepeatAudio, {
        audio: audio,
        btn: repeatBtn
      }));
      /* eslint-disable-next-line ember/no-jquery */

      controls.off('click', Ember.$.proxy(this.playPauseAudio, {
        audio: audio,
        controls: controls
      })); // Hide video player if iPhone is detected
      // iPhones don't respect fixed positioning
      // If the player isn't hidden, it'll occasionally float into view
      // Put other iPhone-specific tweaks here

      if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i)) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.video-player').addClass('hidden');
      }
    },
    //Audio Player
    audioRelocate: function audioRelocate() {
      this.mainPanel.scrollTop(0)
      /* eslint-disable-next-line ember/no-jquery */
      .scrollTop(Ember.$('#' + this.player.attr('relocate')).parent().position().top - 10);
    },
    audioClose: function audioClose() {
      //Remove the playing audio's progress bar
      if (this.playingAudio.id && this.playingAudio.target) {
        Ember.$(this.playingAudio.target).closest('#' + this.playingAudio.id).css('background-image', '');
        this.playingAudio.id = '';
      }

      this.player.removeClass('active');
      this.player.find('audio').get(0).pause();
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.classroom').removeClass('audioPlaying');
    },
    seekAudio: function seekAudio(item) {
      var length = this.duration;

      if (length) {
        this.currentTime = parseInt(item.target.value) / 100 * length;
      }
    },
    audioVolume: function audioVolume(item) {
      this.volume = parseInt(item.target.value) / 100;
    },
    updateSeek: function updateSeek(item) {
      var time = item.target.currentTime,
          minutes = Math.floor(time / 60),
          seconds = Math.floor(time) % 60,
          curTime;
      seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();
      minutes = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
      curTime = minutes + ':' + seconds;
      var audioPercentComplete = Math.round(1000 * (time / item.target.duration)) / 10;
      this.seek.val(audioPercentComplete); //Update the playing audio's progress bar.

      if (this.playingAudio.id && this.playingAudio.target) {
        Ember.$(this.playingAudio.target).closest('#' + this.playingAudio.id).css('background-image', "-webkit-linear-gradient(left, lightgray, lightgray ".concat(audioPercentComplete, "%, transparent 0%, transparent 0%"));
      }

      this.timeCode.attr('time_code', curTime);
    },
    playPauseAudio: function playPauseAudio() {
      if (this.audio.paused) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('audio').not('.public_audio_player').each(function (key, ob) {
          ob.pause();
        });
        this.audio.play();
      } else {
        this.audio.pause();
      }
    },
    toggleRepeatAudio: function toggleRepeatAudio() {
      if (this.btn.hasClass('active')) {
        this.btn.removeClass('active');
        this.audio.loop = false;
      } else {
        this.audio.loop = true;
        this.btn.addClass('active');
      }
    },
    playAudioFromOb: function playAudioFromOb(item) {
      var tgt = item.target.attributes.source || item.target.parentNode.attributes.source,
          title = item.target.attributes.title,
          next = item.target.attributes.next,
          prev = item.target.attributes.prev,
          prevBtn = this.player.find('.prevBtn'),
          nextBtn = this.player.find('.nextBtn'),
          relocate; //Clear the previously played audio's progress bar.

      if (this.playingAudio.id && this.playingAudio.target) {
        Ember.$(this.playingAudio.target).closest('#' + this.playingAudio.id).css('background-image', '');
      } //Set the id for the playing audio. 
      //The html attribute 'data-playback-tracking-id' is added to the parsed topic body's html from canvas.


      this.playingAudio.id = item.target.attributes['data-playback-tracking-id'] ? item.target.attributes['data-playback-tracking-id'].value : false;
      this.playingAudio.target = item.target;
      /* eslint-disable-next-line ember/no-jquery */

      title = title ? title.value : Ember.$(item.target).closest('.lesson-content').prev().attr('topic_title');
      /* eslint-disable-next-line ember/no-jquery */

      var video_player = Ember.$('.video-player .player').get(0);
      var kCont = document.getElementById('kalturaContainer');

      if (kCont.sendNotification) {
        kCont.sendNotification('pause');
      }

      prevBtn.addClass('inactive');
      nextBtn.addClass('inactive');
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('audio').not('.public_audio_player').each(function (key, ob) {
        ob.pause();
      });

      if (prev) {
        prevBtn.removeClass('inactive').attr('for', prev.value);
      }

      if (next) {
        nextBtn.removeClass('inactive').attr('for', next.value);
      }

      video_player.pause();
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.video-player.active').removeClass('active');
      var previousPlaybackRate = this.audio.playbackRate;
      this.audio.attributes.src.value = tgt.value;
      this.audio.load();
      this.audio.playbackRate = previousPlaybackRate;
      this.audio.play();
      this.player.addClass('active');
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.classroom').addClass('audioPlaying');
      this.lesson.attr('title', title);
      /* eslint-disable-next-line ember/no-jquery */

      relocate = Ember.$(item.target).closest('.audio-asset').attr('id') || Ember.$(item.target).closest('.audio_multi').attr('id');
      this.player.attr('relocate', relocate);
    },
    ended: function ended() {
      var player = document.getElementById('publicPlayer'); //  Activate 1 (play button)

      this.get(1).classList.remove('inactive'); //  Deactivate 2 (pause button)

      this.get(2).classList.add('inactive'); //  If 3 (Next button) is active, click it

      if (!this.get(3).classList.contains('inactive')) {
        this.get(3).click();
      } else if (player.loop && player.currentTime > Math.floor(player.duration - 1) && event.eventPhase === 2) {
        player.currentTime = 0;
        player.play();
      }
    },
    paused: function paused() {
      //  Activate 1 (play button)
      this.get(1).classList.remove('inactive'); //  Deactivate 2 (pause button)

      this.get(2).classList.add('inactive');
    },
    isPlaying: function isPlaying() {
      // Activate 2 (pause button)
      this.get(2).classList.remove('inactive'); // Deactivate 1 (play button)

      this.get(1).classList.add('inactive');
    },
    closeAudioPlayer: function closeAudioPlayer() {
      if (this.playingAudio.id && this.playingAudio.target) {
        Ember.$(this.playingAudio.target).closest('#' + this.playingAudio.id).css('background-image', '');
      }

      this.audio.pause();
      this.player.removeClass('active');
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.floating-modal.active').removeClass('audioPlaying');
    },
    //Video Player
    playVideoFromOb: function playVideoFromOb(item) {
      var rect = item.target.parentElement.lastElementChild.getBoundingClientRect(),
          mainRect = document.querySelector('.main-panel').getBoundingClientRect(),

      /* eslint-disable-next-line ember/no-jquery */
      cprnt = Ember.$(item.target)[0].tagName.toLowerCase() === 'button' ? item.target : false,

      /* eslint-disable-next-line ember/no-jquery */
      cpent = !cprnt && (Ember.$(item.target)[0].tagName.toLowerCase() === 'img' || Ember.$(item.target)[0].tagName.toLowerCase() === 'i') ? item.target.parentElement : item.target;
      /* eslint-disable-next-line ember/no-jquery */

      cprnt = !cprnt && (Ember.$(item.target)[0].tagName.toLowerCase() === 'img' || Ember.$(item.target)[0].tagName.toLowerCase() === 'i') ? item.target.parentNode : item.target;
      /* eslint-disable-next-line ember/no-jquery */

      var inf = Ember.$(cprnt).find('i')[0];
      /* eslint-disable-next-line ember/no-jquery */

      var video = Ember.$('.video-player').get(0);
      /* eslint-disable-next-line ember/no-jquery */

      var video_player = Ember.$('.video-player .player').get(0);
      this.context.closeVideoPlayer();
      this.audio.pause();
      video.style.top = item.target.parentElement.offsetTop + 'px';
      video.style.height = rect.height + 'px';
      video.style.left = rect.left - mainRect.left + 'px';
      video.style.width = rect.width + 'px';
      this.targetParent = item.target.parentElement;
      this.player.removeClass('active');
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('#kalturaPlayer.active').removeClass('minified');

      if (inf.classList.contains('fa-pause')) {
        inf.classList.add('fa-play');
        inf.classList.add('paused');
        video_player.pause();
        return;
      }

      if (cprnt.attributes.source.value !== this.context.get('currentVideo')) {
        var source = video_player.getElementsByTagName('source')[0],
            tracks = video_player.getElementsByTagName('track'),
            track_url = cprnt.attributes.track.value,
            source_url = cprnt.attributes.source.value;
        this.context.set('currentVideo', source_url);
        source.setAttribute('src', source_url); // PRM: We can't just reuse the existing track node -- Chrome, at least,
        // doesn't reload the files when src changes

        if (tracks.length > 0) {
          video_player.removeChild(tracks[0]);
        }

        if (track_url) {
          var tn = document.createElement('track');
          tn.setAttribute('src', track_url);
          tn.setAttribute('kind', 'subtitles');
          tn.setAttribute('srclang', 'en');
          tn.setAttribute('label', 'English');
          video_player.appendChild(tn);
        }

        video_player.load();
      }
      /* eslint-disable-next-line ember/no-jquery */


      Ember.$('.player.video i, .kaltura-asset i').removeClass('fa-pause').removeClass('paused').addClass('fa-play');
      inf.classList.add('fa-pause');
      inf.classList.remove('fa-play');
      inf.classList.remove('paused');
      video_player.play(); // Don't perform video movement on iPhone -- that goes straight to full screen

      if (!navigator.userAgent.match(/iPhone/i) && !navigator.userAgent.match(/iPod/i)) {
        video.classList.remove('minified');
        video.classList.add('active');
        video_player.setAttribute('controls', 'true');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.main-panel-container').off('resize', Ember.$.proxy(function () {
          var rect = cpent.getBoundingClientRect(),
              mainRect = document.querySelector('.main-panel').getBoundingClientRect();
          video.style.top = cpent.offsetTop + 'px';
          video.style.height = rect.height + 'px';
          video.style.left = rect.left - mainRect.left + 'px';
          video.style.width = rect.width + 'px';
        }, this));
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.main-panel-container').on('resize', Ember.$.proxy(function () {
          var rect = cpent.getBoundingClientRect(),
              mainRect = document.querySelector('.main-panel').getBoundingClientRect();
          video.style.top = cpent.offsetTop + 'px';
          video.style.height = rect.height + 'px';
          video.style.left = rect.left - mainRect.left + 'px';
          video.style.width = rect.width + 'px';
        }, this));
      }
    },
    closeVideoPlayer: function closeVideoPlayer() {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.video-player.active').removeClass('active').addClass('minified');
      var kCont = document.getElementById('kalturaContainer');

      if (kCont.sendNotification) {
        kCont.sendNotification('pause');
      }
      /* eslint-disable-next-line ember/no-jquery */


      var video_player = Ember.$('.video-player .player').get(0);
      video_player.pause();
      video_player.firstElementChild.attributes.getNamedItem('src').value = '';
    },
    //Kaltura Video Player
    playKalturaFromOb: function playKalturaFromOb(item) {
      var _this = this;

      var rect = item.target.parentElement.lastElementChild.getBoundingClientRect(),
          mainRect = document.querySelector('.main-panel').getBoundingClientRect(),

      /* eslint-disable-next-line ember/no-jquery */
      ctxt = Ember.$(item.target).find('i').length > 0 || Ember.$(item.target)[0].tagName.toLowerCase() !== 'img' ? item.target : Ember.$(item.target.parentNode).find('i')[0],

      /* eslint-disable-next-line ember/no-jquery */
      cprnt = Ember.$(item.target)[0].tagName.toLowerCase() === 'button' ? item.target : false,

      /* eslint-disable-next-line ember/no-jquery */
      cpent = !cprnt && (Ember.$(item.target)[0].tagName.toLowerCase() === 'img' || Ember.$(item.target)[0].tagName.toLowerCase() === 'i') ? item.target.parentElement : item.target;
      /* eslint-disable-next-line ember/no-jquery */

      cprnt = !cprnt && (Ember.$(item.target)[0].tagName.toLowerCase() === 'img' || Ember.$(item.target)[0].tagName.toLowerCase() === 'i') ? item.target.parentNode : item.target;
      /* eslint-disable-next-line ember/no-jquery */

      var inf = Ember.$(cprnt).find('i')[0];
      /* eslint-disable-next-line ember/no-jquery */

      var video = Ember.$('#kalturaPlayer').get(0);
      this.context.closeVideoPlayer();
      this.audio.pause();
      video.style.top = item.target.parentElement.offsetTop + 'px';
      video.style.height = rect.height + 'px';
      video.style.left = rect.left - mainRect.left + 'px';
      video.style.width = rect.width + 'px';
      this.targetParent = item.target.parentElement;
      this.player.removeClass('active');
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('#vanillaPlayer.active').removeClass('minified');

      if (inf.classList.contains('fa-pause')) {
        var kCont = document.getElementById('kalturaContainer');

        if (kCont.sendNotification) {
          inf.classList.add('fa-play');
          inf.classList.add('paused');
          kCont.sendNotification('pause');
        }

        return;
      }
      /* eslint-disable-next-line ember/no-jquery */


      Ember.$('.video-asset .fa-pause, .kaltura-asset .fa-pause').removeClass('fa-pause').removeClass('paused').addClass('fa-play');
      inf.classList.add('fa-pause');
      inf.classList.remove('fa-play');
      inf.classList.remove('paused'); // Don't perform video movement on iPhone -- that goes straight to full screen

      if (!navigator.userAgent.match(/iPhone/i) && !navigator.userAgent.match(/iPod/i)) {
        video.classList.remove('minified');
        video.classList.add('active');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.main-panel-container').off('resize', Ember.$.proxy(function () {
          var rect = cpent.getBoundingClientRect(),
              mainRect = document.querySelector('.main-panel').getBoundingClientRect();
          /* eslint-disable-next-line ember/no-jquery */

          var video = Ember.$('.video-player.active')[0];

          if (video.length === 0) {
            return;
          }

          video.style.top = cpent.offsetTop + 'px';
          video.style.height = rect.height + 'px';
          video.style.left = rect.left - mainRect.left + 'px';
          video.style.width = rect.width + 'px';
        }, this));
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.main-panel-container').on('resize', Ember.$.proxy(function () {
          var rect = cpent.getBoundingClientRect(),
              mainRect = document.querySelector('.main-panel').getBoundingClientRect();
          /* eslint-disable-next-line ember/no-jquery */

          var video = Ember.$('.video-player.active')[0];

          if (video && video.length === 0) {
            return;
          }

          video.style.top = cpent.offsetTop + 'px';
          video.style.height = rect.height + 'px';
          video.style.left = rect.left - mainRect.left + 'px';
          video.style.width = rect.width + 'px';
        }, this));
      }

      if (!ctxt.classList.contains('paused')) {
        var _kCont = document.getElementById('kalturaContainer');

        var source_id = cprnt.attributes.source.value;
        var account = cprnt.attributes.account.value;
        var player_id = cprnt.attributes.player_id.value;
        var player_multi = !!cprnt.attributes.multi;

        if (_kCont.sendNotification) {
          _kCont.sendNotification('changeMedia', {
            'entryId': source_id
          });
        } else {
          try {
            if (player_multi) {
              kWidget.embed({
                targetId: 'kalturaContainer',
                flashvars: {
                  'autoPlay': true,
                  'autoMute': false,
                  'playlistAPI.kpl0Id': source_id
                },
                wid: '_' + account,
                uiconf_id: player_id
              });
            } else {
              kWidget.embed({
                targetId: 'kalturaContainer',
                flashvars: {
                  'autoPlay': true,
                  'autoMute': false
                },
                wid: '_' + account,
                uiconf_id: player_id,
                entry_id: source_id
              });
            }

            kWidget.addReadyCallback(function (playerId) {
              var kdp = document.getElementById(playerId);
              kdp.kBind('playerPaused', function (data) {
                _this.videoPlayBtn(data, true);
              });
              kdp.kBind('playerPlayed', function (data) {
                _this.videoPauseBtn(data, true);
              });
              kdp.kBind('doPlay', function () {
                kdp.sendNotification('changeVolume', 1);
                kdp.kUnbind('doPlay');
              });
            });
          } catch (e) {
            Ember.debug(e.message);
          }
        }
      }
    },
    changeVideoSpeed: function changeVideoSpeed() {
      /* eslint-disable-next-line ember/no-jquery */
      var video_player = Ember.$('.video-player.active video')[0];

      if (!video_player) {
        return;
      }
      /* eslint-disable-next-line ember/no-jquery */


      Ember.$('.video-speed-selector .option').removeClass('current');
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(event.target).addClass('current');
      video_player.playbackRate = this.speed;
    },
    vidScrollStart: function vidScrollStart() {
      this.lessonTitle.addClass('scrolling');
    },
    vidScrollCheck: function vidScrollCheck() {
      /* eslint-disable-next-line ember/no-jquery */
      var activeVid = Ember.$('.video-asset .fa-pause, .video-asset .paused, .kaltura-asset .fa-pause, .kaltura-asset .paused');
      /* eslint-disable-next-line ember/no-jquery */

      var video = Ember.$('.video-player.active')[0];

      if (!video) {
        return;
      }

      this.lessonTitle.removeClass('scrolling');

      if (!video.classList.contains('minified') && activeVid.length > 0) {
        if (activeVid.parent('button:in-viewport').length === 0) {
          this.context.minmaxVideoPlayer();
        }
      }
    },
    minmaxVideoPlayer: function minmaxVideoPlayer() {
      /* eslint-disable-next-line ember/no-jquery */
      var video = Ember.$('.video-player.active');
      /* eslint-disable-next-line ember/no-jquery */

      var video_player = Ember.$('.video-player .player').get(0);

      if (video.hasClass('minified')) {
        video.removeClass('minified');

        if (video_player) {
          video_player.setAttribute('controls', 'true');
        }

        var vspec = video.get(0); // Scroll video into view

        /* eslint-disable-next-line ember/no-jquery */

        if (Ember.$('.video-player:in-viewport').length < 1) {
          this.mainPanel.scrollTop(0).scrollTop(vspec.style.top.substr(0, vspec.style.top.length - 2));
        }
      } else {
        if (!video.hasClass('paused')) {
          video.addClass('minified');

          if (video_player) {
            video_player.removeAttribute('controls');
          }
        }
      }
    },
    videoPlayBtn: function videoPlayBtn(evt, kaltura) {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.video-player.active').removeClass('playing').addClass('paused');

      if (kaltura) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.kaltura-asset .fa-pause').addClass('fa-play').removeClass('fa-pause').addClass('paused');
      } else {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.video-asset .fa-pause').addClass('fa-play').removeClass('fa-pause').addClass('paused');
      }
    },
    videoPauseBtn: function videoPauseBtn(evt, kaltura) {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.video-player.active').addClass('playing').removeClass('paused');

      if (kaltura) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.kaltura-asset .paused').removeClass('fa-play').removeClass('paused').addClass('fa-pause');
      } else {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.video-asset .paused').removeClass('fa-play').removeClass('paused').addClass('fa-pause');
      }
    },
    videoPlayAction: function videoPlayAction() {
      var kCont = document.getElementById('kalturaContainer');

      if (kCont.sendNotification) {
        kCont.sendNotification('play');
      } else {
        /* eslint-disable-next-line ember/no-jquery */
        var video_player = Ember.$('.video-player .player').get(0);
        video_player.play();
      }
    },
    videoPauseAction: function videoPauseAction() {
      var kCont = document.getElementById('kalturaContainer');

      if (kCont.sendNotification) {
        kCont.sendNotification('pause');
      } else {
        /* eslint-disable-next-line ember/no-jquery */
        var video_player = Ember.$('.video-player .player').get(0);
        video_player.pause();
      }
    }
  });

  _exports.default = _default;
});