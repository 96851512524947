define("bocce/mixins/interactions/timed_assignment", [], function () {
  "use strict";

  window.bm = window.bm || {};
  /* eslint-disable-next-line ember/no-jquery */

  Ember.$(function () {
    window.bm.TimedAssignment = function (el) {
      /* eslint-disable-next-line ember/no-jquery */
      this.$el = Ember.$(el);
      this.$el.find('.interaction_content').removeAttr('style');
      this.$interactionData = this.$el.find('.interaction_data');
      this.$containerEl = this.$el.find('.interaction_content');
      this.init();
    };

    window.bm.TimedAssignment.prototype = {
      init: function init() {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('<div class="timedAssignment">Timed Assignment</div>').appendTo(this.$containerEl);
      }
    };
  });
});