define("bocce/controllers/discussions", ["exports", "bocce/mixins/audio-rec", "bocce/mixins/discussable", "bocce/mixins/user-profile", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/enmodal"], function (_exports, _audioRec, _discussable, _userProfile, _videoRec, _rtcRec, _enmodal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Controller.extend(_audioRec.default, _discussable.default, _userProfile.default, _videoRec.default, _rtcRec.default, _enmodal.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.sortProperties = this.sortProperties || ['sortDate:desc'];
    },
    userprofile: Ember.inject.controller(),
    // required by UserProfileMixin
    classroom: Ember.inject.controller(),
    // required by DiscussableMixin
    discussion: Ember.inject.controller('classroom.lessons.discussion'),
    // required by DiscussableMixin
    newDiscussion: Ember.inject.controller('classroom.lessons.discussion-new'),
    // required by DiscussableMixin
    // When the app first boots up, the discussions controller loads
    // without a model property. When that happens Ember crashes because
    // computed properties try and get data from the model. This property
    // returns the model if it exists, otherwise returns an empty array.
    all_model: Ember.computed('model', function () {
      return this.model || [];
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    numRequiredDiscussions: Ember.computed('discussions.{@each.read,responses.@each.read}', 'all_model.[]', 'activities.@each.read', function () {
      return this.requiredDiscussions.filter(function (discussion) {
        return (!discussion.get('read') || discussion.get('hasUnreadResponses')) && !discussion.get('locked');
      }).length;
    }),
    numUnrequiredDiscussions: Ember.computed('discussions.{@each.read,responses.@each.read}', 'all_model.[]', function () {
      var filteredConversations = this.discussions.filter(function (discussion) {
        return !discussion.get('is_required') && (!discussion.get('read') || discussion.get('hasUnreadResponses')) && !discussion.get('locked');
      });
      return filteredConversations.length;
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    numSubmissionsNeedAttention: Ember.computed('submissions.@each.{needsStudentAttention,needsTeacherAttention}', function () {
      var subs = this.submissions,

      /* eslint-disable-next-line ember/no-get */
      filter = this.get('session.isInstructor') ? 'needsTeacherAttention' : 'needsStudentAttention';
      return subs.filterBy(filter, true).get('length');
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    numAssignmentsNeedAttention: Ember.computed('assignments.@each.{needsStudentAttention,needsTeacherAttention}', 'submissions.@each.{needsStudentAttention,needsTeacherAttention}', function () {
      var assignments = this.assignments,

      /* eslint-disable-next-line ember/no-get */
      filter = this.get('session.isInstructor') ? 'needsTeacherAttention' : 'needsStudentAttention';
      return assignments.filterBy(filter, true).get('length');
    }),
    discussions: Ember.computed.filterBy('all_model', 'is_announcement', false),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    requiredDiscussions: Ember.computed('all_model.@each.{is_announcement,responses}', function () {
      var retVal = this.all_model.filterBy('is_required', true);
      /* eslint-disable-next-line ember/no-get */

      if (this.get('session.isInstructor')) {
        retVal = retVal.filter(function (discussion) {
          var now = moment(),
              discussionDue = moment(discussion.get('due_at'));
          return !discussion.get('due_at') || discussionDue < now || discussion.get('dueWithinOneWeek') || discussion.get('dueWithinTwoWeeks');
        });
      }

      return retVal;
    }),
    announcements: Ember.computed.filterBy('all_model', 'is_announcement', true),
    assignments: Ember.computed('loadedAssignments', function () {
      if (this.loadedAssignments) {
        return this.loadedAssignments;
      } else {
        return [];
      }
    }),
    submissions: Ember.computed('assignments.@each.submissions', function () {
      var retval = Ember.A();
      this.assignments.forEach(function (a) {
        return retval.pushObjects(a.get('submissions').toArray());
      });
      return retval;
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    quizzes: Ember.computed(function () {
      return this.store.findAll('quiz');
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    work: Ember.computed('quizzes.@each.attempts', 'discussions.@each.{id,unread_count}', 'assignments.@each.{id,numUngradedSubmissions,submissions,teacher_requires_resubmission}', function () {
      var discussions = this.requiredDiscussions || [],
          assignments = this.assignments || [],
          quizzes = this.quizzes || [],
          quizLength,
          stream = Ember.A(),
          i,
          current,
          emptyWeek = true,
          dividers = [{
        due_at: moment(new Date()).add(1, 'year').isoWeekday(6).toISOString(),
        is_divider: true,
        divider_message: 'To-Do',
        current: true,
        overdue: false
      }, {
        due_at: moment(new Date()).add(1, 'weeks').isoWeekday(1).toISOString(),
        is_divider: true,
        divider_message: 'Completed',
        overdue: true,
        current: false
      }];
      quizLength = quizzes.get('length');

      if (quizLength > 0) {
        for (i = 0; i < quizLength; i++) {
          if (quizzes.objectAt(i).get('attempts.length') > 0) {
            quizzes.objectAt(i).set('latestAttempt', quizzes.objectAt(i).get('attempts').objectAt(0));
          }
        }
      }

      stream.pushObjects(assignments.toArray());
      stream.pushObjects(discussions.toArray());
      stream.pushObjects(quizzes.toArray());

      for (i = 0; i < stream.length; i++) {
        if (!stream[i].get('is_divider')) {
          current = stream[i].get('i_submitted') || stream[i].get('currentUserSubmission') || stream[i].get('latestAttempt');

          if (stream[i].get('due_at')) {
            stream[i].set('due_at', moment(stream[i].get('due_at')).toISOString());
          }

          stream[i].set('overdue', false); // Mark overdue assignments

          if (!current && (!moment(stream[i].get('due_at')).isSame(Date(), 'week') && !moment(stream[i].get('due_at')).isSame(moment(new Date()).isoWeekday(7), 'day') || moment(stream[i].get('due_at')).isSame(moment(new Date()).isoWeekday(7).subtract(1, 'weeks'), 'day'))) {
            stream[i].set('current', true);
            stream[i].set('overdue', true);
          } else {
            emptyWeek = false;
            stream[i].set('current', !current);
          } // Force marked for resubmission assignments to the top of the pile


          if (stream[i].get('teacher_requires_resubmission') === 'needs_resubmit') {
            stream[i].set('current', true);
            stream[i].set('overdue', true);
          }
        }
      }

      if (emptyWeek) {
        dividers.push({
          due_at: moment(new Date()).add(1, 'days').isoWeekday(1).toISOString(),
          is_divider: true,
          empty_week: true,
          divider_message: 'Nothing due currently.',
          current: true
        });
      }

      stream.pushObjects(dividers);
      return Ember.ArrayProxy.extend({
        init: function init() {
          this._super.apply(this, arguments); // eslint-disable-next-line ember/no-side-effects


          this.sortProperties = this.sortProperties || ['current:desc', 'due_at:desc'];
        },
        content: stream,
        sorted: Ember.computed.sort('content', 'sortProperties')
      }).create();
    }),
    numUnreadAnnouncements: Ember.computed('announcements.@each.read', 'all_model.[]', function () {
      return this.announcements.filterBy('read', false).filterBy('locked', false).length;
    }),
    numUngradedSubmissions: Ember.computed('assignments.@each.numUngradedSubmissions', function () {
      var assignments = this.assignments,
          totalUngraded = 0;
      assignments.forEach(function (a) {
        return totalUngraded += a.get('numUngradedSubmissions');
      });
      return totalUngraded;
    }),
    numResubmissionsPending: Ember.computed('assignments.@each.numResubmissionsPending', function () {
      var assns = this.assignments;
      var retval = 0;
      assns.forEach(function (a) {
        return retval += a.get('numResubmissionsPending');
      });
      return retval;
    }),
    numUnreadSubmissions: Ember.computed('assignments.@each.numUnreadSubmissions', function () {
      var assns = this.assignments;
      return assns.reduce(function (total, assn) {
        return total + assn.get('numUnreadSubmissions');
      }, 0);
    }),
    numUnreadAssignments: Ember.computed('assignments.@each.hasUnreadSubmissions', function () {
      var assns = this.assignments;
      return assns.filterBy('hasUnreadSubmissions', true).get('length');
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    activities: Ember.computed('announcements.@each.{id,unread_count}', 'assignments.@each.{comments,grade,id,numUngradedSubmissions,submissions,teacher_requires_resubmission}', 'discussions.@each.{id,unread_count}', 'filterType', function () {
      var discussions = this.discussions || [],
          announcements = this.announcements || [],
          assignments = this.assignments || [],
          stream = Ember.A(),
          ac = assignments.get('content'),
          i,
          z,
          bc,
          cc,
          currentComment,
          comments = [],
          gDate,
          compareDates = function compareDates(a, b) {
        if (a.date < b.date) {
          return 1;
        } else if (a.date > b.date) {
          return -1;
        }

        return 0;
      };

      moment.tz.add(['EST|EST|50|0|', 'America/New_York|EST EDT EWT EPT|50 40 40 40|01010101010101010101010101010101010101010101010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261t0 1nX0 11B0 1nX0 11B0 1qL0 1a10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 RB0 8x40 iv0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|21e6']); // Find latest response time for each assignment
      // If there are none, go with assignment update time
      // BUT - Only if the filter isn't set to 'assignments'
      // IF it is - assignments should only filter by due date

      if (this.filterType !== 'assignments') {
        if (!!ac && ac.length > 0) {
          for (i = 0; i < ac.length; i++) {
            if (!ac[i].record.get) {
              break;
            }

            bc = ac[i].record.get('submissions.arrangedContent.arrangedContent');

            if (bc) {
              for (z = 0; z < bc.length; z++) {
                cc = bc[z].get('comments.arrangedContent.arrangedContent');
                currentComment = cc[cc.length - 1] || false;

                if (currentComment) {
                  comments.push({
                    date: currentComment.get('created_at'),
                    postTime: moment(currentComment.get('created_at')).tz('America/New_York').calendar()
                  });
                }
              }
            }

            if (comments.length > 0) {
              comments.sort(compareDates);
              ac[i].record.set('lastResponse', comments[0]);
            }

            comments = [];
          }
        }
      }
      /* eslint-disable-next-line ember/no-get */


      if (this.get('session.isInstructor')) {
        assignments = assignments.filter(function (assignment) {
          var now = moment(),
              assignmentDue = moment(assignment.get('due_at'));
          return assignmentDue < now || assignment.get('dueWithinOneWeek') || assignment.get('dueWithinTwoWeeks') || !assignment.get('due_at');
        });
        discussions = discussions.filter(function (discussion) {
          var now = moment(),
              discussionDue = moment(discussion.get('due_at'));
          return !discussion.get('is_required') || !discussion.get('due_at') || discussionDue < now || discussion.get('dueWithinOneWeek') || discussion.get('dueWithinTwoWeeks');
        });
      }

      stream.pushObjects(discussions.toArray());
      stream.pushObjects(announcements.toArray());
      stream.pushObjects(assignments.toArray()); // Conventionalize date format, so Ember can perform sorting properly

      for (i = 0; i < stream.length; i++) {
        if (stream[i].get('date') === stream[i].get('lastResponse.date')) {
          gDate = stream[i].get('due_at') || stream[i].get('lastResponse.date');
        } else {
          gDate = stream[i].get('lastResponse.date') || stream[i].get('due_at');
        }

        if (gDate) {
          stream[i].set('sortDate', moment(gDate).toISOString());
        }
      }

      return Ember.ArrayProxy.extend({
        content: stream,
        sorted: Ember.computed.sort('content', 'sortProperties'),
        sortProperties: this.sortProperties
      }).create();
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    unreadCountActivities: Ember.computed('numRequiredDiscussions', 'numUnrequiredDiscussions', 'numUnreadAnnouncements', 'numSubmissionsNeedAttention', 'numAssignmentsNeedAttention', function () {
      var retval = this.numRequiredDiscussions + this.numUnrequiredDiscussions + this.numUnreadAnnouncements;
      /* eslint-disable-next-line ember/no-get */

      if (this.get('session.isInstructor')) {
        return retval + this.numSubmissionsNeedAttention;
      } else {
        return retval + this.numAssignmentsNeedAttention;
      }
    }),
    unfinishedCountWork: Ember.computed('work', 'discussions.@each.i_submitted', 'assignments.@each.{currentUserSubmission,teacher_requires_resubmission}', function () {
      var work = this.work,
          num_assns = work.filterBy('activity_type', 'assignment').filter(function (a) {
        return !a.get('locked') && (!a.get('currentUserSubmission') || a.get('teacher_requires_resubmission') === 'needs_resubmit');
      }).length,
          num_discs = work.filterBy('activity_type', 'discussion').filter(function (d) {
        return !d.get('locked') && !d.get('i_submitted');
      }).length;
      return num_assns + num_discs;
    }),
    actions: {
      viewInLesson: function viewInLesson(assignment_id) {
        var that = this;
        this.store.findRecord('item', assignment_id).then(function (item) {
          var lessonId = item.get('lesson').id;

          if (lessonId) {
            that.transitionToRoute('classroom.lessons', lessonId, assignment_id);
          }
        }, function () {
          Ember.debug('Can\'t find lesson that contains assignment.');
        });
      },
      filterActivities: function filterActivities(filterType, unread) {
        // Grab value of select when a dropdown filter is used
        if (_typeof(unread) === 'object') {
          unread = unread.target.value;
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.activity-filters .filter-action .styled-select').addClass('current');
        }

        var ungraded = unread === 'ungraded';
        var resubmission = unread === 'resubmission';
        var comments = unread === 'comments';
        var ungraded_and_unread = unread === 'new';

        if (filterType === 'assignments' && !unread) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.activity-filters .filter-action .styled-select option').removeAttr('selected');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.activity-filters .filter-action .styled-select').removeClass('current');
        }

        if (unread === 'all') {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.activity-filters .filter-action .styled-select').removeClass('current');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.activity-filters .filter-action .filter-label.assignments').addClass('current');
          unread = false;
        }

        if (filterType !== 'assignments') {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.activity-filters .filter-action .styled-select').removeClass('current');
        }

        unread = unread === 'unread' ? true : false;
        this.set('filterType', filterType);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.activity-filters .filter-action button').removeClass('current');

        if (unread) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.activity-filters .filter-action .filter-unread-count.' + filterType).addClass('current');
        } else if (ungraded) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.activity-filters .filter-action .filter-ungraded-count.ungraded.' + filterType).addClass('current');
        } else if (resubmission) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.activity-filters .filter-action .filter-ungraded-count.pending-resubmit.' + filterType).addClass('current');
        } else {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.activity-filters .filter-action .filter-label.' + filterType).addClass('current');
        }

        switch (filterType) {
          case 'discussions':
            if (unread) {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.activities:not(.work) .discussion.unread:not(.requiredDiscussion)').show();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .discussion.read:not(.requiredDiscussion)').hide();
            } else {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.activities:not(.work) .discussion:not(.requiredDiscussion)').show();
            }
            /* eslint-disable-next-line ember/no-jquery */


            Ember.$('.activities:not(.work) .discussion.requiredDiscussion').hide();
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.activities:not(.work) .announcement').hide();
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.activities:not(.work) .assignment').hide();
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.classroom .side-panel').scrollTop(0);
            break;

          case 'requiredDiscussions':
            if (unread) {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.activities:not(.work) .discussion.unread.requiredDiscussion').show();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .discussion.read.requiredDiscussion').hide();
            } else {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.activities:not(.work) .discussion.requiredDiscussion').show();
            }
            /* eslint-disable-next-line ember/no-jquery */


            Ember.$('.activities:not(.work) .discussion:not(.requiredDiscussion)').hide();
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.activities:not(.work) .announcement').hide();
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.activities:not(.work) .assignment').hide();
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.classroom .side-panel').scrollTop(0);
            break;

          case 'announcements':
            if (unread) {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.activities:not(.work) .announcement.unread').show();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .announcement.read').hide();
            } else {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.activities:not(.work) .announcement').show();
            }
            /* eslint-disable-next-line ember/no-jquery */


            Ember.$('.activities:not(.work) .discussion').hide();
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.activities:not(.work) .assignment').hide();
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.classroom .side-panel').scrollTop(0);
            break;

          case 'assignments':
            if (unread) {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.activities:not(.work) .assignment.requires-attention').show();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .assignment:not(.requires-attention)').hide();
            } else if (ungraded) {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.activities:not(.work) .assignment.has_ungraded').show();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .assignment:not(.has_ungraded)').hide();
            } else if (resubmission) {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.activities:not(.work) .assignment.needs_resubmit').show();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .assignment:not(.needs_resubmit)').hide();
            } else if (comments) {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.activities:not(.work) .assignment.has_comments').show();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .assignment:not(.has_comments)').hide();
            } else if (ungraded_and_unread) {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.activities:not(.work) .assignment.has_ungraded').show();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .assignment.has_comments').show();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .assignment:not(.has_comments):not(.has_ungraded)').hide();
            } else {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.activities:not(.work) .assignment').show();
            }
            /* eslint-disable-next-line ember/no-jquery */


            Ember.$('.activities:not(.work) .discussion').hide();
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.activities:not(.work) .announcement').hide();
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.classroom .side-panel').scrollTop(0);
            break;

          case 'all':
            if (unread) {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.activities:not(.work) .discussion.unread:not(.requiredDiscussion)').show();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .discussion.read:not(.requiredDiscussion)').hide();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .discussion.unread.requiredDiscussion').show();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .discussion.read.requiredDiscussion').hide();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .announcement.unread').show();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .announcement.read').hide();
              /* eslint-disable-next-line ember/no-get */

              if (!this.get('session.isInstructor')) {
                /* eslint-disable-next-line ember/no-jquery */
                Ember.$('.activities:not(.work) .assignment.requires-attention').show();
                /* eslint-disable-next-line ember/no-jquery */

                Ember.$('.activities:not(.work) .assignment:not(.requires-attention)').hide();
              } else {
                /* eslint-disable-next-line ember/no-jquery */
                Ember.$('.activities:not(.work) .assignment:not(.has_ungraded), .activities:not(.work) .assignment:not(.has_comments)').hide();
                /* eslint-disable-next-line ember/no-jquery */

                Ember.$('.activities:not(.work) .assignment.has_ungraded, .activities:not(.work) .assignment.has_comments').show();
              }
              /* eslint-disable-next-line ember/no-jquery */


              Ember.$('.activities:not(.work) .assignment.needs_resubmit').show();
            } else {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.activities:not(.work) .discussion').show();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .announcement').show();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.activities:not(.work) .assignment').show();
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$('.classroom .side-panel').scrollTop(0);
            }

            break;

          default:
            break;
        }
      }
    }
  });

  _exports.default = _default;
});