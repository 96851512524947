define("bocce/mixins/recent-lesson-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create({
    // find the most recent lesson and item ids that the user viewed
    getRecentLessonInfo: function getRecentLessonInfo() {
      var _this = this;

      /* eslint-disable-next-line ember/no-get */
      return this.get('session.user').then(function (user) {
        // Default values; if user hasn't visited the course, use these
        var lesson_id = 0;
        var item_id = 0;
        var lastviewed = user.get('lastviewed');

        var course_id = _this.paramsFor('classroom').course_id;

        var visitedCourse = lastviewed && lastviewed[course_id];

        if (visitedCourse) {
          var info = lastviewed[course_id];
          lesson_id = info.lesson;
          item_id = info.item_id;
        }

        _this.store.nestResources('item', [{
          course: course_id
        }, {
          lesson: lesson_id
        }]);

        return {
          lesson_id: lesson_id,
          item_id: item_id
        };
      });
    }
  });

  _exports.default = _default;
});