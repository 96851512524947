define("bocce/mixins/nested-resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // helper functions for this mixin:
  var requestPromise = function requestPromise(url, method, payload) {
    // makes a request, returns a promise that
    // resolves to the response data.
    return new Promise(function (res, rej) {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$.ajax({
        type: method,
        url: url,
        data: JSON.stringify(payload),
        dataType: 'json',
        contentType: 'application/json',
        success: function success(data) {
          res(data);
        },
        error: function error(data) {
          rej(data);
        }
      });
    });
  }; // The exportable mixin:

  /* eslint-disable-next-line ember/no-new-mixins */


  var _default = Ember.Mixin.create({
    createNestedResources: function createNestedResources(type, object, parentType, parentIds) {
      // Creates several new nested resources with different parents
      //    type: the resource type, as it appears in the interface URL
      //      eg 'discussions'
      //    object: the request payload, eg { discussion: discussionObject }
      //    parentType: the parent type as it should be in the URL, eg 'courses'
      //    parentIds: an array of parentType ids, eg ['1234', '2345', '3456']
      // Returns a promise which resolves to an array of the response data
      var promises = []; // the array of promises that we'll return

      parentIds.forEach(function (parentId) {
        var url = "/interface/".concat(parentType, "/").concat(parentId, "/").concat(type);
        promises.push(requestPromise(url, 'POST', object));
      });
      return Promise.all(promises);
    },
    updateNestedResources: function updateNestedResources(type, objects, parentType) {
      // Updates a set of existing nested resources.
      //    type: the resource type, singular and plural; eg ['discussion', 'discussions']
      //    objects: an array of the existing objects
      //    parentType: the parent type, singular and plural; eg ['section', 'sections']
      // Returns a promise which resolves to an array of the response data
      var promises = []; // pluralize the types

      var typeSingular = type[0],
          typePlural = type[1],
          parentTypeSingular = parentType[0],
          parentTypePlural = parentType[1]; // set up the objects and make the requests

      objects.forEach(function (object) {
        var parentId = object[typeSingular][parentTypeSingular],
            id = object[typeSingular].id,
            url = "/interface/".concat(parentTypePlural, "/").concat(parentId, "/").concat(typePlural, "/").concat(id);
        promises.push(requestPromise(url, 'PUT', object));
      });
      return Promise.all(promises);
    }
  });

  _exports.default = _default;
});