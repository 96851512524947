define("bocce/mixins/interactions/timeline", ["exports", "bocce/mixins/support/render-template", "bocce/mixins/support/util", "bocce/mixins/support/layout"], function (_exports, _renderTemplate, util, layout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function Timeline($el, data) {
    util.bindAll(this);
    this.$el = $el.find('.interaction_content');
    this.data = data; // data tables

    this.events = $el.find('.events')[0];
    this.headers = $el.find('.time-periods')[0] || null; // categories are populated from events

    this.categories = []; // get time scale from the component props

    this.timeScale = parseInt(this.data.config.time_scale) || 100;
  }

  Timeline.prototype = {
    init: function init() {
      var _this = this;

      return (0, _renderTemplate.default)('timeline', 'main', {}).then(function (content) {
        _this.$el.append(content); // time periods


        if (_this.headers) {
          var events = _this.getDataFromTable(_this.headers, Timeline.HeaderEvent);

          _this.headers = new Timeline.Category(Timeline.Category.HEADERS, events, null, _this.timeScale);
        } // events


        _this.events = _this.getDataFromTable(_this.events, Timeline.EventFactory); // get the start date

        var allEvents = _this.headers ? _this.headers.events.concat(_this.events) : _this.events;

        var timelineStartDate = _this.getFirstDate(allEvents); // convert to string


        _this.startYear = Timeline.scrubYear(timelineStartDate.getFullYear()); // create master categories var containing all events, headers with a discrete start year.
        // in general, all start years will be the same, but maybe someone would like to have each
        // category as its own timeline with a unique start. i.e. 1800s, 1900s, 2000s, etc for a more
        // vertical view of things -- or maybe to compare seasons of multiple years: x = dates within years,
        // y = years

        _this.categories = _this.createCategories(_this.events, _this.headers, _this.startYear);

        _this.renderCategories(_this.categories, _this.timeScale); // add zoom controls

        /*
        this.$categoriesEl = this.$el.find('#categories');
        this.zoomControls = new Timeline.ZoomControls(100, this.$categoriesEl);
        $(this.zoomControls.el).appendTo(this.$el);
        */

      });
    },
    createCategories: function createCategories(events, headers, startDate) {
      var _this2 = this;

      var titles = util.unique(events.map(function (e) {
        return e.category;
      })); // categories must reflect user specified order

      var categories = titles.map(function (t) {
        return new Timeline.Category(t, null, startDate, _this2.timeScale);
      });
      events.forEach(function (e) {
        util.any(categories, function (c) {
          if (e.category === c.title) {
            c.addEvent(e);
            return true;
          }
        });
      }); // if the user has specified headers, add them to the beginning of the
      // categories array so they appear at the top of the timeline

      if (headers) {
        // set the start year
        headers.startDate = startDate;
        categories.unshift(headers);
      }

      return categories;
    },
    renderCategories: function renderCategories(categories, timeScale) {
      var container = this.$el.find('.categories');
      categories.forEach(function (c) {
        return c.render(container, timeScale);
      });
    },

    /**
     * utility methods
     **/
    // create new object with arguments based on a table rows <td>'s (in order)
    getDataFromTable: function getDataFromTable(table, model) {
      var data = [];
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(table).find('tr').each(function (i) {
        // skip first row (title row)
        if (i === 0) {
          return true;
        }

        var args = [];
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$(this).find('td').each(function () {
          /* eslint-disable-next-line ember/no-jquery */
          args.push(Ember.$(this));
        });
        var item = Timeline.Construct(model, args);
        data.push(item);
      });
      return data;
    },
    // iterate through a list of events and return the earliest
    getFirstDate: function getFirstDate(events) {
      var earliest = Timeline.parseDate(events[0].timePeriod.start);
      events.forEach(function (e) {
        var startDate = Timeline.parseDate(e.timePeriod.start);

        if (startDate < earliest) {
          earliest = startDate;
        }
      });
      return earliest;
    }
  };
  /**
   * Category
   **/

  Timeline.Category = function (title, events, startDate, timeScale) {
    this.title = title.length ? title : '';
    this.events = events || [];
    this.startDate = startDate || null;
    this.timeScale = timeScale || 100;
    this.label = title.length && title !== Timeline.Category.HEADERS ? new Timeline.Category.Label(this, title) : null;
    util.bindAll(this);
  };

  Timeline.Category.HEADERS = 'headers';
  Timeline.Category.RESIZE = 'resize';
  Timeline.Category.prototype = {
    addEvent: function addEvent(e) {
      this.events.push(e);
    },
    render: function render(container, timeScale) {
      var _this3 = this;

      // only convert the main el to a $ el on first render
      if (typeof this.el === 'string') {
        /* eslint-disable-next-line ember/no-jquery */
        this.el = this.title === Timeline.Category.HEADERS ? Ember.$(this.headerEl).appendTo(container) :
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(this.el).appendTo(container);
      }

      this.events.forEach(function (e) {
        return e.render(_this3.el, timeScale);
      }); // sort the events in chronological order

      this.events.sort(function (a, b) {
        return Timeline.parseDate(a.timePeriod.start) - Timeline.parseDate(b.timePeriod.start);
      }); // TODO: This is causing problems because the stuff isn't on screen yet
      // after rendering and adding events, move them into place

      setTimeout(function () {
        return _this3.positionEvents();
      }, 250); // listen for image loads

      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(this.el).find('.event').bind(Timeline.Event.IMAGE_LOADED, this.imageLoaded); // listen for resize events on the parent el

      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(this.el.parent()).bind(Timeline.Category.RESIZE, this.resize); // render the main category label

      this.renderLabel(); // listen for zoom events

      /* eslint-disable-next-line ember/no-jquery */

      var $container = Ember.$(container);
      $container.bind(Timeline.ZoomControls.ZOOM_IN, this.zoom);
      $container.bind(Timeline.ZoomControls.ZOOM_OUT, this.zoom);
    },
    positionEvents: function positionEvents() {
      var _this4 = this;

      var prev,
          boxes = [];
      this.events.forEach(function (e) {
        /* eslint-disable-next-line ember/no-jquery */
        var $el = Ember.$(e.el),
            left,
            width = $el.width(),
            height = $el.height(); // set el left

        left = Timeline.getWidthFromDateRange(_this4.startDate, e.timePeriod.start, _this4.timeScale); // if current start date is the same as prev end date,
        // make an adjustment so they don't overlap

        if (prev && e.timePeriod.start === prev.timePeriod.end) {
          /* eslint-disable-next-line ember/no-jquery */
          var $prevEl = Ember.$(prev.el); // stored right value isn't computing correctly, so grabbing it
          // again here

          var offset = $prevEl.position().left + $prevEl.width() - left + 5;

          if (offset > 0) {
            $el.width($el.width - offset);
            left += offset;
          }
        }

        var top = layout.box_top(boxes, width, height, left);
        $el.css('left', left + 'px');

        if (top > 0) {
          $el.css('top', top + 'px');
        }

        e.right = left + width;
        boxes.push(layout.bounding_box(left, top, width, height));
        prev = e;
      });
      this.adjustWidth();
      this.adjustHeight();
    },
    adjustWidth: function adjustWidth() {
      var maxRight = util.max(this.events.map(function (e) {
        return e.right;
      }));
      /* eslint-disable-next-line ember/no-jquery */

      var $containerEl = Ember.$(this.el).closest('.container');

      if (maxRight > $containerEl.width()) {
        var newWidth = maxRight + 5;
        $containerEl.width(newWidth + 'px');
      }
    },
    adjustHeight: function adjustHeight() {
      var max = util.max(this.events.map(function (e) {
        /* eslint-disable-next-line ember/no-jquery */
        var $el = Ember.$(e.el);
        return $el.position().top + $el.height();
      }));
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(this.el).height(max + 10 + 'px').trigger(Timeline.Category.RESIZE);
    },
    renderLabel: function renderLabel() {
      // render titles to the left of the category el
      // and pushes the container to the right accordingly
      // TODO: probably a cleaner way to do this with just CSS
      if (this.label) {
        this.label.render();
        /* eslint-disable-next-line ember/no-jquery */

        var $categoriesContainer = Ember.$(this.el).closest('.container');
        /* eslint-disable-next-line ember/no-jquery */

        var $container = Ember.$(this.el).closest('.Timeline');
        /* eslint-disable-next-line ember/no-jquery */

        var $labelEl = Ember.$(this.label.el).appendTo($container);
        var width = $labelEl.width();
        var offset = -width - 4; // position().left doesn't seem to get the value i want

        var categoryLeft = parseInt($categoriesContainer.css('left').split('px')[0]); // ...invert offset for comparison

        var positiveOffset = -offset + 2;

        if (categoryLeft < positiveOffset) {
          $categoriesContainer.css('left', positiveOffset);
        }
        /* eslint-disable-next-line ember/no-jquery */


        var $el = Ember.$(this.el);
        var labelTop = $el.position().top + $el.height() + $container.position().top + 2 + 'px';
        var css = {
          'top': labelTop,
          'left': categoryLeft
        };
        $labelEl.css(css);
      }
    },
    imageLoaded: function imageLoaded() {
      this.positionEvents();
    },
    resize: function resize() {
      this.renderLabel();
    },
    zoom: function zoom(e) {
      if (e.type === Timeline.ZoomControls.ZOOM_IN) {
        this.timeScale -= 50;
      } else if (e.type === Timeline.ZoomControls.ZOOM_OUT) {
        this.timeScale += 50;
      }

      this.positionEvents();
    },
    // TODO: move into template. quicker to develop this way for now
    el: '<div class="category rounded-border white-gloss-gradient"></div>',
    headerEl: '<div class="header-category"></div>'
  };
  /**
   * Category Label
   **/

  Timeline.Category.Label = function (category, title) {
    this.category = category;
    this.title = title;
  };

  Timeline.Category.Label.prototype = {
    render: function render() {
      /* eslint-disable-next-line ember/no-jquery */
      this.el = Ember.$(this.el);
      this.el.text(this.title);
    },
    // TODO: template
    el: '<div class="category-title"></div>'
  };
  /**
   * Event Factory
   **/

  Timeline.EventFactory = function (title, thumbnail, start, end) {
    // assume events with no end are punctuated, one-shot events

    /* eslint-disable-next-line ember/no-jquery */
    end = Ember.$.trim(Ember.$(end).text());

    if (end.length === 0) {
      return Timeline.Construct(Timeline.PunctuatedEvent, arguments);
    } else {
      // default is to return a normal Timeline Event
      return Timeline.Construct(Timeline.Event, arguments);
    }
  };
  /**
   * Event
   **/


  Timeline.Event = function (title, thumbnail, start, end, category, content, css, modalWidth, modalHeight) {
    this.timePeriod = new Timeline.TimePeriod(title, start, end);
    /* eslint-disable-next-line ember/no-jquery */

    this.thumbnail = Ember.$(thumbnail).find('img').attr('src') || undefined;
    /* eslint-disable-next-line ember/no-jquery */

    this.category = category ? Ember.$.trim(Ember.$(category).text()) : '';
    this.content = content &&
    /* eslint-disable-next-line ember/no-jquery */
    Ember.$.trim(Ember.$(content).text()).length > 0 ?
    /* eslint-disable-next-line ember/no-jquery */
    Ember.$(content).children() : undefined;
    /* eslint-disable-next-line ember/no-jquery */

    this.css = css ? Ember.$.trim(Ember.$(css).text()) : undefined;
    /* eslint-disable-next-line ember/no-jquery */

    this.modalWidth = Ember.$.trim(Ember.$(modalWidth).text()) || undefined;
    /* eslint-disable-next-line ember/no-jquery */

    this.modalHeight = Ember.$.trim(Ember.$(modalHeight).text()) || undefined; // store these geometry values here
    // to avoid calculating over and over

    this.right = 0;
    this.width = 0;
  };

  Timeline.Event.IMAGE_LOADED = 'image_loaded';
  Timeline.Event.prototype = {
    // TODO: make template
    el: '<div class="event rounded-border light-blue-gradient"></div>',
    render: function render(container, scale) {
      /* eslint-disable-next-line ember/no-jquery */
      this.el = Ember.$(this.el).appendTo(container);
      this.addText();
      this.addCSS();
      this.loadThumbnail();
      this.renderExt(scale);
    },
    renderExt: function renderExt(scale) {
      // set the width of the event
      this.setWidth(null, scale);
      this.addModal();
      this.addDates();
    },
    addCSS: function addCSS() {
      if (!this.css) {
        return;
      }

      var definitions = this.css.split(';');
      var cssObj = {};
      definitions.forEach(function (d) {
        if (d.length) {
          var split = d.split(':');
          /* eslint-disable-next-line ember/no-jquery */

          var prop = Ember.$.trim(split[0]);
          /* eslint-disable-next-line ember/no-jquery */

          var val = Ember.$.trim(split[1]);
          cssObj[prop] = val;
        }
      });
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(this.el).css(cssObj);
    },
    addText: function addText() {
      var title = this.timePeriod.title; // make title into a hyperlink if this event
      // has popup content

      if (this.content) {
        title = '<a href="#">' + title + '</a>';
      }

      this.el.html(title);
    },
    loadThumbnail: function loadThumbnail() {
      var _this5 = this;

      if (!this.thumbnail) {
        return;
      }

      var img = new Image();
      img.src = this.thumbnail;

      img.onload = function () {
        _this5.renderThumbnail(img);
      };
    },
    renderThumbnail: function renderThumbnail(img) {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(img).prependTo(this.el);
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(this.el).trigger(Timeline.Event.IMAGE_LOADED);
    },
    setWidth: function setWidth(width, scale) {
      this.width = width ? width : Timeline.getWidthFromDateRange(this.timePeriod.start, this.timePeriod.end, scale);
      this.el.width(this.width + 'px');
    },
    addModal: function addModal() {
      // if this event doesn't have content
      // bail out and remove the hand cursor
      if (!this.content) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(this.el).css('cursor', 'default');
        return;
      }
      /* eslint-disable-next-line ember/no-jquery */


      var title = Ember.$(this.timePeriod.title).text();
      /* eslint-disable-next-line ember/no-jquery */

      title = Ember.$.trim(title);

      if (this.timePeriod.end.length) {
        title = title + ': ' + this.timePeriod.start + ' - ' + this.timePeriod.end;
      } else {
        title = this.timePeriod.start + ': ' + title;
      } // common config


      var config = {
        'title': title,
        'overlayOpacity': 0.2
      }; // custom width and height

      if (this.modalWidth && this.modalHeight) {
        config.width = parseInt(this.modalWidth);
        config.height = parseInt(this.modalHeight);
        config.autoDimensions = false;
      } // display page in an iframe if a url is provided

      /* eslint-disable-next-line ember/no-jquery */


      var $content = Ember.$(this.content);
      var link = $content.find('a')[0]; // only display iframe if there's a link AND only one element in
      // $content. if there's a link and more than one element, assume the
      // user wants to display the link as part of some other content

      if (link && $content.length === 1) {
        // display external pages
        config.type = 'iframe';
        /* eslint-disable-next-line ember/no-jquery */

        config.href = Ember.$(link).attr('href');
      } else {
        var _el = this.el; // display cms generated html

        config.content = this.content;

        config.onCleanup = function () {
          var interactionEl; // use a helper to keep dynamic content alive (like jPlayer instances)

          /* eslint-disable-next-line ember/no-jquery */

          interactionEl = Ember.$(_el).closest('.interaction_component');
          window.bm.stashFancyboxContent(config, interactionEl);
        };
      }
      /* eslint-disable-next-line ember/no-jquery */


      Ember.$(this.el).fancybox(config);
    },
    addDates: function addDates() {
      var s = this.timePeriod.start,
          e = this.timePeriod.end;

      if (s.match(/^[0-9]+/)) {
        s = parseInt(s, 10);
      }

      if (e.match(/^[0-9]+/)) {
        e = parseInt(e, 10);
      }

      var datesStr = '<div class="date start">' + s + '</div>';
      datesStr += '<div class="date end">' + e + '</div>';
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(datesStr).appendTo(this.el);
    }
  };
  /**
   * Punctuated Event
   **/

  Timeline.PunctuatedEvent = function () {
    util.mixin(this, Timeline.Construct(Timeline.Event, arguments));
    util.mixin(this, {
      // TODO: template
      el: '<div class="event punctuated"><div class="icon"/></div>',
      renderExt: function renderExt() {
        this.addModal();
        this.addIcon();
      },
      addIcon: function addIcon() {
        /* eslint-disable-next-line ember/no-jquery */
        var icon = Ember.$('<div class="icon"></div>').prependTo(this.el); // add icon's width to the el

        /* eslint-disable-next-line ember/no-jquery */

        var elWidth = Ember.$(this.el).width();
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$(this.el).css('width', elWidth + icon.width() + 6 + 'px');
      },
      addText: function addText() {
        /* eslint-disable-next-line ember/no-jquery */
        var title = Ember.$(this.timePeriod.title);
        var p = title.first('p');

        if (this.content) {
          p.wrapInner('<a href="#"/>');
        }

        p.prepend('<span class="start">' + this.timePeriod.start + ':</span> ');
        this.el.html(title);
      },
      renderThumbnail: function renderThumbnail(img) {
        /* eslint-disable-next-line ember/no-jquery */
        var p = Ember.$(this.el).find('p')[0];
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$(img).prependTo(p);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$(this.el).trigger(Timeline.Event.IMAGE_LOADED);
      }
    });
  };
  /**
   * Header Event
   **/


  Timeline.HeaderEvent = function () {
    var timePeriod, args;
    timePeriod = Timeline.Construct(Timeline.TimePeriod, arguments); // adapt arguments to Timeline.Event constructor format

    args = [];
    args[0] = timePeriod.title;
    args[2] = timePeriod.start;
    args[3] = timePeriod.end;
    args[6] = arguments[3] || undefined; // css!

    util.mixin(this, Timeline.Construct(Timeline.Event, args), {
      el: '<div class="header-event transparent-orange-gradient"></div>',
      renderExt: function renderExt(scale) {
        this.setWidth(null, scale);
        this.addDates();
      }
    });
  };
  /**
   * Time Period
   **/


  Timeline.TimePeriod = function (title, start, end) {
    /* eslint-disable-next-line ember/no-jquery */
    this.title = title ? Ember.$(title).html() : '';
    /* eslint-disable-next-line ember/no-jquery */

    this.start = Timeline.scrubYear(typeof start === 'string' ? start : Ember.$.trim(Ember.$(start).text()));
    /* eslint-disable-next-line ember/no-jquery */

    this.end = Timeline.scrubYear(typeof end === 'string' ? end : Ember.$.trim(Ember.$(end).text()));
  };
  /**
   * Zoom Controls
   **/


  Timeline.ZoomControls = function (increment, containerEl) {
    // the amount to add/substract to scale on each click
    this.increment = increment || 100;
    /* eslint-disable-next-line ember/no-jquery */

    this.$containerEl = Ember.$(containerEl);
    this.render();
  };

  Timeline.ZoomControls.ZOOM_IN = 'zoom_in';
  Timeline.ZoomControls.ZOOM_OUT = 'zoom_out';
  Timeline.ZoomControls.prototype = {
    // TODO: template
    el: '<div id="zoom-controls"><a href="#" id="zoom-out">[-]</a> / <a href="#" id="zoom-in">[+]</a></div>',
    render: function render() {
      /* eslint-disable-next-line ember/no-jquery */
      this.el = Ember.$(this.el);
      var that = this;
      this.el.find('#zoom-in').click(function () {
        that.$containerEl.trigger(Timeline.ZoomControls.ZOOM_IN);
      });
      this.el.find('#zoom-out').click(function () {
        that.$containerEl.trigger(Timeline.ZoomControls.ZOOM_OUT);
      });
    }
  };
  /**
   * Static Methods
   **/
  // Make sure the year is four digits long

  Timeline.scrubYear = function (y) {
    y = y + ''; // first, make sure it's a string

    if (y.match(/^[0-9]+$/)) {
      y = '0000'.substr(y.length) + y; // 0 isn't a valid year

      if (y === '0000') {
        y = '0001';
      }
    }

    return y;
  }; // accepts dates in the form of MM/DD/YYYY
  // scale = num of pixels per year? per day?


  Timeline.getWidthFromDateRange = function (startDate, endDate, scale) {
    // if startDate == endDate and runs through getDaysFromDateRange, 365 is returned. not good.
    if (startDate === endDate) {
      return 0;
    }

    scale = scale || 1;
    var days = Timeline.getDaysFromDateRange(startDate, endDate);
    return days / scale;
  };

  Timeline.getDaysFromDateRange = function (startDate, endDate) {
    startDate = Timeline.parseDate(startDate);
    endDate = Timeline.parseDate(endDate);
    var remainingDaysInStartYear = Timeline.getRemainingDaysInYear(startDate);
    var daysInBetween = 0;

    for (var i = startDate.getFullYear() + 1, len = endDate.getFullYear(); i < len; i++) {
      daysInBetween += Timeline.isLeapYear(i) ? 366 : 365;
    }

    var daysIntoEndYear = Timeline.getDayOfYear(endDate);
    return remainingDaysInStartYear + daysInBetween + daysIntoEndYear;
  }; // gets the number of days left in a year. useful for getting the number
  // of days in the first year of a date range


  Timeline.getRemainingDaysInYear = function (date) {
    return (Timeline.isLeapYear(date.getFullYear()) ? 366 : 365) - Timeline.getDayOfYear(date);
  };

  Timeline.parseDate = function (ds) {
    var int_ds = parseInt(ds, 10); // just a year?

    if ('' + int_ds === ds) {
      ds = ds + '-01-01';
    }

    if (ds.toLowerCase() === 'today') {
      return new Date();
    }

    return new Date(ds);
  };

  Timeline.isLeapYear = function (y) {
    if (typeof y !== 'number') {
      y = y.getFullYear();
    }

    return y % 400 === 0 || y % 4 === 0 && y % 100 !== 0;
  }; // From http://stackoverflow.com/questions/8619879/javascript-calculate-the-day-of-the-year-1-366


  Timeline.getDayOfYear = function (d) {
    var start_days = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
        mon = d.getMonth(),
        day = d.getDate(),
        num = start_days[mon] + day;

    if (mon > 1 && Timeline.isLeapYear(d)) {
      num++;
    }

    return num;
  }; // dynamically create objects with arbitrary arguments array


  Timeline.Construct = function (constructor, args) {
    function F() {
      return constructor.apply(this, args);
    }

    F.prototype = constructor.prototype;
    return new F();
  };

  var _default = Timeline;
  _exports.default = _default;
});