define("bocce/helpers/substring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/helpers/sum.js
  // Check if two provided strings are the same
  var _default = Ember.Helper.helper(function (inp) {
    var str = inp[0],
        start = inp[1],
        end = inp[2];

    if (!str || start == null) {
      return '';
    }

    if (end) {
      return str.substring(start, end);
    } else {
      return str.substring(start);
    }
  });

  _exports.default = _default;
});