define("bocce/controllers/classroom", ["exports", "bocce/mixins/menus", "bocce/mixins/user-profile", "bocce/mixins/calendar-events", "bocce/utilities/dialog", "bocce/mixins/notify", "bocce/mixins/helpguide", "bocce/mixins/enmodal"], function (_exports, _menus, _userProfile, _calendarEvents, _dialog, _notify, _helpguide, _enmodal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend(_menus.default, _userProfile.default, _calendarEvents.default, _notify.default, _helpguide.default, _enmodal.default, {
    lessons: Ember.inject.controller('classroom.lessons'),
    conversations: Ember.inject.controller(),
    discussions: Ember.inject.controller(),
    userprofile: Ember.inject.controller(),
    // required by UserProfileMixin
    classroom: Ember.inject.controller(),
    // required by EnmodalMixin
    heartbeat: Ember.inject.service(),
    session: Ember.inject.service(),
    bookmarks: Ember.inject.service(),
    bookmarksService: Ember.computed.alias('bookmarks'),
    // Variation on the Service Injection design pattern.
    // Using jQuery in the controller's unit tests breaks tests, since there is no DOM
    // and AJAX doesn't work with no interface to connect to. Referencing jQuery through
    // a property lets us mock the jQuery API in our unit tests.
    jQuery: Ember.$,
    topicLoading: Ember.computed.reads('lessons.topicLoading'),
    isBCM: Ember.computed.alias('model.classroom.term.isBCM'),
    lessonLoaded: Ember.computed.alias('session.lessonLoaded'),

    /* eslint-disable-next-line ember/no-observers */
    convoScroller: Ember.observer('activeConversation', function () {
      var _this = this;

      if (!this.activeConversation) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.modal-content').off('scrollstop', function (tgt) {
          if (tgt.target.scrollTop === 0) {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.loading-prv').addClass('active');

            _this.send('loadMoreMessages');
          }
        });
      }
    }),
    nextLessonMap: Ember.computed('model', 'model.classroom.lessons', function () {
      var map = {},
          model = this.model;
      var lessons = model.classroom.get('lessons');

      if (lessons) {
        return lessons.then(function (l_objs) {
          l_objs.forEach(function (lesson) {
            map[lesson.get('number')] = lesson;
          });
          return map;
        });
      }

      return map;
    }),
    userSectionCodes: Ember.computed('model.classroom.id', 'session.user.sectionCodes', function () {
      var _this2 = this;

      // Returns the other sections the user is enrolled in,
      // including courses from other terms.

      /* eslint-disable-next-line ember/no-get */
      var sectionCodes = this.get('session.user.sectionCodes');

      if (sectionCodes === undefined) {
        return false;
      }

      sectionCodes = sectionCodes.filter(function (s) {
        /* eslint-disable-next-line ember/no-get */
        return Number(s.courseId) !== Number(_this2.get('model.classroom.id'));
      });

      if (sectionCodes.length === 0) {
        return false;
      }

      sectionCodes.sort(function (a, b) {
        if (a.termId !== b.termId) {
          return Number(b.termId) - Number(a.termId);
        } else if (a.courseTitle !== b.courseTitle) {
          return a.courseTitle < b.courseTitle ? -1 : 1;
        } else {
          return Number(a.sectionLabel) - Number(b.sectionLabel);
        }
      });
      return sectionCodes;
    }),
    otherCurrentTermSections: Ember.computed('userSectionCodes', 'session.course.term.name', function () {
      var _this3 = this;

      // Returns the other sections the user is enrolled in,
      // ONLY including the current course's term.
      var userSectionCodes = this.userSectionCodes || [];
      var otherCurrentTermSections = userSectionCodes.filter(function (section) {
        /* eslint-disable-next-line ember/no-get */
        return section.termLabel === _this3.get('session.course.term.name');
      });
      return otherCurrentTermSections;
    }),
    shouldOfferSurvey: Ember.computed('isBCM', 'model.classroom.term.weeksSinceStart', 'session.isStudent', function () {
      if (this.isBCM) {
        return false;
      }
      /* eslint-disable-next-line ember/no-get */


      var week = this.get('model.classroom.term.weeksSinceStart'),

      /* eslint-disable-next-line ember/no-get */
      max = this.get('session.isStudent') ? 14 : 15;
      return week >= 6 && week <= max;
    }),
    surveyLabel: Ember.computed('model.classroom.term.weeksSinceStart', function () {
      return 'Survey';
    }),
    loadConversations: function loadConversations() {
      var conversationsLoaded = this.conversationsLoaded || false,
          conversationsController = this.conversations,
          that = this;

      if (!conversationsLoaded) {
        return this.store.findAll('conversation').then(function (conversations) {
          conversationsController.set('model', conversations);
          that.set('conversationsLoaded', true);
        });
      }
    },
    loadEvents: function loadEvents() {
      var _this4 = this;

      var eventsLoaded = this.eventsLoaded || false; // If there was a previous heartbeat error, restart the
      // heartbeat if we're within 30 minutes of a scheduled
      // event start time.

      var restart_heartbeat_if_needed = function restart_heartbeat_if_needed(events) {
        if (window.heartbeatFrequencyInMilliseconds > 0) {
          return;
        }

        var now = new Date();
        events.forEach(function (evt) {
          var start = new Date(evt.get('startAt'));

          if (Math.abs(start - now) < 30 * 60 * 1000) {
            Ember.debug('Restarting heartbeat');
            window.heartbeatFrequencyInMilliseconds = 30 * 1000;
            /* eslint-disable-next-line ember/no-get */

            Ember.run.later(_this4, _this4.get('heartbeat.heartbeat'), 100);
          }
        });
      };

      if (!eventsLoaded) {
        return this.store.findAll('event').then(function (events) {
          //this.set('session.course.events', events);
          _this4.set('eventsLoaded', true);

          restart_heartbeat_if_needed(events);
        });
      } else {
        /* eslint-disable-next-line ember/no-get */
        restart_heartbeat_if_needed(this.get('session.course.events'));
      }
    },
    // lessonNumber and lessonTitle are given to the
    // Classroom::CurrentLessonItem component
    lessonNumber: Ember.computed.reads('lessons.model.lesson.number'),
    lessonTitle: Ember.computed.reads('lessons.model.item.title'),
    // this gets set using updateShortcutsDrawer action
    shortcutsDrawerLabel: 'Loading TOC...',
    hCodeURL: Ember.computed('session.section.id',
    /* eslint-disable-next-line ember/no-get */
    function () {
      return '/interface/sections/' + this.get('session.section.id') + '/acceptHonorCode';
    }),
    // courseDiscussions and courseAssignments are null at first, and are set
    // by the Route once the assignments/discussions findAll promises resolve.
    // These values are then passed to components.
    courseDiscussions: null,
    courseAssignments: null,
    courseRequiredDiscussions: null,
    courseQuizzes: null,
    activityContentLoading: Ember.computed('courseDiscussions', 'courseAssignments', function () {
      return this.courseDiscussions === null || this.courseAssignments === null;
    }),
    // TODO: NK: unreadActivities duplicates the logic from the all_unread
    // property on components/side-panel/activity.js. I'm leaving this as
    // duplicated code for now, but I'd like to find a way of DRYing this,
    // because we also use similar logic on the Work panel. Once the Work
    // panel is component-ized, that'll mean this same logic is needed in three
    // different places.
    unreadActivities: Ember.computed('courseDiscussions.@each.unread', 'courseAssignments.@each.{needsStudentAttention,needsTeacherAttention}', 'session.isInstructor', function () {
      var discussions = this.courseDiscussions || Ember.ArrayProxy.create();
      discussions = discussions.filter(function (d) {
        return !d.get('locked');
      });
      var unreadDiscussions = discussions.filterBy('unread');
      var assignments = this.courseAssignments || Ember.ArrayProxy.create();
      /* eslint-disable-next-line ember/no-get */

      var filter = this.get('session.isInstructor') ? 'needsTeacherAttention' : 'needsStudentAttention';
      var unreadAssignments = assignments.filterBy(filter);
      return Ember.ArrayProxy.create({
        content: [].concat(_toConsumableArray(unreadDiscussions), _toConsumableArray(unreadAssignments))
      });
    }),
    incompleteWork: Ember.computed('courseRequiredDiscussions.@each.todo', 'courseAssignments.@each.todo', 'courseQuizzes.@each.todo', function () {
      var discussions = this.courseRequiredDiscussions || [];
      var assignments = this.courseAssignments || [];
      var quizzes = this.courseQuizzes || [];
      var all = [].concat(_toConsumableArray(discussions.toArray()), _toConsumableArray(assignments.toArray()), _toConsumableArray(quizzes.toArray()));
      return all.filter(function (item) {
        return !item.get('locked') && item.get('todo');
      });
    }),
    actions: {
      setShowLateGradingPolicy: function setShowLateGradingPolicy(show) {
        this.set('showLateGradingPolicy', show);
      },
      acceptHonorCode: function acceptHonorCode(firstTime, skipCheck) {
        var _this5 = this;

        if (!skipCheck) {
          /* eslint-disable-next-line ember/no-jquery */
          if (!Ember.$('#hcode-accept').is(':checked')) {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.hcode-accept-checkbox').addClass('attention');
            return;
          }
        } else {
          this.set('session.section.needsHonorCode', false);
        }
        /* eslint-disable-next-line ember/no-jquery */


        Ember.$('.hcode-accept').addClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.hcode-accept-checkbox').addClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.hcode-working').removeClass('hidden');
        this.jQuery.ajax({
          type: 'POST',
          url: this.hCodeURL,
          success: function success() {
            Ember.debug('Honor code accepted');

            _this5.set('session.section.needsHonorCode', false);

            if (firstTime) {
              _this5.set('helpGuideOpen', true);
            }
          },
          error: function error(_error) {
            _this5.set('session.section.needsHonorCode', true);
            /* eslint-disable-next-line ember/no-jquery */


            Ember.$('.hcode-accept').removeClass('hidden');
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.hcode-accept-checkbox').removeClass('hidden');
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.hcode-working').addClass('hidden');
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.hcode-accept-checkbox').removeClass('attention');
            (0, _dialog.default)('Unable to accept honor code. Something went wrong. Please try submitting again. If this error persists, contact support.');
            Ember.debug('Unable to accept honor code. Something went wrong: ');
            Ember.debug(_error);
          }
        });
      },
      nextLesson: function nextLesson(curLesson) {
        var model = this.model,
            lessons = model.classroom.get('lessons'),
            idx = model.classroom.get('lessons').mapBy('id').indexOf(curLesson.get('id')),
            nxt = idx + 1,
            nextLesson;

        if (nxt < lessons.get('length')) {
          nextLesson = lessons.objectAt(nxt); //$('.main-panel').scrollTop(0);

          this.transitionToRoute('classroom.lessons', nextLesson.get('id'), nextLesson.get('items.firstObject.id'));
        }
      },
      shortcut: function shortcut(lessonId) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.side-panel').scrollTop(0)
        /* eslint-disable-next-line ember/no-jquery */
        .scrollTop(Ember.$('.lesson-divider:nth(' + lessonId + ')').offset().top);
      },
      scrollToHeader: function scrollToHeader(item, lesson) {
        var y;

        if (typeof item === 'number') {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.syllabus-divider.lesson-' + item).get(0).scrollIntoView();
          /* eslint-disable-next-line ember/no-jquery */

          y = Ember.$('.side-panel').scrollTop(); //your current y position on the page

          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.side-panel').scrollTop(y + 20);
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('#shortcuts-drawer-handle').prop('checked', false);
        } else {
          event.target.scrollIntoView();
        }

        this.transitionToRoute('classroom.lessons', lesson.get('id'), lesson.get('items.firstObject.id'));
      },
      openTOC: function openTOC() {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#shortcuts-drawer-handle').prop('checked', true);
      },
      closeTOC: function closeTOC() {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#shortcuts-drawer-handle').prop('checked', false);
      },
      syllabusTransport: function syllabusTransport(lessonId, topicId) {
        /* eslint-disable-next-line ember/no-jquery */
        var tgt = Ember.$('#syllabus-item-' + lessonId + '-' + topicId);

        if (!tgt.parent().hasClass('active')) {
          // Remove UI flourish from sylabus items for now
          // $('.syllabus-entry').removeClass('pbar');
          // tgt.addClass('pbar');
          this.lessons.set('interactionsBooted', false);
        }
      },
      // Click to swap between different left-side panels
      swapPanel: function swapPanel(panel) {
        var that = this,
            $sp,
            $n;
        /* eslint-disable-next-line ember/no-jquery */

        $sp = Ember.$('.side-panel');
        /* eslint-disable-next-line ember/no-get */

        if (!this.get('session.panelPosition')) {
          this.set('session.panelPosition', {});
        }
        /* eslint-disable-next-line ember/no-get */


        this.set('session.panelPosition.' + this.get('session.panel'), $sp.scrollTop());
        $sp.scrollTop(0);
        $sp.removeClass('syllabus');
        /* eslint-disable-next-line ember/no-get */

        if (this.get('session.panel') === panel) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.classroom').toggleClass('active');
        } else {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.classroom').addClass('active');
          this.set('session.panel', panel);

          if (panel === 'syllabus') {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.side-panel').addClass('syllabus');
          }
          /* eslint-disable-next-line ember/no-jquery */


          Ember.$.proxy(setTimeout(function () {
            $sp.scrollTop(that.get('session.panelPosition.' + panel) || 0);

            if (!that.get('session.panelPosition.' + panel)) {
              /* eslint-disable-next-line ember/no-jquery */
              $n = Ember.$('.side-panel .std-link.active');

              if ($n.length > 0) {
                $sp.scrollTop($n.position().top - $sp.height() + 100 + $sp.scrollTop());
              }
            }
          }, 100), this);
        } // JRW: Late load these types only when requested.


        if (panel === 'conversations') {
          this.loadConversations();
        } else if (panel === 'chat') {
          this.loadEvents();
        } else if (panel === 'activity') {
          /* eslint-disable-next-line ember/no-get */
          if (!this.get('session.isInstructor') && !this.studentActivityPanelFirstClick) {
            /* eslint-disable-next-line ember/no-jquery */
            if (Ember.$('.filter-unread-count.all').length > 0) {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.filter-unread-count.all').click();
            }

            this.set('studentActivityPanelFirstClick', true);
          }
        }
      },
      gradeBook: function gradeBook() {
        /* eslint-disable-next-line ember/no-get */
        if (!this.get('session.allowOldGradebook')) {
          this.set('gradebookOpen', true);
        } else {
          /* eslint-disable-next-line ember/no-get */
          var courseId = this.get('model.classroom.id');
          window.open('/courses/' + courseId + '/gradebook', '_blank');
        }
      },
      viewShowcase: function viewShowcase() {
        /* eslint-disable-next-line ember/no-get */
        var showcaseCourseID = this.get('session.section.showcase_course_id');
        window.open('/#/' + showcaseCourseID + '/' + showcaseCourseID + '/' + showcaseCourseID + '/', '_blank');
      },
      closePopup: function closePopup() {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.popup-container').removeAttr('closeId');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.popup-container .content').html('');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.popup-container').removeClass('active');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.popup-backdrop').removeClass('active');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.side-panel').removeClass('on-modal');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.main-panel').removeClass('no-scroll');
      },
      toggleCourseList: function toggleCourseList() {
        /* eslint-disable-next-line ember/no-jquery */
        var $chbx_toggle = Ember.$('#chbx-course-select-drawer-handle');
        /* eslint-disable-next-line ember/no-jquery */

        var $course_toggle = Ember.$('.course-select-drawer-handle');
        /* eslint-disable-next-line ember/no-jquery */

        var $course_list = Ember.$('.course-list-courses');

        if ($chbx_toggle.prop('checked')) {
          $chbx_toggle.prop('checked', false);
          $course_toggle.removeClass('open');
          $course_list.removeClass('open');
        } else {
          $chbx_toggle.prop('checked', true);
          $course_toggle.addClass('open');
          $course_list.addClass('open');
        }
      },
      closeCourseList: function closeCourseList() {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#chbx-course-select-drawer-handle').prop('checked', false);
      },
      switchCourse: function switchCourse(userSectionCode) {
        // TODO: Change from hard page refresh to load ember classroom correctly without refresh.
        var path = 'https://' + window.location.hostname + '/#/' + userSectionCode.courseId + '/' + userSectionCode.code + '/' + userSectionCode.sectionId;
        window.location.href = path;
        window.location.reload();
      },
      //Change playback speed of audio player
      changePlaybackSpeed: function changePlaybackSpeed(value) {
        var speedCalc = parseFloat(value) / 100;
        document.getElementById('publicPlayer').playbackRate = speedCalc;
      },
      // Submit bug report
      bugReport: function bugReport() {
        //window.open('https://docs.google.com/forms/d/1TNgug-nMiauaMUmzju4EeGO8_BttNNyTUaSXebyuN8g/viewform', '_blank');
        // Using Luke's Simpler form
        window.open('https://online.berklee.edu/student-support-contact', '_blank');
      },
      // qualtrics survey
      takeSurvey: function takeSurvey() {
        this.transitionToRoute('classroom.lessons.survey', 'course');
      }
    },
    sendFeedback: function sendFeedback() {
      // opens a form where the user can send feedback, which opens a Salesforce case.

      /* eslint-disable-next-line ember/no-get */
      var userEmail = this.get('session.user.email');
      window.open("https://online.berklee.edu/form_assembly?id=4708322&URLemail=".concat(userEmail), '_blank');
    },
    openAdminModal: function openAdminModal() {
      this.transitionToRoute('classroom.lessons.admin');
    },
    helpSection: function helpSection() {
      this.set('helpGuideOpen', true);
    }
  });

  _exports.default = _default;
});