define("bocce/controllers/notifications", ["exports", "bocce/mixins/prefs", "bocce/mixins/menus", "bocce/utilities/dialog", "bocce/utilities/logout"], function (_exports, _prefs, _menus, _dialog, _logout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */
  var _default = Ember.Controller.extend(_prefs.default, _menus.default, {
    notifications: Ember.computed(function () {
      // Uncomment to display notifications!
      // return this.store.findAll('notification');
      return false;
    }),
    totalNotifications: Ember.computed.reads('notifications.length'),
    actions: {
      clearAll: function clearAll() {
        var that = this;
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$.ajax({
          url: '/interface/notifications/',
          type: 'DELETE',
          success: function success() {
            that.get('notifications').clear();
            that.set('haveNotifications', false);
          }
        });
      },
      logOut: function logOut() {
        (0, _logout.default)();
      },

      /* eslint-disable-next-line ember/no-get */
      goLink: function goLink(type) {
        /* eslint-disable-next-line ember/no-get */
        var currentUser = this.get('session.user');

        switch (type) {
          case 'home':
            window.open('https://online.berklee.edu/my_home', '_blank');
            break;

          case 'profile':
            window.open('http://network.online.berklee.edu/home', '_blank');
            break;

          case 'help':
            window.open('https://online.berklee.edu/student-support-contact', '_blank');
            break;

          case 'advisor':
            window.open('https://online.berklee.edu/about/contact-us', '_blank');
            break;

          case 'library':
            window.open('https://online.berklee.edu/school/lms/library-links', '_blank');
            break;

          case 'materials':
            window.open('https://online.berklee.edu/school/lms/requirements', '_blank');
            break;

          case 'policies':
            window.open('https://online.berklee.edu/about/berklee-online-general-policies', '_blank');
            break;

          case 'doodle':
            window.open('http://www.doodle.com', '_blank');
            break;

          case 'meetup':
            window.open('http://www.meetup.com', '_blank');
            break;

          case 'calendar':
            var icsLink = 'https://' + window.location.hostname + '/interface/calendar-export/' + currentUser.get('calendarHash'),
                bodyMarkup = '<input id=\'calendar-export-link-id\' class=\'calendar-export-link\' value=\'' + icsLink + '\' onclick=\'this.focus();this.select()\' selected=\'selected\'>';
            /* eslint-disable-next-line ember/no-jquery */

            if (!Ember.$.isMobile) {
              bodyMarkup += '<div id=\'calendar-export-link-copy\' class=\'calendar-export-link-copy\' onclick=\'document.querySelector("#calendar-export-link-id").select(); document.execCommand("copy");\'><i class="fas fa-clipboard" aria-hidden="true"></i></div>';
            }

            bodyMarkup += '<br><a href=\'' + icsLink + '\' target=\'_blank\'>or download the feed.</a>';
            var message = 'Copy the link below and paste it into any calendar app that takes iCal feeds (Google Calendar, iCal, Outlook, etc.)';
            (0, _dialog.default)(message, ['Dismiss'], bodyMarkup);
            break;

          default:
            break;
        }
      },
      // Delete the record for the given notification id
      dismissNotification: function dismissNotification(id) {
        this.store.findRecord('notification', id).then(function (post) {
          post.destroyRecord();
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.user-menu').click();
        }, function (err) {
          Ember.debug('Could not delete notification.', err);
        });
      },
      // Transition to the Route for the given modal type+id
      travelToNotification: function travelToNotification(type, id) {
        this.transitionToRoute("classroom.lessons.".concat(type), id);
      }
    }
  });

  _exports.default = _default;
});