define("bocce/models/bookmark", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/models/bookmark.js
  var _default = _model.default.extend({
    user: (0, _model.belongsTo)('user', {
      inverse: 'bookmarks',
      async: false
    }),
    course_id: (0, _model.attr)('number'),
    course_code: (0, _model.attr)('string'),
    course_name: (0, _model.attr)('string'),
    section_id: (0, _model.attr)('number'),
    lesson_id: (0, _model.attr)('number'),
    lesson_name: (0, _model.attr)('string'),
    page_id: (0, _model.attr)('string'),
    page_title: (0, _model.attr)('string'),
    content_tag_id: (0, _model.attr)('number'),
    notes: (0, _model.attr)('string'),
    body: (0, _model.attr)('string')
  });

  _exports.default = _default;
});