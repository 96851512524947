define("bocce/mixins/interactions/content_framing", ["exports", "bocce/mixins/support/render-template"], function (_exports, _renderTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function Question(dataRow) {
    /* eslint-disable-next-line ember/no-jquery */
    var $row = Ember.$(dataRow),
        $cells = $row.find('td'),

    /* eslint-disable-next-line ember/no-jquery */
    aspectRatiosStr = Ember.$.trim(Ember.$($cells[2]).text()),
        aspectRatios = [],

    /* eslint-disable-next-line ember/no-jquery */
    aspectRatioLabels = Ember.$.trim(Ember.$($cells[3]).text()).split(',');

    if (aspectRatiosStr.length) {
      aspectRatios = aspectRatiosStr.split(',');
    } else {
      // default is 4:3
      aspectRatios = ['4:3'];
    } // Make sure that a label is specified for each ratio.
    // If not, use the ratio.

    /* eslint-disable-next-line ember/no-jquery */


    Ember.$(aspectRatios).each(function (i) {
      if (!aspectRatioLabels[i]) {
        aspectRatioLabels[i] = aspectRatios[i];
      }
    });
    /* eslint-disable-next-line ember/no-jquery */

    this.question = Ember.$.trim(Ember.$($cells[0]).html());
    /* eslint-disable-next-line ember/no-jquery */

    this.solution = Ember.$.trim(Ember.$($cells[1]).html());
    this.aspectRatios = aspectRatios;
    this.aspectRatioLabels = aspectRatioLabels;
  }

  function ContentFraming($el) {
    this.$el = $el;
    this.$container = this.$el.find('.interaction_content');
  }

  ContentFraming.prototype = {
    data: {},
    questionIndex: 0,
    template: undefined,
    init: function init() {
      var $dataEl = this.$el.find('.interaction_data');
      this.data = this.createData($dataEl);
      return this.renderQuestion(this.questionIndex);
    },
    createData: function createData($dataEl) {
      var $mainContent = $dataEl.find('table:first'),
          $finalContent = $dataEl.find('table:last'),
          data = {
        questions: [],
        finalContent: undefined
      }; // Get main content from first table

      $mainContent.find('tr').each(function (i) {
        if (i === 0) {
          return; // Skip header row
        }

        data.questions.push(new Question(this));
      }); // Get final content from last table

      data.finalContent = $finalContent.find('td:first').html();
      return data;
    },
    renderQuestion: function renderQuestion(num) {
      var _this = this;

      var question = this.data.questions[num];
      return (0, _renderTemplate.default)('content_framing', 'main', question).then(function (content) {
        _this.$container.empty().html(content);

        _this.resetFrameRect(question.aspectRatios);

        _this.initAspectRatios(question.aspectRatios, question.aspectRatioLabels);

        _this.initRevealSolution();
      });
    },
    resetFrameRect: function resetFrameRect() {
      var $frameRect, $img, imgOffset;
      $frameRect = this.getFrameRect();
      $img = this.$container.find('img:first');
      imgOffset = $img.position();
      $frameRect.css({
        opacity: 0,
        top: imgOffset.top + 'px',
        left: imgOffset.left + 'px'
      }).animate({
        opacity: 1
      }, 1500);
    },
    getFrameRect: function getFrameRect() {
      return this.$container.find('.frame-rect');
    },
    initAspectRatios: function initAspectRatios(ratios) {
      var $ratios = this.$container.find('.aspect-ratio'); // Hide the ratio buttons if only one is provided

      if (ratios.length === 1) {
        this.$container.find('.aspect-ratios-container').hide();
      }
      /* eslint-disable-next-line ember/no-jquery */


      $ratios.each(Ember.$.proxy(function (i, ratioEl) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(ratioEl).click({
          ratio: ratios[i]
        }, Ember.$.proxy(this.changeRatio, this));
      }, this));
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$($ratios[0]).trigger('click');
    },
    changeRatio: function changeRatio(e) {
      e.preventDefault();
      /* eslint-disable-next-line ember/no-jquery */

      var $btn = Ember.$(e.target),
          ratioStr = e.data.ratio,
          ratio = this.getRatio(ratioStr),
          comps = this.getRatioComps(ratioStr),
          $frameRect = this.getFrameRect(),
          // Keep frame around the same size
      // for big and small numerator ratios
      basePx = comps[0] < 10 ? 20 : 10;
      this.$container.find('.selected').removeClass('selected');
      $btn.addClass('selected');
      $frameRect.css({
        width: basePx * comps[0] + 'px',
        height: basePx * comps[1] + 'px'
      });
      $frameRect.draggable().resizable({
        aspectRatio: ratio
      });
    },
    // Divide the components of a string ratio
    getRatio: function getRatio(ratioStr) {
      var ratio = this.getRatioComps(ratioStr);
      return ratio[0] / ratio[1];
    },
    getRatioComps: function getRatioComps(ratioStr) {
      return ratioStr.split(':');
    },
    initRevealSolution: function initRevealSolution() {
      var $revealBtn = this.$container.find('.solution.button'),
          $nextBtn = this.$container.find('.next.button'),
          $solution = this.$container.find('.solution-content');
      /* eslint-disable-next-line ember/no-jquery */

      $revealBtn.click(Ember.$.proxy(function () {
        $solution.slideDown();
        $revealBtn.hide();
        /* eslint-disable-next-line ember/no-jquery */

        $nextBtn.show().click(Ember.$.proxy(this.showNextQuestion, this));
      }, this));
    },
    showNextQuestion: function showNextQuestion() {
      this.questionIndex++;

      if (this.questionIndex < this.data.questions.length) {
        this.renderQuestion(this.questionIndex);
        this.scrollWindow();
      } else {
        this.showFinalFeedback();
      }
    },
    // Make sure that user's window scrolled to the top of the
    // interaction el after each question. This will only work when
    // the interaction is outside of an iframe i.e. doc-display or LMS
    scrollWindow: function scrollWindow() {
      var elTop = this.$el.offset().top,

      /* eslint-disable-next-line ember/no-jquery */
      $mp = Ember.$('.main-panel'),
          curPos = $mp.scrollTop();
      $mp.scrollTop(curPos + elTop - 50);
    },
    showFinalFeedback: function showFinalFeedback() {
      var $resetBtn;
      this.$container.find('.main-content, .solution-content').empty();
      this.$container.find('.bottom .button, .frame-rect, .aspect-ratios-container').hide();
      this.$container.find('.main-content').html(this.data.finalContent);
      $resetBtn = this.$container.find('.reset.button');
      /* eslint-disable-next-line ember/no-jquery */

      $resetBtn.show().click(Ember.$.proxy(function () {
        this.questionIndex = 0;
        this.renderQuestion(this.questionIndex);
      }, this));
    }
  };
  var _default = ContentFraming;
  _exports.default = _default;
});