define("bocce/templates/components/notification-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CqmqSifP",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"avatar_url\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"user-portrait\",[],[[\"@user\"],[\"notification.user\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"portrait\"],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"icon \",[34,0,[\"type\"]]]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"notification-details\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"notification-link\"],[12],[1,[35,0,[\"user\",\"short_name\"]]],[2,\" \"],[13],[1,[35,0,[\"verbose_message\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[11,\"button\"],[24,0,\"notification-dismiss\"],[24,\"aria-label\",\"Dismiss Notification\"],[4,[38,2],[[32,0],\"dismiss\"],null],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fas fa-times-circle\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"notification\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/notification-item.hbs"
    }
  });

  _exports.default = _default;
});