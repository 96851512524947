define("bocce/models/course", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/models/course.js
  var _default = _model.default.extend({
    title: (0, _model.attr)('string'),
    code: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string'),
    earlyUnlock: (0, _model.attr)(),
    term: (0, _model.belongsTo)('term', {
      async: false
    }),
    sections: (0, _model.hasMany)('section'),
    lessons: (0, _model.hasMany)('lesson', {
      async: true
    }),
    // user-specific bookmark
    lastLesson: (0, _model.belongsTo)('lesson', {
      async: true
    }),
    lastItem: (0, _model.belongsTo)('item', {
      async: true
    }),
    course_id: Ember.computed.oneWay('id'),
    isPrivateLesson: (0, _model.attr)('boolean'),
    isReadOnly: (0, _model.attr)('boolean'),
    isGradeLock: (0, _model.attr)('boolean'),
    gradeLockDate: (0, _model.attr)('date'),
    isArchived: (0, _model.attr)('boolean'),
    customGradeBookColumns: (0, _model.attr)(),
    gradebook_weights: (0, _model.hasMany)('gradebook_weight', {
      async: false
    }),
    lateGradingPolicy: (0, _model.attr)('string'),
    events: (0, _model.hasMany)('events', {
      async: true
    }),
    numAssignmentsDue: (0, _model.attr)('number'),
    numUnreadDiscussions: (0, _model.attr)('number'),
    numUnreadAnnouncements: (0, _model.attr)('number'),
    numUnreadConversations: (0, _model.attr)('number'),
    showcaseInstructors: (0, _model.attr)(),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isSample: Ember.computed('term', function () {
      /* eslint-disable-next-line ember/no-get */
      var name = this.get('term.name');
      return name === 'Sample Courses';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isGettingStarted: Ember.computed('term', function () {
      /* eslint-disable-next-line ember/no-get */
      var name = this.get('term.name');
      return name === 'Getting Started';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    termRunning: Ember.computed('term', function () {
      var terms = [{
        date: "1/11/2021",
        term: "Winter"
      }, {
        date: "4/5/2021",
        term: "Spring"
      }, {
        date: "6/7/2021",
        term: "Summer Programs"
      }, {
        date: "6/28/2021",
        term: "Summer"
      }, {
        date: "9/27/2021",
        term: "Fall"
      }, {
        date: "1/10/2022",
        term: "Winter"
      }, {
        date: "4/4/2022",
        term: "Spring"
      }, {
        date: "6/6/2022",
        term: "Early Summer"
      }, {
        date: "6/27/2022",
        term: "Summer"
      }, {
        date: "9/26/2022",
        term: "Fall"
      }, {
        date: "1/9/2023",
        term: "Winter"
      }, {
        date: "4/3/2023",
        term: "Spring"
      }, {
        date: "6/5/2022",
        term: "Early Summer"
      }, {
        date: "6/26/2023",
        term: "Summer"
      }, {
        date: "9/25/2023",
        term: "Fall"
      }, {
        date: "1/8/2024",
        term: "Winter"
      }, {
        date: "4/1/2024",
        term: "Spring"
      }, {
        date: "6/3/2022",
        term: "Early Summer"
      }, {
        date: "6/24/2024",
        term: "Summer"
      }, {
        date: "9/23/2024",
        term: "Fall"
      }, {
        date: "1/13/2025",
        term: "Winter"
      }, {
        date: "4/7/2025",
        term: "Spring"
      }, {
        date: "6/9/2022",
        term: "Early Summer"
      }, {
        date: "6/30/2025",
        term: "Summer"
      }, {
        date: "9/29/2025",
        term: "Fall"
      }, {
        date: "1/12/2026",
        term: "Winter"
      }, {
        date: "4/6/2026",
        term: "Spring"
      }, {
        date: "6/8/2022",
        term: "Early Summer"
      }, {
        date: "6/29/2026",
        term: "Summer"
      }, {
        date: "9/28/2026",
        term: "Fall"
      }, {
        date: "1/11/2027",
        term: "Winter"
      }, {
        date: "4/5/2027",
        term: "Spring"
      }, {
        date: "6/7/2022",
        term: "Early Summer"
      }, {
        date: "6/28/2027",
        term: "Summer"
      }];
      var now = new Date().getTime();
      var today = new Date();
      today.setHours(0, 0, 0, 0);

      for (var i = 0; i < terms.length; i++) {
        var thisEpochDate = new Date(terms[i].date).getTime();
        var nextEpochDate = new Date(terms[i + 1].date).getTime();
        var weekTwo = thisEpochDate + 7 * 24 * 60 * 60 * 1000;
        var afterSix = thisEpochDate + 18 * 60 * 60 * 1000; // Is it: After midnight start of term -- but before midnight of the next term -- and after six pm on SOT -- but before monday of 2nd week of term?

        if (today > thisEpochDate && today <= nextEpochDate && now > afterSix && today < weekTwo) {
          // If so, the current term is running
          return {
            term: terms[i].term,
            date: terms[i].date,
            isRunning: true
          };
        } else if (today <= thisEpochDate) {
          return {
            term: terms[i].term,
            date: terms[i].date,
            isRunning: false
          };
        }
      }

      return false;
    }),
    isLiveLesson: Ember.computed('code', function () {
      var liveLessonCourseCodes = ['OSONG-010', 'OCOMP-010', 'OBASS-010', 'OMPRD-010'];
      var currentCode = this.get('code');
      return liveLessonCourseCodes.indexOf(currentCode) > -1;
    }),
    isOrientation: Ember.computed.equal('code', 'OLGEN-001'),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isShowcase: Ember.computed('term', function () {
      /* eslint-disable-next-line ember/no-get */
      var name = this.get('term.name');
      return name === 'Showcase Courses';
    }),
    // Partner, publicly-visible courses

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isPublic: Ember.computed.equal('term.name', 'Online Courses'),
    isInteractive: Ember.computed('isOrientation', 'isGettingStarted', 'isPublic', function () {
      return !this.isOrientation && !this.isGettingStarted && !this.isPublic;
    })
  });

  _exports.default = _default;
});