define("bocce/templates/classroom/lessons/discussion-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pkX5kBNv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,\"role\",\"dialog\"],[14,\"aria-modal\",\"true\"],[14,\"aria-labelledby\",\"new-modal-title\"],[14,\"aria-activedescendant\",\"discussion-title\"],[14,\"aria-describedby\",\"new-modal-title\"],[15,0,[31,[\"floating-modal active \",[30,[36,4],[[35,3],\"uploading\"],null]]]],[14,1,\"new-discussion\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-title\"],[14,1,\"new-modal-title\"],[12],[1,[34,5]],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"close-button\"],[24,\"aria-label\",\"Close new discussion editor\"],[4,[38,2],[[32,0],\"closeModals\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fas fa-times\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"modal-content \",[30,[36,4],[[35,6],\"no-rec\"],null]]]],[12],[2,\"\\n\"],[6,[37,4],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"discussion/multisection-picker\",[],[[\"@sections\",\"@selected\",\"@closeAction\"],[[34,0],[34,1],[30,[36,2],[[32,0],\"toggleMultiSectionPicker\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"subject-container\"],[12],[2,\"\\n      \"],[8,\"input\",[[24,0,\"subject new\"],[24,\"placeholder\",\"Title\"]],[[\"@value\",\"@aria-required\",\"@id\"],[[34,8],\"true\",\"discussion-title\"]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[19,\"discussion-new-reply-box\",[]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"modals/dark-backdrop\",[],[[\"@action\"],[[30,[36,2],[[32,0],\"closeModals\"],null]]],null]],\"hasEval\":true,\"upvars\":[\"userSectionCodes\",\"multiSections\",\"action\",\"uploadInProgress\",\"if\",\"modalTitle\",\"noRec\",\"multiSectionPicker\",\"titleInput\"]}",
    "meta": {
      "moduleName": "bocce/templates/classroom/lessons/discussion-new.hbs"
    }
  });

  _exports.default = _default;
});