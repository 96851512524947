define("bocce/mixins/interactions/audio_comments", ["exports", "bocce/mixins/support/render-template", "bocce/mixins/support/util"], function (_exports, _renderTemplate, util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* ************************** *
   * Audio Comments interaction *
   * ************************** */

  /*
   * Helper functions
   */
  // return the duration of a comment in seconds
  // if there is no end time on the comment, return FALSE
  function getCommentLength(comment) {
    var start = comment.start,
        end = comment.end;
    return end ? end - start : false;
  } // check for comment equivilency


  function sameComment(commentA, commentB) {
    return commentA && commentB && commentA.label === commentB.label && commentA.start === commentB.start;
  } // sort an array by key


  function sortBy(key, data) {
    data.sort(function (a, b) {
      return a[key] - b[key];
    });
    return data;
  } // using a string of form MM:SS, return number of seconds


  function timeToSeconds(input) {
    if (/\d+:\d+/.test(input)) {
      var split = input.split(':'),
          m = parseInt(split[0]),
          s = parseInt(split[1]);
      return m * 60 + s;
    } else {
      return undefined;
    }
  } // truncate a comment so it fits nicely
  // $comment is the jquery object for the comment span


  function truncateComment($comment) {
    var $label = $comment.find('.label'),
        text = $label.text().trim(),
        parentWidth = $comment.parent().width(),
        left = $comment.position().left,
        width = $label.width(); // truncate the comment to it doesn't spill off the right edge,
    // but limit the truncation so it won't go below 15 characters

    while (left + width > parentWidth && text.length >= 15) {
      text = truncateText(text, -5);
      $label.text(text);
      width = $label.width();
    } // if the comment is still spilling off, shift it to the left


    if (left + width + 10 > parentWidth) {
      $label.css('position', 'absolute');
      $label.css('right', '0');
    }
  }

  function truncateText(text, endIndex) {
    text = text.slice(0, endIndex);

    if (text.slice(-1) === ' ') {
      text = text.slice(0, -1);
    }

    text += '...';
    return text;
  }
  /* ***************************** *
   * AudioComments object; this is *
   * the object that gets exported *
   * ***************************** */
  // AudioComments constructor


  function AudioComments($el, data) {
    // binds all methods to this
    util.bindAll(this);
    this.$el = $el;
    this.$interactionData = $el.find('.interaction_data');
    this.$containerEl = $el.find('.interaction_content');
    this.config = data.config;
    this.duration = timeToSeconds(data.config.duration); // array of all comments

    var dataTable = this.$interactionData.find('table')[0];
    this.comments = sortBy('start', this.getDataFromTable(dataTable));
    this.currentActiveComment = false; // path to the waveform image

    this.bgImagePath = data.assets.bg_image[0]; // path to the audio file

    this.audioPath = data.assets.audio_track[0];
  } // AudioComments prototype


  AudioComments.prototype = {
    // get things up and running.
    init: function init() {
      var _this = this;

      this.$containerEl.attr('style', '');
      return (0, _renderTemplate.default)('audio_comments', 'main', {
        imageSrc: this.bgImagePath,
        audioSrc: this.audioPath,
        comments: this.comments
      }).then(function (content) {
        // put the rendered content on the page:
        _this.$containerEl.append(content); // save ref to the content div


        _this.$contentEl = _this.$containerEl.find('.content');
        _this.$activeCommentText = _this.$contentEl.find('.active-comment'); // save ref to the image

        /* eslint-disable-next-line ember/no-jquery */

        _this.$image = Ember.$(content).find('.bg-image');

        _this.$image.click(_this.bgImageClick); // initialize the audio player


        _this.initPlayer();

        _this.$containerEl.find('.loading').remove(); // truncate comments


        _this.$contentEl.find('.comment, .comment-span').each(function () {
          /* eslint-disable-next-line ember/no-jquery */
          truncateComment(Ember.$(this));
        });
      });
    },

    /*
     * Logic
     * These functions make decisions but don't render anything;
     * if they need to change something in the UI, call a UI function
     */
    initPlayer: function initPlayer() {
      var $player = this.$el.find('audio');
      this.player = $player.get(0); // add *all* the event handlers!

      $player.on('timeupdate', this.timeUpdate); // set up the controls

      this.$seek = this.$containerEl.find('.player-seek');
      this.$seek.on('change.fndtn.slider', this.seekBarClick);
      this.$play = this.$containerEl.find('.playpause');
      this.$play.on('click', this.playPause);
      this.$contentEl.find('.comment, .comment-span').click(this.seekToComment); // save references to useful DOM elements

      this.$highlight = this.$contentEl.find('.highlight');
      this.$highlight.click(this.bgImageClick);
    },
    // given args as input, return a comment object
    commentFactory: function commentFactory(args) {
      ['label', 'start', 'end', 'stopAtEnd', 'css', 'autoPopup'].forEach(function (field) {
        if (args[field]) {
          /* eslint-disable-next-line ember/no-jquery */
          args[field] = Ember.$.trim(Ember.$(args[field]).text());
        }
      });
      /* eslint-disable-next-line ember/no-jquery */

      var bgImagePath = Ember.$(args.bgImage).find('img').attr('src') || '';
      var comment = {
        label: args.label || '',

        /* eslint-disable-next-line ember/no-jquery */
        comment: Ember.$.trim(Ember.$("<div>" + args.comment + "</div>").html()) || '',
        start: timeToSeconds(args.start) || 0,
        end: timeToSeconds(args.end) || undefined,
        bgImage: bgImagePath,
        stopAtEnd: args.stopAtEnd === 'true'
      };
      comment = this.setCommentDimensions(comment);
      return comment;
    },
    // If the user clicks on an area that isn't covered by
    // a comment span, this seeks the audio to the correct time
    bgImageClick: function bgImageClick(e) {
      var player = this.player;
      var imageWidth = this.$image.width();
      /* eslint-disable-next-line ember/no-jquery */

      var box = Ember.$(e.target);
      var pxFromLeft = e.pageX - box.offset().left;
      var percentPosition = pxFromLeft / imageWidth;
      var time = percentPosition * this.duration;
      player.currentTime = time;
    },
    defineEndTimes: function defineEndTimes(data) {
      // if an end time isn't defined, make it either
      // the start time of the next
      for (var i = 0; i < data.length; ++i) {
        if (!data[i].end) {
          var end = data[i].start + 10;

          if (data[i + 1] && data[i + 1].start < end) {
            data[i].end = data[i + 1].start;
          } else if (this.duration < end) {
            data[i].end = this.duration;
          } else {
            data[i].end = end;
          }
        }
      }

      return data;
    },
    getCommentFromTime: function getCommentFromTime() {
      var time = this.player.currentTime,
          theChosenOne = false; // start checking for comments from the last
      // comments, so if there's any overlap the
      // newer comment takes priority

      for (var i = this.comments.length - 1; i >= 0; --i) {
        var comment = this.comments[i];
        var start = comment.start,
            end = comment.end;

        if (start < time && time < end) {
          theChosenOne = comment;
          break;
        }
      }

      return theChosenOne;
    },
    // return an array of comments based on the table contents
    getDataFromTable: function getDataFromTable(table) {
      // array of columns in the table from the cms:
      var COLUMNS = ['label', 'comment', 'start', 'end', 'bgImage', 'stopAtEnd', 'css', 'autoPopup', 'labelOffset', 'dimensions'];
      var data = [];
      /* eslint-disable-next-line ember/no-jquery */

      for (var i = 0; i < Ember.$(table).find('tr').length; ++i) {
        // skip first row (title row)
        if (i === 0) {
          continue;
        }

        var args = {},

        /* eslint-disable-next-line ember/no-jquery */
        $row = Ember.$(table).find('tr');

        for (var j = 0; j < $row.length; ++j) {
          args[COLUMNS[j]] = $row.eq(i).find('td').eq(j).html();
        }

        data.push(this.commentFactory(args));
      }

      data = this.defineEndTimes(data);
      data.forEach(function (d, i) {
        d.id = 'comment-' + i;
      });
      return data;
    },
    playPause: function playPause() {
      var player = this.player;

      if (player.buffered.length < 1) {
        player.load();
      }

      if (player.paused) {
        player.play();
      } else {
        player.pause();
      }

      this.togglePlayButton();
    },
    seekBarClick: function seekBarClick(e) {
      var player = this.player;

      if (!player.duration) {
        return;
      }

      var time = parseInt(e.target.value, 10) / 100 * player.duration;
      player.currentTime = time;
    },
    // Move playback to the clicked comment's time and play
    seekToComment: function seekToComment(e) {
      var target = e.target;

      if (target.className === 'label') {
        // if the user clicks on the label,
        // we want the comment span instead
        target = target.parentElement;
      } // get the numeric index of the element


      var elId = target.className.split(' ').find(function (c) {
        return /comment-\d+/.test(c);
      });
      var comment = this.comments.find(function (c) {
        return c.id === elId;
      });
      this.player.currentTime = comment.start;
      this.player.play();
      this.togglePlayButton();
    },
    timeUpdate: function timeUpdate() {
      var player = this.player;
      this.percent = player.currentTime / player.duration;
      this.updateSeekBar();
      this.updateHighlight(); // check if comment needs to stop playback

      if (this.currentActiveComment.stopAtEnd && player.currentTime >= this.currentActiveComment.end) {
        player.pause();
        this.togglePlayButton();
      } // check if the comment display needs to be updated


      var activeComment = this.getCommentFromTime();

      if (!sameComment(activeComment, this.currentActiveComment)) {
        this.currentActiveComment = activeComment;
        this.displayComment();
      }
    },

    /*
     * UI Functions
     * These change the visual display
     * but shouldn't handle any logic
     */
    displayComment: function displayComment() {
      var _this2 = this;

      if (this.currentActiveComment) {
        var comment = this.currentActiveComment.comment,
            label = this.currentActiveComment.label;
        (0, _renderTemplate.default)('audio_comments', 'comment', {
          label: label,
          comment: comment
        }).then(function (content) {
          _this2.$contentEl.find('.comment, .comment-span').removeClass('current');

          _this2.$contentEl.find(".".concat(_this2.currentActiveComment.id)).addClass('current');

          _this2.$activeCommentText.html(content);
        });
      } else {
        // if we're not currently in a comment, empty the comment div
        this.$activeCommentText.empty();
        this.$contentEl.find('.comment, .comment-span').removeClass('current');
      }
    },
    setCommentDimensions: function setCommentDimensions(comment) {
      // sets display settings for the comment
      // doesn't render anything but these
      // settings are used by render functions
      var start = comment.start,
          length = getCommentLength(comment),
          dur = this.duration;

      if (length) {
        comment.divWidth = length / dur * 100 + '%';
        comment.divType = 'comment-span';
      } else {
        comment.divWidth = '8px';
        comment.divType = 'comment';
      }

      comment.divPos = start / dur * 100 + '%';
      return comment;
    },
    togglePlayButton: function togglePlayButton() {
      if (this.player.paused) {
        this.$play.filter('.fa-play').removeClass('inactive');
        this.$play.filter('.fa-pause').addClass('inactive');
      } else {
        this.$play.filter('.fa-play').addClass('inactive');
        this.$play.filter('.fa-pause').removeClass('inactive');
      }
    },
    updateHighlight: function updateHighlight() {
      var width = "".concat(this.percent * 100, "%");
      var height = this.$image.height() + 'px';
      this.$highlight.width(width).height(height);
    },
    updateSeekBar: function updateSeekBar() {
      this.$seek.val(this.percent * 100.0);
    }
  };
  var _default = AudioComments;
  _exports.default = _default;
});