define("bocce/controllers/index", ["exports", "bocce/mixins/menus", "bocce/mixins/user-profile"], function (_exports, _menus, _userProfile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */
  var _default = Ember.Controller.extend(_menus.default, _userProfile.default, {
    userprofile: Ember.inject.controller(),
    // required by UserProfileMixin
    nextTerm: Ember.computed('model.terms.length', function () {
      return {};
    }),
    previousTerm: Ember.computed('model.terms.length', function () {
      return {};
    }),
    currentTerm: Ember.computed('model.terms.length', function () {
      /* eslint-disable-next-line ember/no-get */
      var terms = this.get('model.terms'),
          best;

      if (!terms || terms.get('length') === 0) {
        return {};
      }

      best = terms.objectAt(0);

      if (terms.get('length') > 1) {// do something here with startsOn.
      }

      return best;
    })
  });

  _exports.default = _default;
});