define("bocce/mixins/support/util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shuffle = shuffle;
  _exports.any = any;
  _exports.unique = unique;
  _exports.sortAsc = sortAsc;
  _exports.sortDesc = sortDesc;
  _exports.max = max;
  _exports.min = min;
  _exports.bindAll = bindAll;
  _exports.mixin = mixin;

  function shuffle(arr) {
    var l = arr.length;

    for (var i = l - 1; i >= 1; i--) {
      var n = Math.floor(Math.random() * i),
          tmp = arr[n];
      arr[n] = arr[i];
      arr[i] = tmp;
    }
  }

  function any(arr, func) {
    // This could be arr.filter(func).length > 0, but looping lets us short circuit
    var l = arr.length;

    for (var i = 0; i < l; i++) {
      if (func(arr[i])) {
        return true;
      }
    }

    return false;
  }

  function unique(arr) {
    return Array.from(new Set(arr));
  }

  function sortAsc(arr) {
    return arr.sort(function (a, b) {
      return a > b ? 1 : -1;
    });
  }

  function sortDesc(arr) {
    return arr.sort(function (a, b) {
      return a > b ? -1 : 1;
    });
  }

  function max(arr) {
    return sortDesc(arr)[0];
  }

  function min(arr) {
    return sortAsc(arr)[0];
  }

  function bindAll(obj) {
    var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    for (var fn in obj) {
      if (typeof obj[fn] !== 'function' || fields && fields.indexOf(fn) === -1) {
        continue;
      }

      obj[fn] = obj[fn].bind(obj);
    }
  }

  function mixin(dest, src, no_overwrites) {
    for (var k in src) {
      if (!dest[k] || !no_overwrites) {
        dest[k] = src[k];
      }
    }
  }
});