define("bocce/models/quiz_question", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/models/quiz_question.js
  var _default = _model.default.extend({
    position: (0, _model.attr)('number'),
    text: (0, _model.attr)('string'),
    points: (0, _model.attr)('number'),
    correct_feedback: (0, _model.attr)('string'),
    wrong_feedback: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    answers: (0, _model.attr)(),
    checked: (0, _model.attr)('boolean'),
    multichoice: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});