define("bocce/routes/classroom/lessons/quiz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect: function redirect(model) {
      var _this = this;

      var itemID = model.get('id');
      return this.store.findRecord('item', itemID).then(function (item) {
        var lessonID = item.get('lesson.id');

        if (!lessonID) {
          throw new Error("Did not find lesson for item ".concat(itemID));
        }

        _this.replaceWith('classroom.lessons', lessonID, itemID);
      });
    }
  });

  _exports.default = _default;
});