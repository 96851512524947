define("bocce/mixins/interactions/mix_visualizer", ["exports", "bocce/mixins/support/render-template"], function (_exports, _renderTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function MixVisualizer(id, debug) {
    this.id = id;
    this.debug = debug;
    /* eslint-disable-next-line ember/no-jquery */

    this.$el = Ember.$(this.id);
    this.$container = this.$el.find('.interaction_content');
    this.$container.css('width', 'initial');
    this.init();
  }

  MixVisualizer.prototype.init = function () {
    var _this = this;

    var currentCircle;

    function getRandomColor() {
      var letters = '0123456789ABCDEF',
          color = '';

      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }

      return color;
    } // Detect whether a given HEX is light or dark, and slect a black or white font based on that


    function lightOrDark(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

      if (result) {
        if (parseInt(result[1], 16) > 150 || parseInt(result[2], 16) > 150 || parseInt(result[3], 16) > 150) {
          return '000';
        }
      }

      return 'fff';
    }

    function detectIE() {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf('MSIE ');

      if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      var trident = ua.indexOf('Trident/');

      if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }

      var edge = ua.indexOf('Edge/');

      if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      } // other browser


      return false;
    }

    return (0, _renderTemplate.default)('mix_visualizer', 'main').then(function (content) {
      _this.$container.empty().html(content);

      var innerCircle; // Set up bindings

      _this.$container.find('#viz-title').on('keyup', function (box) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.ssWrapper').attr('title', box.currentTarget.value);
      });

      _this.$container.find('#element-name').on('keyup', function (box) {
        innerCircle.find('span').html(box.currentTarget.value);
      });

      _this.$container.find('#pan-range').on('input', function () {
        /* eslint-disable-next-line ember/no-jquery */
        var vol = parseInt(Ember.$('#volume-range').val()),
            volRange = 100 - vol,

        /* eslint-disable-next-line ember/no-jquery */
        panRange = parseInt(Ember.$('#pan-range').val());
        currentCircle.css('left', panRange / 100 * 79.6 - 16 * volRange / 100 * ((panRange - 50) / 50) + '%');
        currentCircle.attr('pan', panRange);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#pan-label').attr('val', panRange - 50);
      }); // TODO: here?


      _this.$container.find('#frequency-range').on('input', function () {
        /* eslint-disable-next-line ember/no-jquery */
        var volRange = 100 - parseInt(Ember.$('#volume-range').val()),

        /* eslint-disable-next-line ember/no-jquery */
        freq = parseInt(Ember.$('#frequency-range').val()),
            freqRange = 100 - freq;
        currentCircle.css('top', freqRange / 100 * 65 - 11.7 * volRange / 100 * ((freqRange - 50) / 50) + '%');
        currentCircle.attr('frequency', freq);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#freq-label').attr('val', freq);
      });

      _this.$container.find('#volume-range').on('input', function () {
        /* eslint-disable-next-line ember/no-jquery */
        var vol = parseInt(Ember.$('#volume-range').val()),
            volRange = 100 - vol,

        /* eslint-disable-next-line ember/no-jquery */
        panRange = parseInt(Ember.$('#pan-range').val()),

        /* eslint-disable-next-line ember/no-jquery */
        freq = parseInt(Ember.$('#frequency-range').val()),
            freqRange = 100 - freq,

        /* eslint-disable-next-line ember/no-jquery */
        widthRange = Ember.$('#width-range').val(),
            percent = (vol + 90) / 190,
            widthPercent = 100 + widthRange / 100 * 550,
            calcPercent = widthPercent * percent;
        currentCircle.css('z-index', vol);
        currentCircle.css('left', panRange / 100 * 79.6 - 16 * volRange / 100 * ((panRange - 50) / 50) + '%');
        currentCircle.css('top', freqRange / 100 * 65 - 11.7 * volRange / 100 * ((freqRange - 50) / 50) + '%');
        currentCircle.css('font-size', parseInt(50 + 50 * (vol / 100)) + '%');
        innerCircle.css('width', calcPercent + '%');
        innerCircle.css('height', percent * 100 + '%');
        innerCircle.css('margin', (100 - percent * 100) / 2 + '% ' + (100 - calcPercent) / 2 + '%');
        currentCircle.attr('volume', vol);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#vol-label').attr('val', vol);
      });

      _this.$container.find('#width-range').on('input', function () {
        /* eslint-disable-next-line ember/no-jquery */
        var vol = parseInt(Ember.$('#volume-range').val()),

        /* eslint-disable-next-line ember/no-jquery */
        widthRange = Ember.$('#width-range').val(),
            widthPercent = 100 + widthRange / 100 * 550,
            percent = (vol + 90) / 190,
            calcPercent = widthPercent * percent;
        innerCircle.css('width', calcPercent + '%');
        currentCircle.attr('range', widthRange);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#range-label').attr('val', widthRange);
        innerCircle.css('margin', (100 - percent * 100) / 2 + '% ' + (100 - calcPercent) / 2 + '%');
      });

      _this.$container.find('#location-range').on('input', function () {
        /* eslint-disable-next-line ember/no-jquery */
        var loc = parseInt(Ember.$('#location-range').val());
        innerCircle.find('span').css('left', loc - 50 + '%');
        currentCircle.attr('location', loc);
      });

      _this.$container.find('#height-range').on('input', function () {
        /* eslint-disable-next-line ember/no-jquery */
        var loc = parseInt(Ember.$('#height-range').val());
        innerCircle.find('span').css('top', loc + '%');
        currentCircle.attr('height', loc);
      });

      _this.$container.find('.new-control').on('click', function () {
        var color = getRandomColor();
        var textColor = lightOrDark(color);
        /* eslint-disable-next-line ember/no-jquery */

        var newCircle = Ember.$('<div volume=\'100\' pan=\'50\' frequency=\'50\' location=\'50\' height=\'50\' range=\'0\' class=\'circle-container\' style=\'top: 37%; left: 42.3%;\'><div class=\'circle\' style=\'background-color: #' + color + 'a8; color: #' + textColor + '\'></div></div>');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#sndInput').append(newCircle);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.pan-controls').removeClass('ctrl-disabled');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.dangerous-button').removeClass('ctrl-disabled');
        currentCircle = newCircle;
        innerCircle = currentCircle.find('.circle');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#volume-range').val(currentCircle.attr('volume'));
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#location-range').val(currentCircle.attr('location'));
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#height-range').val(currentCircle.attr('height'));
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#pan-range').val(currentCircle.attr('pan'));
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#frequency-range').val(currentCircle.attr('frequency'));
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#width-range').val(currentCircle.attr('range'));
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#element-name').val('Sound Name');
        innerCircle.html('<span style=\'text-shadow: -1px -1px 0 #' + color + '\'>Sound Name</span>');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#range-label').attr('val', currentCircle.attr('range'));
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#vol-label').attr('val', currentCircle.attr('volume'));
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#freq-label').attr('val', currentCircle.attr('frequency'));
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#pan-label').attr('val', currentCircle.attr('pan'));
        newCircle.on('click', function (circle) {
          /* eslint-disable-next-line ember/no-jquery */
          currentCircle = Ember.$(circle.currentTarget);
          innerCircle = currentCircle.find('.circle');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.pan-controls').removeClass('ctrl-disabled');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.dangerous-button').removeClass('ctrl-disabled');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('#location-range').val(currentCircle.attr('location'));
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('#height-range').val(currentCircle.attr('height'));
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('#volume-range').val(currentCircle.attr('volume'));
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('#pan-range').val(currentCircle.attr('pan'));
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('#frequency-range').val(currentCircle.attr('frequency'));
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('#width-range').val(currentCircle.attr('range'));
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('#element-name').val(innerCircle.text());
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('#range-label').attr('val', currentCircle.attr('range'));
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('#vol-label').attr('val', currentCircle.attr('volume'));
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('#freq-label').attr('val', currentCircle.attr('frequency'));
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('#pan-label').attr('val', currentCircle.attr('pan'));
        });
      });

      _this.$container.find('.remove-control').on('click', function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(currentCircle).remove();
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.pan-controls').addClass('ctrl-disabled');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.dangerous-button').addClass('ctrl-disabled');
      });

      _this.$container.find('.capture-control').on('click', function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.soundStageContainer').addClass('capture');
        window.html2canvas(document.querySelector('.ssWrapper'), {
          allowTaint: false,
          scale: 1
        }).then(function (canvas) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.soundStageContainer').removeClass('capture'); // IE/Edge do not support direct image downloads. Open img in new tab for manual download

          if (!detectIE()) {
            var element = document.createElement('a');
            element.setAttribute('href', canvas.toDataURL());
            element.setAttribute('download', 'soundStage.png');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          } else {
            var image = new Image();
            image.src = canvas.toDataURL();
            var w = window.open('');
            w.document.write(image.outerHTML);
          }
        });
      });
    });
  };

  var _default = MixVisualizer;
  _exports.default = _default;
});