define("bocce/models/notification", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/models/notification.js
  var _default = _model.default.extend({
    updated_at: (0, _model.attr)('date'),
    type: (0, _model.attr)('string'),
    user_id: (0, _model.attr)(),
    notification_id: (0, _model.attr)('string'),
    verbose_message: (0, _model.attr)('string'),
    user: (0, _model.attr)(),
    link: (0, _model.attr)('string')
  });

  _exports.default = _default;
});