define("bocce/helpers/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/helpers/calendar.js
  // Convert date into calendar form with Moment.JS
  var _default = Ember.Helper.helper(function (date) {
    if (date.length === 0) {
      return 'Date Incorrect';
    }

    return moment(date[0]).tz('America/New_York').calendar();
  });

  _exports.default = _default;
});