define("bocce/mixins/attachments-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create({
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    attachment_objects: Ember.computed('attachments.[]', function () {
      var attachments = this.attachments;
      var retval = [];

      var _iterator = _createForOfIteratorHelper(attachments),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;

          // If the item is an object with a URL, that should be all we need to render the attachment component. If there
          // is no URL, assume that item either is or has an ID that we can use to get the attachment model from the store.
          if (_typeof(item) === 'object' && item.url) {
            // If item doesn't already have a name or type, copy other property values that can work instead.
            item.name = item.name || item.display_name || item.filename;
            item.type = item.type || item['content-type'];
            retval.push(item);
          } else {
            // item can either be an ID, or an object with an ID property. If it's an object with an ID, use that.
            var id = void 0;

            if (Object.prototype.hasOwnProperty.call(item, 'id')) {
              id = item.id;
            } else {
              id = item;
            }

            if (id) {
              // This works because the attachment adapter (see adapters/attachment.js) prevents re-fetching attachment models
              // from the server. Attachment models should almost never be fetched individually; instead they should be
              // included as sideloaded models when fetching discussions, messages, submissions, etc. When they're sideloaded
              // the URL property includes a query string that Canvas uses to verify that the user has permission to view it.
              retval.push(this.store.findRecord('attachment', id));
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return retval;
    })
  });

  _exports.default = _default;
});