define("bocce/mixins/webex", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    otherSectionChats: [],
    openWebex: function openWebex(api_url) {
      var eventsCtl = this.events;
      eventsCtl.set('isWorking', true);
      return fetch(api_url).then(function (r) {
        if (!r.ok) {
          throw new Error('Unable to start live chat. Please try again in a few minutes.');
        }

        return r.json();
      }).then(function (dest_url) {
        window.open(dest_url, '_blank');
        eventsCtl.set('isWorking', false);
        return dest_url;
      }).catch(function (error) {
        (0, _dialog.default)("Error starting chat: ".concat(error.message));
      }).finally(function () {
        eventsCtl.set('isWorking', false);
      });
    },
    actions: {
      startChat: function startChat(otherSectionChats) {
        /* eslint-disable-next-line ember/no-get */
        var section_id = this.get('session.section.id');
        otherSectionChats = otherSectionChats || [];
        this.set('otherSectionChats', otherSectionChats);
        this.openWebex("/interface/livechat/createChat/".concat(section_id));
      },
      startStudentChat: function startStudentChat(event_id, event_title) {
        /* eslint-disable-next-line ember/no-get */
        var section_id = this.get('session.section.id');

        if (!event_id) {
          var newStudentEventController = this.controllerFor('classroom.lessons.student-event-new'),
              chatTitle = encodeURI(newStudentEventController.get('titleInput'));

          if (!chatTitle) {
            alert('Your session must have a title.');
            return;
          }

          this.openWebex("/interface/livechat/createStudentChat/".concat(section_id, "/").concat(chatTitle));
        } else {
          event_title = encodeURI(event_title);
          this.openWebex("/interface/livechat/createStudentChat/".concat(section_id, "/").concat(event_title, "/").concat(event_id));
        }

        this.send('closeModals');
      },
      mergeChat: function mergeChat() {
        /* eslint-disable-next-line ember/no-get */
        var section_id = this.get('session.section.id');
        return fetch("/interface/livechat/addSectionToChat/".concat(section_id)).then(null, function () {
          return false;
        });
      },
      startMultiSectionChat: function startMultiSectionChat(executedFrom) {
        var eventController = this.event,
            eventsController = this.events,
            otherSectionChats = executedFrom === 'modal' ? eventController.get('otherSectionChatsData') : eventsController.get('otherSectionChats');
        this.send('startChat', otherSectionChats);
      },
      recordParticipation: function recordParticipation(meetingKeys) {
        /* eslint-disable-next-line ember/no-get */
        var course = this.get('session.course'),
            // Use a placeholder if customGradeBookColumns is undefined
        customGradeBookColumns = course.get('customGradeBookColumns'),

        /* eslint-disable-next-line ember/no-get */
        currentUser = this.get('session.user'),
            meetingKeyColumn,
            numChatsAttendedColumn,
            saveCourse = false;
        /* eslint-disable-next-line ember/no-get */

        if (!this.get('session.isStudent') || !customGradeBookColumns) {
          return;
        }

        meetingKeyColumn = customGradeBookColumns.find(function (gbColumn) {
          return gbColumn.title === 'Live Class Meeting Keys';
        });
        numChatsAttendedColumn = customGradeBookColumns.find(function (gbColumn) {
          return gbColumn.title === 'Live Class Attendance';
        }); // Sometimes, when empty, the column's data comes back as an empty array OR an empty object

        if (meetingKeyColumn.data.length === 0 || Object.keys(meetingKeyColumn.data).length === 0) {
          meetingKeyColumn.data = [{
            content: JSON.stringify(meetingKeys),
            user_id: currentUser.get('id')
          }];
          numChatsAttendedColumn.data = [{
            content: meetingKeys.length.toString(),
            user_id: currentUser.get('id')
          }];
          saveCourse = true;
        } else {
          // Canvas returns annoyingly returns single values in an array.
          var studentData = meetingKeyColumn.data[0],
              data = JSON.parse(studentData.content);
          meetingKeys.forEach(function (meetingKey) {
            if (data.indexOf(meetingKey) === -1) {
              data.push(meetingKey);
              saveCourse = true;
            }
          });
          meetingKeyColumn.data = [{
            content: JSON.stringify(data),
            user_id: currentUser.get('id')
          }];
          numChatsAttendedColumn.data = [{
            content: data.length.toString(),
            user_id: currentUser.get('id')
          }];
        }

        if (saveCourse) {
          course.set('customGradeBookColumns', [meetingKeyColumn, numChatsAttendedColumn]);
          course.save();
        }
      },
      joinChat: function joinChat() {
        var _this = this;

        /* eslint-disable-next-line ember/no-get */
        var user = this.get('session.user'),

        /* eslint-disable-next-line ember/no-get */
        section_id = this.get('session.section.id');
        this.openWebex("/interface/livechat/joinChat/".concat(section_id, "/").concat(user.get('name').replace(/\//g, ''), "/").concat(user.get('email'))).then(function (joinUrl) {
          var parts = joinUrl.split('/'),
              meetingKey = parts[parts.length - 1];

          _this.send('recordParticipation', [meetingKey]);
        });
      },
      joinStudentChat: function joinStudentChat(studentChat) {
        /* eslint-disable-next-line ember/no-get */
        var user = this.get('session.user'),

        /* eslint-disable-next-line ember/no-get */
        sectionId = this.get('session.section.id'),
            meetingKeys = studentChat.get('meetingKeys');

        var _iterator = _createForOfIteratorHelper(meetingKeys),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var meetingKey = _step.value;
            this.openWebex("/interface/livechat/joinChat/".concat(sectionId, "/").concat(user.get('name'), "/").concat(user.get('email'), "/").concat(meetingKey)); // $.ajax({
            //   type: 'GET',
            //   url: `/interface/chat/isStudentChatRunning/${meetingKey}`,
            //   dataType: 'text',
            //   success: (running) => {
            //     if ( running ) {
            //       this.openWebex(`/interface/chat/joinChat/${sectionId}/${user.get('name')}/${user.get('email')}/${meetingKey}`);
            //     }
            //   },
            //   error: (err) => reject(err)
            // });
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      },
      testChat: function testChat() {
        var isStudent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        /* eslint-disable-next-line ember/no-get */
        var section_id = this.get('session.section.id');

        if (!isStudent) {
          this.openWebex("/interface/livechat/createTestChat/".concat(section_id));
        } else {
          this.openWebex("/interface/livechat/createTestChat/".concat(section_id, "/student"));
        }
      },
      //JRW: Added when campus users started using our zoom instance
      // Open a tab so that they can login to zoom, then refresh when
      // the bocce tab regains focus.
      zoomLoginRedirect: function zoomLoginRedirect() {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(window).focus(function () {
          location.reload(true);
        });
        window.open('https://berklee.zoom.us', '_blank');
      },
      rejoinChatAsHost: function rejoinChatAsHost() {
        /* eslint-disable-next-line ember/no-get */
        var section_id = this.get('session.section.id');
        this.openWebex("/interface/livechat/joinChatAsHost/".concat(section_id));
      },
      watchChatUrl: function watchChatUrl(value) {
        if (value) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.watch-button option:first').attr('selected', true);
          window.open(value, '_blank');
        }
      },
      watchArchivedChat: function watchArchivedChat(event_id) {
        var _this2 = this;

        var url = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
        this.store.findRecord('event', event_id).then(function (eventObject) {
          //If it doesn't have kaltura recordings, go to zoom
          if (!eventObject.get('kalturaRecordings') || eventObject.get('kalturaRecordings.length') === 0) {
            if (url && !url.isEmpty()) {
              window.open(url, '_blank');
            }
          }

          _this2.send('viewEvent', event_id);
        });
      }
    }
  });

  _exports.default = _default;
});