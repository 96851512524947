define("bocce/models/section", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/models/section.js
  var _default = _model.default.extend({
    number: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    course: (0, _model.belongsTo)('course', {
      async: false
    }),
    role: (0, _model.attr)('string'),
    showcase_course_id: (0, _model.attr)(),
    showcase_section_id: (0, _model.attr)(),
    ends_at: (0, _model.attr)(),
    discussions: (0, _model.hasMany)('discussion'),
    assignments: (0, _model.hasMany)('assignment'),
    quizzes: (0, _model.hasMany)('quiz'),
    honorCodeAccepted: (0, _model.attr)('boolean'),
    teachers: (0, _model.hasMany)('user', {
      async: true
    }),
    enrollments: (0, _model.hasMany)('enrollment', {
      async: true
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    needsHonorCode: Ember.computed('honorCodeAccepted', 'session.isEnabled.honorCode', 'course.term.isBCM', function () {
      if (
      /* eslint-disable-next-line ember/no-get */
      !this.get('course.term.isBCM') && window.session.get('isEnabled.honorCode') && !window.session.get('course.isShowcase') && !window.session.get('course.isArchived') && !window.session.get('course.isReadOnly') && !window.session.get('course.isSample') && !window.session.get('course.isGettingStarted') && this.role === 'student' && !this.honorCodeAccepted) {
        return true;
      }

      return false;
    }),
    students: Ember.computed.filterBy('enrollments', 'role', 'student'),
    numStudents: Ember.computed('students', function () {
      return this.students.get('length');
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    studentsByLastName: Ember.computed('enrollments', function () {
      var students = this.students;
      return students.sort(function (a, b) {
        var splitA = a.get('user').get('name').split(' '),
            splitB = b.get('user').get('name').split(' '),
            lastA = splitA[splitA.length - 1],
            lastB = splitB[splitB.length - 1];

        if (lastA.toLowerCase() < lastB.toLowerCase()) {
          return -1;
        }

        if (lastA.toLowerCase() > lastB.toLowerCase()) {
          return 1;
        }

        return 0;
      });
    })
  });

  _exports.default = _default;
});