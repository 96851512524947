define("bocce/mixins/enmodal", ["exports", "bocce/mixins/editable", "bocce/utilities/dialog"], function (_exports, _editable, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create(_editable.default, {
    actions: {
      newTypeModal: function newTypeModal(type) {
        this.transitionToRoute("classroom.lessons.".concat(type, "-new"));
      },
      viewModal: function viewModal(type, modal) {
        this.transitionToRoute("classroom.lessons.".concat(type), modal);
      },
      closeModals: function closeModals(posting) {
        var _this = this;

        var dialogPromise;

        if (!posting && this.editorHasContent) {
          var message = 'Are you sure you want to close this window? All unsaved work will be lost.';
          dialogPromise = (0, _dialog.default)(message, ['Yes', 'No']);
        } else {
          dialogPromise = Promise.resolve('Yes');
        }

        dialogPromise.then(function (selection) {
          if (selection === 'Yes') {
            if (_this.files) {
              _this.send('clearAllFiles');
            }

            if (_this.actions && _this.actions.teardown) {
              _this.send('teardown');
            }

            _this.send('deleteArchivedText', {
              'name': 'Auto Save'
            }, true);
            /* eslint-disable-next-line ember/no-jquery */


            Ember.$('.side-panel').removeClass('on-modal');

            _this.send('destroyEditor');

            _this.transitionToRoute('classroom.lessons');
          }
        });
      },
      viewAssignmentInLesson: function viewAssignmentInLesson(id) {
        var that = this;
        this.store.findRecord('item', id).then(function (items) {
          that.transitionToRoute('classroom.lessons', items.get('lesson').id, id);
        });
      }
    }
  });

  _exports.default = _default;
});