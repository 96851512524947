define("bocce/mixins/discussable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create({
    activityMessage: Ember.computed('message', function () {
      var txt = this;
      return txt;
    }),
    postTime: Ember.computed('date', function () {
      var retVal = moment(this.date).tz('America/New_York').format('MMMM Do, h:mmA') + ' ET';
      return retVal;
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    postAuthor: Ember.computed('message', function () {
      var retVal = this.user;
      return retVal;
    }),
    // Track whether or not a model is set
    // Set up/break down modal accordingly

    /* eslint-disable-next-line ember/no-observers */
    toggleModal: Ember.observer('model', 'activeNewDiscussion', 'classroom.activeNewDiscussion', function () {
      var mod = this.model || {},

      /* eslint-disable-next-line ember/no-get */
      and = this.activeNewDiscussion || this.get('classroom.activeNewDiscussion');

      var setupCameraTag = function setupCameraTag() {
        if (window.CameraTag) {
          window.CameraTag.setup();
        }
      };

      if (and) {
        if (and === 'discussion') {
          and = 'conversation';
        }

        this.set('discussion.inEditor', true);
        /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */

        Ember.run.scheduleOnce('afterRender', this, function () {
          setupCameraTag();
        });
      } else if (mod.id) {
        // There is a "read" status for conversations but disabling this
        // for conversations for now. - JRW
        if (mod.constructor.modelName !== 'conversation') {
          this.mark_read(mod);
          setupCameraTag();
          /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */

          Ember.run.scheduleOnce('afterRender', this, function () {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.floating-modal video').attr('preload', 'none');
          });
        }
      } else {
        this.set('discussion.inEditor', false);
        this.set('discussion.isLesson', false);
        this.set('discussion.bodyInput', '');
        this.set('bodyInput', '');
        this.set('titleInput', '');
      }

      this.discussion.send('clearAllFiles');
    }),
    mark_read: function mark_read(model) {
      model.set('read', true);

      if (model.get('hasUnreadResponses')) {
        model.set('markReadThrough', model.get('lastResponseId'));
      }

      model.save();
    },
    actions: {
      treatAssignment: function treatAssignment(id) {
        var that = this;
        this.store.findRecord('assignment', id).then(function (assignment) {
          var submission;

          if (that.session.get('isInstructor') || that.session.get('isObserver')) {
            submission = assignment.get('submissions.content.currentState');

            if (submission.length > 0) {
              submission = submission[0].id;
            } else {
              submission = false;
            }

            if (submission) {
              that.send('viewModal', 'submission', submission);
            } else {
              that.send('viewModal', 'no-submissions', assignment.id);
            }
          } else {
            if (that.session.get('course.isReadOnly')) {
              submission = assignment.get('currentUserSubmission');

              if (submission) {
                that.send('viewModal', 'submission', submission);
              } else {
                that.send('viewModal', 'submission-new', assignment.id);
              }
            } else {
              that.store.nestResources('submission', [{
                section: that.session.section.id
              }, {
                assignment: assignment.id
              }]);
              that.store.findAll('submission', {
                reload: true
              }).then(function (submissions) {
                submission = submissions.find(function (s) {
                  var mine = s.get('my_submission');
                  var correct_assignment = s.get('assignment.id') === assignment.id;
                  return mine && correct_assignment;
                });

                if (submission) {
                  that.send('viewModal', 'submission', submission.id);
                } else {
                  that.send('viewModal', 'submission-new', assignment.id);
                }
              }, function () {
                that.send('viewModal', 'submission-new', assignment.id);
              });
            }
          }
        });
      },
      subscribe: function subscribe() {
        /* eslint-disable-next-line ember/no-get */
        var model = this.get('discussion.model');
        model.set('subscribed', !model.get('subscribed'));
        model.save();
      }
    }
  });

  _exports.default = _default;
});