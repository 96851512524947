define("bocce/components/assignment/assignment-comment", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['reply'],
    classNameBindings: ['deleted'],
    deleted: Ember.computed.reads('response.is_deleted'),
    actions: {
      deleteConfirm: function deleteConfirm() {
        var _this = this;

        var message = 'Are you sure you wish to delete this comment? This action CANNOT be undone!';
        (0, _dialog.default)(message, ['Yes. If permanent deletion of attachments is desired, first delete manually in S3. Then manually delete in Canvas.', 'No']).then(function (choice) {
          if (choice.indexOf('Yes') === 0) {
            /* eslint-disable-next-line ember/no-get */
            var id = _this.get('response.id');

            _this.send('sendParentAction', 'deleteAssignmentComment', id);
          }
        });
      },
      sendParentAction: function sendParentAction(action) {
        (false && !(typeof this.get(action) !== 'undefined') && Ember.assert('action should be defined.', typeof this.get(action) !== 'undefined'));

        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        this.get(action).apply(void 0, args);
      },
      sendToggleUserProfile: function sendToggleUserProfile(id) {
        (false && !(typeof id !== 'undefined') && Ember.assert('id should be defined', typeof id !== 'undefined'));
        this.send('sendParentAction', 'toggleUserProfile', id);
      }
    }
  });

  _exports.default = _default;
});