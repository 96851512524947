define("bocce/helpers/correct", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/helpers/correct.js
  // Check if question is correct
  var _default = Ember.Helper.helper(function (mod) {
    var answer;

    if (!!mod[0] && !!mod[0].findBy) {
      if (!mod[1]) {
        answer = mod[0].filterBy('correct', true);
        return answer.length;
      }

      answer = mod[0].findBy('question_id', parseInt(mod[1]));

      if (answer && answer.correct) {
        return answer.correct;
      }

      return false;
    } else {
      return false;
    }
  });

  _exports.default = _default;
});