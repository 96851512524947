define("bocce/templates/components/user-icons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0RyQYFrK",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"needsIcons\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[35,0,[\"hasAccommodation\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fas fa-universal-access accommodation\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,0,[\"isDegreeStudent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fas fa-graduation-cap degree\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"user\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/user-icons.hbs"
    }
  });

  _exports.default = _default;
});