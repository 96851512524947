define("bocce/templates/classroom/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eBOiY9D7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"masker visible\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"loading-mask\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"loading-spinner\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fas fa-spinner fa-spin\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"loading-message\"],[12],[2,\"\\n    Loading Topics...\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/classroom/loading.hbs"
    }
  });

  _exports.default = _default;
});