define("bocce/services/login-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var started = false;

  var _default = Ember.Service.extend({
    start: function start() {
      var _this = this;

      // Ensure this function only ever runs once.
      if (started) {
        return;
      }

      started = true;
      var interval = 60 * 1000,
          interval_num = 0,
          refresh_saml_every = 15,
          acs_user_id;

      if (window.location.host == 'intro.online.berklee.edu') {
        return;
      }
      /* eslint-disable-next-line ember/no-jquery */


      var iframe = Ember.$('<iframe id="login-refresh" style="width: 1px; height: 1px; left: -500px; position: fixed;"></iframe>');
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('body').append(iframe);

      var login_refresh = function login_refresh() {
        interval_num++;
        Ember.run.later(_this, login_refresh, interval);

        if (document.cookie.match(/masquerading/)) {
          return;
        } // Refresh SAML every 15 minutes or when we no longer see the
        // canvas_user_id cookie.


        if (interval_num % refresh_saml_every === 0 || !document.cookie.match(/^canvas_user_id=|; canvas_user_id=/)) {
          Ember.debug('Refreshing session');
          iframe.attr('src', '/login/saml?return_to=/api/v1/users/self');
        }

        var sess = document.cookie.match(/(\s|^)ad_user_login=([0-9]+)[^0-9]/i);

        if (!sess) {
          sess = document.cookie.match(/(\s|^)ad_session_id=[0-9]+%2c([0-9]+)[^0-9]/i);
        } // If we've been logged in before (ie, not on a dev server, where we can't
        // see o.b.e cookies) and now either have no session or a different user_id,
        // offer to refresh the page.


        if (acs_user_id && (!sess || sess[2] !== acs_user_id) && confirm('You seem to have changed users.  Press "Ok" to reload this page with your new credentials.')) {
          window.location = '/login/saml?return_to=' + encodeURIComponent(window.location.href);
        }

        if (sess) {
          acs_user_id = sess[2];
        }
      };

      Ember.run.later(this, login_refresh, interval);
    }
  });

  _exports.default = _default;
});