define("bocce/models/session", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/models/session.js
  var _default = _model.default.extend({
    user: (0, _model.belongsTo)('user', {
      async: true
    }),
    panel: (0, _model.attr)('string'),
    kaltura: (0, _model.attr)('string'),
    kalturaSession: Ember.computed.reads('kaltura'),
    showLessons: Ember.computed.equal('panel', 'lesson')
  });

  _exports.default = _default;
});