define("bocce/components/upload-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['upload-preview'],
    file: Ember.computed.reads('model.file'),
    uploadedId: Ember.computed.reads('model.uploaded_id'),
    type: Ember.computed.reads('file.type'),
    previewStopped: function previewStopped() {
      var id = this.uploadedId;
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$("#file-id-".concat(id)).removeClass('playing');
      this.currentPreviewAudio
      /* eslint-disable-next-line ember/no-jquery */
      .off('playing', Ember.$.proxy(this.previewPlaying, this))
      /* eslint-disable-next-line ember/no-jquery */
      .off('pause', Ember.$.proxy(this.previewStopped, this))
      /* eslint-disable-next-line ember/no-jquery */
      .off('ended', Ember.$.proxy(this.previewStopped, this));
    },
    previewPlaying: function previewPlaying() {
      var id = this.uploadedId;
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$("#file-id-".concat(id)).addClass('playing');
    },

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    is_image: Ember.computed('attachment', function () {
      var type = this.type;
      return type === 'image/jpeg' || type === 'image/png' || type === 'image/gif';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    is_video: Ember.computed('attachment', function () {
      var type = this.type;
      return type === 'video/mp4';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    is_quicktime: Ember.computed('attachment', function () {
      var type = this.type;
      return type === 'video/quicktime';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    is_audio: Ember.computed('attachment', function () {
      var type = this.type;
      return type === 'audio/mpeg3' || type === 'audio/mp3' || type === 'audio/mpeg';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    is_word: Ember.computed('attachment', function () {
      var type = this.type;
      return type === 'application/msword';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    is_pdf: Ember.computed('attachment', function () {
      var type = this.type;
      return type === 'application/pdf';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    generatedUrl: Ember.computed('attachment', function () {
      if (!this.file.url) {
        return URL.createObjectURL(this.file);
      } // Grab Kaltura thumbnail

      /* eslint-disable-next-line ember/no-get */


      var url = this.get('file.url');
      var kaltura = url.split('https://cdnapisec.kaltura.com/p/2588802/sp/258880200/playManifest/entryId/');

      if (kaltura.length > 1) {
        kaltura = kaltura[1].split('/format/url/protocol/https/flavorParamId/4128/name/course_video.mp4');
        var thumbnailUrl = 'https://cdnsecakmi.kaltura.com/p/2588802/thumbnail/entry_id/' + kaltura[0] + '/width/250';
        return thumbnailUrl;
      }

      return url;
    }),
    actions: {
      audioPreview: function audioPreview() {
        /* eslint-disable-next-line ember/no-jquery */
        var audio = Ember.$('#attachment-audio-player').get(0);
        audio.attributes.src.value = this.generatedUrl;
        audio.load();
        audio.play();
        /* eslint-disable-next-line ember/no-jquery */

        this.set('currentPreviewAudio', Ember.$(audio));
        this.currentPreviewAudio
        /* eslint-disable-next-line ember/no-jquery */
        .on('playing', Ember.$.proxy(this.previewPlaying, this))
        /* eslint-disable-next-line ember/no-jquery */
        .on('pause', Ember.$.proxy(this.previewStopped, this))
        /* eslint-disable-next-line ember/no-jquery */
        .on('ended', Ember.$.proxy(this.previewStopped, this));
      },
      audioPreviewStop: function audioPreviewStop() {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#attachment-audio-player').get(0).pause();
      },
      videoPreview: function videoPreview() {
        if (event.target.paused) {
          event.target.play();
        } else {
          event.target.pause();
        }
      },
      otherFilePreview: function otherFilePreview() {
        var url = this.generatedUrl;
        window.open(url, '_blank');
      }
    }
  });

  _exports.default = _default;
});