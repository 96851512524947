define("bocce/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var _this = this;

      var basic_session = this.store.findRecord('session', 1);
      basic_session.then(function (s) {
        var userID = s.get('user.id');

        var userPromise = _this.store.findRecord('user', userID, {
          reload: true
        });

        _this.session.set('user', userPromise); //TODO: This needs to be replaced with the EAPM equivalent
        //if ( window.newrelic ) {
        //  window.newrelic.setCustomAttribute('user_id', userID);
        //}


        _this['login-refresh'].start();
      });
      return basic_session;
    },
    actions: {
      error: function error(_error) {
        if (_error && _error.errors && _error.errors[0].status.toString() === '401') {
          window.location = '/login/saml?return_to=' + encodeURIComponent(window.location.href);
          return false;
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});