define("bocce/mixins/assignments", ["exports", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/boot", "bocce/mixins/showcase", "bocce/utilities/dialog"], function (_exports, _audioRec, _videoRec, _rtcRec, _boot, _showcase, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create(_audioRec.default, _videoRec.default, _rtcRec.default, _showcase.default, _boot.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.currentTimeMetronome();
    },
    bootContent: function bootContent() {
      /* eslint-disable-next-line ember/no-jquery */
      var node = Ember.$('.timed-assignment-instructions');

      if (node && node.length > 0) {
        this.boot_area(node, false, true, false, false, true);
      }
    },
    grades: ['A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-', 'D', 'F'],
    curgrade: false,
    // NK: I'm making this an observer for now, because this function
    // introduces side effects, which computed properties should not do.

    /* eslint-disable-next-line ember/no-observers */
    assignmentTimerObserver: Ember.observer('activeAssignment.{timed_assignment_data,timed_assignment,timed_assignment.startTime,timed_assignment.endTime}', 'currentTimePulse', function () {
      var _this = this;

      /* eslint-disable-next-line ember/no-get */
      if (!this.get('activeAssignment.timed_assignment')) {
        // Force timed assignment reload
        this.activeAssignment.reload().then(function () {
          /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */
          Ember.run.scheduleOnce('afterRender', _this, function () {
            this.bootContent();
          });
        });
      }
      /* eslint-disable-next-line ember/no-get */


      var endTime = this.get('activeAssignment.timed_assignment.endTime');

      if (!endTime) {
        this.set('lockdown', false);
        this.set('videoRec', false);
        this.set('assignmentTimer', false);
        return;
      }

      var ms = new Date(endTime).getTime() - new Date().getTime();
      /* eslint-disable-next-line ember/no-get */

      var noTimer = this.get('activeAssignment.timed_assignment.no_timer');

      if (!this.get('lockdown') && ms < 0 && !noTimer) {
        this.set('lockdown', true);
      }

      if (!noTimer) {
        // Sync every 60 sec, to let the interface know the user is still active
        if (!this.syncInterval || this.syncInterval > 60) {
          this.set('syncInterval', 1);
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$.ajax({
            type: 'POST',

            /* eslint-disable-next-line ember/no-get */
            url: "/interface/sections/".concat(this.get('session.section.id'), "/assignments/").concat(this.get('activeAssignment.id'), "/timer/sync_timer/"),
            dataType: 'text',
            success: function success() {
              Ember.debug('Timer Synced');
            },
            error: function error(_error) {
              Ember.debug('Unable to sync timer. Something went wrong: ');
              Ember.debug(_error);
            }
          });
        } else {
          this.set('syncInterval', this.syncInterval + 1);
        }

        if (!this.timerOn) {
          this.set('timerOn', true);
          this.currentTimeMetronome();
        }
      }

      if (!noTimer) {
        var seconds = Math.abs(ms / 1000);
        var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour

        seconds = seconds % 3600;
        var minutes = parseInt(seconds / 60);
        hours = hours > 0 ? hours + ':' : '';

        if (minutes < 10 && hours > 1) {
          minutes = '0' + minutes;
        }

        minutes = minutes + ':';
        seconds = Math.floor(seconds % 3600 % 60);

        if (seconds < 10) {
          seconds = '0' + seconds;
        }

        this.set('assignmentTimer', hours + minutes + seconds);
        return;
      }
    }),
    currentTimeMetronome: function currentTimeMetronome() {
      var interval = 1000;

      if (this.timerOn) {
        Ember.run.later(this, function () {
          this.notifyPropertyChange('currentTimePulse');
          this.currentTimeMetronome();
        }, interval);
      }
    },
    requirementsMet: Ember.computed('activeAssignment', 'files.@each.isDone', 'postable', 'processInProgress', 'filesUploading', function () {
      var postable = this.postable;
      var filesUploading = this.filesUploading;
      return postable && !filesUploading;
    }),

    /* eslint-disable-next-line ember/no-observers */
    recalcGrade: Ember.observer('openAssignmentView', 'openAssignmentView.score', function () {
      /* eslint-disable-next-line ember/no-get */
      var score = parseFloat(this.get('openAssignmentView.score'));

      if (event && event.target && event.target.getAttribute && event.target.getAttribute('type') === 'text') {
        var scoreGrade = 'F';

        if (score >= 93) {
          scoreGrade = 'A';
        } else if (score >= 90) {
          scoreGrade = 'A-';
        } else if (score >= 87) {
          scoreGrade = 'B+';
        } else if (score >= 83) {
          scoreGrade = 'B';
        } else if (score >= 80) {
          scoreGrade = 'B-';
        } else if (score >= 77) {
          scoreGrade = 'C+';
        } else if (score >= 73) {
          scoreGrade = 'C';
        } else if (score >= 70) {
          scoreGrade = 'C-';
        } else if (score >= 60) {
          scoreGrade = 'D';
        }

        this.set('openAssignment.score', score);
        this.set('currentGrade', score);
        this.set('openAssignmentView.grade', scoreGrade);
      }
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    canResubmit: Ember.computed('openAssignmentView', function () {
      /* eslint-disable-next-line ember/no-get */
      if (!this.classroom || !this.get('classroom.model.section.teachers').findBy('profile')) {
        return true;
      }
      /* eslint-disable-next-line ember/no-get */


      if (!this.get('classroom.model.section.teachers').findBy('profile').get('profile.can_resubmit')) {
        return true;
      }
      /* eslint-disable-next-line ember/no-get */


      return this.get('classroom.model.section.teachers').findBy('profile').get('profile.can_resubmit')[this.get('session.section.id')] !== 'no';
    }),
    // bind attachment model data to each element of an array
    bindAttachmentObjects: function bindAttachmentObjects(dataArray) {
      dataArray = dataArray ? dataArray.toArray() : [];

      for (var i = 0; i < dataArray.length; ++i) {
        var item = dataArray[i]; // Use Ember.set and Ember.get because it's not always an EmberObject

        Ember.set(item, 'attachment_objects', []);

        if (item.attachments) {
          for (var a = 0; a < item.attachments.length; ++a) {
            var file = item.attachments[a];

            if (_typeof(file) === 'object' && file.url) {
              Ember.set(file, 'name', file.name || file.display_name || file.filename);
              Ember.set(file, 'type', file.type || item['content-type']);
              Ember.get(item, 'attachment_objects').push(file);
            } else {
              var id = file.id || file;
              var model = this.store.findRecord('attachment', id);
              Ember.get(item, 'attachment_objects').push(model);
            }
          }
        }
      }
    },
    submissions: Ember.computed('openAssignmentView.submission_history', function () {
      var submissions = this.get('openAssignmentView.submission_history');

      if (submissions) {
        this.bindAttachmentObjects(submissions);
        return submissions;
      } else {
        return false;
      }
    }),
    // All submissions prior to the newest submission
    submissionHistory: Ember.computed('submissions.[]', function () {
      var hist = this.submissions ? this.submissions.toArray().slice(0, -1) : false;
      return hist;
    }),
    // All submissions after the first submission
    resubmissions: Ember.computed('submissions.[]', function () {
      var submissions = this.submissions; // remove the first submission; we only want REsubmissions:

      var resubmissions = submissions.slice(1); // Add an index to each item, to be used to scroll to
      // different assignment submission versions

      resubmissions.forEach(function (submission, i) {
        return submission.counter = i;
      });
      return resubmissions;
    }),
    comments: Ember.computed('openAssignmentView.comments.@each.{attachments}', function () {
      /* eslint-disable-next-line ember/no-get */
      var comments = this.get('openAssignmentView.comments') || []; // TODO (NK): unroll this so that getting attachments isn't done
      // inside the getter. Maybe in the route?

      this.bindAttachmentObjects(comments);
      return comments;
    }),
    // A sorted array of comments and resubmissions.
    commentTimeline: Ember.computed('comments.@each.{is_deleted}', 'resubmissions.[]', function () {
      var comments = this.comments.toArray().filter(function (c) {
        return !c.is_deleted;
      });
      var history = this.resubmissions.toArray(); // Combine the two arrays

      var stream = comments.concat(history); // comments and submissions have different names for their
      // "created" dates. This gives us a unified sort date.

      stream.forEach(function (item) {
        // Uses Ember.get and Ember.set instead of item.get because not
        // all items are EmberObjects
        var date = Ember.get(item, 'created_at') || Ember.get(item, 'submitted_at');
        item.sortDate = moment(date);
      });
      stream.sort(function (a, b) {
        // Uses Ember.get instead of item.get because not all items
        // are EmberObjects
        var aDate = Ember.get(a, 'sortDate');
        var bDate = Ember.get(b, 'sortDate');
        return aDate - bDate;
      });
      return stream;
    }),
    next_submission: Ember.computed('openAssignmentView', function () {
      if (!this.openAssignmentView || !this.openAssignmentView.get('data.assignment.submissions.content.content')) {
        return;
      }

      var current = this.openAssignmentView.get('data.assignment.submissions.content.content'),
          next_sub = current[current.indexOf(this.openAssignmentView) + 1] || false,
          next = next_sub.id || false;
      return next;
    }),
    prev_submission: Ember.computed('openAssignmentView', function () {
      if (!this.openAssignmentView || !this.openAssignmentView.get('data.assignment.submissions.content.content')) {
        return;
      }

      var current = this.openAssignmentView.get('data.assignment.submissions.content.content'),
          prev_sub = current[current.indexOf(this.openAssignmentView) - 1] || false,
          prev = prev_sub.id || false;
      return prev;
    }),
    openAssignmentModal: Ember.computed('activeAssignment', function () {
      return !!this.activeAssignment;
    }),
    // Router Business

    /* eslint-disable-next-line ember/no-observers */
    toggleAssignmentModal: Ember.observer('openAssignmentView', 'activeAssignment', function () {
      var assignment = this.openAssignmentView,
          activeAssignment = this.activeAssignment,
          openAssignment = this.openAssignment;

      if (assignment && openAssignment) {
        if (assignment.get('assignment').get('id') !== openAssignment.get('id')) {
          openAssignment = false;
        }
      }

      if (!!activeAssignment || !!assignment) {
        // "assignment" here is actually the submission we're viewing...
        if (assignment) {
          if (!openAssignment) {
            this.set('author', assignment.get('data.user.short_name'));
            this.set('openAssignment', assignment.get('assignment'));
            this.store.nestResources('comment', [{
              course: assignment.get('data.course.id')
            }, {
              assignment: assignment.get('data.id')
            }, {
              submission: assignment.get('data.id')
            }]);
          }
        }
        /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */


        Ember.run.scheduleOnce('afterRender', this, function () {
          if (window.CameraTag) {
            window.CameraTag.setup();
          }
        });
      } else if (!activeAssignment && !assignment) {
        this.send('destroyEditor');
      }

      this.send('clearAllFiles');
    }),
    // "user" and "section" are referenced in ShowcaseMixin.
    user: Ember.computed.reads('session.user'),
    section: Ember.computed.reads('session.section'),
    postSubmissionGrade: function postSubmissionGrade(grade) {
      var _this2 = this;

      var sub = this.openAssignmentView,
          that = this;
      this.store.nestResources('submission', [{
        section: that.get('session.section.id')
      }, {
        assignment: sub.get('assignment.id')
      }]);
      var old_grade = sub.get('grade');

      if (grade === 'n') {
        grade = null;
        sub.set('posted_grade', '');
        sub.set('workflow_state', 'submitted');
      } else if (grade === 'r') {
        grade = null;
        sub.set('posted_grade', null);
        sub.set('teacher_requires_resubmission', 'needs_resubmit'); // Purge timer for assignment

        Ember.$.ajax({
          type: 'POST',
          url: "/interface/sections/".concat(that.get('session.section.id'), "/assignments/").concat(sub.get('assignment.id'), "/user/").concat(sub.get('user.id'), "/purge_assignment/"),
          data: {},
          dataType: 'text',
          success: function success() {
            Ember.debug('Assignments purged successfully');
          },
          error: function error(_error2) {
            Ember.debug('Unable to purge assignments. Something went wrong: ');
            Ember.debug(_error2);
          }
        });
      } else if (grade === 'x') {
        sub.set('posted_grade', 0);
        sub.set('workflow_state', 'graded');
        sub.set('teacher_requires_resubmission', 'submitted');
      } else {
        sub.set('posted_grade', grade);
        sub.set('workflow_state', 'graded');
        sub.set('teacher_requires_resubmission', 'submitted');
      }

      sub.save().then(function (sub_with_score) {
        // Success
        if (grade !== old_grade) {
          // See if we need to update the gradebook
          var gbcol = _this2.store.peekRecord('gradebook', sub.get('user.id')),
              assn_id = parseInt(sub.get('assignment.id'), 10);

          if (gbcol) {
            var ndx = gbcol.get('columns').findIndex(function (m) {
              return m && parseInt(m.assignment_id, 10) === assn_id;
            });

            if (ndx >= 0) {
              gbcol.set('columns.' + ndx + '.grade', grade);
              gbcol.set('columns.' + ndx + '.score', sub_with_score.get('score'));
            }
          }
        }
      }, function (error) {
        // Failure
        that.send('switchSubmissionGrade', grade);
        Ember.debug('Failure');
        Ember.debug(error);
        (0, _dialog.default)('Error grading assignment.');
      });
      sub.set('grade', grade);
      sub.set('archive_body', false);
    },
    actions: {
      assignmentSubmission: function assignmentSubmission(assignment_id) {
        this.transitionToRoute('classroom.lessons.submission-new', assignment_id);
      },
      downloadAllSubmissions: function downloadAllSubmissions() {
        /* eslint-disable-next-line ember/no-get */
        var url = '/courses/' + this.get('session.course.id') + '/assignments/' + this.get('openAssignmentView.assignment.id').split('-')[0] + '/submissions?zip=1';
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.bulk_dl_message_status').text('Creating zip file');

        var requestedCompile = false,
            checkZip = function checkZip() {
          var req_url = url;

          if (!requestedCompile) {
            req_url += '&compile=1';
            requestedCompile = true;
          }
          /* eslint-disable-next-line ember/no-jquery */


          Ember.$.ajax({
            url: req_url,
            dataType: 'json',
            success: function success(data) {
              if (data && data.attachment) {
                if (data.attachment.workflow_state === 'zipped') {
                  /* eslint-disable-next-line ember/no-jquery */
                  Ember.$('.bulk_dl_message_status').text('');
                  window.open(url);
                  return;
                }

                if (data.attachment.workflow_state.indexOf('error') >= 0) {
                  /* eslint-disable-next-line ember/no-jquery */
                  Ember.$('.bulk_dl_message_status').text('Failed');
                  /* eslint-disable-next-line ember/no-jquery */

                  setTimeout(function () {
                    return Ember.$('.bulk_dl_message_status').text('');
                  }, 2000);
                  return;
                }
              }

              setTimeout(checkZip, 250);
            },
            error: function error() {
              setTimeout(checkZip, 250);
            }
          });
        };

        checkZip();
      },
      markSubmissionRead: function markSubmissionRead() {
        var submission = this.openAssignmentView;
        if (!submission) return; // Set this locally, but use the route to save (to avoid
        // saving becoming complicated and having to guess our
        // desired operation)

        if (!submission.get('read')) {
          submission.set('read', true);
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$.get("/interface/sections/".concat(this.session.get('section.id'), "/assignments/").concat(submission.get('assignment.id'), "/submissions/").concat(submission.get('id'), "/read"));
        }
      },
      teardown: function teardown() {
        this.send('markSubmissionRead');
      },
      viewAssignmentSubmission: function viewAssignmentSubmission(submission_id) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.drawer-instructions').attr('checked', false);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#current-student-handle').attr('checked', false);
        this.set('showcaseOpen', false);
        this.set('assetsRecovered', true); // Mark the submission comments as read for the submission that we're _leaving_

        this.send('markSubmissionRead');
        this.transitionToRoute('classroom.lessons.submission', submission_id);
      },
      deleteSubmissionBody: function deleteSubmissionBody() {
        var _this3 = this;

        (0, _dialog.default)('Are you sure you want to delete the submission body? This will also mark the submission as needing resubmission.', ['Yes', 'No']).then(function (choice) {
          if (choice === 'Yes') {
            _this3.openAssignmentView.set('archive_body', true);

            _this3.openAssignmentView.set('body', 'SUBMISSION BODY DELETED');

            _this3.postSubmissionGrade('r');
          }
        });
      },
      viewInLesson: function viewInLesson(assignment_id) {
        var that = this;
        /* eslint-disable-next-line ember/no-get */

        this.store.findRecord('item', this.get('activeAssignment.id')).then(function (item) {
          var lessonId = item.get('lesson').id;

          if (lessonId) {
            that.transitionToRoute('classroom.lessons', lessonId, assignment_id);
          }
        }, function () {
          Ember.debug('Can\'t find lesson that contains assignment.');
        });
      },
      startTimer: function startTimer() {
        var _this4 = this;

        this.set('timerStarting', true);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$.ajax({
          type: 'POST',

          /* eslint-disable-next-line ember/no-get */
          url: "/interface/sections/".concat(this.get('session.section.id'), "/assignments/").concat(this.get('activeAssignment.id'), "/timer/start_timer/"),
          data: {},
          dataType: 'text',
          success: function success() {
            _this4.set('timerStarting', false);

            _this4.set('timerOn', true);

            _this4.set('lockdown', false);

            _this4.set('videoRec', false);

            _this4.activeAssignment.reload().then(function () {
              /* eslint-disable-next-line ember/no-get */
              var endTime = _this4.get('activeAssignment.timed_assignment.endTime'); // Timer duraction calculated incorrectly - attempt to restart


              if (!endTime) {
                _this4.send('startTimer');

                return;
              } // Timer duration started at lesss than 0 seconds - attempt to restart


              var ms = new Date(endTime).getTime() - new Date().getTime();

              if (ms < 0) {
                _this4.send('startTimer');

                return;
              }

              _this4.currentTimeMetronome();
              /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */


              Ember.run.later(_this4, function () {
                this.bootContent();
              }, 2000);
            });

            Ember.debug('Timed assignment started sucessfully');
          },
          error: function error(_error3) {
            Ember.debug('Unable to start timed assignment. Something went wrong: ');
            Ember.debug(_error3);
          }
        });
      },
      goToAssignment: function goToAssignment(assignment_id) {
        var _this5 = this;

        var commentBody = this.bodyInput;
        var confirm;

        if (commentBody.length > 0) {
          confirm = (0, _dialog.default)('Are you sure you want to switch submissions? You will lose all unsaved data.', ['Yes', 'No']);
        } else {
          confirm = Promise.resolve('Yes');
        }

        confirm.then(function (choice) {
          if (choice === 'Yes') {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.drawer-select').attr('checked', false);
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.submission-container').scrollTop(0);

            _this5.lessons.set('activeAssignment', false);

            _this5.lessons.set('openAssignment', false);

            _this5.lessons.set('openAssignmentView', false);

            _this5.send('treatAssignment', assignment_id);
          }
        });
      },
      gradeAssignmentSubmission: function gradeAssignmentSubmission(submission_url) {
        if (submission_url) {
          window.open(submission_url, '_blank');
        }
      },
      submitAssignment: function submitAssignment(section_id, assignment_id) {
        var _this6 = this;

        var content = this.bodyInput,
            sub,
            that = this;
        /* eslint-disable-next-line ember/no-jquery */

        if (Ember.$.trim(content.replace(/&nbsp;/g, ' ')).length === 0) {
          // Text is all whitespace. Don't post.
          return;
        }

        this.store.nestResources('submission', [{
          section: section_id
        }, {
          assignment: assignment_id
        }]);
        this.set('uploadInProgress', true);
        /* eslint-disable-next-line ember/no-get */

        sub = this.get('activeAssignment.data.submission');

        if (!sub) {
          sub = this.activeAssignment.store.createRecord('submission');
        }

        if (!this.working && this.file_ids.length > 0) {
          sub.set('file_ids', this.file_ids);
          sub.set('submission_type', 'online_upload');
        } else {
          sub.set('submission_type', 'online_text_entry');
        } // Add any video embeds


        content = this.parseEmbed(content);
        content = content + this.videoEmbedString;
        sub.set('teacher_requires_resubmission', 'submitted');
        sub.set('body', content); // The submission ID is derived from the assignment/user, so if
        // this is a resubmission then saving a new submission will give
        // us two models with identical IDs. To account for that, unload
        // all submissions for the current object before saving

        /* eslint-disable-next-line ember/no-get */

        var user_id = this.get('session.user.id');
        /* eslint-disable-next-line ember/no-get */

        var submissions = this.get('model.submissions');
        var my_submissions = submissions.filter(function (s) {
          return s.get('user.id') === user_id;
        });
        my_submissions.forEach(function (s) {
          return s.unloadRecord();
        });
        sub.save().then(function (sub) {
          that.set('timerOn', false);
          that.activeAssignment.reload();
          that.set('uploadInProgress', false);
          that.set('activeAssignment.did_submit', true);
          that.set('activeAssignment.needsStudentAttention', false);
          that.send('clearAllFiles');
          that.set('inEditor', false);
          that.set('bodyInput', '');
          that.send('destroyEditor');
          that.send('viewAssignmentSubmission', sub.id);
        }, function (err) {
          (0, _dialog.default)('There was a problem submitting your assignment. Please retry in a moment.');
          Ember.debug(content);
          Ember.debug(_this6.bodyInput);
          Ember.debug(err);
          that.set('uploadInProgress', false);
        });
      },
      switchSubmissionGrade: function switchSubmissionGrade(grade) {
        var gradeScoreRubric = {
          'A': 100,
          'A-': 92,
          'B+': 89,
          'B': 86,
          'B-': 82,
          'C+': 79,
          'C': 76,
          'C-': 72,
          'D': 69,
          'F': 0,
          'R': 0
        };
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.quickgrader input').removeClass('checked');

        if (grade) {
          // TODO: rewrite this feature so that we don't imperatively change the
          // selection class here. This should be doable without the runloop.
          Ember.run.schedule('afterRender', function () {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.quickgrader input[value="' + grade + '"]').addClass('checked');
          });
          this.set('currentGrade', grade);
          this.set('openAssignmentView.score', gradeScoreRubric[grade]);
        } else {
          this.set('currentGrade', false);
        }
      },
      postSubmissionGrade: function postSubmissionGrade(grade) {
        this.postSubmissionGrade(grade);
      },
      postSubmissionComment: function postSubmissionComment(assignment, submission_id) {
        var content = this.bodyInput,
            sub = this.openAssignmentView,
            comment = sub.store.createRecord('comment'),

        /* eslint-disable-next-line ember/no-get */
        usr = this.get('session.user'),
            that = this,
            currentGrade;

        if (!content || content === '') {
          return;
        } // Add any video embeds


        content = this.parseEmbed(content);
        content = content + this.videoEmbedString;
        this.store.nestResources('submission', [{
          section: that.get('session.section.id')
        }, {
          assignment: sub.get('assignment.id')
        }]);
        this.store.nestResources('comment', [{
          section: that.get('session.section.id')
        }, {
          assignment: sub.get('assignment.id')
        }, {
          submission: submission_id
        }]);

        if (!this.working && this.file_ids.length > 0) {
          comment.set('file_ids', this.file_ids);
          comment.set('attachments', this.file_ids);
        }

        comment.set('comment', content);
        comment.set('user', usr);
        sub.get('comments').pushObject(comment);
        that.send('clearAllFiles');
        that.set('inEditor', false);
        that.set('bodyInput', '');
        that.send('destroyEditor');
        comment.save().then(function (savedComment) {
          comment.set('db_id', savedComment.get('db_id'));
          that.set('openAssignmentView', sub); // If there's a grade in the pipeline, post it

          var grade_to_assign = that.get('currentGrade');

          if (grade_to_assign) {
            that.set('currentGrade', false);
          } else if (!sub.get('grade_matches_current_submission')) {
            // If an instructor is leaving a comment on a resub and not changing
            // the grade, preserve it for the new submission
            grade_to_assign = sub.get('grade');
          }

          if (grade_to_assign) {
            // If the grade is a number, add a percentage sign to it, so it submits correctly...
            if (!isNaN(parseInt(grade_to_assign)) && toString(grade_to_assign).split('%').length === 1) {
              grade_to_assign = grade_to_assign + '%';
            }

            that.send('postSubmissionGrade', grade_to_assign);
          }
        }, function () {
          var conv = sub.get('comments').objectAt(sub.get('comments.length') - 1);
          currentGrade = that.get('currentGrade');
          conv.set('needs_repost', true);
          conv.set('attached_grade', currentGrade);
          that.set('currentGrade', false);
        });
      },
      scrollToAssignment: function scrollToAssignment(version) {
        /* eslint-disable-next-line ember/no-jquery */
        var verNode = Ember.$('.submission-container li[version=' + version + ']');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.submission-container').scrollTop(0);

        if (verNode.length > 0) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.submission-container input[version=' + version + ']').attr('checked', true);
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.submission-container').scrollTop(verNode.offset().top);
        }
      },
      repostSubmissionComment: function repostSubmissionComment(comment) {
        var that = this;
        comment.set('needs_repost', false);
        comment.set('is_reposting', true);
        comment.save().then(function () {
          comment.set('needs_repost', false);
          comment.set('is_reposting', false);
          var gr = comment.get('attached_grade');

          if (gr) {
            that.send('postSubmissionGrade', gr);
          }
        }, function () {
          comment.set('needs_repost', true);
          comment.set('is_reposting', false);
        });
      },
      setAssignmentSubmissionsHiddenStatus: function setAssignmentSubmissionsHiddenStatus(assignment, status) {
        var _this7 = this;

        (0, _dialog.default)('Are you sure you want to make all student submissions for this assignment viewable to all students in the class?', ['Yes', 'No']).then(function (choice) {
          if (choice === 'Yes') {
            var submissions = assignment.get('submissions');
            submissions.forEach(function (submission) {
              _this7.store.nestResources('submission', [{
                section: assignment.get('section.id')
              }, {
                assignment: assignment.get('id')
              }]);

              submission.set('hidden_from_students', status);
              submission.save();
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});