define("bocce/mixins/interactions/codec_comparison", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This updates the legacy Quicktime Codec Comparison
   * interaction so it can work with the Audio elements
   **/
  function CodecComparison($el) {
    this.$el = $el;
    this.$stage = this.$el.find('.interaction_content');
  }

  CodecComparison.prototype = {
    init: function init() {
      // add cms editor stuff aka .interaction_data contents
      this.addComponentContent(this.$el.find('.interaction_data'));
      this.addButtons();
      this.prepAudio();
    },
    addComponentContent: function addComponentContent(source) {
      var content;
      content = source.html();
      this.$stage.append(content);
    },
    addButtons: function addButtons() {
      var _this = this,
          audioCount,
          i;

      _this.$audioContents = _this.$stage.find('audio');
      _this.$img = _this.$stage.find('img');
      audioCount = _this.$audioContents.length;

      for (i = 0; i < audioCount; i++) {
        this.createButton('Encoding ' + (i + 1), i).click(function (tgt) {
          var currentSource = parseInt(tgt.target.id, 10);

          _this.$img.each(function () {
            this.classList.add('forcedHidden');
          });

          _this.$img[currentSource].classList.remove('forcedHidden');

          _this.toggleTracks(currentSource);
        });
      }

      this.createButton('Pause', '').click(function () {
        _this.pauseTracks();
      });

      if (this.$img.length > 0) {
        this.createButton('Show', '').click(function () {
          _this.$img.show();
        });
        this.createButton('Hide', '').click(function () {
          _this.$img.hide();
        });
      }
    },
    prepAudio: function prepAudio() {
      this.$audioContents.hide();
      this.$img.hide();
      this.$img.each(function () {
        this.classList.add('forcedHidden');
      });
      this.$audioContents.volume = 0;
    },
    createButton: function createButton(title, id) {
      /* eslint-disable-next-line ember/no-jquery */
      return Ember.$('<div>').addClass('button').attr('id', id).text(title).appendTo(this.$stage);
    },
    toggleTracks: function toggleTracks(audio) {
      this.$audioContents.each(function () {
        if (this.played.length === 0) {
          this.preload = true;
          this.load();
        }

        this.volume = 0;
      });
      this.$audioContents.each(function () {
        this.play();
      });
      this.$audioContents[audio].volume = 1;
    },
    pauseTracks: function pauseTracks() {
      this.$audioContents.each(function () {
        this.pause();
      });
    }
  };
  var _default = CodecComparison;
  _exports.default = _default;
});