define("bocce/mixins/kaltura-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create({
    kalturaUploadVideo: function kalturaUploadVideo(vidBlob, failureCallback, isFile, blobURL) {
      var _this = this;

      this.createToken().then(function (token) {
        if (!_this.session) {
          _this.session = window.session;
        }

        if (token) {
          var fd = new FormData(); //Create backup link for plURL

          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.video-backup-rec').attr('href', _this.blobURL);
          fd.append('ks', _this.kSession);
          fd.append('resume', 'false');
          fd.append('finalChunk', 'true');
          fd.append('resumeAt', '-1');
          fd.append('fileData', vidBlob);
          fd.append('uploadTokenId', token.xml.result.id);
          /* eslint-disable-next-line ember/no-get */

          fd.append('fname', _this.get('session.user.name').toLowerCase().replace(/ /g, '_') + '_section-' + _this.get('session.course.id') + '_video_' + Math.round(new Date().getTime() / 1000));
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$.ajax({
            xhr: function xhr() {
              var xhr = new window.XMLHttpRequest();
              xhr.upload.addEventListener('progress', function (evt) {
                if (evt.lengthComputable) {
                  var percentComplete = evt.loaded / evt.total;

                  if (typeof isFile === 'number') {
                    _this.set('encoding_videos.' + isFile + '.percent_uploaded', percentComplete);
                  } else {
                    _this.set('uploadPercentage', percentComplete + '%');
                  }
                }
              }, false);
              return xhr;
            },
            type: 'POST',
            url: token.xml.result.uploadUrl + '/api_v3/service/uploadtoken/action/upload',
            data: fd,
            processData: false,
            contentType: false
          }).done(function (data) {
            var dataJSON = _this.xmlToJson(data);

            if (dataJSON.xml.result && dataJSON.xml.result.objectType === 'KalturaUploadToken') {
              _this.createMediaEntry(token).then(function (videoInfo) {
                _this.addToCategory('145227041', videoInfo.xml.result.rootEntryId);

                _this.attachRtc({
                  name: videoInfo.xml.result.name,
                  id: videoInfo.xml.result.rootEntryId
                }, isFile, blobURL);
              });
            } else {
              Ember.debug('Unable to create media entry for new video. Error Code:' + dataJSON.xml.result.error.code + ' Error Message: ' + dataJSON.xml.result.error.message);
            }
          });
        } else {
          Ember.debug('No session token provided. Aborting.');
          failureCallback('Unable to get session token. Please contact support.');
        }
      });
    },
    addToCategory: function addToCategory(cat_id, entry_id) {
      var _this2 = this;

      /* eslint-disable-next-line ember/no-jquery */
      Ember.$.ajax({
        type: 'POST',
        url: 'https://www.kaltura.com/api_v3/service/categoryentry/action/add',
        data: {
          ks: this.kSession,
          categoryEntry: {
            entryId: entry_id,
            categoryId: cat_id,
            objectType: 'KalturaCategoryEntry'
          }
        },
        success: function success(category) {
          var categoryJSON = _this2.xmlToJson(category);

          if (_typeof(categoryJSON) === 'object' && categoryJSON.xml.result.error) {
            Ember.debug('Unable to add video category. Error Code:' + categoryJSON.xml.result.error.code + ' Error Message: ' + categoryJSON.xml.result.error.message);
          }
        },
        error: function error(_error) {
          Ember.debug('Category error: ' + _error.responseText);
        }
      });
    },
    createMediaEntry: function createMediaEntry(rToken) {
      var _this3 = this;

      /* eslint-disable-next-line ember/no-get */
      var userName = this.get('session.user.name').toLowerCase().replace(/ /g, '_');
      /* eslint-disable-next-line ember/no-get */

      var courseId = this.get('session.course.id');
      var data = {
        ks: this.kSession,
        entry: {
          'mediaType': 1,
          'name': userName + '_section-' + courseId + '_video_' + Math.round(new Date().getTime() / 1000),
          'description': 'User uploaded video from course ID: ' + courseId + ', uploaded by user: ' + userName
        }
      };
      return new Promise(function (resolve) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$.ajax({
          url: 'https://www.kaltura.com/api_v3/service/media/action/add',
          data: data,
          success: function success(mediaEntry) {
            var mediaJSON = _this3.xmlToJson(mediaEntry);

            if (_typeof(mediaJSON) === 'object' && mediaJSON.xml.result.error) {
              Ember.debug('Unable to create media entry for new video. Error Code:' + mediaJSON.xml.result.error.code + ' Error Message: ' + mediaJSON.xml.result.error.message);
              resolve(false);
            } else if (_typeof(mediaJSON) === 'object' && mediaJSON.xml.result.objectType === 'KalturaMediaEntry') {
              var rootEntryId = mediaJSON.xml.result.rootEntryId;
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$.ajax({
                url: 'https://www.kaltura.com/api_v3/service/media/action/addContent',
                data: {
                  ks: _this3.kSession,
                  entryId: rootEntryId,
                  resource: {
                    objectType: 'KalturaUploadedFileTokenResource',
                    token: rToken.xml.result.id
                  }
                },
                success: function success(token) {
                  var tokenJSON = _this3.xmlToJson(token);

                  if (_typeof(tokenJSON) === 'object' && tokenJSON.xml.result.error) {
                    Ember.debug('Unable to add video to media entry. Error Code:' + tokenJSON.xml.result.error.code + ' Error Message: ' + tokenJSON.xml.result.error.message);
                    resolve(false);
                  } else if (_typeof(tokenJSON) === 'object' && tokenJSON.xml.result.objectType === 'KalturaMediaEntry') {
                    resolve(tokenJSON);
                  } else {
                    resolve(false);
                    Ember.debug('Kaltura returned malformed token');
                  }
                },
                error: function error(_error2) {
                  Ember.debug('Kaltura media error: ' + _error2.responseText);
                  resolve(false);
                }
              });
            } else {
              Ember.debug('Unable to create media entry for uploaded video file');
            }
          },
          error: function error(_error3) {
            Ember.debug('Kaltura session start error: ' + _error3.responseText);
            resolve(false);
          }
        });
      });
    },
    createToken: function createToken() {
      var _this4 = this;

      return new Promise(function (resolve) {
        if (!_this4.store) {
          _this4.store = window.store;
        }

        _this4.store.findRecord('session', 1).then(function (session) {
          var kaltura_session = session.get('kaltura');

          _this4.set('kSession', kaltura_session);
          /* eslint-disable-next-line ember/no-jquery */


          Ember.$.ajax({
            url: 'https://www.kaltura.com/api_v3/service/uploadtoken/action/add',
            data: {
              ks: kaltura_session
            },
            success: function success(token) {
              //Convert XML token
              var tokenJSON = _this4.xmlToJson(token);

              if (_typeof(tokenJSON) === 'object' && tokenJSON.xml.result.error) {
                Ember.debug('Unable to create upload token. Error Code:' + tokenJSON.xml.result.error.code + ' Error Message: ' + tokenJSON.xml.result.error.message);
                resolve(false);
              } else if (_typeof(tokenJSON) === 'object' && tokenJSON.xml.result.objectType === 'KalturaUploadToken') {
                resolve(tokenJSON);
              } else {
                Ember.debug('Kaltura returned malformed token');
              }
            },
            error: function error(_error4) {
              Ember.debug('Kaltura token request error: ' + _error4.responseText);
              resolve(false);
            }
          });
        });
      });
    },
    parseFile: function parseFile(file, callback) {
      var fileSize = file.size,
          chunkSize = 64 / Math.pow(1024, 2),
          // 64mb chunk size
      offset = 0,
          chunkReaderBlock = null;

      var readEventHandler = function readEventHandler(evt) {
        if (evt.target.error === null) {
          offset += evt.target.result.length;
          callback(evt.target.result); // callback for handling read chunk
        } else {
          Ember.debug('Read error: ' + evt.target.error);
          return;
        }

        if (offset >= fileSize) {
          Ember.debug('Done reading file');
          return;
        } // of to the next chunk


        chunkReaderBlock(offset, chunkSize, file);
      };

      chunkReaderBlock = function chunkReaderBlock(_offset, length, _file) {
        var r = new FileReader();

        var blob = _file.slice(_offset, length + _offset);

        r.onload = readEventHandler;
        r.readAsText(blob);
      }; // now let's start the read with the first block


      chunkReaderBlock(offset, chunkSize, file);
    },
    // Kaltura returns XML - Convert to obj for ease of use
    xmlToJson: function xmlToJson(xml) {
      // Create the return object
      var obj = {};

      if (xml.nodeType == 1) {
        // element
        // do attributes
        if (xml.attributes.length > 0) {
          obj['@attributes'] = {};

          for (var j = 0; j < xml.attributes.length; j++) {
            var attribute = xml.attributes.item(j);
            obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
          }
        }
      } else if (xml.nodeType == 3) {
        // text
        obj = xml.nodeValue;
      } // do children


      if (xml.hasChildNodes() && xml.childNodes[0].nodeName !== '#text') {
        for (var i = 0; i < xml.childNodes.length; i++) {
          var item = xml.childNodes.item(i);
          var nodeName = item.nodeName;

          if (typeof obj[nodeName] == 'undefined') {
            obj[nodeName] = this.xmlToJson(item);
          } else {
            if (typeof obj[nodeName].push == 'undefined') {
              var old = obj[nodeName];
              obj[nodeName] = [];
              obj[nodeName].push(old);
            }

            obj[nodeName].push(this.xmlToJson(item));
          }
        }
      } else if (xml.hasChildNodes() && xml.childNodes[0].nodeName === '#text') {
        obj = xml.childNodes[0].nodeValue;
      }

      return obj;
    }
  });

  _exports.default = _default;
});