define("bocce/mixins/interactions/text_line_and_text_select_hybrid", ["exports", "bocce/mixins/support/util", "bocce/mixins/interactions/abstract_quiz"], function (_exports, util, _abstract_quiz) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function TextLineAndTextSelectHybrid($el, data) {
    // apply abstract quiz properties to this quiz
    // abstract quiz has a lot of basic quiz functionality
    util.mixin(this, new _abstract_quiz.default($el, data, 'text_line_and_text_select_hybrid')); // add methods from overrides to overwrite abstract methods

    util.mixin(this, this.overrides); // bind 'this' to all methods

    util.bindAll(this); // listeners for method extension

    /* eslint-disable-next-line ember/no-jquery */

    Ember.$(this.el).bind(this.SHOW_QUESTION, this.onShowQuestion);
    /*
    // not using these at the moment
    $(this.el).bind(this.CORRECT, this.onAnswerCorrect);
    $(this.el).bind(this.INCORRECT, this.onAnswerIncorrect);
    $(this.el).bind(this.QUIZ_COMPLETE, this.onQuizComplete);
    */
  }

  TextLineAndTextSelectHybrid.prototype = {
    init: function init() {
      // overwrite json data with table data

      /* eslint-disable-next-line ember/no-jquery */
      var dataTable = Ember.$(this.interactionData).find('table')[0];
      this.json = [];
      var that = this;
      var questions = []; // keep track of errors to provide the user with a single error dump
      // rather than item by item

      var errors = '';
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(dataTable).find('tr').each(function (i) {
        /* eslint-disable-next-line ember/no-jquery */
        var row = Ember.$(this);
        var question, correctIndex;
        var answerSets = []; // if either of the cells in the first row have the correct answer class
        // applied to them, they will override the default feedback strings
        // sorry, this is pretty hack, result of a last minute feature request

        if (i === 0) {
          /* eslint-disable-next-line ember/no-jquery */
          var cells = Ember.$(row).find('td'); // vars to override, in order, corresponding to cell indexes

          var feedbackVars = ['syllableType', 'dropdownType'];
          cells.each(function (el, index) {
            /* eslint-disable-next-line ember/no-jquery */
            var correct = Ember.$(el).find('.correct');

            if (correct.length) {
              /* eslint-disable-next-line ember/no-jquery */
              that[feedbackVars[index]] = Ember.$(correct[0]).text().trim();
            }
          });
          return true;
        }

        row.find('td').each(function (j) {
          // pull out list items for answers

          /* eslint-disable-next-line ember/no-jquery */
          var td = Ember.$(this); // only grab from columns 0 and 1 to prevent mistakes

          if (j === 0) {
            question = td.find('p').html();
          } else if (j === 1) {
            // answers is a multidimensional array i.e. array of arrays
            td.find('ul').each(function () {
              var answerOptions = [];
              /* eslint-disable-next-line ember/no-jquery */

              Ember.$(this).find('li').each(function (k) {
                /* eslint-disable-next-line ember/no-jquery */
                var answerEl = Ember.$(this); // trim off newlines, whitespace, empty nodes, etc

                /* eslint-disable-next-line ember/no-jquery */

                answerOptions.push(Ember.$.trim(answerEl.text())); // capture the index of the correct answer (has the .correct class)

                if (answerEl.find('.correct').length) {
                  correctIndex = k;
                }
              });
              var answerSet = new TextLineAndTextSelectHybrid.AnswerSet(answerOptions, correctIndex); // check to make sure the user selected a correct answer
              // to avoid future confusion

              if (correctIndex === undefined) {
                errors += 'Error: Please specify a correct answer for question ' + i + '. Select the text of the correct answer in the editor and choose "Correct Answer" from the style dropdown menu. If you already entered one, the CMS may have discarded it.\n';
                errors += '---------------\n';
                return;
              }

              answerSets.push(answerSet);
            }); // end 2nd column 'ul' each

            questions.push(new TextLineAndTextSelectHybrid.Question(question, answerSets));
          } // end 2nd column if

        }); // end td each
      }); // end tr each
      // show errors, if there were any

      if (errors.length > 0) {
        alert(errors);
      }

      var questionSet = new TextLineAndTextSelectHybrid.QuestionSet(questions);
      this.json.push(questionSet); // hacked in after quiz was built

      /* eslint-disable-next-line ember/no-jquery */

      var finalTextTable = Ember.$(this.interactionData).find('table')[1];
      /* eslint-disable-next-line ember/no-jquery */

      var finalTextTD = Ember.$(finalTextTable).find('td')[0];
      /* eslint-disable-next-line ember/no-jquery */

      this.finalText = Ember.$(finalTextTD).html();
      return this.showQuestion();
    },
    onShowQuestion: function onShowQuestion() {
      this.getResetBtn().hide();
      /**
       * This is all additional functionality tacked onto a copy of the
       * original interaction. It wasn't in the original spec so it's
       * kind of hacked into this one-off
       **/
      // get syllables and set up handlers

      var corrects = this.el.find('.text-line span.correct');
      var that = this;
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(corrects).each(function () {
        /* eslint-disable-next-line ember/no-jquery */
        var $c = Ember.$(this); // check for broken(correct spans containing '(B)') and set up handlers

        if ($c.text() === '(B)') {
          that.numBrokenFeet++;
          $c.click(that.brokenFootClicked); // remove text so user can't see the answer. replace with a space to give it a clickable area

          $c.text('-');
          $c.addClass('invisible');
        } // otherwise it's a syllable
        else {
            that.numSyllables++;
            $c.click(that.syllableClicked);
          }
      }); // more hackery: convert menus with one <option> to static text. this is starting to
      // look like a spaghetti dinner! mama mia!

      this.el.find('select').each(function () {
        /* eslint-disable-next-line ember/no-jquery */
        var $select = Ember.$(this);

        if ($select.find('option').length === 1) {
          var optionText = $select.find('option').text(); // make the replacement el the same width as the
          // select menu to preserve expected spacing

          /* eslint-disable-next-line ember/no-jquery */

          var $newEl = Ember.$('<div/>');
          $newEl.text(optionText).css('display', 'inline-block').width($select.width());
          $select.replaceWith($newEl);
        }
      }); // user clicks this to validate their input

      this.el.find('.checkAnswers').click(this.checkAnswers);
    },
    // hacked in stuff
    numSyllables: 0,
    numBrokenFeet: 0,
    syllableType: 'stressed syllables',
    dropdownType: 'line types',
    finalText: '',
    // TODO: could refactor the following methods. OK for now
    syllableClicked: function syllableClicked(e) {
      /* eslint-disable-next-line ember/no-jquery */
      var $t = Ember.$(e.target);
      $t.addClass('syllable');
      $t.parent().removeClass('unanswered');
      $t.unbind('click');
      this.numSyllables--;
    },
    brokenFootClicked: function brokenFootClicked(e) {
      /* eslint-disable-next-line ember/no-jquery */
      var $t = Ember.$(e.target);
      $t.addClass('broken-foot');
      $t.unbind('click');
      this.numBrokenFeet--; // reveal the broken foot

      $t.text(' (B) ');
      $t.removeClass('invisible');
    },
    checkAnswers: function checkAnswers(e) {
      e.preventDefault();
      var that = this;
      var numAnswers = 0;
      var numCorrect = 0;
      this.el.find('.question').each(function (i) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(this).find('div.answers').children().each(function (j) {
          /* eslint-disable-next-line ember/no-jquery */
          var select = Ember.$(this); // if the current el isn't a select menu, skip it

          if (!select.is('select')) {
            return true;
          }

          select.removeClass('correct incorrect');
          numAnswers++; // TODO: I'd like to use this, but its not working for some reason
          //var selectedIndex = select.attr('selectedIndex');

          var selectedIndex = select.find('option:selected').index();
          var question = that.json[0].questions[i];
          var answer = question.answerSets[j];
          var correctIndex = answer.correctIndex;

          if (selectedIndex === correctIndex) {
            select.addClass('correct');
            numCorrect++;
          } else {
            select.addClass('incorrect');
          }
        });
      });

      if (numAnswers === numCorrect && this.numSyllables === 0 && this.numBrokenFeet === 0) {
        this.showFeedback('Nice job! Quiz Complete.');
        var resetBtn = this.getResetBtn();
        resetBtn.show();
        resetBtn.click(this.reset);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$(this.el).find('.final-text').html(this.finalText);
      } else {
        var message = '';

        if (numAnswers !== numCorrect) {
          message += 'Some ' + this.dropdownType + ' are <span class="incorrect">incorrect.</span> ';
        }

        if (this.numSyllables > 0) {
          message += '<br/>Click on all ' + this.syllableType + ': ' + this.numSyllables + ' remain. ';
        }

        if (this.numBrokenFeet > 0) {
          message += '<br/>Click on all Broken Feet: ' + this.numBrokenFeet + ' remain. ';
        }

        this.showFeedback('Keep trying. ' + message, true);
      }
    },
    getFeedbackContainer: function getFeedbackContainer() {
      /* eslint-disable-next-line ember/no-jquery */
      return Ember.$(this.el.find('.feedback'));
    },
    getResetBtn: function getResetBtn() {
      /* eslint-disable-next-line ember/no-jquery */
      return Ember.$(this.el.find('.reset'));
    },
    showFeedback: function showFeedback(message, shouldFade) {
      var feedbackContainer = this.getFeedbackContainer();
      /* eslint-disable-next-line ember/no-jquery */

      var feedback = Ember.$(feedbackContainer).find('.message');
      feedback.html(message);
      feedbackContainer.show();
      feedbackContainer.stop(true).css('opacity', '1');

      if (shouldFade) {
        feedbackContainer.fadeOut(7500);
      }
    },
    reset: function reset(e) {
      e.preventDefault(); // trick AbstractQuiz to make reset easier

      this.currentIndex = -1;
      this.nextQuestion();
    },
    overrides: {// override AbstractQuiz here
    }
  };

  TextLineAndTextSelectHybrid.QuestionSet = function (questions) {
    this.questions = questions;
  };

  TextLineAndTextSelectHybrid.Question = function (question, answerSets) {
    this.question = question;
    this.answerSets = answerSets;
  };

  TextLineAndTextSelectHybrid.AnswerSet = function (answers, correctIndex) {
    this.answers = answers;
    this.correctIndex = correctIndex;
  };

  var _default = TextLineAndTextSelectHybrid;
  _exports.default = _default;
});