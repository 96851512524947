define("bocce/models/assignment", ["exports", "@ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/models/assignment.js

  /* eslint-disable-next-line ember/use-ember-data-rfc-395-imports */
  var _default = _model.default.extend({
    db_id: (0, _model.attr)('number'),
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    course: (0, _model.belongsTo)('course', {
      async: true
    }),
    section: (0, _model.belongsTo)('section', {
      async: true
    }),
    created_at: (0, _model.attr)('date'),
    updated_at: (0, _model.attr)('date'),
    due_at: (0, _model.attr)('date'),
    lock_at: (0, _model.attr)('date'),
    unlock_at: (0, _model.attr)('date'),
    grading_type: (0, _model.attr)('string'),
    is_assignment: (0, _model.attr)('boolean'),
    is_hybrid: (0, _model.attr)('boolean'),
    activity_type: (0, _model.attr)('string'),
    submission_requirements: (0, _model.attr)(),
    unread_count: (0, _model.attr)(),
    timed_assignment_data: (0, _model.attr)('string'),
    submissions: (0, _model.hasMany)('submission', {
      async: true
    }),
    needsCurrentUserSubmission: (0, _model.attr)('boolean'),
    dueWithinOneWeek: (0, _model.attr)('boolean'),
    dueWithinTwoWeeks: (0, _model.attr)('boolean'),
    currentUserSubmission: (0, _model.attr)(),
    teacher_requires_resubmission: (0, _model.attr)(),
    currentUserSubmissionGrade: (0, _model.attr)('string'),
    currentUserSubmissionScore: (0, _model.attr)('string'),
    is_updated: (0, _model.attr)('boolean'),
    grade_matches_current_submission: (0, _model.attr)('boolean'),
    is_timed: (0, _model.attr)('boolean'),
    points_possible: (0, _model.attr)('number'),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    timed_assignment: Ember.computed('timed_assignment_data', 'is_timed', function () {
      var tsd = this.timed_assignment_data || '{}';
      var assignmentId_numbersOnly = this.id.split('-')[0];
      var currentTD = JSON.parse(tsd)[assignmentId_numbersOnly];
      return currentTD;
    }),
    numUngradedSubmissions: Ember.computed('submissions.@each.{grade,workflow_state,teacher_requires_resubmission,read}', function () {
      var numSubmitted = this.submissions.filterBy('workflow_state', 'submitted').get('length') || 0,
          // JRW: Since we can't officially change the workflow_state to an already graded
      // assignment submission but still nullify an incorrectly assigned grade on a submission
      // Look for submissions that are graded but with no grade and include them in this count.
      numGradedWithoutGrade = this.submissions.filterBy('workflow_state', 'graded').filterBy('grade', null).get('length') || 0,
          numRegrade = this.submissions.filterBy('workflow_state', 'submitted').filterBy('teacher_requires_resubmission', 'needs_resubmit').get('length') || 0; // Remove any "not_graded" type assignments from counter -- this is an assignment type that doesn't require a grade and should not be part of the counter unless they're unnread
      // Same with any submissions without a due date

      if (this.grading_type === "not_graded" || !this.due_at) {
        var numUnread = this.submissions.filterBy('read', false).get('length');
        return numUnread;
      }

      return numSubmitted + numGradedWithoutGrade - numRegrade;
    }),
    numResubmissionsPending: Ember.computed('submissions.@each.{grade,workflow_state,teacher_requires_resubmission}', 'teacher_requires_resubmission', function () {
      var numPending = this.submissions.filterBy('teacher_requires_resubmission', 'needs_resubmit').get('length');
      return numPending;
    }),
    numUnreadSubmissions: Ember.computed('submissions.@each.read', function () {
      return this.submissions.filterBy('read', false).get('length');
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    formattedDate: Ember.computed('date', function () {
      var d = this.due_at;

      if (!d) {
        return '';
      }

      return moment(d).tz('America/New_York').calendar();
    }),
    // Creating this computed property so that assignments and discussions both
    // have the same formatted due date property
    formattedDueDate: Ember.computed.alias('formattedDate'),
    dueToday: Ember.computed('due_at', function () {
      var d = this.due_at;

      if (d && moment(d).isSame(Date(), 'day')) {
        return true;
      }

      return false;
    }),
    hasUnreadSubmissions: Ember.computed.gt('numUnreadSubmissions', 0),
    needsStudentAttention: Ember.computed('needsCurrentUserSubmission', 'teacher_requires_resubmission', 'hasUnreadSubmissions', function () {
      return this.needsCurrentUserSubmission || this.teacher_requires_resubmission === 'needs_resubmit';
      /*||
      this.get('hasUnreadSubmissions'); */
      // ^^ JRW: commenting out student interaction for read/unread status
    }),
    needsTeacherAttention: Ember.computed('submissions.@each.needsTeacherAttention', function () {
      return this.submissions.filterBy('needsTeacherAttention').get('length') > 0;
    }),
    hasHiddenSubmissions: Ember.computed('submissions.@each.hidden_from_students', function () {
      var hiddenSubmissions = this.submissions.filter(function (submission) {
        return submission.get('hidden_from_students');
      });
      return hiddenSubmissions.length > 0;
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    did_submit: Ember.computed('submissions.[]', function () {
      var current_user_id = this.store.peekRecord('session', 1).get('user.id');
      this.store.nestResources('submission', [
      /* eslint-disable-next-line ember/no-get */
      {
        section: this.get('section.id')
      }, {
        assignment: this.id
      }]);
      return _emberData.default.PromiseObject.create({
        promise: this.submissions.then(function (submissions) {
          return submissions && submissions.toArray().some(function (s) {
            return s.get('user.id') === current_user_id;
          });
        })
      });
    }),
    sortDate: Ember.computed.alias('due_at'),
    todo: Ember.computed.not('currentUserSubmission')
  });

  _exports.default = _default;
});