define("bocce/mixins/rtc-rec", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create({
    videoStatus: 'uninitiated',
    presetResolution: false,
    captureCamera: function captureCamera(resolution, callback) {
      var vidSettings = {};
      var audioSettings = {};

      if (resolution === 'hd') {
        vidSettings = {
          width: {
            min: 1280
          },
          height: {
            min: 720
          }
        };
      }

      vidSettings.deviceId = this.cameraDeviceId;
      audioSettings.deviceId = this.audioInputId;
      navigator.mediaDevices.getUserMedia({
        audio: audioSettings,
        video: vidSettings
      }).then(function (camera) {
        callback(camera);
      }).catch(function (error) {
        Ember.debug(error);
      });
    },
    stopRecordingCallback: function stopRecordingCallback(context, blob) {
      /* eslint-disable-next-line ember/no-jquery */
      var video = Ember.$('.recorder-monitor')[0],
          recorder = window.recorder; // Forcibly assign webm mimetype to blob

      blob = blob.slice(0, blob.size, 'video/webm');
      this.set('blobURL', URL.createObjectURL(blob));
      window.MediaSource = window.MediaSource || window.WebKitMediaSource;
      var mediaSource = new MediaSource();
      mediaSource.addEventListener('sourceopen', function (event) {
        /* eslint-disable-next-line ember/no-jquery */
        var fileType = Ember.$.isSafari ? 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"' : 'video/webm; codecs="vorbis,vp8"';
        event.target.addSourceBuffer(fileType);
      }, false); // Set up video preview

      video.removeAttribute('autoplay');
      video.src = video.srcObject = null;
      video.muted = false;
      video.volume = 1;
      video.controls = true;
      video.src = this.blobURL; // Set up video backup

      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('#video-backup-link').attr('href', this.blobURL);
      this.set('vidBlob', blob);
      recorder.camera.stop();
      recorder.clearRecordedData();
      recorder = null;
      window.recorder = null;
    },
    actions: {
      toggleRTCPanel: function toggleRTCPanel(exit) {
        var _this = this;

        /* eslint-disable-next-line ember/no-jquery */
        var cameraContainer = Ember.$('.floating-modal.active .camera-container');
        this.set('videoStatus', 'uninitiated');
        this.set('presetResolution', false);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.recorder-monitor').addClass('hidden').attr('src', '');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.video-backup-rec').attr('href', '');

        if (exit) {
          cameraContainer.removeClass('active');
          return false;
        }
        /* eslint-disable-next-line ember/no-jquery */


        var videoSelect = Ember.$('.video-input-selector')[0];
        /* eslint-disable-next-line ember/no-jquery */

        var audioInputSelect = Ember.$('.audio-input-selector')[0]; // Clear out camera

        var recorder = window.recorder;

        if (recorder) {
          recorder.camera.stop();
          recorder.clearRecordedData();
          recorder = null;
          window.recorder = null;
        }

        if (videoSelect && audioInputSelect) {
          navigator.mediaDevices.enumerateDevices().then(function (deviceInfos) {
            for (var i = 0; i !== deviceInfos.length; ++i) {
              var deviceInfo = deviceInfos[i];
              var option = document.createElement('option');
              option.value = deviceInfo.deviceId;

              if (deviceInfo.kind === 'audioinput') {
                option.text = deviceInfo.label || 'Microphone ' + (audioInputSelect.length + 1);
                audioInputSelect.appendChild(option);

                if (!_this.audioInputId) {
                  _this.set('audioInputId', deviceInfo.deviceId);
                }
              } else if (deviceInfo.kind === 'videoinput') {
                option.text = deviceInfo.label || 'Camera ' + (videoSelect.length + 1);

                if (!_this.cameraDeviceId) {
                  _this.set('cameraDeviceId', deviceInfo.deviceId);
                }

                videoSelect.appendChild(option);
              }
            }
          });
        }
        /* eslint-disable-next-line ember/no-jquery */


        if (Ember.$('.floating-modal.active .audio-rec-panel').length > 0) {
          this.send('toggleAudioRecPanel', true);
        }

        cameraContainer.addClass('active');
      },
      changeAudioInput: function changeAudioInput(id) {
        this.set('audioInputId', id);
      },
      changeVideoInput: function changeVideoInput(id) {
        this.set('cameraDeviceId', id);
      },
      enableCamera: function enableCamera(resolution) {
        var _this2 = this;

        var presetResolution = this.presetResolution;

        if (presetResolution) {
          resolution = presetResolution;
        } else {
          this.set('presetResolution', resolution);
        }

        this.captureCamera(resolution, function (camera) {
          /* eslint-disable-next-line ember/no-jquery */
          var fileType = Ember.$.isSafari ? 'video/mp4' : 'video/webm';
          /* eslint-disable-next-line ember/no-jquery */

          var video = Ember.$('.recorder-monitor')[0];
          video.setAttribute('autoplay', '');
          video.classList.remove('hidden');
          video.muted = true;
          video.volume = 0;
          video.removeAttribute('controls');
          video.srcObject = camera;
          window.recorder = new MediaStreamRecorder(camera, {
            mimeType: fileType
          });

          _this2.set('videoStatus', 'ready');

          _this2.set('recordingCurrentTime', '00:00'); // release camera on stopRecording


          window.recorder.camera = camera;
        });
      },
      startVideoRec: function startVideoRec() {
        var _this3 = this;

        var recorder = window.recorder;

        if (recorder) {
          this.set('videoStatus', 'recording');
          recorder.record();
        } else {
          Ember.debug('No recorder enabled. Refresh browser and try again');
        }

        this.set('recordingStartTime', moment().unix());
        window.kalturaRec = setInterval(function () {
          var timeCalc = moment().unix() - _this3.recordingStartTime;

          _this3.set('recordingCurrentTime', moment(timeCalc * 1000).format('mm:ss'));
        }, 1000);
      },
      stopVideoRec: function stopVideoRec() {
        var _this4 = this;

        var recorder = window.recorder;

        if (recorder) {
          this.set('videoStatus', 'recorded');
          clearInterval(window.kalturaRec);
          this.set('recordingStartTime', false);
          this.set('recordingCurrentTime', '00:00');
          recorder.stop(function (blob) {
            _this4.stopRecordingCallback(_this4, blob);
          });
        } else {
          Ember.debug('No recorder enabled. Refresh browser and try again');
        }
      },
      attachVideoRec: function attachVideoRec() {
        var _this5 = this;

        this.set('videoStatus', 'uploading');
        this.set('uploadPercentage', '0%');
        this.kalturaUploadVideo(this.vidBlob, function (message) {
          window.alert(message);

          _this5.set('videoStatus', 'recorded');
        }, false, this.blobURL);
      },
      displayVideoBackup: function displayVideoBackup(close) {
        this.set('showVideoBackup', !close);
      }
    }
  });

  _exports.default = _default;
});