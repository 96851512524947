define("bocce/components/classroom/current-lesson-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['current-course-topic'],
    text: Ember.computed('lesson', 'title', function () {
      var lesson = this.lesson;

      if (Number(lesson) === 0) {
        lesson = 'Getting Started';
      }

      var title = this.title;

      if (!title) {
        return '';
      }

      return "".concat(lesson, " - ").concat(title);
    })
  });

  _exports.default = _default;
});