define("bocce/models/gradebook", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/models/gradebook.js
  var _default = _model.default.extend({
    sort: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    short_name: (0, _model.attr)('string'),
    sortable_name: (0, _model.attr)('string'),
    sis_user_id: (0, _model.attr)('string'),
    sis_import_id: (0, _model.attr)('string'),
    integration_id: (0, _model.attr)('string'),
    login_id: (0, _model.attr)('string'),
    avatar_url: (0, _model.attr)('string'),
    columns: (0, _model.attr)()
  });

  _exports.default = _default;
});