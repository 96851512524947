define("bocce/mixins/interactions/view-or-take-a-survey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ViewOrTakeASurvey($el, data) {
    this.$el = $el;
    this.content = this.$el.find('.interaction_content');
    this.data = data;
    this.url = this.data.config.survey_url;
  }

  ViewOrTakeASurvey.prototype = {
    init: function init() {
      this.height = this.content.css('min-height');
      this.width = this.content.css('width');
      this.content.css('width', '').css('min-height', '');

      if (!this.url) {
        this.content.html('Survey has no URL.  Likely an unmigrated Moodle-based survey.');
        return;
      }

      this.content.html('<iframe src="' + this.url + '" height="' + this.height + '" width="' + this.width + '"></iframe>');
    }
  };
  var _default = ViewOrTakeASurvey;
  _exports.default = _default;
});