define("bocce/controllers/userprofile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    userprofile: Ember.inject.controller(),
    classroom: Ember.inject.controller(),
    activeUser: false,
    assignmentsLocked: true,
    unlockWeek: 2,
    model: Ember.computed.alias('activeUser'),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    self: Ember.computed('model.content', function () {
      /* eslint-disable-next-line ember/no-get */
      if (this.get('model.content.id') === this.get('session.user.id')) {
        return true;
      }

      return false;
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    degree: Ember.computed('model.content', function () {
      /* eslint-disable-next-line ember/no-get */
      var allEnrolled = this.get('userprofile.activeUser.enrolled'),
          enrolledUser;

      if (allEnrolled) {
        /* eslint-disable-next-line ember/no-get */
        enrolledUser = allEnrolled.findBy('section.id', this.get('classroom.model.section.id'));

        if (enrolledUser) {
          return enrolledUser.get('degree');
        }
      }

      return '';
    }),

    /* eslint-disable-next-line ember/no-observers */
    earlyUnlockCheck: Ember.observer('model.content', function () {
      var _this = this;

      /* eslint-disable-next-line ember/no-get */
      if (!this.get('session.isStaff')) {
        return;
      }
      /* eslint-disable-next-line ember/no-get */


      var userId = this.get('model.content.id');
      var data = {
        user_id: userId
      };
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$.ajax({
        type: 'GET',
        url: '/interface/early_unlock_check/',
        data: data,
        success: function success(latestUnlockWeek) {
          _this.set('isEarlyUnlocked', latestUnlockWeek);
        }
      });
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    accommodation: Ember.computed('model.content', function () {
      /* eslint-disable-next-line ember/no-get */
      var allEnrolled = this.get('userprofile.activeUser.enrolled'),
          enrolledUser;

      if (allEnrolled) {
        /* eslint-disable-next-line ember/no-get */
        enrolledUser = allEnrolled.findBy('section.id', this.get('classroom.model.section.id'));

        if (enrolledUser && enrolledUser.get('accommodation')) {
          return enrolledUser.get('accommodation');
        }
      }

      return false;
    }),
    actions: {
      unlockAssignments: function unlockAssignments(userId) {
        var _this2 = this;

        this.set('assignmentsLocked', false);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$.ajax({
          type: 'POST',
          url: "/interface/sections/".concat(this.session.get('section.id'), "/assignments/0/user/").concat(userId, "/purge_assignment/"),
          data: {},
          dataType: 'text',
          success: function success() {
            _this2.set('unlockingAssignments', false);

            Ember.debug('Assignments purged successfully');
          },
          error: function error(_error) {
            _this2.set('assignmentsLocked', true);

            Ember.debug('Unable to purge assignments. Something went wrong: ');
            Ember.debug(_error);
          }
        });
      },
      stripAssignmentTimers: function stripAssignmentTimers(userId) {
        var _this3 = this;

        this.set('timersStripped', false);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$.ajax({
          type: 'POST',
          url: "/interface/sections/".concat(this.session.get('section.id'), "/assignments/0/user/").concat(userId, "/strip_timer/"),
          data: {},
          dataType: 'text',
          success: function success() {
            _this3.set('strippingTimers', false);

            Ember.debug('Assignment timers stripped successfully');
          },
          error: function error(_error2) {
            _this3.set('timersStripped', true);

            Ember.debug('Unable to strip assignment timers. Something went wrong: ');
            Ember.debug(_error2);
          }
        });
      },
      refreshZoomId: function refreshZoomId(userId) {
        var _this4 = this;

        /* eslint-disable-next-line ember/no-jquery */
        this.set('refreshingZoom', true);
        Ember.$.ajax({
          type: 'POST',
          url: "/interface/users/".concat(userId, "/refreshZoomId/"),
          data: {},
          dataType: 'text',
          success: function success() {
            _this4.set('refreshingZoom', false);

            _this4.set('zoomRefreshed', true);

            Ember.debug('Zoom Credentials refreshed successfully!');
          },
          error: function error(_error3) {
            _this4.set('refreshingZoom', false);

            Ember.debug('Unable to refresh user Zoom Credentials');
          }
        });
      },
      // Early lesson unlocking
      // Only usable by admins (requires auth)
      changeUnlockWeek: function changeUnlockWeek(weekNumber) {
        this.set('unlockWeek', weekNumber);
      },
      // Revoke selected user's early access
      earlyLock: function earlyLock(userId) {
        var _this5 = this;

        /* eslint-disable-next-line ember/no-get */
        var courseId = this.get('session.course.id');
        var data = {
          user_id: userId,
          course_id: courseId
        };
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$.ajax({
          type: 'GET',
          url: '/interface/early_lock/',
          data: data,
          success: function success() {
            console.log('User\'s early access has been revoked.');

            _this5.set('isEarlyUnlocked', false);
          }
        });
      },
      // Grant selected user early access up until week # "unlockWeek"
      earlyUnlock: function earlyUnlock(userId) {
        var _this6 = this;

        // This functionality is only available for admins
        // Interface will reject teacher (and student) attempts
        var unlockWeek = this.unlockWeek;
        /* eslint-disable-next-line ember/no-get */

        var termId = this.get('session.course.term.id');
        /* eslint-disable-next-line ember/no-get */

        var courseId = this.get('session.course.id');
        this.set('earlyUnlocking', true);
        var data = {
          user_id: userId,
          term_id: termId,
          unlock_week: unlockWeek,
          course_id: courseId
        };
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$.ajax({
          type: 'GET',
          url: '/interface/early_unlock/',
          data: data,
          success: function success(latestUnlockWeek) {
            console.log("User successully enrolled in early unlock group for week ".concat(latestUnlockWeek, "."));

            _this6.set('isEarlyUnlocked', latestUnlockWeek);
          }
        });
      },
      closeProfile: function closeProfile() {
        this.set('activeUser', false);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.user-profile').removeClass('active');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.user-profile-mask').removeClass('active');
      },
      pm: function pm() {
        /* eslint-disable-next-line ember/no-get */
        var id = this.get('model.id');
        this.send('closeProfile');
        this.transitionToRoute('classroom.lessons.conversation-new-with', id);
      },
      activityReport: function activityReport() {
        /* eslint-disable-next-line ember/no-get */
        var courseId = this.get('session.course.id'),

        /* eslint-disable-next-line ember/no-get */
        userId = this.get('model.content.id');
        window.open('/courses/' + courseId + '/users/' + userId + '/usage', '_blank');
      },
      gradeReport: function gradeReport() {
        /* eslint-disable-next-line ember/no-get */
        var courseId = this.get('session.course.id'),

        /* eslint-disable-next-line ember/no-get */
        userId = this.get('model.content.id');
        window.open('/courses/' + courseId + '/grades/' + userId + '#tab-assignments', '_blank');
      },
      reportAtRisk: function reportAtRisk(user) {
        var name = user.get('name'),
            email = user.get('email');

        if (name && email) {
          var atRiskWindow = window.open('', '_blank'),
              path = 'https://online.berklee.edu/at-risk-student-notification-form?student_name=';
          path += name + '&student_email=' + email;
          atRiskWindow.location = path;
        }
      },
      masquerade: function masquerade() {
        /* eslint-disable-next-line ember/no-get */
        var id = this.get('model.id');
        var path = "/users/".concat(id, "/masquerade");
        window.open(path, '_blank');
      }
    }
  });

  _exports.default = _default;
});