define("bocce/routes/classroom/lessons/admin", ["exports", "bocce/routes/classroom/lessons/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modalBase.default.extend({
    /*
    NK: Currently the admin modal doesn't use any of that sweet
    Ember goodness to get the data it's working with. It may be a
    good idea to fetch it here (and in other route hooks) but for
    now everything seems to work well using the controller and
    jQuery.
    */
  });

  _exports.default = _default;
});