define("bocce/templates/components/user-dot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xypp/iHH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"green-dot \",[30,[36,1],[[35,0,[\"isPresent\"]],\"offline\"],null]]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"user\",\"unless\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/user-dot.hbs"
    }
  });

  _exports.default = _default;
});