define("bocce/routes/classroom/lessons/event", ["exports", "bocce/routes/classroom/lessons/modal-base", "bocce/mixins/webex"], function (_exports, _modalBase, _webex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */
  var _default = _modalBase.default.extend(_webex.default, {
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      /* eslint-disable-next-line ember/no-jquery */


      Ember.$('.side-panel').addClass('on-modal');
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.panel-container.chat-panel').addClass('active');
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var meetingKeys = model.get('meetingKeys');

      if (meetingKeys) {
        controller.send('recordParticipation', meetingKeys);
      }
    }
  });

  _exports.default = _default;
});