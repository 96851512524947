define("bocce/components/discussion/reply-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      reply: function reply(id, txt, user) {
        this.reply({
          id: id,
          user: 'Reply to:',
          text: user
        });
      }
    }
  });

  _exports.default = _default;
});