define("bocce/components/discussion/multisection-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SectionOption = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('selected', false);
    },
    selected: false
  });

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.sections = this.sections || Ember.ArrayProxy.create({
        content: Ember.A([])
      });
      this.selected = this.selected || Ember.ArrayProxy.create({
        content: Ember.A([])
      });
      this.options = Ember.ArrayProxy.create({
        content: Ember.A([])
      });
      this.initSelections();
    },
    initSelections: function initSelections() {
      var _this = this;

      this.options.clear();
      this.sections.forEach(function (section) {
        return _this.options.pushObject(SectionOption.create({
          section: section
        }));
      });
    },

    /* eslint-disable-next-line ember/no-get */
    multipleSectionsSelected: Ember.computed.gt('selected.length', 0),
    classNames: ['modal-popover-shade'],
    attributeBindings: ['role', 'ariaDescribedBy:aria-describedby'],
    role: 'dialog',
    ariaDescribedBy: 'popover-title',
    actions: {
      toggleSection: function toggleSection(option) {
        option.toggleProperty('selected');

        if (option.selected) {
          this.selected.pushObject(option.section.sectionId);
        } else {
          this.selected.removeObject(option.section.sectionId);
        }
      },
      selectAll: function selectAll() {
        var _this2 = this;

        var selected = this.selected;
        selected.clear();
        this.options.forEach(function (option) {
          option.set('selected', true);

          _this2.selected.pushObject(option.section.sectionId);
        });
      },
      selectNone: function selectNone() {
        this.selected.clear();
        this.options.forEach(function (option) {
          return option.set('selected', false);
        });
      },
      cancel: function cancel() {
        this.send('selectNone');
        this.send('close');
      },
      close: function close() {
        var closeAction = this.closeAction;

        if (closeAction) {
          closeAction();
        }
      }
    }
  });

  _exports.default = _default;
});