define("bocce/controllers/banner-drawer", ["exports", "bocce/mixins/enmodal", "bocce/mixins/notify", "bocce/utilities/dialog"], function (_exports, _enmodal, _notify, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */
  var _default = Ember.Controller.extend(_enmodal.default, _notify.default, {
    banners: Ember.computed.reads('session.banners'),
    actions: {
      toggleDrawer: function toggleDrawer() {
        if (!this.drawerActive) {
          this.set('drawerActive', true);
          this.set('session.notification', false);
        } else {
          this.set('drawerActive', false);
        }
      },
      //Dismiss banner by index
      dismissNotif: function dismissNotif(id) {
        // ID is not the banner id, but the index of the banner in the array
        // the banner id is the 'serial' property
        var singleBanner = this.session.banners[id],
            dismissable = singleBanner.dismissable,
            banner_id = singleBanner.id,
            course_id = this.get('session.course.id'),
            term_id = this.get('session.termID');

        if (dismissable) {
          /* eslint-disable-next-line ember/no-get */
          var banners = this.get('session.banners'); // Mark announcement as read

          if (banners[id].modal_type === 'discussion') {
            this.store.findRecord('discussion', banners[id].modal_id).then(function (model) {
              model.set('read', true);

              if (model.get('hasUnreadResponses')) {
                model.set('markReadThrough', model.get('lastResponseId'));
              }

              model.save();
            });
          } // Dismiss the banner for the particular user on the back end
          // show error if failure.


          var dismissRequest = $.get("/interface/banners/".concat(banner_id, "/dismiss/").concat(term_id, "/").concat(course_id)); // Remove from banner drawer

          banners.splice(id, 1);
          this.set('session.banners', banners);
          this.notifyPropertyChange('banners');
        }
      },
      viewNotif: function viewNotif(index) {
        var banner = this.session.banners[index];

        if (banner.reload) {
          location.reload(true);
          return;
        } // Remove from banner drawer


        var banners = this.session.banners;
        banners.splice(index, 1);
        this.set('session.banners', banners);
        this.notifyPropertyChange('banners'); // Close drawer

        this.set('drawerActive', false);

        if (banner.link) {
          window.open(banner.link, '_blank');
        } else if (banner.modal_id) {
          this.send('viewModal', banner.modal_type, banner.modal_id);
        } else {
          (0, _dialog.default)(banner.message);
        }
      }
    }
  });

  _exports.default = _default;
});