define("bocce/templates/interactions/timeline/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f/bwXW/3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[10,\"div\"],[14,0,\"categories\"],[12],[13],[10,\"div\"],[14,0,\"clear\"],[12],[13],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/interactions/timeline/main.hbs"
    }
  });

  _exports.default = _default;
});