define("bocce/controllers/classroom/lessons/conversation-new", ["exports", "bocce/mixins/user-profile", "bocce/mixins/discussable", "bocce/mixins/enmodal", "bocce/mixins/conversable", "bocce/mixins/editable", "bocce/mixins/uploadable", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec"], function (_exports, _userProfile, _discussable, _enmodal, _conversable, _editable, _uploadable, _audioRec, _videoRec, _rtcRec) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  window.conversationHeartbeatFrequency = 3 * 1000;

  var _default = Ember.Controller.extend(_conversable.default, _enmodal.default, _discussable.default, _userProfile.default, _editable.default, _uploadable.default, _audioRec.default, _videoRec.default, _rtcRec.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.recipients = this.recipients || [];
    },
    conversation: Ember.inject.controller('classroom.lessons.conversation'),
    conversations: Ember.inject.controller(),
    userprofile: Ember.inject.controller(),
    // required by UserProfileMixin
    discussions: Ember.inject.controller(),
    // required by EditableMixin
    classroom: Ember.inject.controller(),
    // required by DiscussableMixin and EnmodalMixin
    discussion: Ember.inject.controller('classroom.lessons.discussion'),
    // required by DiscussableMixin
    newDiscussion: Ember.inject.controller('classroom.lessons.discussion-new'),
    // required by DiscussableMixin
    lessons: Ember.inject.controller('classroom.lessons'),
    // required by UploadableMixin
    scrollAndUpdateLocalConversation: function scrollAndUpdateLocalConversation(conversation) {
      Ember.run.later(function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.existing-conversation .modal-content').animate({
          scrollTop: Ember.$('.existing-conversation .modal-content').prop('scrollHeight')
        }, 1000);
      }, 500);
      conversation.set('last_authored_message_at', new Date());
      conversation.set('last_message_at', new Date());
      conversation.set('message_count', conversation.get('message_count') + 1);
    },

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    conversationModalContentMarginTop: Ember.computed('model', function () {
      /* eslint-disable-next-line ember/no-get */
      var participantRelationships = this.get('model.participantRelationships');
      return participantRelationships * 26;
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    sharedEnrollments: Ember.computed('model', function () {
      var conversation = this.model,
          retval = [],

      /* eslint-disable-next-line ember/no-get */
      currentUser = this.get('session.user'),
          sectionCodes = currentUser.get('sectionCodes');

      if (conversation) {
        var participantRelationships = conversation.get('participantRelationships') || [];
        participantRelationships.forEach(function (participantRelationship) {
          if (!sectionCodes) {
            return;
          }

          sectionCodes.forEach(function (sectionCode) {
            if (sectionCode.sectionId === participantRelationship.section) {
              retval.push({
                sectionId: sectionCode.sectionId,
                relationship: participantRelationship.relationship,
                courseTitle: sectionCode.courseTitle,
                sectionLabel: sectionCode.sectionLabel
              });
            }
          });
        });
      }

      return retval;
    }),
    privateConversationHeartbeat: function privateConversationHeartbeat() {
      var _this = this;

      var store = this.store,
          heartbeat = function heartbeat() {
        /* eslint-disable-next-line ember/no-get */
        var currentConversationId = _this.get('model.id');

        store.nestResources('conversation-message', [{
          'conversation': currentConversationId
        }]); // Load in conversation messages

        /* eslint-disable-next-line ember/no-jquery */

        Ember.$.get('/interface/conversations/' + currentConversationId).then(function (resolvedData) {
          var data = resolvedData,
              loaded_messages = store.peekAll('conversation_message'),
              ids = loaded_messages.map(function (e) {
            return e.id;
          }),
              to_push = [];

          var _iterator = _createForOfIteratorHelper(data.conversation_message),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var msg = _step.value;

              if (ids.indexOf(msg.id.toString()) === -1) {
                to_push.push(msg);
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          if (to_push.length > 0) {
            store.pushPayload({
              'conversation_message': to_push
            });
            Ember.run.later(function () {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.existing-conversation .modal-content').animate({
                scrollTop: Ember.$('.existing-conversation .modal-content').prop('scrollHeight')
              }, 1000);
            }, 500);
          }
        }).always(function () {
          // When the modal is open, 'currentConversationId' is always set on the
          // conversation controller. When the modal is closed, this value is cleared out.
          // Only heartbeat if there is something stored in this value. - JRW
          if (_this.currentConversationId) {
            Ember.run.later(_this, heartbeat, window.conversationHeartbeatFrequency);
          }
        });
      };

      window.runPMHeartbeat = heartbeat;
      Ember.run.later(this, heartbeat, window.conversationHeartbeatFrequency);
    },
    actions: {
      autoComplete: function autoComplete(param) {
        param = param.trim();

        if (param !== '' && param.length > 2) {
          var handle = this.autoCompleteTimeoutHandle;

          if (handle) {
            Ember.run.cancel(handle);
          }
          /* eslint-disable-next-line ember/no-jquery */


          Ember.$('.recipient-filter-list').removeClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.recipient-filter-list .loading-graphic').removeClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.recipient-filter-list .no-results').addClass('hidden');
          this.set('filteredList', []);
          var that = this; // Wait a moment before we spam the server with searches for "jam", "jame", and "james"
          // all at once

          handle = Ember.run.later(function () {
            param = param.toLowerCase();
            param = encodeURIComponent(param);
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$.ajax({
              type: 'get',
              param: param,
              url: '/interface/user-search/' + param,
              success: function success(results) {
                var _this2 = this;

                var premd = that.store.peekAll('conversation').filter(function (conversation) {
                  var matching_conversations = conversation.get('participants').filter(function (user) {
                    if (typeof _this2.param !== 'string') {
                      return false;
                    }

                    var lc = _this2.param.toLowerCase();

                    return user.get('name').toLowerCase().indexOf(lc) > -1;
                  });
                  return matching_conversations > 0;
                });
                /* eslint-disable-next-line ember/no-jquery */

                Ember.$('.recipient-filter-list .loading-graphic').addClass('hidden');
                var res = results.length < 8 ? results : results.slice(0, 7);
                res = premd.concat(res);
                that.set('filteredList', res);

                if (results.length === 0) {
                  /* eslint-disable-next-line ember/no-jquery */
                  Ember.$('.recipient-filter-list .no-results').removeClass('hidden');
                }
              },
              error: function error(_error) {
                Ember.debug('Error in conversation recipient list.');
                Ember.debug(_error);
              }
            });
          }, 333);
          this.set('autoCompleteTimeoutHandle', handle);
        } else {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.recipient-filter-list').addClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.recipient-filter-list .loading-graphic').addClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.recipient-filter-list .no-results').addClass('hidden');
          this.set('filteredList', []);
        }
      },
      // This choose action represents a user picking a
      // recipient from the autocomplete list above the conversations list
      startConversation: function startConversation() {
        this.set('conversation.uploadInProgress', true);
        var recipArray = [],
            recipURL = '';
        this.recipients.forEach(function (ob) {
          recipArray.push(ob.id);
        });
        recipURL += recipArray.join('-');
        this.set('recipients', []); // Check if a conversation with the user already exists

        var conversations = this.store.peekAll('conversation'),
            existing = conversations.find(function (c) {
          var ob = c.get('conversationPartners');

          if (ob.get('length') > 0) {
            ob = ob.toArray();
          }

          if (!Array.isArray(ob) || !Array.isArray(recipArray) || recipArray.length !== ob.length) {
            return false;
          }

          var arr1 = ob.concat().sort(),
              arr2 = recipArray.concat().sort(),
              i;

          for (i = 0; i < arr1.length; i++) {
            if (parseInt(arr1[i].get('id')) !== parseInt(arr2[i])) {
              return false;
            }
          }

          return true;
        });

        if (existing) {
          if (existing.get('archived')) {
            existing.set('workflow_state', 'active');
            existing.save();
          }

          this.send('viewConversation', existing.id);
        } else {
          this.send('createConversation', recipURL);
        }

        this.set('conversation.uploadInProgress', false);
      },
      choose: function choose(recipient) {
        this.recipients.pushObject(recipient);
      }
    }
  });

  _exports.default = _default;
});