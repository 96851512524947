define("bocce/templates/interactions/imageexplorer/infobottom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xl1jUoj/",
    "block": "{\"symbols\":[],\"statements\":[[19,\"interactions/imageexplorer/stage\",[]],[2,\"\\n\"],[10,\"div\"],[14,0,\"info border scrollbar-mobile\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[2,[34,0]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[19,\"interactions/imageexplorer/nav\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"initialText\"]}",
    "meta": {
      "moduleName": "bocce/templates/interactions/imageexplorer/infobottom.hbs"
    }
  });

  _exports.default = _default;
});