define("bocce/routes/course", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(course) {
      var id = course.get('id');
      var code = course.get('code');
      var section = course.get('sections.firstObject.id');
      var path = "/".concat(id, "/").concat(code, "/").concat(section);
      this.transitionTo(path);
    }
  });

  _exports.default = _default;
});