define("bocce/helpers/startswith", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startswith = startswith;
  _exports.default = void 0;

  // app/helpers/startswith.js
  // Check if the first provided string begins with the second.
  // {{#if (startswith assignments.name 'Assignment 2')}}
  function startswith(inp) {
    var first = inp[0],
        second = inp[1];

    if (!first || !second) {
      return false;
    }

    return first.indexOf(second) === 0;
  }

  var _default = Ember.Helper.helper(startswith);

  _exports.default = _default;
});