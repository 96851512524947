define("bocce/controllers/classroom/lessons/conversation", ["exports", "bocce/mixins/user-profile", "bocce/mixins/discussable", "bocce/mixins/enmodal", "bocce/mixins/conversable", "bocce/mixins/editable", "bocce/mixins/uploadable", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/helpers/heartbeat", "bocce/utilities/dialog"], function (_exports, _userProfile, _discussable, _enmodal, _conversable, _editable, _uploadable, _audioRec, _videoRec, _rtcRec, _heartbeat, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  window.conversationHeartbeatFrequency = 3 * 1000;

  var _default = Ember.Controller.extend(_conversable.default, _enmodal.default, _discussable.default, _userProfile.default, _editable.default, _uploadable.default, _audioRec.default, _videoRec.default, _rtcRec.default, {
    conversationNew: Ember.inject.controller('classroom.lessons.conversation-new'),
    filteredList: Ember.computed.alias('conversationNew.filteredList'),
    userprofile: Ember.inject.controller(),
    // required by UserProfileMixin
    discussions: Ember.inject.controller(),
    submission: Ember.inject.controller('classroom.lessons.submission'),
    // loadHeartbeatData uses this
    classroom: Ember.inject.controller(),
    // required by DiscussableMixin
    discussion: Ember.inject.controller('classroom.lessons.discussion'),
    // required by DiscussableMixin
    newDiscussion: Ember.inject.controller('classroom.lessons.discussion-new'),
    // required by DiscussableMixin
    lessons: Ember.inject.controller('classroom.lessons'),
    // required by UploadableMixin
    currentConversationId: Ember.computed.reads('model.id'),
    scrollAndUpdateLocalConversation: function scrollAndUpdateLocalConversation(conversation) {
      Ember.run.later(function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.existing-conversation .modal-content').animate({
          scrollTop: Ember.$('.existing-conversation .modal-content').prop('scrollHeight')
        }, 1000);
      }, 500);
      conversation.set('last_authored_message_at', new Date());
      conversation.set('last_message_at', new Date());
      conversation.set('message_count', conversation.get('message_count') + 1);
    },
    sortedMessages: Ember.computed.sort('model.messages', function (a, b) {
      var id_a = parseInt(a.get('id'), 10),
          id_b = parseInt(b.get('id'), 10);
      return id_a < id_b ? -1 : 1;
    }),
    conversationModalContentMarginTop: Ember.computed('model.{participants.[],participantRelationships.[]}', function () {
      /* eslint-disable-next-line ember/no-get */
      if (this.get('model.participants.length') > 2) {
        return 0;
      }
      /* eslint-disable-next-line ember/no-get */


      var participantRelationships = this.get('model.participantRelationships.length');
      return participantRelationships * 26;
    }),
    sharedEnrollments: Ember.computed('model.{participants.[],participantRelationships.@each.section}', 'session.user.sectionCodes.[]', function () {
      var conversation = this.model,
          retval = [],

      /* eslint-disable-next-line ember/no-get */
      currentUser = this.get('session.user'),
          sectionCodes = currentUser.get('sectionCodes');

      if (conversation) {
        if (conversation.get('participants.length') > 2) {
          return [];
        }

        var participantRelationships = conversation.get('participantRelationships') || [];
        participantRelationships.forEach(function (participantRelationship) {
          if (!sectionCodes) {
            return;
          }

          sectionCodes.forEach(function (sectionCode) {
            if (sectionCode.sectionId === participantRelationship.section) {
              retval.push({
                sectionId: sectionCode.sectionId,
                relationship: participantRelationship.relationship,
                courseTitle: sectionCode.courseTitle,
                sectionLabel: sectionCode.sectionLabel
              });
            }
          });
        });
      }

      return retval;
    }),
    actions: {
      startPrivateConversationHeartbeat: function startPrivateConversationHeartbeat() {
        var _this = this;

        window.runPMHeartbeat = function () {
          var _messages;

          var convo_id = _this.currentConversationId,
              messages = _this.sortedMessages;

          if (!convo_id || !messages) {
            return;
          }

          var since_id = ((_messages = messages[messages.length - 1]) === null || _messages === void 0 ? void 0 : _messages.id) || 0;
          (0, _heartbeat.default)(["/interface/conversations/".concat(convo_id, "/conversation-messages/since/").concat(since_id)], _this).always(function () {
            // When the modal is open, 'currentConversationId' is always set on the
            // conversation controller. When the modal is closed, this value is cleared out.
            // Only heartbeat if there is something stored in this value. - JRW
            Ember.run.later(_this, window.runPMHeartbeat, window.conversationHeartbeatFrequency);
          });
        };

        Ember.run.later(this, window.runPMHeartbeat, window.conversationHeartbeatFrequency);
      },
      loadMoreMessages: function loadMoreMessages(defer) {
        var _this2 = this;

        // call "convoController.send('loadMoreMessages')" in console to test
        window.convoController = this;
        /* eslint-disable-next-line ember/no-get */

        var loaded = this.get('session.loadedMessages') || [];
        /* eslint-disable-next-line ember/no-get */

        if (loaded.indexOf(this.get('model.id')) >= 0) {
          if (defer) {
            defer.resolve();
          }
          /* eslint-disable-next-line ember/no-jquery */


          Ember.$('.loading-prv').removeClass('active');
          return;
        }
        /* eslint-disable-next-line ember/no-get */


        var messages = this.get('model.messages');

        if (!messages) {
          if (defer) {
            defer.resolve();
          }
          /* eslint-disable-next-line ember/no-jquery */


          Ember.$('.loading-prv').removeClass('active');
          return;
        }

        var num_loaded = messages.get('length'),

        /* eslint-disable-next-line ember/no-get */
        user_id = this.get('session.user.id'),
            args = '';

        if (num_loaded > 0) {
          var min_id;

          for (var i = 0; i < num_loaded; i++) {
            var id = parseInt(messages.objectAt(i).get('id'), 10);

            if (!min_id || id < min_id) {
              min_id = id;
            }
          }

          args = "?page=".concat(min_id);
        }

        setTimeout(function () {
          /* eslint-disable-next-line ember/no-jquery, ember/no-get */
          Ember.$.get("/interface/conversations/".concat(_this2.get('model.id'), "/conversation-messages").concat(args)).then(function (data) {
            if (!data || !data.conversation_message || data.conversation_message.length === 0) {
              /* eslint-disable-next-line ember/no-get */
              loaded.push(_this2.get('model.id'));

              _this2.set('session.loadedMessages', loaded);
            } else {
              var _iterator = _createForOfIteratorHelper(data.conversation_message),
                  _step;

              try {
                for (_iterator.s(); !(_step = _iterator.n()).done;) {
                  var m = _step.value;
                  var auth_id = Number(m.author_id);

                  if (auth_id === Number(user_id)) {
                    m.belongsToSelf = true;
                  }
                }
              } catch (err) {
                _iterator.e(err);
              } finally {
                _iterator.f();
              }

              _this2.store.pushPayload(data);
            }

            if (defer) {
              defer.resolve();
            }
            /* eslint-disable-next-line ember/no-jquery, ember/no-incorrect-calls-with-inline-anonymous-functions */


            Ember.run.scheduleOnce(function () {
              Ember.$('.loading-prv').removeClass('active');
            });
          });
        }, 250);
      },
      scrollToBottom: function scrollToBottom() {
        /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */
        Ember.run.scheduleOnce('afterRender', this, function () {
          var _this3 = this;

          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.existing-conversation .modal-content').animate({
            scrollTop: Ember.$('.existing-conversation .modal-content').prop('scrollHeight')
          }, 500);
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.modal-content').on('scrollstop', function (tgt) {
            if (tgt.target.scrollTop === 0 && _this3.nxtScroll) {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.loading-prv').addClass('active');

              _this3.send('loadMoreMessages');
            } else if (!_this3.nxtScroll) {
              _this3.set('nxtScroll', true);
            }
          });
        });
      },
      autoComplete: function autoComplete(param) {
        this.conversationNew.send('autoComplete', param);
      },
      choose: function choose(recipient) {
        var _this4 = this;

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$.ajax({
          type: 'POST',

          /* eslint-disable-next-line ember/no-get */
          url: '/interface/conversations/' + this.get('model.id') + '/addRecipient/' + recipient.id,
          success: function success() {
            _this4.model.reload();

            Ember.debug('Recipient Added sucessfully');
          },
          error: function error(_error) {
            Ember.debug('Unable to add recipient. Something went wrong: ');
            Ember.debug(_error);
          }
        });
        this.set('adding_member', false);
      },
      addUserToggle: function addUserToggle() {
        if (this.adding_member) {
          this.set('adding_member', false);
        } else {
          this.set('adding_member', true);
        }
      },
      toggleArchived: function toggleArchived() {
        var convo = this.model;
        var isArchived = convo.get('archived');
        var un = isArchived ? 'un' : '';
        var dialogMessage = 'Are you sure you wish to ' + un + 'archive this conversation? To undo this action later, click the folder icon again.';
        (0, _dialog.default)(dialogMessage, ['Yes', 'No']).then(function (selection) {
          if (selection === 'Yes') {
            convo.set('workflow_state', isArchived ? 'active' : 'archived');
            convo.save();
          }
        });
      },
      updateConversation: function updateConversation() {
        var _this5 = this;

        var message = this.parseEmbed(this.bodyInput);
        /* eslint-disable-next-line ember/no-jquery */

        if (Ember.$.trim(message.replace(/&nbsp;/g, ' ').replace(/<!---->/g, ' ')).length === 0) {
          return;
        }

        message = message.replace('<!---->', '');
        message = message.trim(); // Add any video embeds

        message = message + this.videoEmbedString;
        this.set('uploadInProgress', true);
        var current_conversation = this.model,
            conversationPartners = current_conversation.get('conversationPartners'); // Uploading attachments

        this.set('uploadInProgress', true); // The first message is saved along with the original conversation

        if (current_conversation.get('isNew')) {
          current_conversation.set('lastActivity', new Date());
          current_conversation.set('new_message_body', message);

          if (!this.working && this.file_ids.length > 0) {
            current_conversation.set('attachments', this.file_ids);
          }

          current_conversation.save().then(function (savedConversation) {
            _this5.set('bodyInput', '');
            /* eslint-disable-next-line ember/no-jquery */


            Ember.$('.rte-editor-input').html('');

            _this5.send('clearAllFiles');

            _this5.set('uploadInProgress', false);

            savedConversation.set('last_authored_message', message);
          }, function (err) {
            Ember.debug(err, 'Create new conversation to ' + conversationPartners.id);

            _this5.set('uploadInProgress', false);

            window.alert('Unable to post message. Please try again.');
          }).then(function () {
            _this5.transitionToRoute('classroom.lessons.conversation', current_conversation.get('id'));
          });
        } else {
          this.store.nestResources('conversation-message', [{
            'conversation': current_conversation.get('id')
          }]);
          var new_conversation_message = current_conversation.store.createRecord('conversation_message');
          new_conversation_message.set('body', message);
          new_conversation_message.set('createdAt', new Date());
          new_conversation_message.set('belongsToSelf', true);
          new_conversation_message.set('conversation', current_conversation.get('id'));
          new_conversation_message.set('conversationPartners', conversationPartners);

          if (!this.working && this.file_ids.length > 0) {
            new_conversation_message.set('attachments', this.file_ids);
          }

          new_conversation_message.save().then(function () {
            _this5.set('bodyInput', '');
            /* eslint-disable-next-line ember/no-jquery */


            Ember.$('.rte-editor-input').html('');

            _this5.send('clearAllFiles');

            _this5.set('uploadInProgress', false);

            current_conversation.set('last_authored_message', message);
            current_conversation.set('last_message', message);
            current_conversation.set('lastActivity', new Date());
          }, function (err) {
            Ember.debug({
              Location: 'Post conversation reply',
              Status: err.status,
              Message: err.message,
              Error: err.responseText
            });

            _this5.set('uploadInProgress', false);

            window.alert('Unable to post message. Please try again.');
          }).then(function () {
            _this5.scrollAndUpdateLocalConversation(current_conversation);
          });
        }
        /* eslint-disable-next-line ember/no-jquery */


        Ember.$('.pop-attachment-drawer').addClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.floating-modal.active').removeClass('drawer-open');
      },
      deleteUnsentConversation: function deleteUnsentConversation() {
        var current_conversation = this.model;

        if (current_conversation.get('isNew')) {
          current_conversation.destroyRecord();
        }
      },
      closeModals: function closeModals() {
        // Let the enmodal mixin close things out first
        this._super();

        this.send('deleteUnsentConversation'); // Ensure that the heartbeat stops

        this.set('model', null);
      }
    }
  });

  _exports.default = _default;
});