define("bocce/templates/components/classroom/header/section-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vbmPtuz0",
    "block": "{\"symbols\":[],\"statements\":[[2,\"Section \"],[1,[34,1]],[2,\" \"],[1,[34,2]],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  Week \"],[1,[35,0,[\"week\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"term\",\"_sectionLabel\",\"_termLabel\",\"showWeek\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/classroom/header/section-info.hbs"
    }
  });

  _exports.default = _default;
});