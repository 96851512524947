define("bocce/helpers/heartbeat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loadHeartbeatData;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var last_banner_serial;

  function loadHeartbeatData(routes, ctl) {
    var view = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var keys = ['attachment', 'discussion', 'response', 'submission', 'conversation', 'conversation_message', 'event', 'comment', 'user', 'deletes'];
    var deleble_types = ['comment'];
    var store = ctl.get('store');
    var user_id = parseInt(ctl.session.get('user.id'), 10);
    /* eslint-disable-next-line ember/no-jquery */

    var promises = routes.map(function (url) {
      return Ember.$.get(url);
    }),
        course_hb_index = -1,
        full_reload_p = false;

    for (var i = 0; i < routes.length; i++) {
      if (routes[i].match(/courses\/.*\/heartbeat/)) {
        course_hb_index = i;
      }

      if (routes[i].indexOf('heartbeat') === -1) {
        full_reload_p = true;
      }
    }
    /* eslint-disable-next-line ember/no-jquery */


    return Ember.$.when.apply(Ember.$, promises).done(function () {
      for (var _len = arguments.length, data = new Array(_len), _key = 0; _key < _len; _key++) {
        data[_key] = arguments[_key];
      }

      // Data is an array of array of the parameters that the done function
      // would normally get.  data[nn][0] is the actual data we're looking
      // for from each hit.
      if (routes.length === 1) {
        data = [data];
      }

      for (var _i = 0; _i < data.length; _i++) {
        if (data[_i][2].status === 500) {
          Ember.debug('Heartbeat request returned 500 error, disabling');
          window.heartbeatFrequencyInMilliseconds = 0;
          return;
        }
      }

      if (view && course_hb_index >= 0) {
        var course_hb_data = data[course_hb_index][0];
        ctl.set('session.isInstructorChatRunning', !!course_hb_data.meeting_key);
        ctl.set('session.canMergeChats', !!course_hb_data.other_section_meeting_key);

        if (ctl.get('session.allowStudentChats') && course_hb_data.student_meeting_keys) {
          ctl.set('session.areStudentChatsRunning', course_hb_data.student_meeting_keys.length > 0);
          ctl.set('session.studentMeetingKeys', course_hb_data.student_meeting_keys || []);
        }

        ctl.set('session.anyChatsRunning', ctl.get('session.isInstructorChatRunning') || ctl.get('session.areStudentChatsRunning'));

        if (course_hb_data.meeting_key) {
          delete course_hb_data.meeting_key;
        }

        if (course_hb_data.other_section_meeting_key) {
          delete course_hb_data.other_section_meeting_key;
        }

        ctl.set('session.globalReadOnly', !!course_hb_data.readonly);

        if (course_hb_data.readonly) {
          delete course_hb_data.readonly;
        }

        if (course_hb_data.banner) {
          var ban = course_hb_data.banner;

          if (last_banner_serial !== ban.serial) {
            view.send('notify', ban.banner, ban.dismissable, ban.modal_type || null, ban.modal_id || null, 100, ctl.get('session'), false, ban.link, ban.serial, "banner");
            last_banner_serial = ban.serial;
          }

          delete course_hb_data.banner;
        } else if (ctl.get('session.notification') && last_banner_serial) {
          view.send('dismissNotif', ctl.get('session'));
          last_banner_serial = null;
        }

        if (course_hb_data.activity) {
          var activity = course_hb_data.activity;
          var fiveMinutesAgo = moment().subtract(5, 'minutes');

          for (var uid in activity) {
            var user = store.peekRecord('user', uid);

            if (user) {
              user.set('whenPresent', activity[uid]);

              if (activity[uid]) {
                var isPresent = new Date(activity[uid]) > fiveMinutesAgo;
                user.set('isPresent', isPresent);
              }
            }
          }
        }
      }

      var _iterator = _createForOfIteratorHelper(keys),
          _step;

      try {
        var _loop = function _loop() {
          var key = _step.value;
          var objs = [];
          data.map(function (d) {
            if (d[0][key] && d[0][key].length > 0) {
              objs = objs.concat(d[0][key]);
            }
          }); // Use .peekAll instead of find to avoid a REST hit

          var loaded_objs = store.peekAll(key);
          var ids = loaded_objs.map(function (e) {
            return '' + e.id;
          });
          var to_push = [],
              interface_ids = [];

          for (var j = 0; j < objs.length; j++) {
            if (ids.indexOf('' + objs[j].id) === -1) {
              // TODO: make my_submission a computed property instead of computing it
              // in the inteface's toEmber function.
              if (key === 'submission') {
                (function () {
                  var isSubmissionOwner = parseInt(objs[j].user) === user_id,
                      grade = objs[j].grade;
                  objs[j].my_submission = isSubmissionOwner;

                  if (isSubmissionOwner) {
                    store.findRecord('assignment', objs[j].assignment).then(function (assignment) {
                      assignment.set('currentUserSubmissionGrade', grade);
                    });
                  }
                })();
              } else if (key === 'comment') {
                var submissionCtl = ctl.get('submission');
                var open_id = submissionCtl.get('openAssignmentView.id'); // If the comment is one we didn't make ourselves and we're not looking at
                // the submission right now, set it to unread

                if (objs[j].user !== user_id && open_id !== objs[j].submission) {
                  var submission = store.peekRecord('submission', objs[j].submission);

                  if (submission) {
                    submission.set('read', false);
                    submission.set('workflow_state', 'unread');
                  }
                }
              } // If the item's id doesn't exist in the store, add the item
              // to the to_push array.


              to_push.push(objs[j]);
            } else if (objs[j].is_deleted || objs[j].is_updated) {
              // If object remarked as deleted or updated, push too
              to_push.push(objs[j]);
            }

            interface_ids.push(objs[j].id);
          } // If this is a deleble type, and we're doing a full reload,
          // delete any objects that weren't included in the data dump


          if (full_reload_p && deleble_types.indexOf(key) >= 0) {
            loaded_objs.filter(function (obj) {
              return interface_ids.indexOf(obj.id) === -1;
            }).forEach(function (obj) {
              Ember.debug('Removing orphan ' + key + ' ' + obj.id);
              store.unloadRecord(obj);
            });
          }

          if (to_push.length > 0) {
            Ember.debug('Pushing ' + to_push.length + ' new ' + key);
            Ember.debug(to_push.map(function (e) {
              return e.id;
            })); // Uncomment to bring back growler

            /*
            growler
              .attr('message', messages[i])
              .removeClass('growl')
              .addClass('growl');
            */

            var payload = {};
            payload[key] = to_push;
            store.pushPayload(key, payload);

            if (key === 'response') {
              var _iterator2 = _createForOfIteratorHelper(to_push),
                  _step2;

              try {
                for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                  var resp = _step2.value;
                  var disc = store.peekRecord('discussion', resp.discussion);

                  if (disc) {
                    var unread_count = disc.get('unread_count');
                    disc.set('unread_count', unread_count + 1);
                  }
                }
              } catch (err) {
                _iterator2.e(err);
              } finally {
                _iterator2.f();
              }
            }

            if (key === 'conversation_message') {
              var _iterator3 = _createForOfIteratorHelper(to_push),
                  _step3;

              try {
                for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                  var conversation_message = _step3.value;
                  var conversation = store.peekRecord('conversation', conversation_message.conversation);

                  if (conversation) {
                    conversation.set('workflow_state', 'unread');
                    conversation.set('last_message', conversation_message.body);
                  }
                }
              } catch (err) {
                _iterator3.e(err);
              } finally {
                _iterator3.f();
              }
            } // JRW: Sometimes announcements come back as already read.


            if (key === 'discussion') {
              var _iterator4 = _createForOfIteratorHelper(to_push),
                  _step4;

              try {
                for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
                  var discussion = _step4.value;
                  var loadedDiscussion = store.peekRecord('discussion', discussion.id);

                  if (loadedDiscussion.get('is_announcement')) {
                    loadedDiscussion.set('read', false);
                  }
                }
              } catch (err) {
                _iterator4.e(err);
              } finally {
                _iterator4.f();
              }
            }
          }
        };

        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    });
  }
});