define("bocce/routes/lobby", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('lobby');
    },
    renderTemplate: function renderTemplate() {
      var conversationsController = this.controllerFor('conversations');
      this.render(); // Render primary template

      this.render('conversations', {
        into: 'lobby',
        outlet: 'conversations',
        controller: conversationsController,
        model: 'conversation'
      });
    }
  });

  _exports.default = _default;
});