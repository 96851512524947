define("bocce/helpers/past-due", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/helpers/past-due.js
  // Check if date is past due or not
  var _default = Ember.Helper.helper(function (date, options) {
    if (new Date(date).getTime() < new Date().getTime()) {
      return options.fn(this);
    }

    return options.inverse(this);
  });

  _exports.default = _default;
});