define("bocce/routes/classroom/lessons/modal-base", ["exports", "bocce/mixins/routable"], function (_exports, _routable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */
  var _default = Ember.Route.extend(_routable.default, {
    beforeModel: function beforeModel() {
      // Fit audio player

      /* eslint-disable-next-line ember/no-jquery */
      if (Ember.$('.audio-player.active').length > 0) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.classroom').addClass('audioPlaying');
      }

      delete localStorage.localDocRunner;
    },
    // Cleanup code for modals
    resetController: function resetController() {
      // Clean out autoSave
      var fl;

      try {
        fl = JSON.parse(localStorage.localDocs);
        delete fl['Auto Save'];
      } catch (err) {
        fl = {};
      }

      localStorage.localDocs = JSON.stringify(fl);
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('a').removeAttr('tabindex');
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('button').removeAttr('disabled');
    },
    renderTemplate: function renderTemplate() {
      // get the default template name via the routeName property.
      // The simpler way to do this would be to use this.templateName,
      // but because this is a superclass it's undefined here and only
      // accessible from the subclass.
      var template = this.routeName;
      this.render(template, {
        into: 'classroom',
        outlet: 'modals'
      });
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('a').attr('tabindex', '-1'); // NK: Including .bocce in the selector below prevents tests from aborting.
      // I'm not sure why the test framework aborts, but making sure that we only
      // change buttons within the BOCCE body seems to appease QUnit.

      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.bocce button').attr('disabled', 'true');
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.floating-modal a, .popup-container a').removeAttr('tabindex');
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.floating-modal button, .popup-container button').removeAttr('disabled');
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.help-guide-container button').removeAttr('disabled');
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('.floating-modal [tabindex="0"]').focus();
    }
  });

  _exports.default = _default;
});