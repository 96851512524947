define("bocce/models/quiz", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/models/quiz.js
  var _default = _model.default.extend({
    section: (0, _model.belongsTo)('section'),
    title: (0, _model.attr)('string'),
    search_id: (0, _model.attr)(),
    introduction: (0, _model.attr)('string'),
    due_at: (0, _model.attr)('date'),
    is_graded: (0, _model.attr)('boolean'),
    allowed_attempts: (0, _model.attr)('number'),
    presentation: (0, _model.attr)('string'),
    shuffle: (0, _model.attr)('boolean'),
    is_quiz: true,
    attempts: (0, _model.hasMany)('attempt', {
      async: true
    }),
    quiz_questions: (0, _model.hasMany)('quiz_question', {
      async: true
    }),

    /* eslint-disable-next-line ember/require-return-from-computed */
    formattedDate: Ember.computed('due_at', function () {
      if (this.due_at) {
        var d = this.due_at;

        if (!d) {
          return '';
        }

        return moment(d).tz('America/New_York').calendar();
      }
    }),
    formattedDueDate: Ember.computed.alias('formattedDate'),
    dueToday: Ember.computed('due_at', function () {
      var d = this.due_at;

      if (d && moment(d).isSame(Date(), 'day')) {
        return true;
      }

      return false;
    }),
    todo: Ember.computed.equal('attempts.length', 0),
    sortDate: Ember.computed.alias('due_at')
  });

  _exports.default = _default;
});