define("bocce/templates/interactions/fillintheblanksquiz/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yy3ENwkD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"feedback\"],[14,5,\"display: none;\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,1,\"question\"],[14,0,\"question\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"button-container\"],[12],[2,\"\\n  \"],[10,\"input\"],[14,2,\"Check Answer\"],[14,0,\"button\"],[14,4,\"button\"],[12],[13],[2,\"\\n  \"],[10,\"input\"],[14,2,\"Next Question\"],[14,0,\"button attention\"],[14,5,\"display: none;\"],[14,4,\"button\"],[12],[13],[2,\"\\n  \"],[10,\"input\"],[14,2,\"Show Correct?\"],[14,0,\"button show-correct-button\"],[14,5,\"display: none;\"],[14,4,\"button\"],[12],[13],[2,\"\\n  \"],[10,\"input\"],[14,2,\"Try Again?\"],[14,0,\"button attention\"],[14,5,\"display: none;\"],[14,4,\"button\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/interactions/fillintheblanksquiz/main.hbs"
    }
  });

  _exports.default = _default;
});