define("bocce/components/side-panel/panel-list-item/icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['icon', 'portraits']
  });

  _exports.default = _default;
});