define("bocce/services/heartbeat", ["exports", "bocce/helpers/heartbeat"], function (_exports, _heartbeat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function urlExists(url) {
    return new Promise(function (resolve) {
      var http = new XMLHttpRequest();
      http.open('HEAD', url + '?_=' + new Date().getTime());
      http.setRequestHeader('Cache-Control', 'no-cache');

      http.onreadystatechange = function () {
        resolve(http.status !== 404);
      };

      http.send();
    });
  }

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments); // For easier debugging just change the frequency here. - JRW


      window.heartbeatFrequencyInMilliseconds = 30 * 1000;
      this.set('last_heartbeat', new Date());
      this.set('blurred', false);
    },
    missed_heartbeat: false,
    session: Ember.inject.service(),
    begin: function begin(notificationController) {
      var _this = this;

      this.set('notificationController', notificationController); // Very simple approach for right now.  If we're out of focus, skip
      // that particular heartbeat run.

      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(window).on('blur', function () {
        return _this.set('blurred', true);
      });
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(window).on('focus', Ember.run.bind(this, function () {
        // If we were stopped, do a one-off run to resync immediately
        if (_this.blurred && _this.missed_heartbeat) {
          _this.heartbeat(true);
        }

        _this.set('blurred', false);
      }));
      Ember.run.next(this, this.heartbeat);
    },
    end: function end() {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$(window).off('blur');
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(window).off('focus');
    },
    heartbeat: function heartbeat(dont_reschedule_p, full_p) {
      var _this2 = this;

      var ctl = this.notificationController;
      (false && !(ctl) && Ember.assert('heartbeat must have a controller to which it can send notifications.', ctl));
      var bodyInput, cas, myText, arName; // Archive any text to running archive automatically

      /* eslint-disable-next-line ember/no-jquery */

      if (Ember.$('.floating-modal.active').length > 0 && Ember.$('.floating-modal.active .rte-editor-input').text().length > 0) {
        /* eslint-disable-next-line ember/no-jquery */
        var archiver = Ember.$('#archiver');
        /* eslint-disable-next-line ember/no-jquery */

        bodyInput = Ember.$('.floating-modal.active .rte-editor-input').html();
        archiver.addClass('saving');
        archiver.one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
          archiver.removeClass('saving');
        });

        if (localStorage.localDocs) {
          cas = JSON.parse(localStorage.localDocs);
        } else {
          cas = {};
        }
        /*
        if (localStorage.localDocRunner) {
          arName = localStorage.localDocRunner;
          cas[arName].textContent = bodyInput;
        } else {**/


        arName = 'Auto Save';
        myText = {
          isText: true,
          name: arName,
          textContent: bodyInput,
          type: 'text'
        };
        cas[myText.name] = myText; //        }

        localStorage.localDocs = JSON.stringify(cas);
      }
      /* eslint-disable-next-line ember/no-get */


      if (this.get('session.isStaticContent') || this.get('session.course.isReadOnly')) {
        return;
      }

      if (this.blurred && !dont_reschedule_p) {
        this.set('missed_heartbeat', true);

        if (window.heartbeatFrequencyInMilliseconds) {
          Ember.run.later(this, 'heartbeat', window.heartbeatFrequencyInMilliseconds);
        }

        return;
      }

      this.checkCurrent();
      this.set('missed_heartbeat', false);
      Ember.debug('Beep.');
      this.set('session.time', Date.now());
      var now = new Date(),
          full_reload_p = now - this.last_heartbeat > 120000 || full_p,
          // types that can be deleted and that also reference another type

      /* eslint-disable-next-line ember/no-get */
      currentSection = this.get('session.section.id');
      var routes = [
      /* eslint-disable-next-line ember/no-get */
      '/interface/courses/' + this.get('session.section.course.id') + '/heartbeat/' + this.get('session.section.id')]; // Heartbeat is only good for 120 seconds of data.  If we run over that,
      // do a full refresh

      if (!full_reload_p) {
        routes.push('/interface/users/heartbeat');
      } else {
        routes.push('/interface/sections/' + currentSection + '/discussions/');
        routes.push('/interface/sections/' + currentSection + '/assignments/');
        routes.push('/interface/conversations/');
        /* eslint-disable-next-line ember/no-get */

        routes.push('/interface/courses/' + this.get('session.section.course.id') + '/events/');
      }

      this.set('last_heartbeat', now);
      (0, _heartbeat.default)(routes, ctl, ctl).always(function () {
        // Try to load up any videos that may be waiting on CameraTag

        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.video-container video').each(function () {
          if (this.paused && this.duration < 3) {
            this.load();
          }
        });

        if (!dont_reschedule_p && window.heartbeatFrequencyInMilliseconds) {
          Ember.run.later(_this2, 'heartbeat', window.heartbeatFrequencyInMilliseconds);
        }
      });
    },
    // Ping JS/CSS files to make sure they are still current...
    checkCurrent: function checkCurrent() {
      var _this3 = this;

      var resultCss = this._searchFiles('link'),
          resultScript = this._searchFiles('script');

      if (resultCss.length > 0 && resultScript.length > 0) {
        urlExists(resultScript).then(function (jsResult) {
          if (jsResult) {
            urlExists(resultCss).then(function (cssResult) {
              _this3._notifyUpdates(cssResult);
            });
          } else {
            _this3._notifyUpdates(jsResult);
          }
        });
      }
    },
    _searchFiles: function _searchFiles(type) {
      var ctl = this.notificationController;
      var key,
          value,

      /* eslint-disable-next-line ember/no-get */
      result = ctl.get('session.bFiles' + type);

      if (!result) {
        var scripts = document.getElementsByTagName(type);

        for (key in scripts) {
          if (type === 'link') {
            value = scripts[key].href;
          } else {
            value = scripts[key].src;
          }

          if (!!value && value.indexOf('assets/bocce') > -1) {
            result = value;
            break;
          }
        }

        ctl.set('session.bFiles' + type, result);
      }

      return result;
    },
    _notifyUpdates: function _notifyUpdates(result) {
      if (!result) {
        var ctl = this.notificationController;
        /* eslint-disable-next-line ember/no-get */

        ctl.send('notify', 'We have made some updates - click here to reload this page.', true, null, null, 1000, ctl.get('session'), true);
      } else {
        Ember.debug('JS/CSS are current.');
      }
    }
  });

  _exports.default = _default;
});