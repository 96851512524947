define("bocce/mixins/interactions/flash_cards", ["exports", "bocce/mixins/support/util", "bocce/mixins/support/render-template"], function (_exports, util, _renderTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function FlashCards($el) {
    util.bindAll(this);
    this.$el = $el.find('.interaction_content'); // grab the data

    var dataTable = $el.find('.interaction_data table')[0];
    this.data = FlashCards.GetDataFromTable(dataTable, FlashCards.Question); // timer for timed questions

    this.timer = {}; // is this a timed quiz?

    this.isTimed = this.data.some(function (q) {
      return q.timeLimit && q.timeLimit > 0;
    });
  }

  FlashCards.prototype = {
    init: function init(e) {
      if (e) {
        e.preventDefault();
      } // make a deep copy of the data


      this.questions = JSON.parse(JSON.stringify(this.data));
      this.currentIndex = -1; // show an initial start screen if this is a timed quiz

      if (this.isTimed) {
        // if the interaction is timed, it is very important that all
        // question images are loaded up front so they appear instantly
        this.preloadImages();
      } else {
        return this.nextQuestion();
      }
    },
    preloadImages: function preloadImages() {
      var numToLoad = 0,
          that = this;
      this.data.forEach(function (q) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(q.questionHtml).find('img').each(function () {
          numToLoad++;
          var image = new Image();

          image.onload = function () {
            if (--numToLoad <= 0) {
              that.showStartScreen();
            }
          };
          /* eslint-disable-next-line ember/no-jquery */


          image.src = Ember.$(this).attr('src');
        });
      }); // if no images are detected, kick it off

      if (numToLoad === 0) {
        this.showStartScreen();
      }
    },
    showStartScreen: function showStartScreen() {
      this.$el.empty();
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('<div class="card"><h2><a href="#" id="beginBtn" class="button blue">Click here to start the quiz</a></h2></div>').appendTo(this.$el);
      this.$el.find('#beginBtn').click(this.nextQuestion);
    },
    nextQuestion: function nextQuestion(e) {
      var _this = this;

      if (e) {
        e.preventDefault();
      }

      this.currentIndex++;

      if (this.currentIndex >= this.questions.length) {
        this.currentIndex = 0;
      }

      this.$el.empty();
      var question = this.questions[this.currentIndex];
      var context = {
        'questionHtml': question.questionHtml,
        'answerHtml': question.answerHtml,
        'count': this.questions.length + ' remaining'
      };
      return (0, _renderTemplate.default)('flash_cards', 'main', context).then(function (content) {
        _this.$el.append(content); // button handlers


        _this.$el.find('#showAnswerBtn').click(_this.showAnswer); // if question has a time limit...


        if (question.timeLimit) {
          var $timeEl = _this.$el.find('#time');

          $timeEl.data('timeLimit', question.timeLimit);

          _this.startTimer($timeEl);
        }
      });
    },
    startTimer: function startTimer($timeEl) {
      var timeRemaining = $timeEl.data('timeLimit');
      this.updateTimeText($timeEl, timeRemaining);
      var that = this;
      this.timer = setInterval(function () {
        // kill the timer when it's less than zero
        if (--timeRemaining <= 0) {
          clearInterval(that.timer);
          $timeEl.text('Time\'s up. Correct answer displayed.');
          that.showAnswer(null, true);
        } else {
          that.updateTimeText($timeEl, timeRemaining);
        }
      }, 1000);
    },
    updateTimeText: function updateTimeText($timeEl, timeRemaining) {
      // pluralize seconds
      var seconds = timeRemaining === 1 ? 'second' : 'seconds';
      $timeEl.text(timeRemaining + ' ' + seconds + ' left'); // set color to red if 2 seconds remain

      if (timeRemaining <= 2) {
        $timeEl.css('color', 'red');
      }
    },
    gotIt: function gotIt(e) {
      e.preventDefault(); // remove question

      this.questions = this.questions.slice(0, this.currentIndex).concat(this.questions.slice(this.currentIndex + 1));

      if (this.questions.length === 0) {
        this.quizComplete();
      } else {
        this.currentIndex--;
        this.nextQuestion();
      }
    },
    showAnswer: function showAnswer(e, timeRanOut) {
      if (e) {
        e.preventDefault();
      }

      this.$el.find('#answer').removeClass('hidden');
      this.$el.find('#showAnswerBtn').remove();
      this.$el.find('#gotItBtn').removeClass('hidden').click(this.gotIt);
      this.$el.find('#nextBtn').removeClass('hidden').click(this.nextQuestion);

      if (timeRanOut === undefined || timeRanOut === false) {
        // stop the timer, if there is one
        if (this.timer) {
          clearInterval(this.timer);
        } // clear the time field


        this.$el.find('#time').text('');
      } // only show shuffle button if there is more than one
      // question remaining


      if (this.questions.length > 1) {
        this.$el.find('#shuffleBtn').removeClass('hidden').click(this.shuffleQuestions);
      }
    },
    shuffleQuestions: function shuffleQuestions(e) {
      e.preventDefault();
      util.shuffle(this.questions);
      this.currentIndex = -1;
      this.nextQuestion();
    },
    quizComplete: function quizComplete() {
      this.$el.empty();
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$('<div class="card"><h2>Quiz Complete. Nice job!</h2></div><div id="buttons"><a href="#" id="tryAgainBtn" class="button blue">Try Again?</a></div>').appendTo(this.$el);
      this.$el.find('#tryAgainBtn').click(this.init);
    }
  };
  /**
   * Question Class
   **/

  FlashCards.Question = function (questionHtml, answerHtml, timeLimit) {
    /* eslint-disable-next-line ember/no-jquery */
    this.questionHtml = Ember.$(questionHtml).html();
    /* eslint-disable-next-line ember/no-jquery */

    this.answerHtml = Ember.$(answerHtml).html(); // how much time before the answer is automatically displayed?

    /* eslint-disable-next-line ember/no-jquery */

    this.timeLimit = parseInt(Ember.$.trim(Ember.$(timeLimit).text())) || undefined;
  };
  /**
   * Add Utility Methods
   **/
  // create new object with arguments based on a table rows <td>'s (in order)


  FlashCards.GetDataFromTable = function (table, model) {
    var data = [];
    /* eslint-disable-next-line ember/no-jquery */

    Ember.$(table).find('tr').each(function (i) {
      // skip first row (title row)
      if (i === 0) {
        return true;
      }

      var args = [];
      /* eslint-disable-next-line ember/no-jquery */

      Ember.$(this).find('td').each(function () {
        /* eslint-disable-next-line ember/no-jquery */
        args.push(Ember.$(this));
      });
      var item = FlashCards.Construct(model, args);
      data.push(item);
    });
    return data;
  }; // dynamically create objects with arbitrary arguments array


  FlashCards.Construct = function (constructor, args) {
    function F() {
      return constructor.apply(this, args);
    }

    F.prototype = constructor.prototype;
    return new F();
  };

  var _default = FlashCards;
  _exports.default = _default;
});