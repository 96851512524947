define("bocce/controllers/announcement", ["exports", "bocce/mixins/audio-rec", "bocce/mixins/editable", "bocce/mixins/discussable", "bocce/mixins/user-profile", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/enmodal", "bocce/mixins/notify", "bocce/mixins/prefs"], function (_exports, _audioRec, _editable, _discussable, _userProfile, _videoRec, _rtcRec, _enmodal, _notify, _prefs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */
  var _default = Ember.Controller.extend(_audioRec.default, _editable.default, _discussable.default, _userProfile.default, _enmodal.default, _videoRec.default, _rtcRec.default, _prefs.default, _notify.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.sortProperties = this.sortProperties || ['lastResponse.date:desc'];
    },
    userprofile: Ember.inject.controller(),
    // required by UserProfileMixin
    discussions: Ember.inject.controller(),
    // required by EditableMixin
    classroom: Ember.inject.controller(),
    // required by DiscussableMixin and EnmodalMixin
    discussion: Ember.inject.controller('classroom.lessons.discussion'),
    // required by DiscussableMixin
    newDiscussion: Ember.inject.controller('classroom.lessons.discussion-new'),
    // required by DiscussableMixin
    sorted_model: Ember.computed.sort('model', 'sortProperties'),
    announcements: Ember.computed.filterBy('sorted_model', 'is_announcement', true),

    /* eslint-disable-next-line ember/no-observers */
    contentObserver: Ember.observer('model.content.isLoaded', function () {
      var c = this.model; // content.content.isLoaded tells us when the array is fully loaded

      if (!(c = this.model) || !(c = c.get('content')) || !c.get('isLoaded') || this.announcementInitialLoad) {
        return;
      }

      var anns = this.announcements.sortBy('date'),
          anlen = anns.get('length');

      if (anlen === 0) {
        this.set('noAnnouncements', true);
      } else {
        for (var i = 0; i < anlen; i++) {
          var ann = anns.objectAt(i);

          if (!ann.get('read')) {
            this.send('notify', ann.get('title'), true, 'discussion', ann.id);
          }
        }
      }
      /* eslint-disable-next-line ember/no-get */


      if (this.get('session.user.profile.can_showcase')) {
        this.send('togglePrefPanel');
      }
      /* eslint-disable-next-line ember/no-get */


      if (this.get('session.user.profile.font_size')) {
        /* eslint-disable-next-line ember/no-get */
        this.send('adjustFont', this.get('session.user.profile.font_size'));
      }

      this.set('announcementInitialLoad', true);
    })
  });

  _exports.default = _default;
});