define("bocce/router", ["exports", "bocce/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.on('routeDidChange', function () {
        if (window.ga) {
          window.ga('set', 'page', _this.url);
          window.ga('send', 'pageview');
        }
      });
    }
  });
  Router.map(function () {
    this.route('classroom', {
      path: '/:course_id/:course_code/:section_id'
    }, function () {
      this.route('lessons', {
        path: '/:lesson_id/:item_id'
      }, function () {
        this.route('discussion-new', {
          path: '/discussion/new'
        });
        this.route('discussion', {
          path: '/discussion/:discussion_id'
        });
        this.route('announcement-new', {
          path: '/announcement/new'
        });
        this.route('conversation-new-with', {
          path: '/conversation/new/:user_ids'
        });
        this.route('conversation-new', {
          path: '/conversation/new'
        });
        this.route('conversation', {
          path: '/conversation/:conversation_id'
        });
        this.route('submission', {
          path: '/submission/:submission_id'
        });
        this.route('submission-new', {
          path: '/new-submission/:assignment_id'
        });
        this.route('no-submissions', {
          path: '/no-submissions/:assignment_id'
        });
        this.route('event-new', {
          path: '/event/new'
        });
        this.route('event-legacy-new', {
          path: '/event-legacy/new'
        });
        this.route('event', {
          path: '/event/:event_id'
        });
        this.route('survey');
        this.route('student-event-new', {
          path: '/student-event/new'
        });
        this.route('admin', function () {
          this.route('cache');
          this.route('live');
          this.route('assignments');
          this.route('banners');
          this.route('quizzes');
        });
        this.route('assignments', {
          path: '/assignments/:assignment_id'
        });
        this.route('quiz', {
          path: '/quiz/:quiz_id'
        });
      });
    });
    this.route('course', {
      path: '/:course_id'
    });
    this.route('lobby');
  });
  var _default = Router;
  _exports.default = _default;
});