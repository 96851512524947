define("bocce/components/kaltura-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    kalturaEmbed: function kalturaEmbed() {
      if (window.kWidget) {
        var kalturaRecording = this.kalturaRecording;
        var kEmbedObject = {
          targetId: this.elementId,
          flashvars: {
            'autoPlay': false
          },
          wid: '_2588802',
          uiconf_id: 44413892,
          entry_id: kalturaRecording.kalturaId
        };
        var embedResult = window.kWidget.embed(kEmbedObject);
        (false && !(embedResult !== false) && Ember.assert('kWidget.embed is called on an existing DOM element', embedResult !== false));
      }
    },
    didRender: function didRender() {
      this.kalturaEmbed();
    }
  });

  _exports.default = _default;
});