define("bocce/templates/components/bookmarks/bookmark-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7NW5S8KA",
    "block": "{\"symbols\":[\"@bookmarksService\"],\"statements\":[[10,\"div\"],[14,\"aria-hidden\",\"true\"],[14,0,\"popup-backdrop\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"bookmarks-preview floating-modal active\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bookmarks-preview-header\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"bookmarks-preview-modal-title modal-title\"],[12],[2,\"Bookmark Page\"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n            \"],[11,\"button\"],[24,0,\"bookmark-preview-visit\"],[24,\"hint\",\"Visit\"],[24,0,\"fas fa-external-link button\"],[4,[38,0],[[32,0],\"visitBookmark\",\"classroom.lessons\",[32,1,[\"bookmarkPreview\",\"course_id\"]],[32,1,[\"bookmarkPreview\",\"course_code\"]],[32,1,[\"bookmarkPreview\",\"section_id\"]],[32,1,[\"bookmarkPreview\",\"lesson_id\"]],[32,1,[\"bookmarkPreview\",\"page_id\"]]],[[\"target\"],[[32,1]]]],[12],[13],[2,\"\\n            \"],[11,\"button\"],[24,0,\"bookmarks-preview-close-button close-button fas fa-times\"],[24,\"aria-label\",\"Close bookmarks\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"setBookmarkPreview\",false],[[\"target\"],[[32,1]]]],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bookmark-preview-body\"],[12],[2,\"\\n        \"],[2,[32,1,[\"bookmarkPreview\",\"bodyCleaned\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/bookmarks/bookmark-preview.hbs"
    }
  });

  _exports.default = _default;
});