define("bocce/components/attachment-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    att_URL: Ember.computed('attachment.url', function () {
      // HOTFIX: Swap docker IP for bocce URL

      /* eslint-disable-next-line ember/no-get */
      var attachment = this.get('attachment.url') || '';
      var docker_ip_regex = /https:\/\/(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/;
      var host = window.location.origin;
      return attachment.replace(docker_ip_regex, host);
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    is_image: Ember.computed('attachment', function () {
      /* eslint-disable-next-line ember/no-get */
      var t = this.get('attachment.type');
      return t === 'image/jpeg' || t === 'image/png';
    }),
    is_video: Ember.computed.equal('attachment.type', 'video/mp4'),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    is_quicktime: Ember.computed.equal('attachment.type', 'video/quicktime'),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    is_audio: Ember.computed('attachment', function () {
      /* eslint-disable-next-line ember/no-get */
      var t = this.get('attachment.type');
      return t === 'audio/mpeg3' || t === 'audio/mp3' || t === 'audio/mpeg';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    is_word: Ember.computed.equal('attachment.type', 'application/msword'),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    is_pdf: Ember.computed.equal('attachment.type', 'application/pdf'),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    is_zip: Ember.computed.equal('attachment.type', 'application/zip')
  });

  _exports.default = _default;
});