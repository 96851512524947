define("bocce/mixins/uploadable", ["exports", "bocce/mixins/legacy-attachment-manager"], function (_exports, _legacyAttachmentManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create(_legacyAttachmentManager.default, {
    /**
     * Path that handles the file uploads.
     *
     * @property dropletUrl
     * @type {String}
     */

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    dropletUrl: Ember.computed('this.lesson', function () {
      /* eslint-disable-next-line ember/no-get */
      var section = this.get('lessons.model.lesson.id') || 0,

      /* eslint-disable-next-line ember/no-get */
      course = this.get('classroom.model.classroom.id') || 0;
      return '/interface/courses/' + course + '/sections/' + section + '/upload';
    }),

    /**
     * @property dropletOptions
     * @type {Object}
     */
    dropletOptions: {
      fileSizeHeader: true,
      useArray: false
    },

    /**
     * Specifies the blacklisted MIME types. Add any file types you want to ban here.
     *
     * @property mimeTypes
     * @type {Array}
     */
    mimeTypes: [],

    /**
     * Apply this property if you want your MIME types above to be appended to the white-list
     * as opposed to replacing the white-list entirely.
     *
     * @property concatenatedProperties
     * @type {Array}
     */
    concatenatedProperties: ['mimeTypes'],
    actions: {
      initUploadDialog: function initUploadDialog() {
        var selector = '.floating-modal.active .discussionAttachment';
        var input = document.querySelector(selector);

        if (input) {
          input.click();
        }
      }
    }
  });

  _exports.default = _default;
});