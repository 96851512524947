define("bocce/templates/classroom/lessons/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dXbLMQvQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,\"role\",\"dialog\"],[14,0,\"floating-modal active\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-title\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,\"role\",\"button\"],[14,0,\"close-button\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fas fa-times\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-content\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fas fa-spinner fa-spin\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" Loading...\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"modals/dark-backdrop\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/classroom/lessons/loading.hbs"
    }
  });

  _exports.default = _default;
});