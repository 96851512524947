define("bocce/templates/interactions/contentframing/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2dbNwJUM",
    "block": "{\"symbols\":[\"label\"],\"statements\":[[10,\"div\"],[14,0,\"framing-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"frame-rect\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"main-content\"],[12],[2,[34,0]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"solution-content\"],[14,5,\"display: none;\"],[12],[2,[34,1]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"aspect-ratios-container\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"Aspect Ratio: \"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"a\"],[14,0,\"aspect-ratio button\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"bottom\"],[12],[2,\"\\n    \"],[10,\"a\"],[14,0,\"solution button\"],[12],[2,\"Reveal Solution\"],[13],[2,\"\\n    \"],[10,\"a\"],[14,0,\"next button\"],[14,5,\"display: none;\"],[12],[2,\"Next >\"],[13],[2,\"\\n    \"],[10,\"a\"],[14,0,\"reset button\"],[14,5,\"display: none;\"],[12],[2,\"Try again?\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"question\",\"solution\",\"aspectRatioLabels\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "bocce/templates/interactions/contentframing/main.hbs"
    }
  });

  _exports.default = _default;
});