define("bocce/controllers/gradebook", ["exports", "bocce/mixins/user-profile", "bocce/utilities/dialog"], function (_exports, _userProfile, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Controller.extend(_userProfile.default, {
    classroom: Ember.inject.controller(),
    discussions: Ember.inject.controller(),
    userprofile: Ember.inject.controller(),
    // required by UserProfileMixin
    ungradedAsZero: true,

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    model: Ember.computed('classroom.gradebookOpen', 'discussions.unreadCountActivities', function () {
      return this.store.findAll('gradebook', {
        reload: true
      });
    }),
    assignmentHeaders: Ember.computed('model', 'model.content', function () {
      return this.model.toArray()[0];
    }),
    assignmentUsers: Ember.computed('model', 'model.content', function () {
      return this.model.toArray().slice(1);
    }),
    grades: Ember.computed(function () {
      return ['-', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-', 'D', 'F', 'I'];
    }),
    passfail: Ember.computed('session.aFNonCredit', function () {
      var retval = [{
        display: '-',
        value: '-'
      }, {
        display: 'Pass',
        value: 'A'
      }, {
        display: 'Fail',
        value: 'F'
      }];
      /* eslint-disable-next-line ember/no-get */

      if (this.get('session.aFNonCredit')) {
        retval = [{
          display: '-',
          value: '-'
        }, {
          display: 'Pass',
          value: 'A'
        }, {
          display: 'Fail',
          value: 'F'
        }, {
          display: 'A',
          value: 'A'
        }, {
          display: 'A-',
          value: 'A-'
        }, {
          display: 'B+',
          value: 'B+'
        }, {
          display: 'B',
          value: 'B'
        }, {
          display: 'B-',
          value: 'B-'
        }, {
          display: 'C+',
          value: 'C+'
        }, {
          display: 'C',
          value: 'C'
        }, {
          display: 'C-',
          value: 'C-'
        }, {
          display: 'D',
          value: 'D'
        }, {
          display: 'F',
          value: 'F'
        }, {
          display: 'I',
          value: 'I'
        }];
      }

      return retval;
    }),
    gradesWillLock: Ember.computed('session.gradeLockAt', function () {
      /* eslint-disable-next-line ember/no-get */
      return !!this.get('session.gradeLockAt');
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    gradesDueAt: Ember.computed(function () {
      /* eslint-disable-next-line ember/no-get */
      var gradesDue = moment(this.get('session.gradeLockAt')).subtract(1, 'days').format('MMMM DD'); // TEMP FIX: Summer grades are due 1 week after term close, instead of 2

      /* eslint-disable-next-line ember/no-get */

      if (this.get('session.section.course.term.name').toLowerCase().indexOf('summer') > -1) {
        /* eslint-disable-next-line ember/no-get */
        gradesDue = moment(this.get('session.section.ends_at')).add(6, 'days').format('MMMM DD');
      } // TEMP FIX: Graduate grades are always due 3 days after term end, unless...

      /* eslint-disable-next-line ember/no-get */


      if (parseInt(this.get('session.section.course.code').split('-')[1]) >= 500) {
        /* eslint-disable-next-line ember/no-get */
        gradesDue = moment(this.get('session.section.ends_at')).add(2, 'days').format('MMMM DD');
      } // If grades fall on Christmas, move the due date by 4 days


      if (gradesDue === 'December 25') {
        gradesDue = 'December 29';
      }

      return gradesDue;
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    gradesUrgent: Ember.computed('gradesDueAt', function () {
      /* eslint-disable-next-line ember/no-get */
      return moment(this.get('session.gradeLockAt')).subtract(7, 'days') < moment();
    }),

    /* eslint-disable-next-line ember/no-observers */
    calcGrades: Ember.observer('assignmentHeaders.@each.muted', 'ungradedAsZero', function () {
      var _this = this;

      var avgStore = [],
          usersPrp = this.assignmentUsers;

      var _loop = function _loop(h) {
        var existingGroupNames = [];
        var gradeTypes = usersPrp[h].get('columns').filter(function (itm) {
          var isNewName = !!itm.assignment_type && typeof itm.group_id === 'number' && existingGroupNames.indexOf(itm.assignment_type) === -1;

          if (!isNewName) {
            return false;
          }

          existingGroupNames.push(itm.assignment_type);
          return true;
        });

        var _loop2 = function _loop2(i) {
          var filteredAssignments = usersPrp[h].get('columns').filter(function (itm) {
            if ( // Is it inscrutable?
            typeof itm === 'undefined' || !itm || !itm.assignment_type || // Is it a dummy assignment?
            itm.assignment_weight === 0 || // Is it an old assignment?
            itm.grading_standard_id == null && itm.due_at === null && (itm.assignment_type == "Assignments" || itm.assignment_type == "Discussion Participation") || // Is it the manual final grade?
            itm.grading_standard_id === 2) {
              return false;
            }

            return gradeTypes[i].assignment_type === itm.assignment_type;
          });

          if (filteredAssignments.length === 0) {
            return "continue";
          }

          var scoreCalc = 0,
              gradeCalc = '',
              scoreSum = 0,
              possibleSum = 0;
          var assignmentCount = filteredAssignments.length;

          for (var j = 0; j < filteredAssignments.length; j++) {
            // Remove ungraded zeros from equation, if the option is enabled -- and remove manual final grade
            // Also remove all dropped grades (some classes have a setting to drop N of the student's lowest grades)

            /* eslint-disable-next-line ember/no-get */
            if (!_this.get('ungradedAsZero') && filteredAssignments[j].score === null || filteredAssignments[j].due_at === null && filteredAssignments[j].grade === null || filteredAssignments[j].excused || filteredAssignments[j].omit_from_final_grade || filteredAssignments[j].grading_standard_id === 2 || filteredAssignments[j].muted || filteredAssignments[j].low_order || filteredAssignments[j].assignment_type === 'Assignments' && filteredAssignments[j].points_possible <= 2) {
              assignmentCount--;
              continue;
            }

            var addScore = parseInt(filteredAssignments[j].score);

            if (!isNaN(addScore)) {
              scoreSum += addScore;
            }

            possibleSum += filteredAssignments[j].points_possible;
          }

          var indx = usersPrp[h].get('columns').findIndex(function (m) {
            return m && m.calcType && m.calcType.indexOf(gradeTypes[i].assignment_type) > -1;
          });

          if (assignmentCount === 0) {
            _this.set('assignmentUsers.' + h + '.columns.' + indx + '.grade', 'N/A');

            return "continue";
          }

          scoreCalc = (scoreSum / possibleSum * 100).toFixed(2);
          gradeCalc = _this.calcLetter(scoreCalc);

          _this.set('assignmentUsers.' + h + '.columns.' + indx + '.score', scoreCalc + '%');

          _this.set('assignmentUsers.' + h + '.columns.' + indx + '.grade', gradeCalc);

          if (filteredAssignments.length === 0) {
            return "continue";
          }

          avgStore.push(scoreCalc * (filteredAssignments[0].assignment_weight / 100));
        };

        for (var i = 0; i < gradeTypes.length; i++) {
          var _ret = _loop2(i);

          if (_ret === "continue") continue;
        }

        var totalIndx = usersPrp[h].get('columns').findIndex(function (m) {
          return m && m.calcType === 'Total';
        });
        var totalScore = avgStore.reduce(function (total, num) {
          return total + num;
        });
        avgStore = [];

        var finalGrade = _this.calcLetter(totalScore.toFixed(2));

        _this.set('assignmentUsers.' + h + '.columns.' + totalIndx + '.score', totalScore.toFixed(2) + '%');

        _this.set('assignmentUsers.' + h + '.columns.' + totalIndx + '.grade', finalGrade);
      };

      for (var h = 0; h < usersPrp.length; h++) {
        _loop(h);
      }
    }),
    calcLetter: function calcLetter(scoreCalc) {
      var gradeCalc = '';

      if (scoreCalc >= 93) {
        gradeCalc = 'A';
      } else if (scoreCalc >= 90) {
        gradeCalc = 'A-';
      } else if (scoreCalc >= 87) {
        gradeCalc = 'B+';
      } else if (scoreCalc >= 83) {
        gradeCalc = 'B';
      } else if (scoreCalc >= 80) {
        gradeCalc = 'B-';
      } else if (scoreCalc >= 77) {
        gradeCalc = 'C+';
      } else if (scoreCalc >= 73) {
        gradeCalc = 'C';
      } else if (scoreCalc >= 70) {
        gradeCalc = 'C-';
      } else if (scoreCalc >= 60) {
        gradeCalc = 'D';
      } else {
        gradeCalc = 'F';
      }

      return gradeCalc;
    },
    actions: {
      muteToggleAssignment: function muteToggleAssignment(assignment_index) {
        var usersPrp = this.assignmentUsers;

        for (var i = 0; i < usersPrp.length; i++) {
          var isMuted = this.get('assignmentUsers.' + i + '.columns.' + assignment_index + '.muted');
          this.set('assignmentUsers.' + i + '.columns.' + assignment_index + '.muted', !isMuted);
        }

        this.calcGrades();
      },
      ungradedAsZeroToggle: function ungradedAsZeroToggle() {
        var asZero = this.ungradedAsZero;
        this.set('ungradedAsZero', !asZero);
      },
      gradeEditOn: function gradeEditOn(column) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.gradebook-container .content .tg-wrap table td#cell-' + column).addClass('editing');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.gradebook-container .content .tg-wrap table td#cell-' + column + ' input').focus();
      },
      gradeEditOff: function gradeEditOff(column) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.gradebook-container .content .tg-wrap table td#cell-' + column).removeClass('editing');
      },
      assignGrade: function assignGrade(assignment_id, submission_id, student_id, score) {
        var _this2 = this;

        var usr = this.assignmentUsers,
            inx = usr.findIndex(function (m) {
          return m && m.id === student_id;
        }),
            indx = usr[inx].get('columns').findIndex(function (m) {
          return m && m.assignment_id === assignment_id;
        });

        if (!score || _typeof(score) === 'object') {
          score = event.target.value;
        }

        usr[inx].set('columns.' + indx + '.score', score);
        this.set('assignmentUsers', usr);
        /* eslint-disable-next-line ember/no-get */

        this.store.nestResources('submission', [{
          section: this.get('session.section.id')
        }, {
          assignment: assignment_id
        }]);
        this.store.findRecord('submission', submission_id + '_' + student_id).then(function (sub) {
          sub.set('posted_grade', score);
          sub.save().then(function () {
            // PRM: I hate these confirmations _so_ much.

            /* eslint-disable-next-line ember/no-get */
            if (!_this2.get('session.user.hideGradebookConfirmations')) {
              (0, _dialog.default)('Your grade has been successfully submitted.');
            }

            _this2.calcGrades();

            Ember.notifyPropertyChange(_this2, 'assignmentUsers');
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.gradebook-container .content .tg-wrap table td').removeClass('editing');
          }, function (err) {
            var prompt = 'Please try again in a few minutes.';

            if (err.errors && err.errors.length > 0) {
              var status = err.errors[0].status;

              if (status === '401') {
                prompt = 'You do not have permission to grade in this course. Please make sure you are enrolled as an instructor.';
              } else if (status === '501') {
                prompt = 'Something went wrong with the server. Please contact support.';
              } else if (status === '503') {
                prompt = 'Please try again in a few minutes.';
              } else {
                prompt = 'Please contact suppot with the following error: ' + err.errors[0].detail;
              }
            }

            (0, _dialog.default)('Unable to save your grade. ' + prompt);
          });
        });
      },
      assignFinalGrade: function assignFinalGrade(assignment_id, submission_id, student_id) {
        var grade = event.target.value; // Lock out grading after session ends

        /* eslint-disable-next-line ember/no-get */

        if (this.get('session.readOnly')) {
          (0, _dialog.default)('Final grades for this course are now locked. Please contact the registrar directly to modify any final student grades.');
          return;
        }

        var usr = this.assignmentUsers,
            inx = usr.findIndex(function (m) {
          return m && m.id === student_id;
        }),
            indx = usr[inx].get('columns').findIndex(function (m) {
          return m && m.assignment_id === assignment_id;
        });
        usr[inx].set('columns.' + indx + '.grade', grade);
        /* eslint-disable-next-line ember/no-get */

        this.store.nestResources('submission', [{
          section: this.get('session.section.id')
        }, {
          assignment: assignment_id
        }]);
        this.store.findRecord('submission', submission_id + '_' + student_id).then(function (sub) {
          sub.set('posted_grade', grade);
          sub.save().then(function () {
            (0, _dialog.default)('Your grade has been successfully submitted.');
            /* eslint-disable-next-line ember/no-jquery */

            Ember.$('.gradebook-container .content .tg-wrap table td').removeClass('editing');
          }, function (err) {
            var prompt = 'Please try again in a few minutes.';

            if (err.errors && err.errors.length > 0) {
              var status = err.errors[0].status;

              if (status === '401') {
                prompt = 'You do not have permission to grade in this course. Please make sure you are enrolled as an instructor.';
              } else if (status === '501') {
                prompt = 'Something went wrong with the server. Please contact support.';
              } else if (status === '503') {
                prompt = 'Please try again in a few minutes.';
              } else {
                prompt = 'Please contact suppot with the following error: ' + err.errors[0].detail;
              }
            }

            (0, _dialog.default)('Unable to save your grade. ' + prompt);
          });
        });
      },
      generateCSV: function generateCSV() {
        var csvArr = [],
            rowsTemp = [];
        /* eslint-disable-next-line ember/no-get */

        var headersPrp = this.get('assignmentHeaders.columns'),
            usersPrp = this.assignmentUsers;

        for (var i = 0; i < headersPrp.length; i++) {
          var text = headersPrp[i].name || headersPrp[i].title || headersPrp[i];
          rowsTemp.push(text);
        }

        csvArr.push(rowsTemp);

        for (var _i = 0; _i < usersPrp.length; _i++) {
          rowsTemp = [];

          for (var j = 0; j < usersPrp[_i].get('columns').length; j++) {
            var current = usersPrp[_i].get('columns')[j];

            var _text = '';

            if (!current) {
              _text = '-';
            } else if (current.grade) {
              if (current.grade === 'Complete') {
                _text = '+';
              } else {
                _text = current.score + '%' + ' (' + current.grade + ')';
              }
            } else if (current.score) {
              _text = current.score;
            } else if (current.name) {
              _text = current.name;
            } else {
              _text = '-';
            }

            if (current && current.late) {
              _text += ' (LATE)';
            }

            rowsTemp.push(_text);
          }

          csvArr.push(rowsTemp);
        }

        var csvContent = 'data:text/csv;charset=utf-8,' + csvArr.map(function (e) {
          return e.join(',');
        }).join('\n');
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        /* eslint-disable-next-line ember/no-get */

        link.setAttribute('download', this.get('session.course.code') + '_' + this.get('session.course.term.name') + '_gradebook.csv');
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
      },
      closeGradebook: function closeGradebook() {
        this.classroom.set('gradebookOpen', false);
      },
      previewAssignment: function previewAssignment(modal) {
        this.transitionToRoute('classroom.lessons.discussion', modal);
      }
    }
  });

  _exports.default = _default;
});