define("bocce/templates/components/legacy-boot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IYlVut+3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"bootable-area\"],[12],[2,\"\\n  \"],[2,[34,0]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"content\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/legacy-boot.hbs"
    }
  });

  _exports.default = _default;
});