define("bocce/templates/interactions/audiomarkersquiz/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ELKclYtv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"loading\"],[12],[2,\"Loading audio. Please wait...\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"player_container\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"feedback\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"try_again\"],[14,5,\"display: none\"],[12],[2,\"\\n  Correct! You got them all. \"],[10,\"a\"],[14,6,\"#\"],[12],[2,\"Try again?\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"answer_area\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"image_container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"progress_bar\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"marker_bin\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/interactions/audiomarkersquiz/main.hbs"
    }
  });

  _exports.default = _default;
});