define("bocce/templates/audio-rec", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pevgh93k",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"audio-rec-panel standby\"],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fas fa-spinner fa-spin\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"],[11,\"i\"],[24,\"role\",\"button\"],[24,0,\"fas fa-circle active\"],[24,\"aria-label\",\"Record Audio\"],[4,[38,0],[[32,0],\"audioRecRecord\"],null],[12],[13],[2,\"\\n  \"],[11,\"i\"],[24,\"role\",\"button\"],[24,0,\"fas fa-stop stop-recording\"],[24,\"aria-label\",\"Stop Recording Audio\"],[4,[38,0],[[32,0],\"audioRecStop\"],null],[12],[13],[2,\"\\n  \"],[11,\"i\"],[24,\"role\",\"button\"],[24,0,\"fas fa-stop stop-playback\"],[24,\"aria-label\",\"Stop Audio Playback\"],[4,[38,0],[[32,0],\"audioRecPrevStop\"],null],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"time-code\"],[14,\"time_code\",\"00:00\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"audio-rec-status\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "bocce/templates/audio-rec.hbs"
    }
  });

  _exports.default = _default;
});