define("bocce/mixins/notify", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create({
    hasNotification: Ember.computed('session.notification', function () {
      /* eslint-disable-next-line ember/no-get */
      return !!this.get('session.notification');
    }),
    isBannerNotification: false,
    currentBannerId: 0,
    actions: {
      notify: function notify(message, dismissable, modal_type, modal_id, priority, session, reload, link, id) {
        var type = arguments.length > 9 && arguments[9] !== undefined ? arguments[9] : "notification";
        // Let us use this mixin in session-less types, like views
        session = session || this.session;
        var is_banner = false;
        var existing = session.get('notification');

        if (type === "banner") {
          is_banner = true;
        }

        priority = priority || 0;
        var notif_type = '';

        if (modal_type === 'discussion') {
          notif_type = 'global';
        } else if (modal_type === 'status') {
          notif_type = 'status';
        } else if (reload) {
          notif_type = 'urgent';
        }

        if (existing && existing.priority > priority) {
          Ember.debug('Ignoring notification ' + message + ', priority is lower than visible notification');
        } else {
          session.set('notification', false);
          session.set('notification', {
            message: message,
            dismissable: dismissable,
            modal_type: modal_type,
            type: notif_type,
            modal_id: modal_id,
            priority: priority,
            link: link,
            reload: reload,
            active: link || reload,
            id: id,
            is_banner: is_banner
          });
        }

        if (!session.get('banners')) {
          session.set('banners', []);
        }

        session.get('banners').pushObject({
          message: message,
          dismissable: dismissable,
          modal_type: modal_type,
          type: notif_type,
          modal_id: modal_id,
          priority: priority,
          link: link,
          reload: reload,
          active: link || reload,
          id: id,
          is_banner: is_banner
        });
        session.set('banners', session.get('banners').sortBy('priority').reverse());
      },
      dismissNotif: function dismissNotif(session) {
        var term_id = this.get('session.termID'),
            course_id = this.get('session.course.id');

        if (this.get('isBannerNotification')) {
          this.set('isBannerNotification', false);
          this.set('currentBannerId', 0);
        }

        if (session.is_banner) {
          var dismissRequest = $.get("/interface/banners/".concat(session.id, "/dismiss/").concat(term_id, "/").concat(course_id));
          this.session.set('banners', []);
        }

        this.session.set('notification', null);
      },
      viewNotif: function viewNotif(modal_type, modal_id, session) {
        if (this.session.get('notification.reload')) {
          location.reload(true);
          return;
        } // Should this be higher up, before the if statement?


        var s = session || this.session,
            link = s.get('notification.link'),
            message = s.get('notification.message');
        s.set('notification', null);

        if (link) {
          window.open(link, '_blank');
        } else if (modal_id) {
          this.send('viewModal', modal_type, modal_id);
        } else {
          (0, _dialog.default)(message);
        }
      }
    }
  });

  _exports.default = _default;
});