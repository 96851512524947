define("bocce/mixins/menus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create({
    actions: {
      toggleMenu: function toggleMenu(menu) {
        var menuElem = document.querySelector('.menu.' + menu);

        if (menuElem) {
          menuElem.classList.toggle('active');
        }

        var menuBackdrop = document.querySelector('.clear-backdrop.' + menu);

        if (menuBackdrop) {
          menuBackdrop.classList.toggle('active');
        } else {
          var clear = document.querySelector('.clear-backdrop');

          if (clear) {
            clear.classList.toggle('active');
          }
        }
      },
      closeMenu: function closeMenu() {
        var menu = document.querySelector('.menu.active');

        if (menu) {
          menu.classList.remove('active');
        }

        var backdrop = document.querySelector('.clear-backdrop');

        if (backdrop) {
          backdrop.classList.remove('active');
        }
      },
      toggleHamburger: function toggleHamburger() {
        var classroom = document.querySelector('.classroom');

        if (classroom) {
          classroom.classList.toggle('active');
        }
      },
      myHome: function myHome() {
        /* eslint-disable-next-line ember/no-get */
        if (!this.get('session.isBCM')) {
          window.open('https://online.berklee.edu/my_home', '_blank');
        }
      }
    }
  });

  _exports.default = _default;
});