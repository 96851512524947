define("bocce/templates/reply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aJjTYI1S",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"reply-options-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"reply-author\"],[12],[1,[34,0]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"reply-text\"],[15,\"reply_id\",[31,[[34,1]]]],[12],[2,\"\\n      \"],[1,[34,2]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"reply-cancel\"],[24,\"aria-label\",\"Cancel Reply\"],[4,[38,3],[[32,0],\"replyCancel\"],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fas fa-times\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"replyAuthor\",\"replyId\",\"replyText\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/reply.hbs"
    }
  });

  _exports.default = _default;
});