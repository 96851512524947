define("bocce/mixins/embed-parser", ["exports", "js-video-url-parser"], function (_exports, _jsVideoUrlParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create({
    parseEmbed: function parseEmbed(content, type) {
      if (!content) {
        return '';
      }

      content = content.replace(/&amp;/g, '&').replace(/&nbsp;/g, ' ');
      var that = this,
          spacedTags = content.replace(/<(?:.|\n)*?/gm, ' <').replace(/>/g, '> '),
          result = window.URI.withinString(spacedTags, function (url, start, end, source) {
        // URI.js's ignoreHtml setting is unreliable, it seems.  Here, check to see
        // if the URL we've got is contained within an HTML tag.  That is, are there
        // < and > characters to either side, not preceded by the opposite?
        var prefix = source.substring(0, start),
            prefix_gt = prefix.lastIndexOf('>'),
            prefix_lt = prefix.lastIndexOf('<'),
            suffix = source.substring(end + 1),
            suffix_gt = suffix.indexOf('>'),
            suffix_lt = suffix.indexOf('<');

        if (prefix_lt >= 0 && prefix_lt > prefix_gt && suffix_gt >= 0 && (suffix_lt === -1 || suffix_gt < suffix_lt)) {
          return url;
        } // URI.js normally trims parentheses off the end of URL matches, which
        // breaks an annoying number of wikipedia links.  We redefine the "trim"
        // list below to keep parens, but to allow for parenthetical links
        // (ie, http://berklee.edu), we'll look here to see if the parens in the
        // URL are balanced or not.  If they are, great, but if not, we'll trim
        // the close paren and add it back after the embed parsing has finished.


        var needs_close_paren = false;

        if (url[url.length - 1] === '.' || url[url.length - 1] === ',') {
          url = url.substring(0, url.length - 1);
        }

        if (url[url.length - 1] === ')' && url.indexOf('(') === -1) {
          needs_close_paren = true;
          url = url.substring(0, url.length - 1);
        }

        var res,
            lowerCaseUrl = url.toLowerCase();

        switch (true) {
          case lowerCaseUrl.indexOf('.jpg') !== -1 && (type === 'img' || !type):
            res = that.imageParser(url);
            break;

          case lowerCaseUrl.indexOf('.jpeg') !== -1 && (type === 'img' || !type):
            res = that.imageParser(url);
            break;

          case lowerCaseUrl.indexOf('.png') !== -1 && (type === 'img' || !type):
            res = that.imageParser(url);
            break;

          case lowerCaseUrl.indexOf('.gif') !== -1 && (type === 'img' || !type):
            res = that.imageParser(url);
            break;

          case lowerCaseUrl.indexOf('.mp4') !== -1 && (type === 'mp4' || !type):
            res = that.mp4Parser(url);
            break;

          case lowerCaseUrl.indexOf('youtube') !== -1 && (type === 'youtube' || !type):
            res = that.youTubeParser(url);
            break;

          case lowerCaseUrl.indexOf('youtu.be') !== -1 && (type === 'youtube' || !type):
            res = that.youTubeParser(url);
            break;

          case lowerCaseUrl.indexOf('vimeo') !== -1 && (type === 'vimeo' || !type):
            res = that.vimeoParser(url);
            break;

          case lowerCaseUrl.indexOf('https://soundcloud.com/upload') !== -1 && (type !== 'soundcloud' || !type):
            res = that.linkParser(url);
            break;

          case lowerCaseUrl.indexOf('https://soundcloud.com/discover') !== -1 && (type !== 'soundcloud' || !type):
            res = that.linkParser(url);
            break;

          case lowerCaseUrl.indexOf('https://soundcloud.com/signin') !== -1 && (type !== 'soundcloud' || !type):
            res = that.linkParser(url);
            break;

          case lowerCaseUrl.indexOf('https://soundcloud.com/pages') !== -1 && (type !== 'soundcloud' || !type):
            res = that.linkParser(url);
            break;

          case lowerCaseUrl.indexOf('https://soundcloud.com') !== -1 && (type === 'soundcloud' || !type):
            res = that.soundCloudParser(url);
            break;

          case lowerCaseUrl.indexOf('tidal') !== -1 && (type === 'tidal' || !type):
            res = that.tidalParser(url, type);
            break;

          case lowerCaseUrl.indexOf('noteflight') !== -1 && (type === 'noteflight' || !type):
            res = that.noteFlightParser(url);
            break;

          case lowerCaseUrl.indexOf('wistia.com/medias') !== -1 && (type === 'wistia' || !type):
            res = that.wistiaParser(url);
            break;

          default:
            if (!type || type === 'url') {
              res = that.linkParser(url);
            } else {
              res = url;
            }

        }

        if (!res) {
          res = that.linkParser(url);
        }

        if (needs_close_paren) {
          res = res + ')';
        }

        return res;
      }, {
        ignoreHtml: true,
        trim: /[`![\]{};:'".,<>?«»“”„‘’]+$/
      }); // Undo some of the spacing we had to add earlier

      result = result.replace(/ <(?:.|\n)*?/g, '<').replace(/> /g, '>');
      return result;
    },
    youTubeParser: function youTubeParser(url) {
      var videoInfo = _jsVideoUrlParser.default.parse(url.replace(/&amp;/g, '&')); // If nothing's returned, it's not a recognizable video url.


      if (!videoInfo) {
        return this.linkParser(url);
      }

      videoInfo.params = videoInfo.params || {};
      return '<iframe class="bocce-generated you-tube embed" width="560" height="315" src="' + _jsVideoUrlParser.default.create({
        videoInfo: videoInfo,
        format: 'embed',
        params: 'internal'
      }) + '" frameborder="0" allowfullscreen></iframe>';
    },
    vimeoParser: function vimeoParser(url) {
      var vimeoParser = function vimeoParser(url) {
        var match = /vimeo.*\/(\d+)/i.exec(url);

        if (match && match[1]) {
          return match[1];
        }
      };

      url = 'https://player.vimeo.com/video/' + vimeoParser(url) + '?title=0&byline=0&portrait=0';
      url = '<iframe class="bocce-generated you-tube embed" src="' + url + '" width="560" height="315" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
      return url;
    },
    tidalParser: function tidalParser(url, embed) {
      var parsed = url.split('/'),
          id = parsed[parsed.length - 1],
          retval = '';

      if (!embed) {
        if (url && url.indexOf('/playlist/') !== -1) {
          retval += '<iframe style="width: 100%; height: 550px" src="https://embed.tidal.com/player/?type=p&id=' + id + '" frameborder="0" allowfullscreen="true" class="tidal-embed playlist"></iframe>';
          retval += '<a class="tidal-link" href="https://listen.tidal.com/playlist/' + id + '" target="_blank"><img class="tidal-img" src="/front_end/images/tidal.png">Open in TIDAL</a>';
          return retval;
        } else if (url && url.indexOf('/album/') !== -1) {
          retval += '<iframe style="width: 100%; height: 400px" src="https://embed.tidal.com/player/?type=a&id=' + id + '" frameborder="0" allowfullscreen="true" class="tidal-embed album"></iframe>';
          retval += '<a class="tidal-link" href="https://listen.tidal.com/playlist/' + id + '" target="_blank"><img class="tidal-img" src="/front_end/images/tidal.png">Open in TIDAL</a>';
          return retval;
        } else if (url && url.indexOf('/track/') !== -1) {
          retval += '<iframe style="width: 100%; height: 180px" src="https://embed.tidal.com/player/?type=t&id=' + id + '" frameborder="0" allowfullscreen="true" class="tidal-embed track"></iframe>';
          retval += '<a class="tidal-link" href="https://listen.tidal.com/playlist/' + id + '" target="_blank"><img class="tidal-img" src="/front_end/images/tidal.png">Open in TIDAL</a>';
          return retval;
        } else if (url && url.indexOf('/video/') !== -1) {
          retval += '<iframe style="width: 100%; height: 580px" src="https://embed.tidal.com/player/?type=v&id=' + id + '" frameborder="0" allowfullscreen="true" class="tidal-embed video"></iframe>';
          retval += '<a class="tidal-link" href="https://listen.tidal.com/playlist/' + id + '" target="_blank"><img class="tidal-img" src="/front_end/images/tidal.png">Open in TIDAL</a>';
          return retval;
        }
      } else {
        if (url && url.indexOf('/playlist/') !== -1) {
          retval += '<div class="tidal-embed playlist" data-type="p" data-id="' + id + '"></div>';
          retval += '<a class="tidal-link" href="https://listen.tidal.com/playlist/' + id + '" target="_blank"><img class="tidal-img" src="/front_end/images/tidal.png">Open in TIDAL</a>';
          return retval;
        } else if (url && url.indexOf('/album/') !== -1) {
          retval += '<div class="tidal-embed album" data-type="a" data-id="' + id + '"></div>';
          retval += '<a class="tidal-link" href="https://listen.tidal.com/album/' + id + '" target="_blank"><img class="tidal-img" src="/front_end/images/tidal.png">Open in TIDAL</a>';
          return retval;
        } else if (url && url.indexOf('/track/') !== -1) {
          retval += '<div class="tidal-embed track" data-type="t" data-id="' + id + '"></div>';
          retval += '<a class="tidal-link" href="https://listen.tidal.com/track/' + id + '" target="_blank"><img class="tidal-img" src="/front_end/images/tidal.png">Open in TIDAL</a>';
          return retval;
        } else if (url && url.indexOf('/video/') !== -1) {
          retval += '<div class="tidal-embed video" data-type="v" data-id="' + id + '"></div>';
          retval += '<a class="tidal-link" href="https://listen.tidal.com/video/' + id + '" target="_blank"><img class="tidal-img" src="/front_end/images/tidal.png">Open in TIDAL</a>';
          return retval;
        }
      }
    },
    soundCloudParser: function soundCloudParser(url) {
      var frm = '<iframe class="bocce-generated sound-cloud embed" width="100%" height="450" scrolling="no" frameborder="no"' + 'src="https://w.soundcloud.com/player/?url=' + url + '&amp' + 'auto_play=false&amp;hide_related=false&amp;show_comments=true' + '&amp;show_user=true&amp;show_reposts=false&amp;visual=true"></iframe>';
      return frm;
    },
    mp4Parser: function mp4Parser(url) {
      var frm = '<video class="embed" preload="none" src="' + url + '" controls controlsList="nodownload">' + 'Your browser does not support HTML5 Video...' + '</video>';
      return frm;
    },
    imageParser: function imageParser(url) {
      var frm = '<a target="_blank" href="' + url + '">' + '<img alt="Embedded Image Attachment" class="embeded-image embed" src="' + url + '"/></a>';
      return frm;
    },
    noteFlightParser: function noteFlightParser(url) {
      var regExp = /^(.*)\/scores\/view\/(.*)/,
          match = url.match(regExp),
          retres;

      if (match) {
        retres = match[1] + '/embed/' + match[2] + '?scale=1';
        retres = '<iframe class="bocce-generated noteflight" width="640" height="298" src="' + retres + '"></iframe>';
      } else {// No matched NF Content
      }

      return retres;
    },
    wistiaParser: function wistiaParser(url) {
      var regExp = /^(.*)\/medias\/(.*)/,
          match = url.match(regExp);
      url = '<div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">' + '<div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">' + '<iframe src="//fast.wistia.net/embed/iframe/' + match[2] + '?videoFoam=true" title="Wistia video player" allowtransparency="true" ' + 'frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" ' + 'allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen ' + 'msallowfullscreen width="100%" height="100%"></iframe></div></div>';
      return url;
    },
    linkParser: function linkParser(url) {
      //var urlSub = url.length > 40 ? url.substring(0, 20) + "..." : url,
      var urlSub = url,
          frm;

      if (url && !/^(f|ht)tps?:\/\//i.test(url)) {
        url = 'http://' + url;
      }

      frm = '<a target="_blank" href="' + url + '">' + urlSub + '</a>';
      return frm;
    }
  });

  _exports.default = _default;
});