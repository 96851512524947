define("bocce/templates/interactions/imageexplorer/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gYcISKgz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"nav\"],[12],[2,\"\\n  \"],[10,\"button\"],[14,0,\"prev\"],[14,\"aria-label\",\"Previous\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fas fa-arrow-circle-left\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"count\"],[12],[2,\"1 of 1\"],[13],[2,\"\\n  \"],[10,\"button\"],[14,0,\"next\"],[14,\"aria-label\",\"Next\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fas fa-arrow-circle-right\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/interactions/imageexplorer/nav.hbs"
    }
  });

  _exports.default = _default;
});