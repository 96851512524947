define("bocce/components/discussion-response", ["exports", "bocce/mixins/user-profile", "bocce/mixins/editable", "bocce/mixins/showcase"], function (_exports, _userProfile, _editable, _showcase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */
  var _default = Ember.Component.extend(_editable.default, _userProfile.default, _showcase.default, {
    replyId: Ember.computed('replyBody', function () {
      /* eslint-disable-next-line ember/no-jquery */
      var replyBody = Ember.$('<div>' + this.replyBody + '</div>');

      if (replyBody && replyBody.find('.is-reply-id').length > 0) {
        return replyBody.find('.is-reply-id').text();
      } else {
        return null;
      }
    }),
    // returns the text of the reply
    responseBody: Ember.computed('response.message', function () {
      /* eslint-disable-next-line ember/no-jquery, ember/no-get */
      var message = Ember.$("<div>".concat(this.get('response.message'), "</div>"));

      if (message) {
        if (message.find('.is-reply')) {
          message.find('.is-reply').remove();
        }

        if (message[0]) {
          return message[0].innerHTML;
        }
      }

      return null;
    }),
    // returns an HTML string with metadata about the message to which this is replying
    replyBody: Ember.computed('response.message', function () {
      /* eslint-disable-next-line ember/no-jquery, ember/no-get */
      var message = Ember.$("<div>".concat(this.get('response.message'), "</div>"));

      if (message) {
        var replyInfo = message.find('.is-reply');

        if (replyInfo[0]) {
          return replyInfo[0].innerHTML;
        }
      }

      return null;
    }),
    actions: {
      scrollTo: function scrollTo(id) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#discussion-response-' + id).removeClass('resp-flash');
        setTimeout(function () {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('#discussion-response-' + id).addClass('resp-flash');
        }, 2);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#discussion-response-' + id)[0].scrollIntoView();
      },
      edit: function edit(id) {
        this.edit(id);
      },
      delete: function _delete(id) {
        this.delete(id);
      }
    }
  });

  _exports.default = _default;
});