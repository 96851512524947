define("bocce/helpers/selected-answer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/helpers/selected-answer.js
  // Specify which answer(s) is selected in given quiz question
  var _default = Ember.Helper.helper(function (inp) {
    var answers = inp[0],
        questionId = inp[1],
        compare_id = inp[2];

    if (!answers || answers.length < 1) {
      return false;
    }

    var currentQuestion = answers.find(function (_ref) {
      var question_id = _ref.question_id;
      return parseInt(question_id) === parseInt(questionId);
    });

    if (!currentQuestion) {
      return false;
    }

    currentQuestion = currentQuestion.answer_id;

    if (!currentQuestion) {
      return false;
    }

    if (typeof currentQuestion === "number") {
      return parseInt(currentQuestion) === parseInt(compare_id);
    }

    return currentQuestion.indexOf(compare_id) > -1;
  });

  _exports.default = _default;
});