define("bocce/helpers/dashify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/helpers/dashify.js
  // Turn string to dashed string
  var _default = Ember.Helper.helper(function (dec) {
    var item = dec[0];
    return item.replace(/\s+/g, '-').toLowerCase();
  });

  _exports.default = _default;
});