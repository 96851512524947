define("bocce/components/datetime-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    // datetime must be a moment.js object
    date: Ember.computed('datetime', {
      get: function get() {
        return this.datetime.format('YYYY-MM-DD');
      },
      set: function set(key, value) {
        var date = value,
            time = this.time,
            dateString = "".concat(date, "T").concat(time, ":00"),
            datetime = moment(dateString);
        this.set('datetime', datetime);
        return date;
      }
    }),
    time: Ember.computed('datetime', {
      get: function get() {
        return this.datetime.format('HH:mm');
      },
      set: function set(key, value) {
        var date = this.date,
            time = value,
            dateString = "".concat(date, "T").concat(time, ":00"),
            datetime = moment(dateString);
        this.set('datetime', datetime);
        return time;
      }
    })
  });

  _exports.default = _default;
});