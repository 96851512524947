define("bocce/components/side-panel/work", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.scrollCallback = function () {
        if (document.querySelector('.side-panel').scrollTop > 10) {
          $('.side-panel .panel-container.work-panel .late-grading-policy-cta-container').addClass('boxShadow');
        } else {
          $('.side-panel .panel-container.work-panel .late-grading-policy-cta-container').removeClass('boxShadow');
        }
      };

      document.querySelector('.side-panel').addEventListener('scroll', this.scrollCallback);
    },
    willDestroyElement: function willDestroyElement() {
      document.querySelector('.side-panel').removeEventListener('scroll', this.scrollCallback);
    },
    classNames: ['activities', 'work'],
    assignmentsArray: Ember.computed('assignments', function () {
      return this.assignments || [];
    }),
    discussionsArray: Ember.computed('discussions', function () {
      return this.discussions || [];
    }),
    quizzesArray: Ember.computed('quizzes', function () {
      return this.quizzes || [];
    }),
    all: Ember.computed('quizzesArray.[]', 'assignmentsArray.@each.locked', 'discussionsArray.@each.locked', function () {
      var quizzesArray = this.quizzesArray,
          assignmentsArray = this.assignmentsArray,
          discussionsArray = this.discussionsArray;
      var all = [].concat(_toConsumableArray(quizzesArray.toArray()), _toConsumableArray(assignmentsArray.toArray().filter(function (a) {
        return !a.get('locked');
      })), _toConsumableArray(discussionsArray.toArray().filter(function (a) {
        return !a.get('locked');
      })));
      all.sort(function (a, b) {
        return new Date(b.get('sortDate')) - new Date(a.get('sortDate'));
      });
      return all;
    }),
    todo: Ember.computed('all.@each.todo', function () {
      var all = this.all;
      return all.filter(function (item) {
        return item.get('todo');
      });
    }),
    done: Ember.computed('all.@each.todo', function () {
      return this.all.filter(function (item) {
        return !item.get('todo');
      });
    }),
    actions: {
      setShowLateGradingPolicy: function setShowLateGradingPolicy(show) {
        this.set('showLateGradingPolicy', show);
      }
    }
  });

  _exports.default = _default;
});