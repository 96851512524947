define("bocce/controllers/classroom/lessons/conversation-new-with", ["exports", "bocce/controllers/classroom/lessons/conversation"], function (_exports, _conversation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _conversation.default.extend();

  _exports.default = _default;
});