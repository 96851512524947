define("bocce/controllers/classroom/lessons/event-legacy-new", ["exports", "bocce/mixins/discussable", "bocce/mixins/editable", "bocce/mixins/uploadable", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/nested-resources", "bocce/mixins/enmodal"], function (_exports, _discussable, _editable, _uploadable, _audioRec, _videoRec, _rtcRec, _nestedResources, _enmodal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */
  // create the default start time; default
  // is the next half-hour increment
  function defaultTime() {
    var start = moment().tz('America/New_York').add(5, 'minutes');
    start.utcOffset(-5);

    if (start.minutes() < 30) {
      start.startOf('hour').minute(30);
    } else {
      start.startOf('hour').add(1, 'hour');
    }

    return start;
  }

  var _default = Ember.Controller.extend(_discussable.default, _enmodal.default, _editable.default, _uploadable.default, _audioRec.default, _videoRec.default, _rtcRec.default, _nestedResources.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.extraSections = this.extraSections || [];
    },
    application: Ember.inject.controller(),
    discussions: Ember.inject.controller(),
    classroom: Ember.inject.controller(),
    // required by DiscussableMixin
    discussion: Ember.inject.controller('classroom.lessons.discussion'),
    // required by DiscussableMixin
    newDiscussion: Ember.inject.controller('classroom.lessons.discussion-new'),
    // required by DiscussableMixin
    lessons: Ember.inject.controller('classroom.lessons'),
    // required by UploadableMixin
    titleInput: '',
    modalTitle: Ember.computed('session.isInstructor', function () {
      /* eslint-disable-next-line ember/no-get */
      if (this.get('session.isInstructor')) {
        return 'New Live Class';
      } else {
        return 'New Live Session';
      }
    }),
    eventTitle: '',
    frequencyText: 'weeks',
    startDate: defaultTime(),
    sectionDrawerToggled: false,
    readyToSave: Ember.computed('bodyInput', 'titleInput', 'startDate', 'classroom.activeNewEvent', 'isWorking', function () {
      /* eslint-disable-next-line ember/no-jquery */
      return Ember.$.trim(this.titleInput.replace(/&nbsp;/g, ' ').replace(/<!---->/g, ' ')).length > 0 &&
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$.trim(this.bodyInput.replace(/&nbsp;/g, ' ').replace(/<!---->/g, ' ')).length > 0 && this.startDate.isValid() && !this.isWorking;
    }),
    // JRW: Using this observer to show/hide the submit button
    // because some chrome plugins (grammarly) affect ember
    // template rendering.

    /* eslint-disable-next-line ember/no-observers */
    readyToSaveObserver: Ember.observer('bodyInput', 'titleInput', 'startDate', 'classroom.activeNewEvent', function () {
      if (this.readyToSave) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#new-event .editor-save-btn.with-action').removeClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#new-event .editor-save-btn.without-action').addClass('hidden');
      } else {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('#new-event .editor-save-btn.with-action').addClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('#new-event .editor-save-btn.without-action').removeClass('hidden');
      }
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    otherEnrolledSections: Ember.computed(function () {
      // returns the other current-term sections the user is enrolled in

      /* eslint-disable-next-line ember/no-get */
      var filteredCodes = this.get('classroom.otherCurrentTermSections');

      if (filteredCodes.length > 6) {
        /* eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions */
        Ember.run.scheduleOnce('afterRender', this, function () {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.floating-modal#new-event .rte-editor-input').addClass('height-limited');
        });
      }

      return filteredCodes || [];
    }),
    actions: {
      saveEvent: function saveEvent() {
        var _this = this;

        var title = this.titleInput,
            description = this.parseEmbed(this.bodyInput) ? this.parseEmbed(this.bodyInput) : '',

        /* eslint-disable-next-line ember/no-jquery */
        repeat = Ember.$('#event-repeat-checkbox').is(':checked'),

        /* eslint-disable-next-line ember/no-jquery */
        repeatCount = parseInt(Ember.$('#event-repeat-count').val()),
            start_time = this.startDate,
            end_time = moment(start_time).add(1, 'hours'),
            currentCourseId = this.session.get('section.course.id'),
            currentCourse = this.session.get('section.course'),
            announcementTitle,
            announcementMessage,
            announcementDate,
            announcementData,

        /* eslint-disable-next-line ember/no-get */
        usr = this.get('application.user'),
            now = moment().tz('America/New_York'),
            extraSections = this.extraSections,
            otherCourseIds = extraSections.map(function (section) {
          return section.courseId;
        }),
            otherSectionIds = extraSections.map(function (section) {
          return section.sectionId;
        }),
            promises = [];
        this.set('isWorking', true);

        if (start_time.unix() < now.unix()) {
          alert('You can only schedule live classes in the future.');
          return;
        }

        if (!start_time.isValid()) {
          alert('Please choose a valid date for the scheduled live class.');
          return;
        } // Force the value of the time input to avoid DST conversion


        var start_time_from_input = Ember.$("input[type='time']").val();
        start_time = "".concat(start_time.format('YYYY-MM-DD'), "T").concat(start_time_from_input, ":00");
        var start_time_moment = moment(start_time),
            end_time_moment = moment(start_time).add("1", "hours");
        var eventData = {
          title: title,
          description: description ? description : '',
          course: currentCourseId,
          futureEvent: true,
          startAt: start_time_moment.format('YYYY-MM-DDTHH:mm:ss'),
          endAt: end_time_moment.format('YYYY-MM-DDTHH:mm:ss')
        };
        var thisEvent = this.store.createRecord('event', eventData);
        console.log('Event created');
        console.log(thisEvent);
        announcementDate = new Date(start_time);
        announcementTitle = 'Live Class scheduled for ' + start_time_moment.format('MMM, D YYYY h:mm A') + ' ET';
        announcementMessage = '<b>' + title + '</b>';
        announcementMessage += description ? '<br><br>' + description : ''; // Alter the announcement data if it's a repeated event.

        if (repeat) {
          // thisEvent.set("repeatCount", repeatCount-1);
          announcementTitle = 'New Live Classes Scheduled';
          announcementMessage = '<b>' + title + '</b>';
          announcementMessage += description ? '<br><br>' + description : '';
          announcementMessage += '<br><br>';
          announcementMessage += start_time_moment.format('MMM, D YYYY h:mm A') + ' ET<br/>';

          for (var i = 0; i < repeatCount; i++) {
            var alteredDate = moment(start_time);
            alteredDate.add(i + 1, 'w');
            announcementMessage += alteredDate.format('MMM, D YYYY h:mm A') + ' ET';

            if (i !== repeatCount - 1) {
              announcementMessage += '<br>';
            }
          }
        } // Create the announcement if this isn't a private lesson


        announcementData = {
          user: usr,
          date: announcementDate,
          message: announcementMessage,
          title: announcementTitle,
          is_announcement: true
        };
        /* eslint-disable-next-line ember/no-get */

        if (!currentCourse.get('isPrivateLesson') && this.get('session.isInstructor')) {
          var newAnnouncement = this.store.createRecord('discussion', announcementData); // save this section's announcement

          promises.push(newAnnouncement.save()); // post the announcement to any other sections

          promises.push(this.createNestedResources('discussions', {
            discussion: announcementData
          }, 'sections', otherSectionIds));
        } // publish the first event in the current section


        promises.push(thisEvent.save()); // publish the first event in the other sections

        promises.push(this.createNestedResources('events', {
          event: eventData
        }, 'courses', otherCourseIds)); // If repeat was selected, create the repeating events

        if (repeat) {
          for (var _i = 0; _i < repeatCount; _i++) {
            var repeatStartTime = moment(start_time),
                repeatEndTime = moment(start_time),
                repeatData = eventData;
            repeatStartTime = repeatStartTime.add(_i + 1, 'w');
            repeatEndTime = repeatEndTime.add(1, 'h');
            repeatEndTime = repeatEndTime.add(_i + 1, 'w');
            repeatData.startAt = repeatStartTime.format('YYYY-MM-DDTHH:mm:ss');
            repeatData.endAt = repeatEndTime.format('YYYY-MM-DDTHH:mm:ss');
            var repeatEvent = this.store.createRecord('event', repeatData);
            promises.push(repeatEvent.save()); // post the repeating event in the other sections

            promises.push(this.createNestedResources('events', {
              event: eventData
            }, 'courses', otherCourseIds));
          }
        } // once all requests have resolved, clean up


        Promise.all(promises).then(function () {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.activity-filters .filter-action .current').click();

          _this.set('titleInput', '');

          _this.set('bodyInput', '');

          _this.send('destroyEditor');

          var discussionsCtl = _this.discussions;

          if (discussionsCtl.send) {
            discussionsCtl.send('closeModals', true);
          }

          _this.set('isWorking', false);
        }, function (err) {
          Ember.debug(err, 'Save New Event');

          _this.set('uploadInProgress', false);

          _this.set('isWorking', false);

          alert('Failed to schedule event.  Please retry.');
        });
      },
      toggleExtraSection: function toggleExtraSection(section) {
        var extraSections = this.extraSections || [],
            existingIndex = -1;
        extraSections.forEach(function (extraSection, index) {
          if (extraSection.courseId === section.courseId) {
            existingIndex = index;
          }
        });

        if (existingIndex >= 0) {
          extraSections.splice(existingIndex, 1);
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$(".floating-modal .event-multiple-sections .sections-list li.course-".concat(section.courseId)).removeClass('selected');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$(".floating-modal .event-multiple-sections .sections-list li.course-".concat(section.courseId, " i")).addClass('fa-square').removeClass('fa-check-square');
        } else {
          extraSections.push(section);
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$(".floating-modal .event-multiple-sections .sections-list li.course-".concat(section.courseId)).addClass('selected');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$(".floating-modal .event-multiple-sections .sections-list li.course-".concat(section.courseId, " i")).addClass('fa-check-square').removeClass('fa-square');
        }

        this.set('extraSections', extraSections);
      },
      closeEventModal: function closeEventModal() {
        this.set('repBox', false);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.modal-content .title').val('');
        this.set('titleInput', '');
        this.send('closeModals', false);
        this.set('flatpickrFirstOpened', false);
      }
    }
  });

  _exports.default = _default;
});