define("bocce/components/modals/dark-backdrop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dark-backdrop'],
    click: function click() {
      this.action();
    }
  });

  _exports.default = _default;
});