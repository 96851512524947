define("bocce/templates/classroom/lessons/conversation-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3mz94Y1e",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,\"role\",\"dialog\"],[14,\"aria-modal\",\"true\"],[14,\"aria-labelledby\",\"conversation-start-label\"],[14,\"aria-describedby\",\"conversation-start-label\"],[15,0,[31,[\"floating-modal new-conversation active \",[30,[36,1],[[35,0],\"uploading\"],null]]]],[15,1,[31,[[34,2,[\"modalId\"]]]]],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"close-button\"],[24,\"aria-label\",\"Close current message\"],[4,[38,3],[[32,0],\"closeModals\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fas fa-times\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"conversation-start-label\"],[12],[2,\"Start a message with:\"],[13],[2,\"\\n  \"],[8,\"recipient-filter\",[],[[\"@type\",\"@value\",\"@filteredList\",\"@recipients\",\"@session\",\"@viewConversation\",\"@autoComplete\",\"@choose\"],[\"text\",\"recipient.name\",[34,4],[34,5],[34,6],[30,[36,3],[[32,0],\"viewConversation\"],null],[30,[36,3],[[32,0],\"autoComplete\"],null],[30,[36,3],[[32,0],\"choose\"],null]]],null],[2,\"\\n  \"],[11,\"button\"],[24,0,\"start-conversation-btn\"],[24,\"aria-label\",\"Start the conversation\"],[4,[38,3],[[32,0],\"startConversation\"],null],[12],[2,\"Start Message\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"modals/dark-backdrop\",[],[[\"@action\"],[[30,[36,3],[[32,0],\"closeModals\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"uploadInProgress\",\"if\",\"model\",\"action\",\"filteredList\",\"recipients\",\"session\"]}",
    "meta": {
      "moduleName": "bocce/templates/classroom/lessons/conversation-new.hbs"
    }
  });

  _exports.default = _default;
});