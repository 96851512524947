define("bocce/controllers/conversations", ["exports", "bocce/mixins/user-profile", "bocce/mixins/discussable", "bocce/mixins/enmodal", "bocce/mixins/conversable"], function (_exports, _userProfile, _discussable, _enmodal, _conversable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */
  var _default = Ember.Controller.extend(_userProfile.default, _discussable.default, _conversable.default, _enmodal.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.sortProperties = this.sortProperties || ['lastActivity'];
      this.conversationSortRules = this.conversationSortRules || ['lastActivity:desc'];
    },
    classroom: Ember.inject.controller(),
    conversation: Ember.inject.controller('classroom.lessons.conversation'),
    userprofile: Ember.inject.controller(),
    // required by UserProfileMixin
    discussion: Ember.inject.controller('classroom.lessons.discussion'),
    // required by DiscussableMixin
    newDiscussion: Ember.inject.controller('classroom.lessons.discussion-new'),
    // required by DiscussableMixin
    sortAscending: false,
    filteredList: null,
    recipientId: null,
    sortedConversations: Ember.computed.sort('activeConversations', 'conversationSortRules'),
    activeConversations: Ember.computed('model.@each.{workflow_state,conversationPartners}', function () {
      var convos = this.model;

      if (convos) {
        return this.model.filterBy('active', true);
      }

      return [];
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    unreadCountInbox: Ember.computed('model.@each.workflow_state', function () {
      /* eslint-disable-next-line ember/no-get */
      var conversationsLoaded = this.get('classroom.conversationsLoaded') || false;

      if (!conversationsLoaded) {
        /* eslint-disable-next-line ember/no-get */
        return this.get('session.course.numUnreadConversations');
      } else {
        return this.model.filterBy('workflow_state', 'unread').length;
      }
    }),
    actions: {
      closeModals: function closeModals() {
        this._super();

        this.conversation.send('deleteUnsentConversation');
      }
    }
  });

  _exports.default = _default;
});