define("bocce/mixins/support/layout", ["exports", "bocce/mixins/support/util"], function (_exports, util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.box_top = box_top;
  _exports.bounding_box = bounding_box;
  _exports.line_overlaps = line_overlaps;
  _exports.box_overlaps = box_overlaps;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  // Given a list of bounding boxes and the width,
  // height, and left position of a new box, return a height
  // offset that avoids overlaps.
  function box_top(boxes, width, height, left) {
    var rows;

    if (boxes.length === 0) {
      rows = [0];
    } else {
      rows = util.unique(util.sortAsc(boxes.map(function (b) {
        return b.tl.y;
      }))); // Add a final empty row as a catch-all

      rows.push(util.max(boxes.map(function (b) {
        return b.br.y;
      })) + 8);
    }

    var _iterator = _createForOfIteratorHelper(rows),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var row = _step.value;
        var test_box = bounding_box(left, row, width, height),
            invalid = false;

        var _iterator2 = _createForOfIteratorHelper(boxes),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var b = _step2.value;

            if (box_overlaps(b, test_box)) {
              invalid = true;
              break;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }

        if (!invalid) {
          return row;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return 0;
  }

  function bounding_box(left_or_node, top, width, height) {
    var left = left_or_node,
        retval = {
      tl: {},
      br: {}
    };

    if (top === undefined) {
      if (!left.jquery) {
        /* eslint-disable-next-line ember/no-jquery */
        left = Ember.$(left);
      }

      var pos = left.offset(),
          padding = parseInt(left.css('padding-left') || 0, 10);
      retval.tl.x = (pos.left || 0) - padding;
      retval.tl.y = (pos.top || 0) - padding;
      retval.br.x = pos.left + left.width() || 0;
      retval.br.y = pos.top + left.height() || 0;
    } else {
      retval.tl.x = left_or_node;
      retval.tl.y = top;
      retval.br.x = left_or_node + width;
      retval.br.y = top + height;
    }

    return retval;
  }

  function line_overlaps(min1, max1, min2, max2) {
    return max1 >= min2 && max2 >= min1;
  }

  function box_overlaps(b1, b2) {
    return line_overlaps(b1.tl.x, b1.br.x, b2.tl.x, b2.br.x) && line_overlaps(b1.tl.y, b1.br.y, b2.tl.y, b2.br.y);
  }
});