define("bocce/templates/components/lessons/event-new-collapsible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h6RwD2oz",
    "block": "{\"symbols\":[\"@event\",\"@index\",\"@updateDate\",\"@updateTime\",\"@deleteEvent\",\"@toggleShowEventDetails\"],\"statements\":[[10,\"div\"],[14,0,\"collapsible-container\"],[12],[2,\"\\n    \"],[10,\"table\"],[14,0,\"summary-table\"],[15,5,[30,[36,0],[[32,1,[\"shown\"]],\"visibility:hidden;\",\"\"],null]],[12],[2,\"\\n        \"],[10,\"tr\"],[14,0,\"row\"],[12],[2,\"\\n            \"],[10,\"td\"],[15,0,[31,[\"column-title \",[30,[36,0],[[32,1,[\"incomplete\"]],\"incomplete\"],null]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"titleInput\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                    \"],[1,[32,1,[\"titleInput\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                    \"],[10,\"span\"],[14,0,\"empty\"],[12],[2,\"*title\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n            \"],[10,\"td\"],[15,0,[31,[\"date \",[30,[36,0],[[32,1,[\"incomplete\"]],\"incomplete\"],null]]]],[12],[8,\"input\",[[4,[38,2],[\"input\",[30,[36,1],[[32,3],[32,2]],null]],null]],[[\"@type\",\"@value\"],[\"date\",[32,1,[\"startDate_date\"]]]],null],[13],[2,\"\\n            \"],[10,\"td\"],[15,0,[31,[\"time \",[30,[36,0],[[32,1,[\"incomplete\"]],\"incomplete\"],null]]]],[12],[8,\"input\",[[4,[38,2],[\"input\",[30,[36,1],[[32,4],[32,2]],null]],null]],[[\"@type\",\"@value\"],[\"time\",[32,1,[\"startDate_time\"]]]],null],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"delete fa fas fa-trash\"],[4,[38,2],[\"click\",[32,5]],null],[12],[13],[2,\"\\n    \"],[11,\"button\"],[16,0,[31,[\"expand collapsible-caret fa-lg fas \",[30,[36,0],[[32,1,[\"shown\"]],\"fa-caret-down\",\"fa-caret-right\"],null]]]],[4,[38,2],[\"click\",[32,6]],null],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/lessons/event-new-collapsible.hbs"
    }
  });

  _exports.default = _default;
});