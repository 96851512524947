define("bocce/mixins/support/local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function getNamespace(id) {
    return window.location.hash.split('/').slice(1, 3) + '#' + id;
  }

  var _default = {
    saveData: function saveData(id, data) {
      if (_typeof(data) === 'object') {
        data = JSON.stringify(data);
      }

      localStorage.setItem(getNamespace(id), data);
    },
    getData: function getData(id) {
      var data = localStorage.getItem(getNamespace(id));

      if (!data) {
        return undefined;
      } else {
        try {
          return JSON.parse(data);
        } catch (e) {
          Ember.debug(e);
        } // if data isn't a parseable json object...


        return data;
      }
    }
  };
  _exports.default = _default;
});