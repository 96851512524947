define("bocce/models/event", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _model.default.extend({
    title: (0, _model.attr)('string'),
    course: (0, _model.belongsTo)('course', {
      async: true
    }),
    startAt: (0, _model.attr)(),
    endAt: (0, _model.attr)(),
    description: (0, _model.attr)('string'),
    hidden: (0, _model.attr)(),
    repeatCount: (0, _model.attr)(),
    privateLessonStudentId: (0, _model.attr)(),
    user: (0, _model.belongsTo)('user', {
      async: true
    }),
    meetingKeys: (0, _model.attr)(),
    meetings: (0, _model.attr)(),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isEmpty: Ember.computed('description', 's3Recordings', function () {
      if (this.description || this.s3Recordings || this.kalturaRecordings) {
        return false;
      } else {
        return true;
      }
    }),
    s3Recordings: (0, _model.attr)(),
    kalturaRecordings: (0, _model.attr)(),
    is_updated: (0, _model.attr)('boolean'),
    isStudentChat: (0, _model.attr)('boolean'),
    isStudentChatCurrentlyRunning: Ember.computed('meetingKeys', 'session.studentMeetingKeys', function () {
      var studentChats = window.session.get('studentMeetingKeys');

      if (!studentChats || studentChats.length == 0) {
        return false;
      }

      var meetingKeys = this.meetingKeys;

      if (meetingKeys) {
        var _iterator = _createForOfIteratorHelper(this.meetingKeys),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var meetingKey = _step.value;

            var _iterator2 = _createForOfIteratorHelper(studentChats),
                _step2;

            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var studentChat = _step2.value;

                if (studentChat.meeting_key === meetingKey) {
                  return true;
                }
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }

      return false;
    }),
    currentUserIsOwner: Ember.computed('user', function () {
      var currentUser = window.session.get('user.id');

      if (this.user.get('id') !== currentUser) {
        return false;
      } else {
        return true;
      }
    }),
    startedBy: Ember.computed('user', function () {
      var eventUser = this.get('user');
      return eventUser.get('name');
    }),
    shortDescription: Ember.computed('description', function () {
      var description = this.description || '';
      var maxLength = 20;

      if (description.length > maxLength) {
        var trimmedDescription = description.substring(0, maxLength);
        return trimmedDescription = trimmedDescription.substr(0, Math.min(trimmedDescription.length, trimmedDescription.lastIndexOf(' ')));
      } else {
        return description;
      }
    }),
    formattedStartTime: Ember.computed('startAt', function () {
      var startAt = moment(this.startAt);
      return startAt.tz('America/New_York').format('MMMM Do YYYY, h:mm a') + ' ET';
    }),
    timeOnlyStartTime: Ember.computed('startAt', function () {
      var startAt = moment(this.startAt);
      return startAt.tz('America/New_York').format('h:mm a') + ' ET';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    runnable: Ember.computed('startAt', 'session.time', function () {
      var now = moment();
      return moment(this.startAt) <= now + 10 * 60 * 1000 && moment(this.endAt) >= now;
    }),
    startsWithinAWeek: Ember.computed('startAt', function () {
      var now = moment();
      return moment(this.startAt) <= now + 1000 * 60 * 60 * 24 * 7;
    })
  });

  _exports.default = _default;
});