define("bocce/mixins/prefs", ["exports", "bocce/helpers/upload", "bocce/helpers/image", "bocce/utilities/dialog"], function (_exports, _upload, _image, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create({
    prefChangesMade: Ember.computed('fontChanged', 'showcasePermChanged', 'resubPermChanged', 'bioChanged', 'avatarUrl', 'locChanged', 'lateGradingPolicyChanged', function () {
      var ret = this.lateGradingPolicyChanged || this.showcasePermChanged || this.resubPermChanged || this.bioChanged || this.locChanged || this.avatarUrl !== '' || this.fontChanged;
      return ret;
    }),

    /* eslint-disable-next-line ember/no-observers */
    avatarChanged: Ember.observer('newAvatar', function () {
      var _this = this;

      /* eslint-disable-next-line ember/no-jquery */
      var input = Ember.$('.avatar-upload').get(0);

      if (input && input.files && input.files[0]) {
        this.set('avatarUploading', true);

        _image.BocceImage.from_file(input.files[0]).then(function (img) {
          img.crop_to_square(300).then(function () {
            img.to_blob().then(function (blob) {
              blob.name = 'avatar.png';
              /* eslint-disable-next-line ember/no-get */

              (0, _upload.default)(blob, _this.get('session.user.id'), _this.store, 'my files/profile photos', true).then(function (fi_url) {
                _this.set('avatarUrl', fi_url);

                _this.set('avatarUploading', false);

                input.value = '';
              });
            });
          });
        });
      }
    }),
    avatarUrl: Ember.computed(function () {
      return '';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    prefBio: Ember.computed(function () {
      /* eslint-disable-next-line ember/no-get */
      var existingBio = this.get('session.user.profile.bio');
      return existingBio || '';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    bioChanged: Ember.computed('prefBio', function () {
      /* eslint-disable-next-line ember/no-get */
      var existingBio = this.get('session.user.profile.bio'),
          prefBio = this.prefBio;

      if (prefBio && prefBio !== '' && existingBio !== prefBio) {
        return prefBio;
      }

      return false;
    }),
    lateGradingPolicy: Ember.computed('session.lateGradingPolicy', function () {
      return this.get('session.lateGradingPolicy') || '';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    lateGradingPolicyChanged: Ember.computed('lateGradingPolicy', function () {
      /* eslint-disable-next-line ember/no-get */
      var existingLateGradingPolicy = this.get('session.lateGradingPolicy'),
          lateGradingPolicy = this.lateGradingPolicy;
      return existingLateGradingPolicy !== lateGradingPolicy;
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    prefLoc: Ember.computed(function () {
      /* eslint-disable-next-line ember/no-get */
      var existingLoc = this.get('session.user.profile.location');
      return existingLoc || '';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    locChanged: Ember.computed('prefLoc', function () {
      /* eslint-disable-next-line ember/no-get */
      var existingLoc = this.get('session.user.profile.location'),
          prefLoc = this.prefLoc;

      if (prefLoc && prefLoc !== '' && existingLoc !== prefLoc) {
        return prefLoc;
      }

      return false;
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    resubPermChanged: Ember.computed(function () {
      /* eslint-disable-next-line ember/no-get */
      if (this.get('session.user.profile.can_resubmit')) {
        /* eslint-disable-next-line ember/no-get */
        return this.get('session.user.profile.can_resubmit')[this.get('session.section.id')];
      }

      return 'yes';
    }),
    actions: {
      togglePrefPanel: function togglePrefPanel(close) {
        if (close) {
          this.set('prefPanelOn', false);
          /* eslint-disable-next-line ember/no-get */

          this.set('prefBio', this.get('session.user.profile.bio') || '');
          /* eslint-disable-next-line ember/no-get */

          this.set('prefLoc', this.get('session.user.profile.location') || '');
          this.set('showcasePermChanged', false);
          this.set('fontChanged', false);
          this.send('removeAvatar');
          this.set('newAvatar', '');
        } else {
          this.set('newAvatar', '');
          this.set('prefPanelOn', true);
        }
      },
      removeAvatar: function removeAvatar() {
        this.set('avatarUrl', false);
        this.set('newAvatar', '');
      },
      changeFont: function changeFont(value) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.pref-dialog .pref-content .prefs li .font-size').removeClass('checked');
        this.set('fontChanged', value);
      },
      adjustFont: function adjustFont(size) {
        /* eslint-disable-next-line ember/no-jquery */
        var fontSetter = Ember.$('.font-setter');
        fontSetter.find('.fa-font').removeClass('active');

        if (size === 'small') {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('body').css('font-size', '12px');
          fontSetter.find('.font-setter-small').addClass('active');
        } else if (size === 'medium') {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('body').css('font-size', '14px');
          fontSetter.find('.font-setter-medium').addClass('active');
        } else if (size === 'large') {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('body').css('font-size', '20px');
          fontSetter.find('.font-setter-large').addClass('active');
        }
      },
      changeShowcasePerm: function changeShowcasePerm(value) {
        this.set('showcasePermChanged', value);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.pref-dialog .pref-content .prefs li .showcase-perm').removeClass('checked');
        (0, _dialog.default)('NOTE: This change will only apply to future showcases. All currently showcased posts will remain showcased.', ['I Understand']);
      },
      changeResubPerm: function changeResubPerm(value) {
        this.set('resubPermChanged', value);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.pref-dialog .pref-content .prefs li .resubmit-perm').removeClass('checked');
      },
      changeAvatar: function changeAvatar(value) {
        this.set('avatarChanged', value);
      },
      savePrefs: function savePrefs() {
        var prefs = {},
            that = this,
            set = false,

        /* eslint-disable-next-line ember/no-get */
        can_resubmit_col = this.get('session.user.profile.can_resubmit') || {};
        /* eslint-disable-next-line ember/no-get */

        if (this.get('session.user.profile')) {
          /* eslint-disable-next-line ember/no-get */
          prefs = this.get('session.user.profile');
          set = true;
        }

        if (!set && this.bioChanged) {
          prefs.bio = this.prefBio;
        } else if (set && this.bioChanged) {
          Ember.set(prefs, 'bio', this.prefBio);
        }

        if (!set && this.locChanged) {
          prefs.location = this.prefLoc;
        } else if (set && this.locChanged) {
          Ember.set(prefs, 'location', this.prefLoc);
        }

        if (!set && this.resubPermChanged) {
          /* eslint-disable-next-line ember/no-get */
          can_resubmit_col[this.get('session.section.id')] = this.resubPermChanged;
          prefs.can_showcase = can_resubmit_col;
        } else if (set && this.resubPermChanged) {
          /* eslint-disable-next-line ember/no-get */
          can_resubmit_col[this.get('session.section.id')] = this.resubPermChanged;
          Ember.set(prefs, 'can_resubmit', can_resubmit_col);
        }

        if (!set && this.showcasePermChanged) {
          prefs.can_showcase = this.showcasePermChanged;
        } else if (set && this.showcasePermChanged) {
          Ember.set(prefs, 'can_showcase', this.showcasePermChanged);
        }

        if (!set && this.fontChanged) {
          prefs.font_size = this.fontChanged;
        } else if (set && this.fontChanged) {
          Ember.set(prefs, 'font_size', this.fontChanged);
        }
        /* eslint-disable-next-line ember/no-get */


        this.store.findRecord('user', this.get('session.user.id')).then(function (record) {
          record.set('profile_updated', true);

          if (that.lateGradingPolicyChanged) {
            var courseCodeWithoutSection = that.get('session.courseCodeWithoutSection'); //the profile/bio won't get saved in canvas with the late grading policy. This 
            //object is just used to carry the data to interface. Interface will then 
            //save the late grading policy and then delete the late grading policy
            //from profile/bio.

            prefs['lateGradingPolicy'] = _defineProperty({}, courseCodeWithoutSection, that.lateGradingPolicy);
          }

          record.set('profile', prefs);

          if (that.get('avatarUrl') && that.get('avatarUrl') !== '') {
            record.set('image_url', that.get('avatarUrl'));
          }

          record.save();
          that.send('togglePrefPanel', true);

          if (prefs.font_size) {
            that.send('adjustFont', prefs.font_size);
          }
        });
      }
    }
  });

  _exports.default = _default;
});