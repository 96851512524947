define("bocce/components/notification-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['notification'],
    attributeBindings: ['role', 'ariaLabel:aria-label'],
    role: 'button',
    ariaLabel: 'Go to selected notification',
    actions: {
      click: function click() {
        var type = this.notification.type;
        var id = this.notification.notification_id;

        if (this.action) {
          this.action(type, id); // action defined by parent template
        } else {
          Ember.debug('click action is not supplied on NotificationItem');
        }
      },
      // @dismissAction must be defined in the parent!
      dismiss: function dismiss() {
        if (this.dismissAction) {
          var id = this.notification.notification_id;
          this.dismissAction(id);
        } else {
          Ember.debug('dismissAction is not defined.');
        }
      }
    }
  });

  _exports.default = _default;
});