define("bocce/models/poll_submission", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/models/poll_submission.js
  var _default = _model.default.extend({
    poll_choice_id: (0, _model.attr)(),
    user_id: (0, _model.belongsTo)('user', {
      async: true
    }),
    created_at: (0, _model.attr)()
  });

  _exports.default = _default;
});