define("bocce/templates/components/polls/poll-drawer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AU6KaibI",
    "block": "{\"symbols\":[\"pollChoice\",\"index\"],\"statements\":[[10,\"ul\"],[14,0,\"poll-options\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[2,\"\\n      \"],[8,\"input\",[[24,\"required\",\"true\"],[24,0,\"poll-option-txt\"],[24,\"placeholder\",\"Poll option\"]],[[\"@type\",\"@value\",\"@aria-required\"],[\"text\",[32,1,[\"text\"]],\"false\"]],null],[2,\"\\n      \"],[10,\"div\"],[14,0,\"counter\"],[12],[1,[32,1,[\"remainingChars\"]]],[13],[2,\"\\n      \"],[11,\"button\"],[24,0,\"poll-option-del\"],[4,[38,0],[[32,0],\"clearPollChoice\",[32,2]],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"far fa-times-circle\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[35,1,[\"length\"]],10],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[24,0,\"poll-add-entry\"],[4,[38,0],[[32,0],\"addPollEntries\"],null],[12],[2,\"+\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"pollChoices\",\"-track-array\",\"each\",\"morethan\",\"unless\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/polls/poll-drawer.hbs"
    }
  });

  _exports.default = _default;
});