define("bocce/mixins/legacy-attachment-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create({
    files: [],
    _addFile: function _addFile(file, valid) {
      var extension = file.name.substr((~-file.name.lastIndexOf('.') >>> 0) + 2);
      var className = "extension-".concat(extension).toLowerCase();
      var record = {
        file: file,
        valid: valid,
        className: className,
        uploaded: false,
        deleted: false
      };
      this.files.pushObject(record);
      return record;
    },
    actions: {
      addValidFile: function addValidFile(file) {
        return this._addFile(file, true);
      },
      addInvalidFile: function addInvalidFile(file) {
        return this._addFile(file, false);
      },
      clearAllFiles: function clearAllFiles() {
        this.setProperties({
          'files': [],
          'file_ids': [],
          'promises': []
        });
      },
      deleteFile: function deleteFile(file) {
        var fileRec = this.files.findBy('file', file);
        var ids = this.file_ids;
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('audio, video').each(function () {
          this.pause();
        });
        ids.removeObject(ids.findBy('id', fileRec.uploaded_id));
        Ember.set(fileRec, 'deleted', true);
        return file;
      },
      undeleteFile: function undeleteFile(file) {
        var fileRec = this.files.findBy('file', file);
        Ember.set(fileRec, 'deleted', false);

        if (fileRec.uploaded_id) {
          this.file_ids.pushObject({
            'id': fileRec.uploaded_id
          });
        }

        return file;
      }
    }
  });

  _exports.default = _default;
});