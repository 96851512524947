define("bocce/components/assignment/assignment-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.sortProperties = this.sortProperties || ['due_at'];
    },
    tagName: 'ul',
    classNames: 'assignment-list',
    sortedAssignments: Ember.computed.sort('assignments', 'sortProperties'),
    actions: {
      sendGoToAssignment: function sendGoToAssignment(id) {
        this.goToAssignment(id);
      }
    }
  });

  _exports.default = _default;
});