define("bocce/routes/classroom/lessons", ["exports", "bocce/mixins/discussable", "bocce/mixins/recent-lesson-redirect"], function (_exports, _discussable, _recentLessonRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */
  var _default = Ember.Route.extend(_discussable.default, _recentLessonRedirect.default, {
    router: Ember.inject.service(),
    scrollThrough: false,
    shouldRenderLoading: true,
    mainPanelHalfHeight: false,
    discussionsLoaded: false,
    beforeModel: function beforeModel(transition) {
      // Look and see if we have the pages for this lesson already loaded.
      // If not, make a reload call to /courses/NN/lessons/MM?all=1 to
      // get all the data in a single go.
      var params = transition.to.find(function (ri) {
        return ri.name === 'classroom.lessons';
      }).params;

      if (parseInt(params.lesson_id) === -1) {
        // if lesson_id is -1, get the most recent lesson the user viewed
        return this.getRecentLessonInfo().then(function (i) {
          params.lesson_id = i.lesson_id;
          params.item_id = i.item_id;
        });
      }

      var lesson = this.store.peekRecord('lesson', params.lesson_id),
          needs_reload_p = true;

      if (!lesson) {
        // If we didn't find the lesson, that could mean that we haven't
        // loaded the data at all yet, or that lesson doesn't exist.
        var all_lessons = this.store.peekAll('lesson');
        /* eslint-disable-next-line ember/no-get */

        if (all_lessons.get('length') > 0) {
          lesson = all_lessons.objectAt(0);
          /* eslint-disable-next-line ember/no-get */

          params.lesson_id = lesson.get('id');
        }
      } else {
        /* eslint-disable-next-line ember/no-get */
        var item = lesson.get('items').objectAt(0);

        if (item) {
          /* eslint-disable-next-line ember/no-get */
          needs_reload_p = !this.store.peekRecord(item.get('type').toLowerCase(), item.get('id'));
          /* eslint-disable-next-line ember/no-get */

          Ember.debug('Checking ' + item.get('type').toLowerCase(), item.get('id'), needs_reload_p);
        }
      }

      if (needs_reload_p) {
        Ember.debug('Reloading lesson ' + params.lesson_id);
        return this.store.findRecord('lesson', params.lesson_id, {
          adapterOptions: {
            all: 1
          },
          reload: true
        });
      }
    },
    model: function model(params) {
      var _this = this;

      var lesson_id = params.lesson_id;
      var item_id = params.item_id;
      var lessonPromise = this.store.findRecord('lesson', lesson_id);
      var course_id = this.paramsFor('classroom').course_id;
      var itemPromise = lessonPromise.then(function (lesson) {
        _this.store.nestResources('item', [{
          course: course_id
        }, {
          lesson: lesson_id
        }]); // The item should already be loaded at this point, since this
        // follows the lesson promise


        var item = _this.store.peekRecord('item', item_id);

        if (!item) {
          /* eslint-disable-next-line ember/no-get */
          return lesson.get('items').objectAt(0);
        }

        return item;
      });
      this.updateLessonInfo(lesson_id, item_id);
      var topicsPromise = lessonPromise.then(function (lesson) {
        /* eslint-disable-next-line ember/no-get */
        return Promise.all(lesson.get('items').map(function (item) {
          /* eslint-disable-next-line ember/no-get */
          var type = item.get('type').toLowerCase();
          /* eslint-disable-next-line ember/no-get */

          var id = item.get('id');
          return _this.store.findRecord(type, id);
        }));
      });
      return Ember.RSVP.hash({
        course: this.store.findRecord('course', course_id),
        lesson: lessonPromise,
        item: itemPromise,
        topics: topicsPromise,
        session: {
          panel: {
            activity: true
          }
        }
      });
    },
    afterModel: function afterModel() {
      var _this2 = this;

      var that = this,
          st = this.scrollThrough;

      if (this.paramsFor('classroom.lessons').item_id === '0') {
        /* eslint-disable-next-line ember/no-get */
        this.modelFor('classroom.lessons').lesson.get('items').then(function (items) {
          /* eslint-disable-next-line ember/no-get */
          var firstItem = items.get('currentState')[0];

          if (firstItem) {
            that.transitionTo('classroom.lessons', 0, firstItem.id);
          }
        });
      }

      if (st) {
        this.set('scrollThrough', false);
      } else {
        if (document.documentElement.clientWidth < 1034) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.classroom').removeClass('active');
        }
      }

      this.controllerFor('classroom.lessons').set('interactionsBooted', true);

      var position_sidepanel = function position_sidepanel() {
        var $n,
            $sp,
            dest,
            retry_p = false;

        if (!st) {
          /* eslint-disable-next-line ember/no-jquery */
          $sp = Ember.$('.side-panel');
          var activeLink = $sp.find('a.active');

          if (activeLink.length > 0) {
            dest = activeLink.position().top - 100;

            if (dest < 0) {
              dest = 0;
              retry_p = true;
            }
          }

          var visibleActiveLink = $sp.find('a.active:in-viewport');

          if (visibleActiveLink.length === 0 && activeLink.length > 0) {
            $sp.scrollTop(activeLink.position().top - $sp.height() + 100 + $sp.scrollTop());
          }

          var contentNode = _this2.controller.getItemNode();

          if (contentNode.length > 0) {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.main-panel').scrollTop(0).scrollTop(contentNode.position().top - 100);
          }
        } else {
          /* eslint-disable-next-line ember/no-jquery */
          $n = Ember.$('.side-panel a.active:in-viewport');

          if ($n.length === 0 || $n.position().top < 50) {
            /* eslint-disable-next-line ember/no-jquery */
            $sp = Ember.$('.side-panel');
            /* eslint-disable-next-line ember/no-jquery */

            $n = Ember.$('.side-panel a.active');

            if ($n.length > 0) {
              $sp.scrollTop($n.position().top - $sp.height() + 100 + $sp.scrollTop());
            }
          }
        } // Sometimes while loading all LinkTo components have the .active class. Putting this class on the syllabus
        // lets us keep the syllabus items from all being red while still loading lesson content.


        _this2.set('session.lessonLoaded', true); // If we're still loading, wait a second and try again

        /* eslint-disable-next-line ember/no-jquery */


        if (retry_p && Ember.$('.loading-mask').is(':visible')) {
          Ember.run.later(_this2, position_sidepanel, 1000);
        }
      };

      Ember.run.scheduleOnce('afterRender', this, position_sidepanel);
    },
    renderTemplate: function renderTemplate() {
      this.render();
      var discussionsController = this.controllerFor('discussions'),
          assignments = this.store.findAll('assignment');
      discussionsController.set('loadedAssignments', assignments);
      this.set('discussionsLoaded', true);
    },
    updateLessonInfo: function updateLessonInfo(lessonId, itemId) {
      this.session.setProperties({
        lessonId: lessonId,
        itemId: itemId
      });
    },
    actions: {
      updatePath: function updatePath() {
        /* eslint-disable-next-line ember/no-jquery */
        var allInViewport = Ember.$('li.lesson-content:in-viewport'),

        /* eslint-disable-next-line ember/no-jquery */
        $reset = Ember.$('.main-panel.reset'),
            content = this.currentModel.item.id,
            inViewport,
            new_id,
            contentNode;
        inViewport = allInViewport.eq(1);

        if (!this.mainPanelHalfHeight) {
          /* eslint-disable-next-line ember/no-jquery */
          this.set('mainPanelHalfHeight', Ember.$('.main-panel').height() / 2);
        }

        if (inViewport.length === 0 || inViewport.position().top >= this.mainPanelHalfHeight) {
          inViewport = allInViewport.first();
        }

        if (inViewport) {
          new_id = inViewport.attr('id');
          var itemChanged = new_id !== content;
          var hasReset = $reset.length > 0;
          /* eslint-disable-next-line ember/no-get */

          var currentRouteName = this.get('router.currentRouteName');
          var regex = /^classroom\.lessons(\.index)?$/;
          var onLessons = regex.test(currentRouteName);
          /* eslint-disable-next-line ember/no-jquery */

          var loadingMask = Ember.$('.visible .loading-mask').length > 0;

          if ((itemChanged || hasReset) && onLessons && !loadingMask) {
            if ($reset.length === 0) {
              this.set('scrollThrough', true);
              this.set('shouldRenderLoading', false);
              this.transitionTo('classroom.lessons', new_id);
            } else {
              /* eslint-disable-next-line ember/no-jquery */
              Ember.$('.main-panel.reset').removeClass('reset');
              /* eslint-disable-next-line ember/no-jquery */

              contentNode = Ember.$('#' + content);

              if (contentNode.length > 0) {
                /* eslint-disable-next-line ember/no-jquery */
                Ember.$('.main-panel').scrollTop(0).scrollTop(contentNode.position().top - 100);
              }
            }
          }
          /* eslint-disable-next-line ember/no-jquery */


          if (!Ember.$.isMobile) {
            /* eslint-disable-next-line ember/no-jquery */
            this.controller.boot_area(Ember.$('#' + new_id), false, true, true, true, true);
          }
          /* eslint-disable-next-line ember/no-jquery */


          Ember.$('#' + new_id).find('img').not('.img_booted').each(function (key, img_node) {
            if (img_node.attributes['data-src']) {
              img_node.setAttribute('src', img_node.attributes['data-src'].value);
              img_node.classList.add('img_booted');
            }
          });
        }
      },
      resetHeight: function resetHeight() {
        var content, contentNode; // Reset the current half-height marker
        // Also make sure scroll-spy is caught up

        if (this.mainPanelHalfHeight) {
          /* eslint-disable-next-line ember/no-jquery */
          this.set('mainPanelHalfHeight', Ember.$('.main-panel').height() / 2);
        } // Only mess with scroll-spy if user isn't scrolling


        if (!this.amScrolling) {
          content = this.paramsFor('classroom.lessons').item_id;
          content = content.replace('=', '\\=');
          /* eslint-disable-next-line ember/no-jquery */

          contentNode = Ember.$('#' + content);

          if (contentNode.length > 0) {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.main-panel').scrollTop(0).scrollTop(contentNode.position().top - 10);
          }
        }
      },
      bookmarkTopic: function bookmarkTopic() {
        var itemFromModel = this.currentModel.item,
            bookmarked = !itemFromModel.bookmarked;
        Ember.set(itemFromModel, 'bookmarked', bookmarked);
      },
      completeTopic: function completeTopic() {
        var itemFromModel = this.currentModel.item,
            completed = !itemFromModel.completed;
        Ember.set(itemFromModel, 'completed', completed);
      },
      willTransition: function willTransition() {
        // Clean up on transition between lessons
        if (this.shouldRenderLoading) {
          //Clean up video and audio player

          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('audio').each(function () {
            this.pause();
          });
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('video').each(function () {
            this.pause();
          });
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.audio-player').removeClass('active');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.video-player').removeClass('active').addClass('minified');
        }
      },
      loading: function loading(transition) {
        var _this3 = this;

        Ember.debug("LessonsRoute.shouldRenderLoading: ".concat(this.shouldRenderLoading));

        if (!this.shouldRenderLoading) {
          transition.then(function () {
            _this3.set('shouldRenderLoading', true);
          });
        } // When loading returns false, the loading template does not
        // render. This lets the transition take place (updating the URL
        // and link-to active states) without disrupting the user
        // scroll/interaction with the lesson


        return this.shouldRenderLoading;
      }
    }
  });

  _exports.default = _default;
});