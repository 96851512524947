define("bocce/components/user-portrait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['portrait'],
    attributeBindings: ['ariaHidden:aria-hidden', 'ariaLabel:aria-label'],
    ariaHidden: 'false',
    ariaLabel: Ember.computed('user.name', function () {
      /* eslint-disable-next-line ember/no-get */
      return "View profile of ".concat(this.get('user.name'));
    }),
    click: function click() {
      if (this.action) {
        this.action();
      }
    }
  });

  _exports.default = _default;
});