define("bocce/mixins/editable", ["exports", "bocce/mixins/embed-parser", "bocce/helpers/upload", "sanitize-html", "bocce/utilities/dialog"], function (_exports, _embedParser, _upload, _sanitizeHtml, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function sanitize(string) {
    var sanitizeHtmlOptions = {
      // options for the sanitize-html package. see docs:
      // https://www.npmjs.com/package/sanitize-html#htmlparser2-options
      allowedTags: _sanitizeHtml.default.defaults.allowedTags.concat(['h2', 'u']),
      transformTags: {
        'h1': 'h2',
        // Word's header is h1, but our header is h2
        'a': function a(tagName, attribs) {
          // filter out useless links because apparently the geniuses
          // at Microsoft decided to start including THOSE now
          if (!attribs['href'] || attribs['href'] === '') {
            return false;
          } else {
            return {
              tagName: tagName,
              attribs: {
                href: attribs.href
              }
            };
          }
        }
      }
    };
    return (0, _sanitizeHtml.default)(string, sanitizeHtmlOptions);
  }
  /* eslint-disable-next-line ember/no-new-mixins */


  var _default = Ember.Mixin.create(_embedParser.default, {
    inEditor: false,
    bodyInput: '',
    replyText: '',
    replyAuthor: '',
    file_ids: [],
    encoding_videos: [],
    replyId: false,
    postable: Ember.computed('bodyInputHasContent', 'processInProgress', 'model', function () {
      var bodyInput = this.bodyInputHasContent;
      return bodyInput && !this.processInProgress;
    }),
    mobileUpload: Ember.computed(function () {
      /* eslint-disable-next-line ember/no-jquery */
      return Ember.$.isMobile;
    }),
    totalFiles: Ember.computed('files.@each.deleted', function () {
      var length = this.files.filterBy('deleted', false).filterBy('valid', true).length;

      if (length === 0) {
        length = false;
      }

      return length;
    }),
    videoEmbedString: Ember.computed('files.@each.deleted', function () {
      var embeds = this.files.filterBy('deleted', false).filterBy('valid', true),
          string = '',
          i;

      for (i = 0; i < embeds.length; i++) {
        if (embeds[i].file.isUrl) {
          string += '<div class=\'video-container\'>';
          string += '<video class=\'post-video-embed\' preload=\'none\' controls controlsList=\'nodownload\'>';
          string += '<source src=\'' + embeds[i].file.url + '\' type=\'' + embeds[i].file.type + '\'>';
          string += '<source src=\'/front_end/images/video_processing_placeholder.mp4\'>';
          string += '</video>';
          string += '<div class="sub-time"><i class="far fa-clock"></i> ' + embeds[i].file.created_at_formatted + '</div>';
          string += '</div>';
        }
      }

      return string;
    }),
    localArchive: Ember.computed('files.[]', function () {
      var array, obj, faved, unfaved;

      if (!localStorage.localArchive || localStorage.localArchive.length === 0) {
        return [];
      }

      try {
        obj = JSON.parse(localStorage.localArchive);
      } catch (err) {
        obj = {};
      }
      /* eslint-disable-next-line ember/no-jquery */


      array = Ember.$.map(obj, function (value) {
        return [value];
      });
      array.reverse();
      /* eslint-disable-next-line ember/no-jquery */

      faved = Ember.$.grep(array, function (e) {
        return e.isFaved === true;
      });
      /* eslint-disable-next-line ember/no-jquery */

      unfaved = Ember.$.grep(array, function (e) {
        return e.isFaved !== true;
      });
      array = faved.concat(unfaved);
      return array;
    }),
    localDocs: Ember.computed(function () {
      var array, obj, faved, unfaved;

      if (!localStorage.localDocs || localStorage.localDocs.length === 0) {
        return [];
      }

      try {
        obj = JSON.parse(localStorage.localDocs);
      } catch (err) {
        obj = {};
      }
      /* eslint-disable-next-line ember/no-jquery */


      array = Ember.$.map(obj, function (value) {
        return [value];
      });
      array.reverse();
      /* eslint-disable-next-line ember/no-jquery */

      array = Ember.$.grep(array, function (e) {
        return e.name !== 'Auto Save';
      });
      /* eslint-disable-next-line ember/no-jquery */

      faved = Ember.$.grep(array, function (e) {
        return e.isFaved === true;
      });
      /* eslint-disable-next-line ember/no-jquery */

      unfaved = Ember.$.grep(array, function (e) {
        return e.isFaved !== true;
      });
      array = faved.concat(unfaved);
      return array;
    }),
    currentModel: Ember.computed.reads('model'),
    working: Ember.computed('files.@each.uploaded', function () {
      return this.files.filterBy('uploaded', false).length > 0;
    }),

    /* eslint-disable-next-line ember/no-observers */
    destroyer: Ember.observer('model', function () {
      if (!this.model) {
        if (this.files) {
          this.send('clearAllFiles');
        }

        this.send('destroyEditor');
      }
    }),
    // Grabs up files and uploads them immediately
    // Shoves returned ID into the fileIDs array

    /* eslint-disable-next-line ember/no-observers */
    uploadFiles: Ember.observer('files.[]', function () {
      var files = this.files;

      if (!this.store) {
        return;
      }

      var _iterator = _createForOfIteratorHelper(files),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var f = _step.value;
          this.uploadOneFile(f);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }),
    uploadOneFile: function uploadOneFile(f) {
      var _this = this;

      if (!f || f.uploading || f.uploaded) {
        return;
      }

      var ftype = f.file.type.split('/')[0];
      f.uploaded_name = f.uploaded_name || f.file.name;
      var videoUploadCheck;

      if (ftype === 'video' && f.file.type !== 'video/mp4') {
        videoUploadCheck = (0, _dialog.default)('This file will be available for download only. Continue uploading or upload via video tool?', ['Continue uploading', 'Use video tool']);
      } else {
        videoUploadCheck = Promise.resolve('Continue uploading');
      }

      videoUploadCheck.then(function (choice) {
        if (choice === 'Use video tool') {
          _this.send('toggleCameraPanel', false);

          _this.files.removeObject(f);
        } else if (choice === 'Continue uploading') {
          if (!f.uploading && f.valid) {
            _this.set('processInProgress', true);

            Ember.debug('Uploading ' + f.file.name);
            Ember.set(f, 'uploading', true);

            if (f.file.isUrl) {
              // File is a CameraTag Embed
              _this.set('processInProgress', false);

              Ember.set(f, 'uploading', false);
              Ember.set(f, 'uploaded', true);
              return;
            }

            (0, _upload.default)(f.file, _this.session.get('user.id'), _this.store,
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$('.existing-conversation').hasClass('active') ? 'conversation attachments' : null, null, function (progress) {
              Ember.set(f, 'percent_uploaded', progress);
            }).then(function (att_id) {
              return _this.store.findRecord('attachment', att_id).then(function () {
                if (f && _this.files.findBy('file', f.file)) {
                  var ids = _this.file_ids;
                  ids.push({
                    id: att_id
                  });

                  _this.set('file_ids', ids);

                  _this.set('processInProgress', false);

                  Ember.set(f, 'uploading', false);
                  Ember.set(f, 'uploaded', true);
                  Ember.set(f, 'uploaded_id', att_id);
                }
              });
            }, function (err) {
              Ember.debug(err, 'Could not upload file');
              alert("\"".concat(f.file.name, "\" failed to upload.  Please retry."));
              this.files.removeObject(f);
            }.bind(f));
          }
        }
      });
    },
    filesUploading: Ember.computed('files.@each.uploading', function () {
      var uploadingFiles = this.files ? !!this.files.findBy('uploading', true) : false;
      return uploadingFiles;
    }),
    urlize: function urlize(str) {
      return str.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]+/g, '_').toLowerCase();
    },
    bodyInputHasContent: Ember.computed('bodyInput', function () {
      // NK note to future self: Needing to handle all this at this stage
      // is ugly, but it gets the job done. A friendlier interface will
      // be to abstract away the sanitization/cleanup code within an
      // rte-editor Component, so that the Controller can just interact
      // with the bodyInput value without needing to do too much cleanup.
      var bodyInput = this.bodyInput || ''; // cut out HTML

      var cleaned = sanitize(bodyInput); // remove line breaks

      cleaned = cleaned.replace(/<br( \/)?>/g, ''); // Remove the comment block that the browser puts in.

      cleaned = cleaned.replace(/<!---->/g, ''); // Remove spaces

      cleaned = cleaned.replace(/\s/g, '');
      return cleaned.length > 0;
    }),
    hasNonDeletedAttachments: Ember.computed('files.@each.deleted', function () {
      var files = this.files || [];
      var hasNonDeleted = Boolean(files.find(function (file) {
        return file.valid && !file.deleted;
      }));
      return hasNonDeleted;
    }),
    editorHasContent: Ember.computed('bodyInputHasContent', 'hasNonDeletedAttachments', function () {
      var hasContent = this.bodyInputHasContent;
      var hasAttachments = this.hasNonDeletedAttachments;
      return hasContent || hasAttachments;
    }),
    actions: {
      promptAsRecovery: function promptAsRecovery(save) {
        var _this2 = this;

        (0, _dialog.default)('Looks like you closed your tab (or the application crashed) while you were writing something. Would you like to recover it?', ['Yes', 'No']).then(function (choice) {
          if (choice === 'Yes') {
            _this2.set('assetsRecovered', true);

            _this2.send('useArchivedText', save, true);

            _this2.send('deleteArchivedText', save);
          } else {
            _this2.set('assetsRecovered', true);

            _this2.send('deleteArchivedText', save);
          }
        });
      },
      editPost: function editPost(post_id) {
        var pid = post_id ? '#discussion-response-' + post_id + ' .discussion-reply .discussion-reply-body' : '.first-post',

        /* eslint-disable-next-line ember/no-jquery */
        $bod = Ember.$('.floating-modal.active .modal-content ' + pid),

        /* eslint-disable-next-line ember/no-jquery */
        title = Ember.$('.floating-modal.active .modal-content .subject .discussion-title').html() || '',
            div = document.createElement('div'),
            vidString = '',
            whitelist = 'a, br, b, u, h2, ul, ol, li, p',
            vid,
            replyText,
            replyId,
            replyUser,
            i,
            reply,
            body;
        div.innerHTML = $bod.html();
        vid = div.getElementsByClassName('video-container');
        this.set('response_video', ''); // Remove video embeds and stow them away to be added back
        // When reply edit is posted

        if (vid.length > 0) {
          for (i = 0; i < vid.length; i++) {
            vidString += '<div class="video-container">' + vid[i].innerHTML + '</div>';
          }

          this.set('response_video', vidString);

          for (i = 0; i < vid.length; i++) {
            vid[i].remove();
          }
        }

        if (post_id) {
          // Clean out reply, if there is one
          // Pull out reply elements
          reply = div.getElementsByClassName('is-reply');

          if (reply.length > 0) {
            replyText = reply[0].getElementsByClassName('is-reply-text')[0].innerText;
            replyId = reply[0].getElementsByClassName('is-reply-id')[0].innerText;
            replyUser = reply[0].getElementsByClassName('is-reply-user')[0].innerText;
            this.send('replyToDiscussion', {
              id: replyId,
              user: replyUser,
              text: replyText
            });
            reply[0].remove();
          }

          this.set('reply_id', post_id);
          this.set('is_reply', true);
        } else {
          this.set('titleInput', title.trim());
        }
        /* eslint-disable-next-line ember/no-jquery */


        Ember.$(div).find('iframe').remove();
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$(div).find('*').not(whitelist).each(function () {
          /* eslint-disable-next-line ember/no-jquery */
          var content = Ember.$(this).contents();
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$(this).replaceWith(content);
        });
        body = div.innerHTML.replace(/<a .*?href="(.*?)".*?>.*?<\/a>/gi, '$1');
        this.set('is_editing', true);
        this.set('bodyInput', body);
      },
      deletePost: function deletePost(post_id) {
        var _this3 = this;

        var self = this;
        (0, _dialog.default)("Are you sure you wish to delete this discussion".concat(post_id ? ' post' : '', "? This action CANNOT be undone!"), ["Yes".concat(post_id ? '. If permanent deletion of attachments is desired, first delete manually in S3. Then manually delete in Canvas.' : ''), 'No']).then(function (choice) {
          if (choice.indexOf('Yes') === 0) {
            var model = _this3.model;

            if (post_id) {
              _this3.store.nestResources('response', [{
                section: model.get('section').get('id')
              }, {
                discussion: model.get('id')
              }]);

              _this3.store.findRecord('response', post_id).then(function (record) {
                var childResponses = self.childResponses ? self.childResponses[post_id] : null;

                try {
                  record.destroyRecord().then(function () {
                    if (childResponses && childResponses.length > 0) {
                      var promises = [];
                      childResponses.forEach(function (childResponse) {
                        promises.push(self.store.findRecord('response', childResponse.get('id')).then(function (record) {
                          record.destroyRecord();
                        }));
                      });

                      try {
                        Promise.all(promises).catch(function (e) {
                          (0, _dialog.default)('There was a problem deleting replies to the discussion post. These must now be manually deleted.');
                        });
                      } catch (e) {
                        (0, _dialog.default)('There was a problem deleting replies to the discussion post. These must now be manually deleted.');
                      }
                    }
                  }).catch(function (e) {
                    (0, _dialog.default)('There was a problem deleting the discussion post. Please reload the page and try again.');
                  });
                } catch (e) {
                  (0, _dialog.default)('There was a problem deleting the discussion post. Please reload the page and try again.');
                }
              });
            } else {
              model.destroyRecord().then(function () {
                var discussionsCtl = _this3.discussions;

                if (discussionsCtl.send) {
                  discussionsCtl.send('closeModals', true);
                }
              });
            }
          }
        });
      },
      useArchivedText: function useArchivedText(file, recovery) {
        /* eslint-disable-next-line ember/no-jquery */
        var repBox = Ember.$('.floating-modal.active .rte-editor-input');
        repBox.html(repBox.html() + file.textContent);

        if (!recovery) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.video-archive').removeClass('visible');
        }
      },
      favoriteText: function favoriteText(file) {
        var fl;

        try {
          fl = JSON.parse(localStorage.localDocs);
        } catch (err) {
          fl = {};
        }

        fl[file.name].isFaved = true;
        localStorage.localDocs = JSON.stringify(fl);
        Ember.notifyPropertyChange(this, 'localDocs');
      },
      unFavoriteText: function unFavoriteText(file) {
        var fl;

        try {
          fl = JSON.parse(localStorage.localDocs);
        } catch (err) {
          fl = {};
        }

        fl[file.name].isFaved = false;
        localStorage.localDocs = JSON.stringify(fl);
        Ember.notifyPropertyChange(this, 'localDocs');
      },
      deleteArchivedText: function deleteArchivedText(file) {
        var _this4 = this;

        var fl;

        try {
          fl = JSON.parse(localStorage.localDocs);
        } catch (err) {
          fl = {};
        }

        var confirmation;

        if (file.name === 'Auto Save') {
          confirmation = Promise.resolve('Yes');
        } else {
          confirmation = (0, _dialog.default)('Are you sure you want to delete this file from your archive? This cannot be undone.', ['Yes', 'No']);
        }

        confirmation.then(function (choice) {
          if (choice === 'Yes') {
            if (!file.recName) {
              // checks if there's a recName recorded on the file name.
              // old version of the code didn't include the date in the recName
              for (var fileName in fl) {
                if (fl[fileName].name === file.name) {
                  file.recName = fileName;
                }
              }
            }

            delete fl[file.recName];

            if (localStorage.localDocRunner === file.name) {
              delete localStorage.localDocRunner;
            }

            localStorage.localDocs = JSON.stringify(fl);
            Ember.notifyPropertyChange(_this4, 'localDocs');
          }
        });
      },
      previewArchivedText: function previewArchivedText(file) {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.popup-container').addClass('active');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.popup-container .content').html(file.textContent);
      },
      toggleLocalArchive: function toggleLocalArchive() {
        this.send('updateLocalDocs');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.video-archive').toggleClass('visible');
      },
      updateLocalDocs: function updateLocalDocs() {
        this.notifyPropertyChange('localDocs');
      },
      cancelUpload: function cancelUpload(file) {
        var _this5 = this;

        (0, _dialog.default)('Are you sure you want to cancel the file upload?', ['Yes', 'No']).then(function (choice) {
          if (choice === 'Yes') {
            _this5.files.removeObject(file);
          }
        });
      },
      editFileName: function editFileName(file) {
        var identifier = !file.file.isUrl ? file.uploaded_id : file.file.name;
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$(".attachments .file-name.file-id-".concat(identifier)).addClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$(".attachments .file-name-input.file-id-".concat(identifier)).removeClass('hidden');
      },
      editFileNameConfirm: function editFileNameConfirm(file, fileExtension) {
        var identifier = file.uploaded_id,

        /* eslint-disable-next-line ember/no-jquery */
        newName = Ember.$(".attachments .file-name-input.file-id-".concat(identifier, " input")).val();

        if (!newName || newName.includes('.')) {
          alert('Invalid file name, must not contain "."');
          return;
        }

        newName = newName + fileExtension;
        Ember.set(file, 'uploaded_name', newName);
        this.store.findRecord('attachment', file.uploaded_id).then(function (loadedAttachment) {
          loadedAttachment.set('name', newName);
          loadedAttachment.save();
        });
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$(".attachments .file-name.file-id-".concat(identifier)).removeClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$(".attachments .file-name-input.file-id-".concat(identifier)).addClass('hidden');
      },
      destroyEditor: function destroyEditor() {
        this.set('bodyInput', '');

        if (this.titleInput) {
          this.set('titleInput', '');
        }
      },
      // save response from editor if save=true.
      // change styling so that editor is not visible.
      doneEditing: function doneEditing(save) {
        var content = this.bodyInput,
            currentModel = this.currentModel,
            currentModelType = currentModel.constructor.modelName;
        /* eslint-disable-next-line ember/no-jquery */

        if (Ember.$.trim(content.replace(/&nbsp;/g, ' ').replace(/<!---->/g, ' ')).length > 0) {
          if (save) {
            //this.set('uploadInProgress', true);
            if (currentModelType !== 'conversation') {
              if (this.is_editing) {
                this.send('submitEdit', content);
              } else {
                this.send('submitResponse', content);
              }

              if (this.repBox) {
                this.repBox.html('');
              }

              this.set('titleInput', '');
              this.set('bodyInput', '');
              this.set('replyId', false);
              this.set('replyText', '');
              this.set('replyAuthor', '');
            }
          } else {
            this.send('clearAllFiles');
            this.set('inEditor', false);
            this.set('titleInput', '');
            this.set('is_editing', false);
            this.send('destroyEditor');
            this.set('replyId', false);
            this.set('replyText', '');
            this.set('replyAuthor', '');
            this.set('reply_id', false);
            this.set('is_reply', false);
            this.set('response_video', false);
          }
        }
      },
      // user submits response to discussion
      // this occurs through either the regular input form or through
      // the wysiwyg editor
      submitResponse: function submitResponse(content) {
        var currMod = this.currentModel,
            date = new Date(),
            that = this,
            replyId = this.replyId,
            replyText = this.replyText,
            replyAuthor = this.replyAuthor,
            replyBk,
            resp,
            usr,
            discId;

        if (content) {
          content = this.parseEmbed(content);
        }

        if (replyId) {
          replyBk = '<div class="is-reply"><div class="is-reply-id">' + replyId + '</div><div class="is-reply-user">' + replyAuthor + '</div><div class="is-reply-text">' + replyText + '</div></div>';
          content = replyBk + content;
        }

        if (content.length > 64000) {
          (0, _dialog.default)('Your post exceeds the body size limit by ' + (content.length - 64000) + ' characters. Please shorten it and try posting again.');
          return;
        }
        /* eslint-disable-next-line ember/no-jquery */


        Ember.$('#pop-attachment-drawer-handle').prop('checked', false);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.pop-attachment-drawer').addClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.floating-modal.active').removeClass('drawer-open');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.camera-container').removeClass('active');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.audio-rec-panel').removeClass('active');

        if (!currMod) {
          discId = '' + this.model.item.get('data').id + '';
          currMod = this.discussions.model.content.findBy('id', discId);
        }

        if (currMod && currMod.get) {
          /* eslint-disable-next-line ember/no-get */
          if (this.get('model.conversationPartner.name')) {
            this.store.nestResources('response', [{
              section: currMod.get('section').get('id')
            }, {
              conversation: currMod.get('id')
            }]);
          } else {
            this.store.nestResources('response', [{
              section: currMod.get('section').get('id')
            }, {
              discussion: currMod.get('id')
            }]);
          }

          if (!content) {
            return false;
          }

          if (content.trim() !== '') {
            usr = this.session.get('user');
            /* eslint-disable-next-line ember/no-get */

            if (this.get('model.conversationPartner.name')) {
              resp = this.store.createRecord('response', {
                user: usr,
                conversation: currMod
              });
            } else {
              resp = this.store.createRecord('response', {
                user: usr,
                discussion: currMod,
                parent: replyId || null
              });
            } // Add any video embeds


            content = content + this.videoEmbedString;
            resp.set('message', content);
            resp.set('date', date);
            /* eslint-disable-next-line ember/no-get */

            this.get('currentModel.responses').pushObject(resp);
            that.set('inEditor', false);
            currMod.set('i_submitted', true);
            /* eslint-disable-next-line ember/no-get */

            if (!this.working && this.get('file_ids.length') > 0) {
              resp.set('attachments', that.get('file_ids'));
            }

            resp.save().then(function () {
              that.send('clearAllFiles');
            }, function () {
              var conv;
              that.send('clearAllFiles');
              conv = that.get('currentModel.responses').objectAt(that.get('currentModel.responses.length') - 1);
              conv.set('needs_repost', true);

              if (that.get('currentModel')) {
                that.get('currentModel').set('failed_count', that.get('currentModel.failed_count') ? that.get('currentModel.failed_count') + 1 : 1);
              }
            });
          }

          return false;
        }
      },
      repostResponse: function repostResponse(resp) {
        var that = this;
        resp.set('needs_repost', false);
        resp.set('is_reposting', true);
        resp.save().then(function () {
          resp.set('is_reposting', false);

          if (that.get('currentModel')) {
            that.get('currentModel').set('failed_count', that.get('currentModel.failed_count') === 1 ? false : that.get('currentModel.failed_count') - 1);
          }
        }, function () {
          resp.set('needs_repost', true);
          resp.set('is_reposting', false);
        });
      },
      // user submits response to discussion
      // this occurs through either the regular input form or through
      // the wysiwyg editor
      submitEdit: function submitEdit(content) {
        var _this6 = this;

        var currMod = this.currentModel,
            title = this.is_reply ? false : this.titleInput,
            message = this.parseEmbed(content),
            that = this,
            replyId,
            replyAuthor,
            replyText,
            replyBk; // Add any video embeds

        message = message + this.response_video + this.videoEmbedString;
        this.set('uploadInProgress', true);
        this.set('replyInProgress', true);

        if (this.is_reply) {
          // Tack reply contents, if this is a reply
          replyId = this.replyId;

          if (replyId) {
            replyText = this.replyText;
            replyAuthor = this.replyAuthor;
            replyBk = '<div class="is-reply"><div class="is-reply-id">' + replyId + '</div><div class="is-reply-user">' + replyAuthor + '</div><div class="is-reply-text">' + replyText + '</div></div>';
            message = replyBk + message;
          }

          this.store.nestResources('response', [{
            section: currMod.get('section').get('id')
          }, {
            discussion: currMod.get('id')
          }]);
          this.store.findRecord('response', this.reply_id).then(function (record) {
            record.set('message', message);
            record.save().then(function () {
              that.set('uploadInProgress', false);
              that.set('replyInProgress', false);
              that.set('is_editing', false);
              that.set('is_reply', false);
              that.set('reply_id', false);
              that.set('response_video', false);
              that.set('titleInput', '');
              that.send('clearAllFiles');
              that.send('destroyEditor');
            });
          });
        } else {
          if (title) {
            currMod.set('title', title);
          }

          currMod.set('message', message);
          currMod.save().then(function () {
            _this6.set('uploadInProgress', false);

            _this6.set('is_editing', false);

            _this6.set('titleInput', '');

            _this6.send('clearAllFiles');

            _this6.send('destroyEditor');

            _this6.discussions.send('closeModals', true);
          });
        }
      }
    }
  });

  _exports.default = _default;
});