define("bocce/services/session", ["exports", "bocce/config/environment", "ember-data"], function (_exports, _environment, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function is_in_range(ranges, course_id) {
    if (!ranges) {
      return false;
    }

    course_id = parseInt(course_id, 10);
    ranges = ranges.split(',');

    var _iterator = _createForOfIteratorHelper(ranges),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var range = _step.value;

        if (range.indexOf('-') === -1) {
          if (parseInt(range, 10) === course_id) {
            return true;
          }

          continue;
        }

        var _range$split = range.split('-'),
            _range$split2 = _slicedToArray(_range$split, 2),
            min = _range$split2[0],
            max = _range$split2[1];

        min = parseInt(min, 10);

        if (max) {
          max = parseInt(max, 10);

          if (course_id >= min && course_id <= max) {
            return true;
          }
        } else {
          if (course_id >= min) {
            return true;
          }
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return false;
  }

  var _default = Ember.Service.extend({
    features: Ember.inject.service(),
    isStaticContent: Ember.computed('features.staticContent', function () {
      return this.features.isEnabled('staticContent');
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    portrait: Ember.computed.reads('user.image_url'),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    course: Ember.computed.reads('section.course'),
    termID: Ember.computed.reads('course.term.id'),
    termName: Ember.computed.reads('course.term.name'),
    lessonId: -1,
    itemId: -1,
    isBCM: Ember.computed.alias('course.term.isBCM'),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isInstructor: Ember.computed('user', 'user.teaching.[]', 'section', function () {
      /* eslint-disable-next-line ember/no-get */
      var section_id = this.get('section.id');

      if (!section_id) {
        return false;
      }
      /* eslint-disable-next-line ember/no-get */


      var teaching = this.get('user.teaching');
      /* eslint-disable-next-line ember/no-get */

      return teaching && teaching.filter(function (s) {
        return section_id === s.get('id');
      }).get('length') > 0;
    }),
    teachingUser: Ember.computed('section', function () {
      var section = this.get('section');
      return section.get('teachers').get('firstObject');
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isShowcaseInstructor: Ember.computed('user', 'user.teaching.[]', 'section', function () {
      /* eslint-disable-next-line ember/no-get */
      var instructors = this.get('course.showcaseInstructors') || [];
      /* eslint-disable-next-line ember/no-get */

      return instructors.indexOf(parseInt(this.get('user.id'))) >= 0;
    }),
    isObserver: Ember.computed('section.role', function () {
      var sec = this.section;
      /* eslint-disable-next-line ember/no-get */

      return sec.get('role') === 'observer';
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isStudent: Ember.computed('user', 'user.enrolled.[]', 'section', function () {
      /* eslint-disable-next-line ember/no-get */
      var section_id = this.get('section.id');

      if (!section_id) {
        return false;
      }
      /* eslint-disable-next-line ember/no-get */


      var enrollments = this.get('user.enrolled');

      if (!enrollments || enrollments.length == 0) {
        return false;
      }

      return enrollments
      /* eslint-disable-next-line ember/no-get */
      .filter(function (e) {
        return section_id === e.get('section.id') && e.get('role') === 'student';
      })
      /* eslint-disable-next-line ember/no-get */
      .get('length') > 0;
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isStaff: Ember.computed.reads('user.staff'),
    isAdmin: Ember.computed.reads('user.admin'),
    gradeLock: Ember.computed.reads('course.isGradeLock'),
    gradeLockAt: Ember.computed.reads('course.gradeLockDate'),
    courseCodeWithoutSection: Ember.computed('course.code', function () {
      var courseCodeWithSection = this.get('course.code');
      var indexOfDot = courseCodeWithSection.indexOf('.');
      return courseCodeWithSection.substring(0, indexOfDot > -1 ? indexOfDot : courseCodeWithSection.length);
    }),
    lateGradingPolicy: Ember.computed('course.lateGradingPolicy', function () {
      return this.get('course.lateGradingPolicy') || '';
    }),
    bookmarks: Ember.computed('user.bookmarks', function () {
      return this.get('user.bookmarks') || [];
    }),
    gradebookWeights: Ember.computed('course.gradebook_weights', function () {
      var _this$get;

      return (_this$get = this.get('course.gradebook_weights')) !== null && _this$get !== void 0 ? _this$get : [];
    }),
    readOnly: Ember.computed.or('globalReadOnly', 'course.isReadOnly'),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    isEnabled: Ember.computed('course.id', function () {
      var retval = {},

      /* eslint-disable-next-line ember/no-get */
      cid = this.get('course.id'),

      /* eslint-disable-next-line ember/no-get */
      courseCodeRaw = this.get('course.code'),
          courseCode = courseCodeRaw ? courseCodeRaw.split('.')[0] : false;

      for (var feature in _environment.default.APP.courseFeatures) {
        var featureContents = _environment.default.APP.courseFeatures[feature];

        if (_typeof(featureContents) === 'object') {
          retval[feature] = _environment.default.APP.courseFeatures.aFNonCredit.indexOf(courseCode) > -1;
        } else {
          retval[feature] = is_in_range(_environment.default.APP.courseFeatures[feature], cid);
        }
      }

      return retval;
    }),
    allowStudentChats: Ember.computed('course.id', function () {
      /* eslint-disable-next-line ember/no-get */
      var courseId = this.get('course.id');
      return is_in_range(_environment.default.APP.courseFeatures.coursesAllowStudentChats, courseId);
    }),
    allowNewLCS: Ember.computed('user.id', function () {
      /* eslint-disable-next-line ember/no-get */
      var userId = this.get('user.id');
      return is_in_range(_environment.default.APP.courseFeatures.useNewLCS, userId);
    }),
    allowOldGradebook: Ember.computed('user.id', function () {
      /* eslint-disable-next-line ember/no-get */
      var userId = this.get('user.id');
      return is_in_range(_environment.default.APP.courseFeatures.useOldGradebook, userId);
    }),
    hasZoomId: Ember.computed('user.hasZoomId', function () {
      /* eslint-disable-next-line ember/no-get */
      var hasZoomId = this.get('user.hasZoomId');
      return hasZoomId;
    }),
    isSimplifiedTerm: Ember.computed('course.term', function () {
      var termName = this.get('course.term.name'),
          simplifiedTermKeywords = _environment.default.APP.courseFeatures.simplifiedTermKeywords || [],
          isSimplifiedTerm = false;

      var _iterator2 = _createForOfIteratorHelper(simplifiedTermKeywords),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var simplifiedTermKeyword = _step2.value;

          if (termName.includes(simplifiedTermKeyword)) {
            isSimplifiedTerm = true;
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      return isSimplifiedTerm;
    }),
    aFNonCredit: Ember.computed('course.code', function () {
      /* eslint-disable-next-line ember/no-get */
      var courseCodeRaw = this.get('course.code');
      var courseCode = courseCodeRaw ? courseCodeRaw.split('.')[0] : false;
      return _environment.default.APP.courseFeatures.aFNonCredit.indexOf(courseCode) > -1;
    })
  });

  _exports.default = _default;
});