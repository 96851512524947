define("bocce/components/drag-and-drop", ["exports", "bocce/mixins/editable", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/uploadable", "bocce/mixins/user-profile", "bocce/mixins/discussable", "bocce/mixins/enmodal", "bocce/mixins/kaltura-upload", "bocce/mixins/legacy-attachment-manager"], function (_exports, _editable, _audioRec, _videoRec, _rtcRec, _uploadable, _userProfile, _discussable, _enmodal, _kalturaUpload, _legacyAttachmentManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */
  var _default = Ember.Component.extend(_legacyAttachmentManager.default, _editable.default, _audioRec.default, _videoRec.default, _rtcRec.default, _uploadable.default, _userProfile.default, _discussable.default, _enmodal.default, _kalturaUpload.default, {
    didInsertElement: function didInsertElement() {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.discussionAttachment').on('change', Ember.$.proxy(this.appropriateAttachment, this));
    },
    willDestroyElement: function willDestroyElement() {
      /* eslint-disable-next-line ember/no-jquery */
      Ember.$('.discussionAttachment').off('change', Ember.$.proxy(this.appropriateAttachment, this));
    },
    appropriateAttachment: function appropriateAttachment(e) {
      var files = e.target.files,
          len = files.length,
          controller = this.controller,
          mimeTypes = controller.mimeTypes,
          i;

      if (mimeTypes) {
        for (i = 0; i < len; i++) {
          if (files[i].type.indexOf('video') > -1) {
            this.encoding_videos.pushObject({
              name: files[i].name,
              percent_uploaded: 0
            });
            var fileIndex = this.encoding_videos.length - 1;
            this.kalturaUploadVideo(files[i], function () {
              Ember.debug('Unable to upload video to Kaltura. Uploading to S3...');
              controller.send('addValidFile', files[i]);
            }, fileIndex);
          } else if (mimeTypes.indexOf(files[i].type) === -1) {
            controller.send('addValidFile', files[i]);
          } else {
            controller.send('addInvalidFile', files[i]);
          }
        }
      } // Purge file input


      e.target.value = '';
    }
  });

  _exports.default = _default;
});