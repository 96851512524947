define("bocce/mixins/interactions/reveal_text_quiz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function RevealTextQuiz($el) {
    // class vars
    this.el = $el;
    this.interactionData = $el.find('.interaction_data');
    this.container = $el.find('.interaction_content');
    this.data = []; // answer, text to reveal, x-position

    this.numCorrect = 0; // keep track of how many corrects the user has

    this.totalCorrect = 0; // the number of correct answers
  }

  RevealTextQuiz.prototype = {
    init: function init() {
      var dataRef = this.data;
      this.interactionData.find('table tr').each(function (i, tr) {
        if (i === 0) {
          return true; // skip 0 because this is the header row
        } // probably should dry this up and abstract -- we'll be doing this
        // type of data extraction a lot...
        // CL: this trim logic looks kinda gross to me... wasn't expecting IE to be missing
        //       the trim method.

        /* eslint-disable-next-line ember/no-jquery */


        var answer = Ember.$.trim(Ember.$(tr).find('td:nth-child(1)').text());
        /* eslint-disable-next-line ember/no-jquery */

        var textToReveal = Ember.$.trim(Ember.$(tr).find('td:nth-child(2)').text());
        /* eslint-disable-next-line ember/no-jquery */

        var xPos = Ember.$.trim(Ember.$(tr).find('td:nth-child(3)').text());
        /* eslint-disable-next-line ember/no-jquery */

        var correct = Ember.$.trim(Ember.$(tr).find('td:nth-child(4)').text());
        dataRef.push(new RevealTextQuiz.QuizItem(answer, textToReveal, xPos, correct));
      });
      this.render();
    },
    render: function render() {
      // add div structure... this should really be in a template or something
      var IMG_CONTAINER = 'imgContainer',
          IMG_CONTAINER_ID = '#' + IMG_CONTAINER;
      var ANSWER_CHOICES = 'answerChoices',
          ANSWER_CHOICES_ID = '#' + ANSWER_CHOICES;
      var FEEDBACK = 'feedback',
          FEEDBACK_ID = '#' + FEEDBACK;
      var i, len; // if feedback div isn't present, create it. if it is, leave it alone because
      // we may have a feedback message lingering from the previous attempt

      if (!this.container.find(FEEDBACK_ID)[0]) {
        this.container.append('<div id="' + FEEDBACK + '"> </div>');
        /* eslint-disable-next-line ember/no-jquery */

        this.feedback = Ember.$(FEEDBACK_ID);
      } // remove existing items -- instead of emptying full div so feedback
      // can linger if needed


      this.container.find(IMG_CONTAINER_ID).remove();
      this.container.find(ANSWER_CHOICES_ID).remove();
      this.container.append('<div id="' + IMG_CONTAINER + '" style="position: relative;"></div><div id="' + ANSWER_CHOICES + '"></div>'); // add image to #imgContainer at the top

      var img = this.interactionData.find('img').clone();
      this.imgContainer = this.container.find(IMG_CONTAINER_ID);
      this.imgContainer.append(img); // reset number of correct for this question (not really necessary to do this
      // dynamically for this quiz, but useful for multi-question quizzes)

      this.totalCorrect = 0;
      this.numCorrect = 0; // shuffle and add answer choices

      var answerChoicesEl = this.container.find(ANSWER_CHOICES_ID);
      this.data = this.shuffleArray(this.data);

      for (i = 0, len = this.data.length; i < len; i++) {
        var elData = this.data[i];
        answerChoicesEl.append('<p class="reveal-text-quiz answer"><input type="checkbox">' + elData.answer + '</input></p>'); // get ref to newly create item for event binding, etc
        // is there a better way to do this? get a ref before appending??

        var inputEl = answerChoicesEl.find('input').last();
        inputEl.val(i); // set value to data index

        inputEl.bind('click', this, this.checkAnswer); // pass a ref to 'this' into event
        // clicks to the surrounding parent el will also trigger a click to
        // the input el

        /* eslint-disable-next-line ember/no-jquery */

        var parentCont = Ember.$(inputEl).closest('.reveal-text-quiz.answer');
        parentCont.bind('click', function (e) {
          /* eslint-disable-next-line ember/no-jquery */
          var t = Ember.$(e.target).find('input');
          t.attr('checked', 'checked');
          t.trigger('click');
        }); // check if answer is correct. if so, increment totalCorrect count

        if (elData.correct.toLowerCase() === 'true') {
          this.totalCorrect++;
        }
      } // add hover

      /* eslint-disable-next-line ember/no-jquery */


      Ember.$('.reveal-text-quiz.answer').hover(function () {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$(this).toggleClass('answer-over');
      });
    },
    checkAnswer: function checkAnswer(e) {
      /* eslint-disable-next-line ember/no-jquery */
      var t = Ember.$(e.target);
      var index = parseInt(t.val());
      var that = e.data; // pull master 'this' into this scope

      var data = that.data[index]; // clear out feedback field

      that.feedback.empty();

      if (data.correct.toLowerCase() === 'true') {
        that.numCorrect++;
        t.attr('disabled', true); // disable checkbox

        t.parent().unbind('mouseenter mouseleave').removeClass('answer-over').addClass('answer-correct');
        that.imgContainer.append('<p class="reveal-text-quiz hidden-item" style="left: ' + data.xPos + 'px;">' + data.textToReveal + '</p>');

        if (that.totalCorrect === that.numCorrect) {
          that.feedback.append('<p class="reveal-text-quiz feedback quiz-complete">Quiz complete. Nice job!</p>'); // disable all buttons

          that.container.find('input').each(function () {
            /* eslint-disable-next-line ember/no-jquery */
            Ember.$(this).attr('disabled', true);
          });
        } else {
          that.feedback.append('<p class="reveal-text-quiz feedback correct">Correct! Keep going.</p>');
          that.feedback.find('.correct').fadeOut(3000);
        }
      } else {
        that.feedback.append('<p class="reveal-text-quiz feedback incorrect">Incorrect. Try again.</p>');
        that.feedback.find('.incorrect').fadeOut(3000);
        that.render();
      }
    },
    shuffleArray: function shuffleArray(arr) {
      return arr.sort(function () {
        return 0.5 - Math.random();
      });
    }
  };

  RevealTextQuiz.QuizItem = function (answer, textToReveal, xPos, correct) {
    this.answer = answer;
    this.textToReveal = textToReveal;
    this.xPos = xPos;
    this.correct = correct;
  };

  var _default = RevealTextQuiz;
  _exports.default = _default;
});