define("bocce/components/side-panel/panel-list-item/quiz-icon", ["exports", "bocce/components/side-panel/panel-list-item/icon"], function (_exports, _icon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _icon.default.extend({
    iconClass: 'fas fa-question',
    latestAttempt: Ember.computed('item.attempts.[]', function () {
      /* eslint-disable-next-line ember/no-get */
      var attempts = this.get('item.attempts');
      return attempts ? attempts.objectAt(0) : null;
    })
  });

  _exports.default = _default;
});