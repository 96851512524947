define("bocce/routes/classroom/lessons/discussion", ["exports", "bocce/routes/classroom/lessons/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modalBase.default.extend({
    afterModel: function afterModel(model) {
      model.set('unread_count', 0);
      model.set('read', true);
    }
  });

  _exports.default = _default;
});