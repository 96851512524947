define("bocce/helpers/array-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayItem = arrayItem;
  _exports.default = void 0;

  function arrayItem(params
  /*, hash*/
  ) {
    var array = params[0];
    var index = params[1];
    return array[index];
  }

  var _default = Ember.Helper.helper(arrayItem);

  _exports.default = _default;
});