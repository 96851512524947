define("bocce/templates/interactions/imageexplorer/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dPkyilLu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"loading\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/front_end/images/loading.gif\"],[14,0,\"loading-spinner\"],[12],[13],[2,\"Loading...\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "bocce/templates/interactions/imageexplorer/loading.hbs"
    }
  });

  _exports.default = _default;
});