define("bocce/templates/interactions/hotspotquiz/main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sus31vUT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"question-container border-bottom\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"question\"],[12],[1,[30,[36,1],[[35,0]],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"feedback-container\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"feedback\"],[12],[13],[10,\"a\"],[14,6,\"#\"],[14,0,\"continue-btn hidden\"],[12],[2,\"Click to continue\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[10,\"div\"],[14,0,\"clear\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"image-area\"],[12],[10,\"img\"],[12],[13],[13],[10,\"div\"],[14,0,\"clear\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"question-count fr\"],[12],[2,\"1 of 1\"],[13],[10,\"div\"],[14,0,\"clear\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"question\",\"decode\"]}",
    "meta": {
      "moduleName": "bocce/templates/interactions/hotspotquiz/main.hbs"
    }
  });

  _exports.default = _default;
});