define("bocce/templates/components/side-panel/work", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q6yxxy/L",
    "block": "{\"symbols\":[\"item\",\"item\",\"@showLateGradingPolicy\"],\"statements\":[[10,\"span\"],[14,0,\"late-grading-policy-cta-container\"],[12],[2,\"\\n  \"],[10,\"i\"],[14,\"aria-hidden\",\"true\"],[14,0,\"late-grading-policy-cta-icon fa fas fa-info-circle\"],[12],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"lesson-divider late-grading-policy-cta\"],[4,[38,0],[\"click\",[32,3]],null],[12],[2,\"\\n    Instructor Late Grading Policy\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"h3\"],[14,0,\"lesson-divider\"],[12],[2,\"To-Do\"],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"todo activities\"],[14,\"role\",\"navigation\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"side-panel/panel-list-item\",[],[[\"@item\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[13],[2,\"\\n\"],[10,\"h3\"],[14,0,\"lesson-divider\"],[12],[2,\"Completed\"],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"done activities\"],[14,\"role\",\"navigation\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"side-panel/panel-list-item\",[],[[\"@item\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"todo\",\"-track-array\",\"each\",\"done\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/side-panel/work.hbs"
    }
  });

  _exports.default = _default;
});