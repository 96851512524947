define("bocce/mixins/conversable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create({
    actions: {
      createConversation: function createConversation(recipient_id) {
        this.transitionToRoute('classroom.lessons.conversation-new-with', recipient_id);
      },
      viewConversation: function viewConversation(conversation_id) {
        this.transitionToRoute('classroom.lessons.conversation', conversation_id);
      },
      toggleConversationStar: function toggleConversationStar(conversation_id) {
        this.store.findRecord('conversation', conversation_id).then(function (conversation) {
          var starred = !conversation.get('starred');
          conversation.set('starred', starred);
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$.ajax({
            type: 'put',
            url: "/interface/conversations/".concat(conversation_id),
            data: JSON.stringify({
              conversation: {
                starred: starred
              }
            }),
            success: function success() {// debug('Success starring conversation');
            },
            error: function error(_error) {
              Ember.debug('Error starring conversation');
              Ember.debug(_error);
            }
          });
        });
      }
    }
  });

  _exports.default = _default;
});