define("bocce/controllers/classroom/lessons/discussion-new", ["exports", "bocce/mixins/discussable", "bocce/mixins/audio-rec", "bocce/mixins/video-rec", "bocce/mixins/rtc-rec", "bocce/mixins/embed-parser", "bocce/mixins/enmodal", "bocce/mixins/uploadable", "bocce/mixins/editable", "bocce/mixins/polls", "bocce/mixins/nested-resources", "bocce/utilities/dialog"], function (_exports, _discussable, _audioRec, _videoRec, _rtcRec, _embedParser, _enmodal, _uploadable, _editable, _polls, _nestedResources, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */
  var _default = Ember.Controller.extend(_discussable.default, _editable.default, _audioRec.default, _videoRec.default, _rtcRec.default, _embedParser.default, _uploadable.default, _enmodal.default, _polls.default, _nestedResources.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.multiSections = this.multiSections || Ember.ArrayProxy.create({
        content: Ember.A([])
      });
    },
    classroom: Ember.inject.controller(),
    discussions: Ember.inject.controller(),
    discussion: Ember.inject.controller('classroom.lessons.discussion'),
    // required by DiscussableMixin
    newDiscussion: Ember.inject.controller('classroom.lessons.discussion-new'),
    // required by DiscussableMixin
    lessons: Ember.inject.controller('classroom.lessons'),
    // required by UploadableMixin
    titleInput: '',
    isAnnouncement: false,
    modalTitle: Ember.computed('multipleSectionsSelected', 'isAnnouncement', function () {
      if (this.isAnnouncement) {
        if (this.multipleSectionsSelected) {
          return 'New Multi-Section Announcement';
        } else {
          return 'New Announcement';
        }
      } else {
        return 'New Conversation';
      }
    }),
    multipleSectionsSelected: Ember.computed.gt('multiSections.length', 0),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    userSectionCodes: Ember.computed('multiSections.[]', function () {
      /* eslint-disable-next-line ember/no-get */
      var sectionCodes = this.get('classroom.otherCurrentTermSections');
      return sectionCodes && sectionCodes.length > 0 ? sectionCodes : false;
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    readyToSave: Ember.computed('titleInput', 'postable', function () {
      /* eslint-disable-next-line ember/no-get */
      return this.get('titleInput.length') > 0 && this.postable;
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    modified: Ember.computed('titleInput', 'bodyInput', function () {
      return this.titleInput.length > 0 || this.bodyInput.length > 0;
    }),
    postToSection: function postToSection(sections, recObject) {
      var _this = this;

      var pollObject = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      // post to the current course first so it appears
      // in bocce right away; then proceed to post elsewhere
      var poll;

      if (pollObject) {
        poll = this.store.createRecord('poll', pollObject).save();
      } else {
        poll = Promise.resolve(false);
      }

      var discussion = this.store.createRecord('discussion', recObject).save();
      var currentSectionCreation = Promise.all([poll, discussion]).then(function (res) {
        var discussion = res[1];

        if (pollObject) {
          var _poll = res[0];
          discussion.set('poll_id', _poll.id);
        }

        discussion.set('subscribed', true);
        return discussion.save();
      });
      var postableDiscussion = {
        'discussion': recObject
      },
          pollsPromise,
          discussionsPromise; // if there's a poll, send the requests to create the poll
      // otherwise, simply return a promise with 'false'

      if (pollObject) {
        var postablePoll = {
          'poll': pollObject
        };
        pollsPromise = this.createNestedResources('polls', postablePoll, 'sections', sections);
      } else {
        pollsPromise = Promise.resolve(false);
      } // send the requests to create the discussion in all sections


      discussionsPromise = this.createNestedResources('discussions', postableDiscussion, 'sections', sections); // Wait for all promises to resolve, then modify the returned
      // discussion objects to subscribe & attach poll ids.
      // If there are no other sections, this resolves right away.

      var otherSectionsCreation = Promise.all([pollsPromise, discussionsPromise]).then(function (res) {
        var polls = res[0],
            discussions = res[1];

        for (var i = 0; i < discussions.length; i++) {
          // subscribe to each newly-created discussion
          discussions[i].discussion.subscribed = true; // if there's a poll, attach each poll's id to the
          // respective discussion object

          if (polls) {
            var poll_id = polls[i].poll[0].id;
            discussions[i].discussion.poll_id = poll_id;
          }
        }

        return _this.updateNestedResources(['discussion', 'discussions'], discussions, ['section', 'sections']);
      }); // Once the current section and other sections
      // have successfully posted, clean things up
      // and close the modal.

      Promise.all([currentSectionCreation, otherSectionsCreation]).then(function () {
        // At this point everything is created/posted; clean up the UI
        _this.send('resetPoll');

        var discussionsCtl = _this.discussions;

        _this.set('uploadInProgress', false);

        _this.set('titleInput', '');

        _this.send('clearAllFiles');

        _this.send('destroyEditor');

        _this.set('multiSections', []);

        if (discussionsCtl.send) {
          discussionsCtl.send('closeModals', true);
        }
      }).catch(function (err) {
        // oops you broke it
        Ember.debug(err, 'Posting announcement failed');

        _this.set('uploadInProgress', false);

        _this.set('multiSections', []);

        alert('This message failed to post. Please retry.');
      });
    },
    actions: {
      toggleMultiSectionPicker: function toggleMultiSectionPicker() {
        this.toggleProperty('multiSectionPicker');
      },
      saveDiscussion: function saveDiscussion() {
        /* eslint-disable-next-line ember/no-get */
        var usr = this.get('session.user'),
            title = this.titleInput,
            message = this.parseEmbed(this.bodyInput),
            date = new Date(),
            recObject,
            pollObject,
            pollQuestions = [],
            multiSections = this.multiSections.toArray() || [],
            pollChoices = this.pollChoices.map(function (pc) {
          return pc.get('text');
        }),

        /* eslint-disable-next-line ember/no-get */
        term = this.get('session.course.term');
        /* eslint-disable-next-line ember/no-jquery */

        if (Ember.$.trim(message.replace(/&nbsp;/g, ' ').replace(/<!---->/g, ' ')).length === 0 || Ember.$.trim(title.replace(/&nbsp;/g, ' ').replace(/<!---->/g, ' ')).length === 0) {
          // Text is all whitespace. Don't post.
          return;
        } // Add any video embeds


        message = message + this.videoEmbedString; // Is there a poll?

        if (this.pollStarted) {
          for (var i = 0; i < pollChoices.length; i++) {
            if (pollChoices[i].length > 0) {
              pollQuestions.push({
                text: pollChoices[i],
                is_correct: true,
                position: i + 1
              });
            }
          } // the database's poll description column has a limit of 255 characters


          var description = message.substring(0, 255);

          if (pollQuestions.length < 2) {
            (0, _dialog.default)('Your poll only has one option. Please add more or delete the poll before posting.');
            return;
          }

          pollObject = {
            question: title,
            description: description,
            choices: JSON.stringify(pollQuestions)
          };
          Ember.debug('Creating Poll...');
        }

        if (!this.working && this.file_ids.length > 0) {
          this.set('uploadInProgress', true);
          recObject = {
            user: usr,
            message: message,
            date: date,
            attachments: this.file_ids,
            title: title,
            is_announcement: this.isAnnouncement || this.isAdminAnnouncement,
            is_admin_announcement: this.isAdminAnnouncement,
            admin_announcement_term: term.get('id'),
            subscribed: !(this.isAnnouncement || this.isAdminAnnouncement)
          }; // Send out announcement to any marked sections

          this.postToSection(multiSections, recObject, pollObject);
        } else {
          this.set('uploadInProgress', true);
          recObject = {
            user: usr,
            date: date,
            message: message,
            title: title,
            is_announcement: this.isAnnouncement || this.isAdminAnnouncement,
            is_admin_announcement: this.isAdminAnnouncement,
            admin_announcement_term: term.get('id')
          }; // Send out announcement to any marked sections

          this.postToSection(multiSections, recObject, pollObject);
        }
      },
      toggleAnnouncement: function toggleAnnouncement() {
        this.toggleProperty('isAnnouncement');
      },
      toggleAdminAnnouncement: function toggleAdminAnnouncement() {
        if (!this.isAdminAnnouncement) {
          var c = confirm('This is the global announcement button, your announcement will be sent to all active users in all courses of this term. Are you absolutely sure you want to do this?');

          if (c) {
            this.set('isAdminAnnouncement', true);
          } else {
            this.set('isAdminAnnouncement', false);
          }
        } else {
          this.set('isAdminAnnouncement', false);
        }
      }
    }
  });

  _exports.default = _default;
});