define("bocce/components/side-panel/late-grading-policy", ["exports", "bocce/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.keyupCallback = function (evt) {
        evt = evt || window.event;
        var isEscape = false;

        if ("key" in evt) {
          isEscape = evt.key === "Escape" || evt.key === "Esc";
        } else {
          isEscape = evt.keyCode === 27;
        }

        if (isEscape) {
          _this.closeLateGradingPolicy();
        }
      };

      document.addEventListener('keyup', this.keyupCallback);
    },
    willDestroyElement: function willDestroyElement() {
      document.removeEventListener('keyup', this.keyupCallback);
    },
    lateGradingPolicy: Ember.computed('session', function () {
      return this.get('session.lateGradingPolicy') || _environment.default.APP.text.noLateGradingPolicy;
    })
  });

  _exports.default = _default;
});