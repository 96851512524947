define("bocce/mixins/polls", ["exports", "bocce/utilities/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var descriptionCharacterLimit = 255; // Implement pollChoices as an ArrayProxy of EmberObjects; this simplifies bindings in the template

  /* eslint-disable-next-line ember/no-new-mixins */

  var PollChoice = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('text', '');
    },

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    remainingChars: Ember.computed('text', function () {
      (false && !(typeof this.text === 'string') && Ember.assert('PollChoice.text must be a string', typeof this.text === 'string'));
      /* eslint-disable-next-line ember/no-get */

      return descriptionCharacterLimit - this.get('text.length');
    })
  });
  /* eslint-disable-next-line ember/no-new-mixins */

  var _default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      this._initializePoll();
    },
    pollDrawerOpen: false,
    _initializePoll: function _initializePoll() {
      var pollChoices = [];

      for (var i = 0; i < 2; ++i) {
        pollChoices.push(PollChoice.create());
      }

      this.set('pollChoices', Ember.ArrayProxy.create({
        content: Ember.A(pollChoices)
      }));
    },

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    pollDescriptionLength: Ember.computed.reads('bodyInput.length'),
    remainingDescriptionChars: Ember.computed('pollDescriptionLength', function () {
      // poll description length is limited to 255 characters
      return descriptionCharacterLimit - this.pollDescriptionLength;
    }),
    pollStarted: Ember.computed( // NK: previously depended on text.length, which is invalid because @each only goes
    // one level deep. Since JS strings are immutable, the text value is reassigned
    // any time the input changes, so we can simply depend on .text. Also, doing it
    // this way makes Ember complain about one less thing.
    'pollChoices.@each.text', function () {
      var pollStarted = this.pollChoices.filter(function (item) {
        return item.get('text.length') > 0;
      });
      return pollStarted && pollStarted.get('length') > 0;
    }),
    actions: {
      togglePollsDrawer: function togglePollsDrawer() {
        var _this = this;

        if (!this.pollDrawerOpen) {
          this.set('pollDrawerOpen', true);
        } else {
          (0, _dialog.default)('Are you sure you wish to delete this poll? This action CANNOT be undone!', ['Yes', 'No']).then(function (choice) {
            if (choice === 'Yes') {
              _this.set('pollDrawerOpen', false);

              _this.send('resetPoll');
            }
          });
        }
      },
      addPollEntries: function addPollEntries() {
        this.pollChoices.pushObject(PollChoice.create());
      },
      clearPollChoice: function clearPollChoice(choice) {
        this.pollChoices.removeAt(choice);
      },
      resetPoll: function resetPoll() {
        this._initializePoll();
      },
      destroyEditor: function destroyEditor() {
        this._super.apply(this, arguments);

        this.send('resetPoll');
      }
    }
  });

  _exports.default = _default;
});