define("bocce/controllers/classroom/lessons/event", ["exports", "bocce/mixins/user-profile", "bocce/mixins/enmodal", "bocce/mixins/editable", "bocce/mixins/webex", "bocce/mixins/nested-resources", "bocce/utilities/dialog"], function (_exports, _userProfile, _enmodal, _editable, _webex, _nestedResources, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */
  var _default = Ember.Controller.extend(_enmodal.default, _userProfile.default, _editable.default, _webex.default, _nestedResources.default, {
    classroom: Ember.inject.controller(),
    discussions: Ember.inject.controller(),
    userprofile: Ember.inject.controller(),
    // required by UserProfileMixin
    event: Ember.inject.controller('classroom.lessons.event'),
    // required by WebexMixin
    events: Ember.inject.controller(),
    // required by WebexMixin
    newStudentEvent: Ember.inject.controller('classroom.lessons.student-event-new'),
    // required by WebexMixin
    hasOtherSectionChats: false,
    multiChatConnectionStatus: '',
    waitingForOtherSectionChats: true,
    sendCancelNotification: false,
    cancelRepeating: false,
    cancelAllSections: false,
    cancelEventConfirmedVisible: false,
    editingEventDescription: false,
    description: Ember.computed('model.description', function () {
      /* eslint-disable-next-line ember/no-get */
      var desc = this.get('model.description');

      if (desc && desc.indexOf('The instructor has started a live class!') === 0) {
        return '';
      }

      return desc;
    }),
    future: Ember.computed('model.startAt', 'session.time', function () {
      /* eslint-disable-next-line ember/no-get */
      return moment() < moment(this.get('model.startAt'));
    }),
    archived: Ember.computed('model.endAt', 'session.time', function () {
      /* eslint-disable-next-line ember/no-get */
      return moment() > moment(this.get('model.endAt'));
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    runnable: Ember.computed('model.startAt', 'session.time', function () {
      var now = moment();
      /* eslint-disable-next-line ember/no-get */

      return moment(this.get('model.startAt')) <= now + 10 * 60 * 1000 &&
      /* eslint-disable-next-line ember/no-get */
      moment(this.get('model.endAt')) >= now;
    }),
    // NK: Ideally this would be a computed property, but the current
    // implementation introduces side effects, which is a no-no for
    // computed properties. Rewriting this as an observer for now, but
    // at some point when there's time (hah) this should be refactored to
    // use computed properties, so Ember is happy, which will make us all
    // happy by proxy.

    /* eslint-disable-next-line ember/no-observers */
    otherSectionChatsObserver: Ember.observer('model', function () {
      var _this = this;

      var classroomController = this.classroom,
          sectionCodes = classroomController.get('userSectionCodes') || [],
          currentTermEnrollments = sectionCodes.filter(function (sectionCode) {
        /* eslint-disable-next-line ember/no-get */
        return sectionCode.termLabel === _this.get('session.course.term.name') && sectionCode.courseId !== _this.get('session.course.id');
      }),
          promises = [],
          events = [];
      this.set('waitingForOtherSectionChats', true);
      currentTermEnrollments.forEach(function (currentTermEnrollment) {
        var courseId = currentTermEnrollment.courseId;
        /* eslint-disable-next-line ember/no-jquery */

        promises.push(Ember.$.get("/interface/courses/".concat(courseId, "/events")).then(function (results) {
          var otherSectionChats = results.event,
              filteredOtherSectionChats = [];

          if (otherSectionChats) {
            filteredOtherSectionChats = otherSectionChats.filter(function (otherSectionChat) {
              /* eslint-disable-next-line ember/no-get */
              return otherSectionChat.title === _this.get('model.title') && otherSectionChat.startAt === _this.get('model.startAt');
            });
          }

          for (var i = 0; i < filteredOtherSectionChats.length; i++) {
            filteredOtherSectionChats[i]['sectionId'] = currentTermEnrollment.sectionId;
            filteredOtherSectionChats[i]['courseTitle'] = currentTermEnrollment.courseTitle;
            filteredOtherSectionChats[i]['code'] = currentTermEnrollment.code;
            filteredOtherSectionChats[i]['sectionLabel'] = currentTermEnrollment.sectionLabel;
          }

          events = events.concat(filteredOtherSectionChats);
        }));
      });

      if (promises.length > 0) {
        this.set('otherSectionChats', Promise.all(promises).then(function () {
          _this.set('hasOtherSectionChats', events.length > 0 ? true : false);

          _this.set('otherSectionChatsData', events || []);

          _this.set('waitingForOtherSectionChats', false);

          return Promise.resolve(events || []);
        }));
        return;
      } else {
        this.set('waitingForOtherSectionChats', false);
        this.set('otherSectionChats', Promise.resolve());
      }
    }),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    otherRecurringChats: Ember.computed('model', function () {
      var _this2 = this;

      var otherRecurringChats = this.store.peekAll('event').filter(function (event) {
        // Compare titles, and the starting hours and minutes of the livechats.

        /* eslint-disable-next-line ember/no-get */
        var currentEventStartAt = moment(_this2.get('model.startAt')),
            filteredEventStartAt = moment(event.get('startAt')),

        /* eslint-disable-next-line ember/no-get */
        titlesMatch = event.get('title') === _this2.get('model.title'),
            timesMatch = currentEventStartAt.hours() === filteredEventStartAt.hours() && currentEventStartAt.minutes() === filteredEventStartAt.minutes(),

        /* eslint-disable-next-line ember/no-get */
        isSameEvent = event.get('id') === _this2.get('model.id');

        return titlesMatch && timesMatch && !isSameEvent;
      });
      return otherRecurringChats || [];
    }),
    hasRecurringChats: Ember.computed('otherRecurringChats', function () {
      var otherRecurringChats = this.otherRecurringChats;
      return otherRecurringChats.length > 0;
    }),
    actions: {
      editEventDescription: function editEventDescription(eventDescription) {
        eventDescription = eventDescription || ''; //Show the edit box and save button

        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.event-description-edit').removeClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.event-description-save').removeClass('hidden'); // Hide the description value

        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.event-description').addClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.edit-chat-description-btn').addClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.modal-content .watch-button').addClass('hidden');
        this.set('editingEventDescription', true);
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.floating-modal').removeClass('empty-evt');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.rte-editor-input').html(eventDescription);
      },
      cancelEditEventDescription: function cancelEditEventDescription() {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.event-description-edit').addClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.event-description-save').addClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.event-description').removeClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.edit-chat-description-btn').removeClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.modal-content .watch-button').removeClass('hidden');
        this.set('editingEventDescription', false);
      },
      saveEventDescription: function saveEventDescription() {
        var _this3 = this;

        var thisEvent = this.model,

        /* eslint-disable-next-line ember/no-jquery */
        newDescription = Ember.$('.rte-editor-input').html(),
            promises = [];

        if (!newDescription) {
          alert('Description must not be blank.');
          return;
        }

        if (this.hasOtherSectionChats) {
          var otherChatsData = this.otherSectionChatsData.map(function (chatData) {
            //update the description
            chatData.description = newDescription; // wrap the chat data in an object ready for the request

            return {
              event: chatData
            };
          });
          promises.push(this.updateNestedResources(['event', 'events'], otherChatsData, ['course', 'courses']).then(function () {
            Ember.debug('Successfully updated event in other courses');
          }));
        }

        thisEvent.set('description', newDescription);
        promises.push(thisEvent.save());
        Promise.all(promises).then(function () {
          // Hide the textbox and save button, show the value

          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.event-description-edit').addClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.event-description-save').addClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.event-description').removeClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.edit-chat-description-btn').removeClass('hidden');
          /* eslint-disable-next-line ember/no-jquery */

          Ember.$('.modal-content .watch-button').removeClass('hidden');

          _this3.set('editingEventDescription', false);
          /* eslint-disable-next-line ember/no-jquery */


          Ember.$('.rte-editor-input').text('');
        }).catch(function () {
          (0, _dialog.default)('There was a problem updating the live class description in the other sections. Please update them manually.');
        });
      },
      cancelEvent: function cancelEvent() {
        this.set('cancelEventConfirmedVisible', true);
      },
      cancelEventConfirmed: function cancelEventConfirmed(event_id) {
        // Cancel the event
        // If needed, generate and send out an announcement.
        // If needed, cancel subsequent events in the series
        // If needed, cancel other sections' chats (and other sections' repeating chats)
        var discussionsCtl = this.discussions,
            otherRecurringChats = this.otherRecurringChats,
            that = this;
        this.store.findRecord('event', event_id).then(function (eventObject) {
          var announcementData = {},
              announcement = {},
              sendCancelNotification = that.get('sendCancelNotification'),
              cancelRepeating = that.get('cancelRepeating'),
              cancelAllSections = that.get('cancelAllSections'),
              currentEventStartAt = moment(eventObject.get('startAt')); // start generating announcement if checked (don't send it yet)

          if (sendCancelNotification) {
            if (cancelRepeating && that.get('otherRecurringChats').length > 0) {
              announcementData.title = 'Live classes have been cancelled';
              announcementData.message = 'Several live classes titled \'' + eventObject.get('title') + '\' have been cancelled.<br><br>';
              announcementData.message += 'The cancelled live classes were scheduled for:<br><br>' + currentEventStartAt.format('MMM, D YYYY h:mm A z') + '<br>';
              announcementData.is_announcement = true;
            } else {
              announcementData = {
                message: 'A live class titled \'' + eventObject.get('title') + '\' occuring at ' + currentEventStartAt.format('MMM, D YYYY h:mm A z') + ' has been cancelled.',
                title: 'Live Class Cancelled',
                is_announcement: true
              };
            }
          } // delete repeating chats if checked


          if (cancelRepeating) {
            for (var i = 0; i < otherRecurringChats.length; i++) {
              var recurringEvent = otherRecurringChats[i],
                  recurringEventStartAt = moment(recurringEvent.get('startAt'));

              if (currentEventStartAt < recurringEventStartAt) {
                announcementData.message += recurringEventStartAt.format('MMM, D YYYY h:mm A z') + '<br>';
                recurringEvent.destroyRecord();
              }
            }
          } // delete the other section chats if checked


          if (cancelAllSections) {
            var eventsToDelete = [],
                promises = []; // add the other sections' versions of the current event to the kill list

            for (var _i = 0; _i < that.otherSectionChatsData.length; _i++) {
              eventsToDelete.push({
                course: that.otherSectionChatsData[_i].course,
                event: that.otherSectionChatsData[_i].id
              });
            } // if recurring, get the other sections' subsequent events


            if (cancelRepeating && otherRecurringChats.length > 0) {
              var _currentEventStartAt = moment(that.get('model.startAt')),
                  currentEventTitle = that.get('model.title');

              that.otherSectionChatsData.forEach(function (otherSectionChat) {
                // iterate through each other section where the chat occurs and add the recurring events to the list.
                var courseId = otherSectionChat.course,
                    currentEventId = otherSectionChat.id;
                /* eslint-disable-next-line ember/no-jquery */

                promises.push(Ember.$.get("/interface/courses/".concat(courseId, "/events")).then(Ember.run.bind(function (results) {
                  // For each event in the other section, check if it's an instance of the repeating event.
                  var otherSectionChats = results.event,
                      filteredOtherSectionChats = [];

                  if (otherSectionChats) {
                    filteredOtherSectionChats = otherSectionChats.filter(function (otherSectionChat) {
                      var filteredEventStartAt = moment(otherSectionChat.startAt),
                          titlesMatch = otherSectionChat.title === currentEventTitle,
                          timesMatch = _currentEventStartAt.hours() === filteredEventStartAt.hours() && _currentEventStartAt.minutes() === filteredEventStartAt.minutes(),
                          isSameEvent = otherSectionChat.id === currentEventId;
                      return titlesMatch && timesMatch && !isSameEvent;
                    });
                  } // add the filtered events to the to-delete list.


                  filteredOtherSectionChats.forEach(function (otherChat) {
                    eventsToDelete.push({
                      course: otherChat.course,
                      event: otherChat.id
                    });
                  });
                })));
              });
            } // Once all other sections' event data is collected, delete all the other events.


            Promise.all(promises).then(function () {
              eventsToDelete.forEach(function (event) {
                /* eslint-disable-next-line ember/no-jquery */
                Ember.$.ajax({
                  type: 'DELETE',
                  url: "/interface/courses/".concat(event.course, "/events/").concat(event.event),
                  success: function success() {
                    Ember.debug("Deleted event ".concat(event.event, " in course ").concat(event.course));
                  },
                  error: function error(jqXHR, textStatus, errorThrown) {
                    Ember.debug("Failed to delete event ".concat(event.event, " in course ").concat(event.course, ", error: ").concat(errorThrown));
                    (0, _dialog.default)('There was a problem cancelling the live class in the other sections. Please cancel them manually.');
                  }
                });
              });
            });
          } // send out the announcment if checked


          if (sendCancelNotification) {
            announcement = that.store.createRecord('discussion', announcementData);
            announcement.save(); // send the announcement to the other sections if we're deleting it for them too

            if (cancelAllSections) {
              announcementData = {
                discussion: announcementData
              };

              for (var _i2 = 0; _i2 < that.otherSectionChatsData.length; _i2++) {
                /* eslint-disable-next-line ember/no-jquery */
                Ember.$.ajax({
                  type: 'POST',
                  url: '/interface/sections/' + that.otherSectionChatsData[_i2].sectionId + '/discussions',
                  data: JSON.stringify(announcementData),
                  dataType: 'json',
                  contentType: 'application/json'
                });
              }
            }
          } // FINISH HIM


          eventObject.destroyRecord();
        }); // clean up and close the modal

        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.side-panel').removeClass('on-modal');
        this.set('cancelEventConfirmedVisible', false);

        if (discussionsCtl.send) {
          discussionsCtl.send('closeModals');
        }
      },
      destroyEditor: function destroyEditor() {
        // this gets called from the 'closeModals' function in app/mixins/enmodal.js
        this._super();

        this.set('cancelEventConfirmedVisible', false);
      }
    }
  });

  _exports.default = _default;
});