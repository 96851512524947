define("bocce/models/lessons", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/models/lessons.js
  var _default = _model.default.extend({
    title: (0, _model.attr)('string'),
    number: (0, _model.attr)('number'),
    items: (0, _model.hasMany)('item', {
      async: true
    }),
    course: (0, _model.belongsTo)('course', {
      inverse: 'lessons',
      async: false
    }),
    unlocks_at: (0, _model.attr)('date'),
    locked: Ember.computed('unlocks_at', function () {
      var isLocked = false,
          ulock = this.unlocks_at,
          ulockDate,
          now;

      if (ulock) {
        ulockDate = moment(ulock).tz('America/New_York');
        now = moment(new Date()).tz('America/New_York');

        if (ulockDate > now) {
          isLocked = true;
        }
      }

      return isLocked;
    }),
    nextNumber: Ember.computed('number', function () {
      return this.number + 1;
    }),
    linkClass: Ember.computed('locked', function () {
      var clname = 'std-link';

      if (this.locked) {
        clname = 'locked-link';
      }

      return clname;
    }),
    startsOn: Ember.computed('unlocks_at', function () {
      var unlock = this.unlocks_at;

      if (!unlock) {
        return '';
      }

      return moment(unlock).tz('America/New_York').format('MMM Do');
    }),
    endsOn: Ember.computed('unlocks_at', function () {
      var unlock = this.unlocks_at;

      if (!unlock) {
        return '';
      }

      return moment(unlock).tz('America/New_York').add(6, 'days').format('MMM Do');
    })
  });

  _exports.default = _default;
});