define("bocce/components/rte-input", ["exports", "sanitize-html", "bocce/utilities/dialog"], function (_exports, _sanitizeHtml, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function sanitize(string) {
    string = cleanup(string);
    var sanitizeHtmlOptions = {
      // options for the sanitize-html package. see docs:
      // https://www.npmjs.com/package/sanitize-html#htmlparser2-options
      allowedTags: _sanitizeHtml.default.defaults.allowedTags.concat(['h2', 'u']),
      transformTags: {
        'h1': 'h2',
        // Word's header is h1, but our header is h2
        'a': function a(tagName, attribs) {
          // filter out useless links because apparently the geniuses
          // at Microsoft decided to start including THOSE now
          if (!attribs['href'] || attribs['href'] === '') {
            return false;
          } else {
            return {
              tagName: tagName,
              attribs: {
                href: attribs.href
              }
            };
          }
        }
      }
    };
    return (0, _sanitizeHtml.default)(string, sanitizeHtmlOptions);
  }

  function cleanup(string) {
    var cleaned = string; // convert curly quotes to straight quotes:

    cleaned = cleaned.replace(/[\u2018\u2019]/g, '\'');
    cleaned = cleaned.replace(/[\u201C\u201D]/g, '"'); //get rid of certain strings

    var noNoWords = []; //empty divs or paragraphs

    noNoWords.push(/<(div|p)>( |&nbsp;)<\/(div|p)>/g); //Empty lines (doesn't affect display but HTML looks nicer)

    noNoWords.push(/^[\r\n]/gm); // empty comments

    noNoWords.push(/<!---->/g); //get rid of all noNoWords

    for (var _i = 0, _noNoWords = noNoWords; _i < _noNoWords.length; _i++) {
      var pattern = _noNoWords[_i];
      cleaned = cleaned.replace(pattern, '');
    }

    return cleaned;
  }

  function archiverSaveAnimation() {
    /* eslint-disable-next-line ember/no-jquery */
    var archiver = Ember.$('#archiver');
    archiver.addClass('saving');
    archiver.one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
      archiver.removeClass('saving');
    });
  } // Move the cursor to the end of domNode


  function moveCursorToEnd(domNode) {
    var range = document.createRange();
    range.selectNodeContents(domNode);
    range.collapse(false);
    var selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  } // NK: I'm putting this variable here because it should be treated as
  // a private property, inaccessible from outside of the component.


  var changedFromDOM = false;

  var _default = Ember.Component.extend({
    /*
     * initialization
     */
    classNames: ['rte-input'],
    didInsertElement: function didInsertElement() {
      this.bindRepBox();
    },
    bindRepBox: function bindRepBox() {
      /* eslint-disable-next-line ember/no-jquery */
      var repBox = Ember.$(this.element).find('.rte-editor-input');
      var userInput = this.userInput || '';
      repBox.html(userInput);
      this.set('repBox', repBox);
    },

    /*
     * userInput/repBox value binding functions
     */
    // Two-Way Binding Info (NK)
    //
    // This component relies on a tricky pattern, and the current implementation
    // works but is fragile. Two-way bindings are no longer a feature of Ember
    // Octane, as Ember is increasingly using vanilla Javascript objects.
    // We need to be able to update the userInput value both from inside the
    // component (when the user types something or pastes something in), and from
    // the parent component (e.g. loading in a value if the user is updating a
    // post, or running the sanitizing function on pasted text.)
    //
    // The changedFromDOM boolean is how we distinguish what triggered the change.
    // When userInput is updated from the parent component, we want to update the
    // RTE so the user sees the current content. When it's updated from the DOM
    // (i.e. when the user is typing), we should leave the RTE alone.
    //
    // Since observers don't have timing guarantees, we can't use the old pattern
    // where updateUserInput() would set it, update userInput, and then unset it.
    // Instead, we have updateUserInput() set changedFromDOM, and leave it true.
    // The next time updateRepbox() runs, it will skip updating userInput, and
    // unset changedFromDOM.
    //
    // TODO (NK): Figure out a way to do this _without_ using an observer.
    // Observers are deprecated, and are generally a mess to debug.
    // Watches for changes to userInput and, unless changedFromDOM is
    // set, updates the editor to match

    /* eslint-disable-next-line ember/no-observers */
    updateRepbox: Ember.observer('userInput', function () {
      if (changedFromDOM) {
        changedFromDOM = false;
      } else {
        var newValue = this.userInput;
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$(this.element).find('.rte-editor-input').html(newValue);
      }
    }),
    // Update userInput without triggering the updateRepbox observer
    updateUserInput: function updateUserInput() {
      var repBoxHTML = this.repBox.get(0).innerHTML;
      /* eslint-disable-next-line ember/no-get */

      var userInput = this.get('userInput');

      if (repBoxHTML !== userInput) {
        changedFromDOM = true;
        this.set('userInput', repBoxHTML);
      }
    },
    // Clean repbox HTML after a change from the DOM
    sanitizeRepBox: function sanitizeRepBox() {
      var repBox = this.repBox;
      var newHTML = repBox.html();
      var clean = sanitize(newHTML);
      this.set('userInput', clean);
    },

    /*
     * Misc config/utility
     */
    // used in the template to decide how to display buttons
    mobileUpload: Ember.computed(function () {
      /* eslint-disable-next-line ember/no-jquery */
      return Ember.$.isMobile;
    }),
    // what to display when input is empty
    placeholderVal: Ember.computed('placeholder', function () {
      return this.placeholder || 'Say something (or paste in a cool URL)...';
    }),
    // check cursor location and update formatting buttons to match
    // current styles
    updateButtonsStatus: function updateButtonsStatus() {
      var sel = document.getSelection(),

      /* eslint-disable-next-line ember/no-jquery */
      boldBtn = Ember.$('.fa-bold'),

      /* eslint-disable-next-line ember/no-jquery */
      italicBtn = Ember.$('.fa-italic'),

      /* eslint-disable-next-line ember/no-jquery */
      underlineBtn = Ember.$('.fa-underline'),

      /* eslint-disable-next-line ember/no-jquery */
      ulBtn = Ember.$('.fa-list-ul'),

      /* eslint-disable-next-line ember/no-jquery */
      olBtn = Ember.$('.fa-list-ol'),

      /* eslint-disable-next-line ember/no-jquery */
      hBtn = Ember.$('.fa-header'),

      /* eslint-disable-next-line ember/no-jquery */
      preBtn = Ember.$('.preformatted-button');
      /* eslint-disable-next-line ember/no-jquery */

      if (Ember.$(sel.anchorNode).closest('b, strong').length > 0) {
        boldBtn.addClass('active');
      } else {
        boldBtn.removeClass('active');
      }
      /* eslint-disable-next-line ember/no-jquery */


      if (Ember.$(sel.anchorNode).closest('i').length > 0) {
        italicBtn.addClass('active');
      } else {
        italicBtn.removeClass('active');
      }
      /* eslint-disable-next-line ember/no-jquery */


      if (Ember.$(sel.anchorNode).closest('u').length > 0) {
        underlineBtn.addClass('active');
      } else {
        underlineBtn.removeClass('active');
      }
      /* eslint-disable-next-line ember/no-jquery */


      if (Ember.$(sel.anchorNode).closest('ul').length > 0) {
        ulBtn.addClass('active');
      } else {
        ulBtn.removeClass('active');
      }
      /* eslint-disable-next-line ember/no-jquery */


      if (Ember.$(sel.anchorNode).closest('ol').length > 0) {
        olBtn.addClass('active');
      } else {
        olBtn.removeClass('active');
      }
      /* eslint-disable-next-line ember/no-jquery */


      if (Ember.$(sel.anchorNode).closest('h2').length > 0) {
        hBtn.addClass('active');
      } else {
        hBtn.removeClass('active');
      }
      /* eslint-disable-next-line ember/no-jquery */


      if (Ember.$(sel.anchorNode).closest('pre').length > 0) {
        preBtn.addClass('active');
      } else {
        preBtn.removeClass('active');
      }
    },
    sendUpdateArchive: function sendUpdateArchive() {
      if (this.updateArchive) {
        this.updateArchive();
      }
    },
    actions: {
      // clean up text on blur
      blurHandler: function blurHandler() {
        this.sanitizeRepBox();
      },
      // clean up text and position cursor on paste
      pasteHandler: function pasteHandler() {
        var _this = this;

        var target = event.target; // NK: I don't love using setTimeout here, but this is the best
        // way I've found so far to make sure that this bit of code runs
        // after the normal paste action finishes.

        setTimeout(function () {
          _this.sanitizeRepBox();

          moveCursorToEnd(target);
        });
      },
      mouseOrKeyUpHandler: function mouseOrKeyUpHandler() {
        this.updateUserInput();
        this.updateButtonsStatus();
      },
      formatBlock: function formatBlock(tag) {
        if (event.target.classList.contains('active')) {
          document.execCommand('formatBlock', false, '<div>');
        } else {
          document.execCommand('formatBlock', false, "<".concat(tag, ">"));
        }
      },
      formatInline: function formatInline(tag) {
        // target the 'active'class list now instead of waiting for
        // the user to type or move the cursor (triggering the keyUp
        // event). TODO: Can the 'active' tag come from a computed
        // property/observer instead of doing it this way?
        event.target.classList.toggle('active');
        document.execCommand(tag, false, null);
      },
      insertText: function insertText(text) {
        document.execCommand('insertText', false, text);
      },
      saveArchive: function saveArchive() {
        var _this2 = this;

        var bodyInput = this.userInput;
        archiverSaveAnimation();
        var namedDialog;

        if (localStorage.localDocRunner) {
          // if localDocRunner exists, then we already have a record name
          namedDialog = Promise.resolve({
            recName: localStorage.localDocRunner,
            name: localStorage.localDocRunnerName
          });
        } else {
          // ... otherwise, we need to get it from the user.
          namedDialog = (0, _dialog.default)('Please enter a short title for your draft.', ['Save and Continue', 'Cancel'], '<i><br>Note: These drafts are stored locally. They will ONLY be available on this device.</i>', true).then(function (name) {
            // dialog returns false if the user clicks "cancel"
            if (name === false) {
              return false;
            } // generate recName and assign it to the localStorage object


            var recName = name + '_' + new Date().getTime();
            localStorage.localDocRunner = recName;
            localStorage.localDocRunnerName = name; // return both name and the generated recName

            return {
              name: name,
              recName: recName
            };
          });
        }

        namedDialog.then(function (result) {
          // result === false when user clicks Cancel
          if (!result) {
            return;
          }

          var name = result.name,
              recName = result.recName;
          var myText = {
            isText: true,
            textContent: bodyInput,
            type: 'text',
            name: name,
            recName: recName
          };
          var cas;

          if (localStorage.localDocs) {
            cas = JSON.parse(localStorage.localDocs);
          } else {
            cas = {};
          }

          cas[recName] = myText;
          localStorage.localDocs = JSON.stringify(cas);

          _this2.sendUpdateArchive();
        });
      },
      // NK: The rte-input component and the editable mixin are more
      // tightly coupled than I'd like when it comes to the drafts saving
      // feature, but I don't have time at the moment to untangle them.
      viewArchive: function viewArchive() {
        /* eslint-disable-next-line ember/no-jquery */
        Ember.$('.pop-attachment-drawer').removeClass('hidden');
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.floating-modal.active').addClass('drawer-open');
        this.sendUpdateArchive(); // TODO (NK): We really don't need the runloop to handle this, but I want
        // to minimize the changes I'm making right now. the .video-archive
        // element class list has conditionals in the property list which get
        // updated after calling sendUpdateArchive(). This causes the entire class
        // list to be reset, and we lose any classes we added manually. We should
        // rewrite this so we declaratively apply the class as needed in the
        // template, rather than imperatively changing it via actions.

        Ember.run.schedule('afterRender', function () {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.video-archive').toggleClass('visible');
        });
      }
    }
  });

  _exports.default = _default;
});