define("bocce/models/poll", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/models/poll.js
  var _default = _model.default.extend({
    did_vote: (0, _model.attr)('boolean'),
    created_at: (0, _model.attr)('date'),
    user_id: (0, _model.attr)(),
    total_results: (0, _model.attr)(),
    total_votes: (0, _model.attr)(),
    question: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    choices: (0, _model.attr)(),

    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    total_results_array: Ember.computed('total_results', function () {
      return JSON.parse(this.total_votes);
    }),
    voter_info: Ember.computed('did_vote', function () {
      return JSON.parse(this.did_vote);
    })
  });

  _exports.default = _default;
});