define("bocce/initializers/global-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // from http://brewhouse.io/blog/2015/02/12/ember-vs-angular-authentication
  var _default = {
    name: 'global-session',
    initialize: function initialize(application) {
      // Make the session service available to all routes and controller
      application.inject('route', 'session', 'service:session');
      application.inject('controller', 'session', 'service:session');
      application.inject('adapter', 'session', 'service:session'); // NOTE: If needed, we can also inject the session service on-the-fly to other Ember objects (e.g. Components)
      // See: https://guides.emberjs.com/v2.18.0/applications/services/#toc_accessing-services

      application.inject('route:application', 'login-refresh', 'service:login-refresh');
    }
  };
  _exports.default = _default;
});