define("bocce/helpers/inarray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/helpers/sum.js
  // Check if two provided strings are the same
  var _default = Ember.Helper.helper(function (inp) {
    var first = inp[0],
        second = inp[1];
    return second.indexOf(first) > -1;
  });

  _exports.default = _default;
});