define("bocce/controllers/classroom/lessons/admin", ["exports", "bocce/mixins/enmodal"], function (_exports, _enmodal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */
  var _default = Ember.Controller.extend(_enmodal.default);

  _exports.default = _default;
});