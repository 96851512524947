define("bocce/models/response", ["exports", "@ember-data/model", "bocce/mixins/discussable", "bocce/mixins/attachments-mixin"], function (_exports, _model, _discussable, _attachmentsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/models/response.js

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */
  var _default = _model.default.extend(_discussable.default, _attachmentsMixin.default, {
    user: (0, _model.belongsTo)('user', {
      async: true
    }),
    message: (0, _model.attr)('string'),
    discussion: (0, _model.belongsTo)('discussion', {
      async: true
    }),
    date: (0, _model.attr)('date'),
    attachments: (0, _model.attr)(),
    parent: (0, _model.attr)(),
    read: (0, _model.attr)('boolean'),
    // embededContent: DS.belongsTo('embededContent', {async: true}),
    htmlId: Ember.computed('id', function () {
      return 'disc-response-' + this.id;
    })
  });

  _exports.default = _default;
});