define("bocce/components/buttons/button-generic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['button'],
    click: function click() {
      this.action();
    }
  });

  _exports.default = _default;
});