define("bocce/components/polls/poll-drawer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pop-poll-drawer'],
    actions: {
      addPollEntries: function addPollEntries() {
        this.addPollEntries();
      },
      clearPollChoice: function clearPollChoice(index) {
        this.clearPollChoice(index);
      }
    }
  });

  _exports.default = _default;
});