define("bocce/templates/components/user-portrait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1h0XJWpC",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"image_url\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"img\"],[15,\"alt\",[31,[\"User avatar of \",[34,0,[\"name\"]]]]],[15,\"src\",[31,[[34,0,[\"image_url\"]]]]],[14,\"onerror\",\"this.src='/images/avatar.png'\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"img\"],[15,\"alt\",[31,[\"No avatar available for \",[34,0,[\"name\"]]]]],[14,\"src\",\"front_end/css/style-assets/no-avatar.gif\"],[15,\"alt\",[31,[\"No user avatar available for \",[34,0,[\"name\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[8,\"user-dot\",[],[[\"@user\"],[[34,0]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"user\",\"if\"]}",
    "meta": {
      "moduleName": "bocce/templates/components/user-portrait.hbs"
    }
  });

  _exports.default = _default;
});