define("bocce/helpers/eq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/helpers/eq.js
  // Compare two values in a way that {{#if}} is happy
  var _default = Ember.Helper.helper(function (params) {
    return params[0] === params[1];
  });

  _exports.default = _default;
});