define("bocce/controllers/lobby", ["exports", "bocce/mixins/prefs", "bocce/mixins/menus", "bocce/utilities/logout"], function (_exports, _prefs, _menus, _logout2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-mixins */

  /* eslint-disable-next-line ember/no-mixins */
  var _default = Ember.Controller.extend(_prefs.default, _menus.default, {
    sortedCourses: Ember.computed.sort('model', function (a, b) {
      var as = a.get('start_at'),
          bs = b.get('start_at');

      if (as > bs) {
        return -1;
      } else if (as < bs) {
        return 1;
      } else if (a.get('section') < b.get('section')) {
        return -1;
      } else {
        return 1;
      }
    }),
    current: Ember.computed('sortedCourses', function () {
      var model = this.sortedCourses;
      var current = model.filter(function (itm) {
        return itm.get('current');
      });
      return current;
    }),
    past: Ember.computed('sortedCourses', function () {
      var model = this.sortedCourses;
      var past = model.filter(function (itm) {
        return !itm.get('current');
      });
      return past;
    }),
    loadConversations: function loadConversations() {
      var _this = this;

      var conversationsLoaded = this.conversationsLoaded || false,
          conversationsController = this.conversations;

      if (!conversationsLoaded) {
        return this.store.findAll('conversation').then(function (conversations) {
          conversationsController.set('model', conversations);

          _this.set('conversationsLoaded', true);
        });
      }
    },
    actions: {
      toggleMessagePanel: function toggleMessagePanel(open) {
        if (open) {
          this.set('messagePanelOn', true);
        } else {
          this.set('messagePanelOn', false);
        }
      },
      logout: function logout() {
        (0, _logout2.default)();
      }
    }
  });

  _exports.default = _default;
});