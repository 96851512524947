define("bocce/mixins/user-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable-next-line ember/no-new-mixins */
  var _default = Ember.Mixin.create({
    actions: {
      toggleUserProfile: function toggleUserProfile(id, messageOptionHidden) {
        var posX = window.event ? window.event.pageX : window.mouseX,
            posY = window.event ? window.event.pageY : window.mouseY,
            userId = parseInt(id),
            //Use this ID to fetch other user info
        userprofileController = this.userprofile,
            store = this.store,

        /* eslint-disable-next-line ember/no-jquery */
        $uPro = Ember.$('.user-profile');

        if (!store) {
          /* eslint-disable-next-line ember/no-get */
          store = this.get('targetObject.store');
        }

        if (!userprofileController) {
          /* eslint-disable-next-line ember/no-get */
          userprofileController = this.get('targetObject.userprofile');
        }

        userprofileController.set('activeUser', store.findRecord('user', userId));

        if (window.innerHeight < $uPro.height() + posY) {
          posY = window.innerHeight - $uPro.height() - 44;
        }

        if (window.innerWidth < $uPro.width() + posX) {
          posX = window.innerWidth - $uPro.width() - 44;
        }
        /* eslint-disable-next-line ember/no-jquery */


        Ember.$('.user-profile').addClass('active').css({
          'top': posY,
          'left': posX
        });
        /* eslint-disable-next-line ember/no-jquery */

        Ember.$('.user-profile-mask ').addClass('active');

        if (messageOptionHidden) {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.user-profile .options .pm').addClass('hidden');
        } else {
          /* eslint-disable-next-line ember/no-jquery */
          Ember.$('.user-profile .options .pm').removeClass('hidden');
        }
      }
    }
  });

  _exports.default = _default;
});