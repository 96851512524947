define("bocce/templates/discussion-contents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DoLYfM2E",
    "block": "{\"symbols\":[\"attachment\"],\"statements\":[[10,\"div\"],[14,0,\"bootable-area\"],[12],[2,\"\\n  \"],[2,[35,0,[\"message\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,4],[[35,0,[\"attachments\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"ul\"],[14,0,\"discussion-attachments\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"attachment-icon\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fas fa-paperclip\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" Attachments:\\n    \"],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,0,[\"attachment_objects\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"attachment-preview-box\"],[12],[2,\"\\n          \"],[8,\"attachment-preview\",[],[[\"@attachment\"],[[32,1]]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"discussion-main\"],[12],[2,\"\\n\"],[6,[37,4],[[35,5,[\"course\",\"isPublic\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"button\"],[14,\"role\",\"button\"],[14,\"aria-label\",\"Disabled Open discussion dialog button\"],[14,0,\"reply-to-discussion\"],[12],[2,\"\\n      Participate in Discussion!\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"reply-to-discussion\"],[4,[38,1],[[32,0],\"viewModal\",\"discussion\",[35,0,[\"id\"]]],[[\"bubbles\"],[false]]],[12],[2,\"\\n      Participate in Discussion!\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"discussioncontents\",\"action\",\"-track-array\",\"each\",\"if\",\"session\"]}",
    "meta": {
      "moduleName": "bocce/templates/discussion-contents.hbs"
    }
  });

  _exports.default = _default;
});