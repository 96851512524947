define("bocce/components/expandable-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /* eslint-disable-next-line ember/require-computed-property-dependencies */
    expandable: Ember.computed('elements', 'threshold', function () {
      /* eslint-disable-next-line ember/no-get */
      return this.get('elements.length') > this.threshold;
    }),
    result: Ember.computed('elements', 'threshold', 'expanded', function () {
      if (this.expanded) {
        return this.elements;
      } else {
        var threshold = this.threshold;
        return this.elements.slice(0, threshold);
      }
    })
  });

  _exports.default = _default;
});