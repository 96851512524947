define("bocce/routes/classroom/lessons/conversation", ["exports", "bocce/routes/classroom/lessons/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modalBase.default.extend({
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('uploadInProgress', true); // HOTFIX - Define nesting for the conversation message route in advance

      this.store.nestResources('conversation-message', [{
        'conversation': model.get('id')
      }]); // Update the unread count if the conversation isn't read

      if (!model.get('read')) {
        model.set('workflow_state', 'read');
        model.save();
      }

      var loaded_defer = Ember.RSVP.defer();
      controller.send('loadMoreMessages', loaded_defer);
      loaded_defer.promise.then(function () {
        controller.set('uploadInProgress', false);
        controller.send('startPrivateConversationHeartbeat');
        controller.send('scrollToBottom');
      });
    }
  });

  _exports.default = _default;
});